import React, { useState, useEffect } from 'react'
// import { AboutComp4 } from './AboutComp4'
import AOS from 'aos';
import 'aos/dist/aos.css';


export const Startuphighlights = (props) => {
    const [actBtn, setActBtn] = useState(2);
    const [currTab, setCurrtab] = useState("Company Formation")
    const [tabdata,setTabdata ] = useState('')
    // const [defaltdata,setDefaultdata] = useState(props.data[0].content)
    

    const chageTab = (btnId, comp,data) => {
        setActBtn(btnId);
        setCurrtab(comp)
        setTabdata(data)
    }

    const isActive = (btnId) => {
        return actBtn === btnId ? 'active-btn' : '';
    };



    useEffect(() => {
        AOS.init({
            duration: 2000
        });
    }, []);


    return (
        <>
            <div className={`about-comp2 px-lg-5 px-md-4 px-3 py-lg-5 py-md-4 py-3 `} style={{background:(currTab === "our-comp" || currTab === "cvalue" || currTab === "milestone") ?  "#F7F7FD": currTab === "investor" ? "linear-gradient(102deg, #3360B2 20.11%, #274B8D 96.24%)":""}}>

                <div className="abc-body  ps-lg-3 ps-2">
                    <div className="overf-div">
                        <div className="btns d-flex align-items-center gap-lg-3 gap-2 ">
                        {props?.data && props?.data.map((item,i)=>(
                            <button className={`ps-0 ${isActive(item.id)} ${currTab === "investor" ? "text-white" :""}`} onClick={() => chageTab(item.id, `${item.title}`,`${item.content}` )} style={{fontSize:"15px"}}>{item.title}</button>
                        ))}
                            {/* <button className={`${isActive(2)} ${currTab === "investor" ? "text-white" :""}`} onClick={() => chageTab(2, "journey")}>Our Journey</button> */}
                            {/* <button className={`${isActive(3)} ${currTab === "investor" ? "text-white" :""}`} onClick={() => chageTab(3, "cvalue")}>Core Values</button> */}
                            {/* <button className={`${isActive(4)} ${currTab === "investor" ? "text-white" :""}`} onClick={() => chageTab(4, "milestone")}>Milestone Reached</button> */}
                            {/* <button className={""} style={{color: currTab === "investor" ? "#FFAE00" :"", borderBottom: currTab === "investor" ? "2px solid #FFAE00": "",}} onClick={() => chageTab(5, "investor")}>Our Investors</button> */}
                        </div>
                    </div>

                    {
                        currTab === currTab ?
                            <div className="our-comp d-flex justify-content-between  py-md-4 py-3 p-3"

                            >
                               <p dangerouslySetInnerHTML={{__html:tabdata}}></p>
                            </div>
                             :  null
                    }









                </div>
            </div>
        </>
    )
}
