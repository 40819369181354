import React from 'react'
import "../../style/JoinOurTeam.css"
import { Link } from 'react-router-dom'


export const CurrOpening = () => {
  
    return (
    <>
    <div id="currOpn"  className="curr-opening mx-lg-5 mx-md-4 mx-3 my-lg-5 my-md-4 my-3">
        <div className="main">
            <h3 className='text-center mb-lg-3 mb-md-3 mb-3'>Current Opening</h3>

            <div className="cards d-flex gap-3 flex-wrap justify-content-between">
                <div className="card shadow-sm">
                    <div className="img-div text-center position-relative">
                        <img src="/assets/images/job.png" alt="" />
                        <span>Urgent</span>
                    </div>
                    <div className="card-body px-0">
                        <h5 style={{fontWeight:"600", color:"#002a5b",paddingBottom:"5px", borderBottom:"1px solid #002a5b30"}}>UI/UX Designer</h5>
                        <div className="d-flex justify-content-between align-items-center">
                            <p><strong>Number of Openings:</strong></p>
                            <p>10</p>
                        </div>
                        <div className="d-flex justify-content-between align-items-center">
                              <p><strong>Minimum Experience:</strong></p>
                            <p>2 years</p>
                        </div>
                        <div className="d-flex justify-content-between align-items-center">
                            <p> <strong>Job Location:</strong></p>
                            <p>Delhi</p>
                        </div>
                        <div className="d-flex justify-content-between align-items-center">
                            <p><strong> Part Time / Full Time::</strong></p>
                            <p>Both</p>
                        </div>
                        <div>
                            <p><strong> Skills Required:</strong></p>
                            <span className='big-para'>Lorem ipsum dolor sit amet consectetur adipisicing elit. Quasi dolorum ipsa libero!</span>
                        </div>
                        
                    </div>
                    <Link to = "/job-description"> <button>Apply Now</button></Link>
                    
                </div>
                <div className="card shadow-sm">
                    <div className="img-div text-center position-relative">
                        <img src="/assets/images/job.png" alt="" />
                        <span>Urgent</span>
                    </div>
                    <div className="card-body px-0">
                        <h5 style={{fontWeight:"600", color:"#002a5b",paddingBottom:"5px", borderBottom:"1px solid #002a5b30"}}>Php Developer</h5>
                        <div className="d-flex justify-content-between align-items-center">
                            <p><strong>Number of Openings:</strong></p>
                            <p>10</p>
                        </div>
                        <div className="d-flex justify-content-between align-items-center">
                              <p><strong>Minimum Experience:</strong></p>
                            <p>2 years</p>
                        </div>
                        <div className="d-flex justify-content-between align-items-center">
                            <p> <strong>Job Location:</strong></p>
                            <p>Delhi</p>
                        </div>
                        <div className="d-flex justify-content-between align-items-center">
                            <p><strong> Part Time / Full Time::</strong></p>
                            <p>Both</p>
                        </div>
                        <div>
                            <p><strong> Skills Required:</strong></p>
                            <span className='big-para'>Lorem ipsum dolor sit amet consectetur adipisicing elit. Quasi dolorum ipsa libero!</span>
                        </div>
                        
                    </div>
                    <Link to = "/job-description"> <button>Apply Now</button></Link>
                    
                </div>
                <div className="card shadow-sm">
                    <div className="img-div text-center position-relative">
                        <img src="/assets/images/job.png" alt="" />
                        <span>Urgent</span>
                    </div>
                    <div className="card-body px-0">
                        <h5 style={{fontWeight:"600", color:"#002a5b",paddingBottom:"5px", borderBottom:"1px solid #002a5b30"}}>Digital Marketing</h5>
                        <div className="d-flex justify-content-between align-items-center">
                            <p><strong>Number of Openings:</strong></p>
                            <p>10</p>
                        </div>
                        <div className="d-flex justify-content-between align-items-center">
                              <p><strong>Minimum Experience:</strong></p>
                            <p>2 years</p>
                        </div>
                        <div className="d-flex justify-content-between align-items-center">
                            <p> <strong>Job Location:</strong></p>
                            <p>Delhi</p>
                        </div>
                        <div className="d-flex justify-content-between align-items-center">
                            <p><strong> Part Time / Full Time::</strong></p>
                            <p>Both</p>
                        </div>
                        <div>
                            <p><strong> Skills Required:</strong></p>
                            <span className='big-para'>Lorem ipsum dolor sit amet consectetur adipisicing elit. Quasi dolorum ipsa libero!</span>
                        </div>
                        
                    </div>
                    <Link to = "/job-description"> <button>Apply Now</button></Link>
                    
                </div>
                <div className="card shadow-sm">
                    <div className="img-div text-center position-relative">
                        <img src="/assets/images/job.png" alt="" />
                        <span>Urgent</span>
                    </div>
                    <div className="card-body px-0">
                        <h5 style={{fontWeight:"600", color:"#002a5b",paddingBottom:"5px", borderBottom:"1px solid #002a5b30"}}>UI/UX Designer</h5>
                        <div className="d-flex justify-content-between align-items-center">
                            <p><strong>Number of Openings:</strong></p>
                            <p>10</p>
                        </div>
                        <div className="d-flex justify-content-between align-items-center">
                              <p><strong>Minimum Experience:</strong></p>
                            <p>2 years</p>
                        </div>
                        <div className="d-flex justify-content-between align-items-center">
                            <p> <strong>Job Location:</strong></p>
                            <p>Delhi</p>
                        </div>
                        <div className="d-flex justify-content-between align-items-center">
                            <p><strong> Part Time / Full Time::</strong></p>
                            <p>Both</p>
                        </div>
                        <div>
                            <p><strong> Skills Required:</strong></p>
                            <span className='big-para'>Lorem ipsum dolor sit amet consectetur adipisicing elit. Quasi dolorum ipsa libero!</span>
                        </div>
                        
                    </div>
                    <Link to = "/job-description"> <button>Apply Now</button></Link>
                    
                </div>
                
               
            </div>
        </div>
    </div>
    
    </>
  )
}
