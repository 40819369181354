import React from 'react';
import CountUp from 'react-countup';
import { FaBusinessTime, FaShareSquare } from "react-icons/fa";
import { MdHome, MdMiscellaneousServices, MdProductionQuantityLimits } from "react-icons/md";
import { VscFileSymlinkDirectory } from "react-icons/vsc";
import { Link } from 'react-router-dom';

const ProductsCard = () => {
    return (
        <>
            <div className='product-card_bg'>
                <div className='container pd-cards'>

                    <div className='updated-product-card'>
                        <Link to={'/product-home'}>
                      
                        <div className='p_card'>
                            <div className='p_card_icon'>
                                <div className='left_icon'>
                                    <h3><MdProductionQuantityLimits style={{ fontSize: "25px" }} /></h3>
                                </div>
                               

                            </div>
                            <h2>Product</h2>
                            <p>Discover a variety of products with detailed info and competitive pricing to meet your needs listed from the top Sellers at APNASHAHER.Com</p>
                        </div>
                        </Link>
                        <Link to={'/services-home'}>
                        <div className='p_card'>
                            <div className='p_card_icon'>
                                <div className='left_icon'>
                                    <h3><MdMiscellaneousServices style={{ fontSize: "25px" }} /></h3>
                                </div>
                               

                            </div>
                            <h2>Services</h2>
                            <p>APNASHAHER.Com features a diverse range of professional services designed to meet your unique requirements From expert consulting to specialised solutions.</p>
                        </div>
                        </Link>
                        <Link to={'/directory-home'}>
                        <div className='p_card'>
                            <div className='p_card_icon'>
                                <div className='left_icon'>
                                    <h3><VscFileSymlinkDirectory style={{ fontSize: "25px" }} /></h3>
                                </div>
                               

                            </div>
                            <h2>Directory</h2>
                            <p>Our directory highlights verified sellers offering a variety of products and services to cater to diverse needs. Each seller listing make it easy for you to connect and establish reliable partnerships.</p>
                        </div>
                        </Link>
                        <Link to={'/startup-home'}>
                        <div className='p_card'>
                            <div className='p_card_icon'>
                                <div className='left_icon'>
                                    <h3><FaBusinessTime style={{ fontSize: "25px" }} /></h3>
                                </div>
                               

                            </div>
                            <h2>Startups</h2>
                            <p>APNASHAHER.Com showcases innovative startups across various industries, and it serves as a hub of inspiration, networking, and resources to help build and scale your own startup successfully.</p>
                        </div>
                        </Link>
                    </div>
                    {/* <div className='row'>
                    <div className='col-lg-3 col-md-6 col-sm-6 col-12 my-2 text-start'>
                        <div className='apna-pro-bg' id="hello">
                            <img src="/assets/images/apna-pro-1.png" />
                           
                            <div className='d-flex justify-content-between text-product'>
                            <h6>Products</h6>
                            <h4 className='mt-3'><FaShareSquare /></h4>
                            </div>

                            
                            <p className='apna-pro-pera'>Lorem ipsum dolor sit amet, con ut enium minim , qui rel nostlor sectetur adipiscing elit.</p>
                            <div>
                                <div className='d-flex justify-content-between'>
                                    <div className='counter-part1'>
                                        <CountUp delay={5} start={1000} end={5000} suffix="+" />
                                        <p>Products</p>
                                    </div>
                                    <div className='counter-part1'>
                                        <CountUp delay={5} end={20} suffix="K+" />
                                        <p>Sell</p>
                                    </div>
                                </div>
                                <div className='d-flex justify-content-between'>
                                    <div className='counter-part1'>
                                        <CountUp delay={5} end={500} suffix="+" />
                                        <p>Happy Customers</p>
                                    </div>
                                    <div className='counter-part1'>
                                        <CountUp delay={5} end={100} suffix="K+" />
                                        <p>Clicked</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='col-lg-3 col-md-6 col-sm-6 col-12 my-2 text-start'>
                        <div className='apna-pro-bg' id="hello">
                            <img src="/assets/images/apna-pro-2.png" />
                            <div className='d-flex justify-content-between text-product'>
                            <h6>Services</h6>
                            <h4 className='mt-3'><FaShareSquare /></h4>
                            </div>
                           
                            <p className='apna-pro-pera'>Lorem ipsum dolor sit amet, con ut enium minim , qui rel nostlor sectetur adipiscing elit.</p>
                            <div>
                                <div className='d-flex justify-content-between'>
                                    <div className='counter-part1'>
                                        <CountUp delay={5} start={1000} end={5000} suffix="+" />
                                        <p>Products</p>
                                    </div>
                                    <div className='counter-part1'>
                                        <CountUp delay={5} end={20} suffix="K+" />
                                        <p>Sell</p>
                                    </div>
                                </div>
                                <div className='d-flex justify-content-between'>
                                    <div className='counter-part1'>
                                        <CountUp delay={5} end={500} suffix="+" />
                                        <p>Happy Customers</p>
                                    </div>
                                    <div className='counter-part1'>
                                        <CountUp delay={5} end={100} suffix="K+" />
                                        <p>Clicked</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='col-lg-3 col-md-6 col-sm-6 col-12 my-2 text-start'>
                        <div className='apna-pro-bg' id="hello">
                            <img src="/assets/images/apna-pro-3.png" />
                            <div className='d-flex justify-content-between text-product'>
                            <h6>Directory</h6>
                            <h4 className='mt-3'><FaShareSquare /></h4>
                            </div>
                            
                            <p className='apna-pro-pera'>Lorem ipsum dolor sit amet, con ut enium minim , qui rel nostlor sectetur adipiscing elit.</p>
                            <div>
                                <div className='d-flex justify-content-between'>
                                    <div className='counter-part1'>
                                        <CountUp delay={5} start={1000} end={5000} suffix="+" />
                                        <p>Products</p>
                                    </div>
                                    <div className='counter-part1'>
                                        <CountUp delay={5} end={20} suffix="K+" />
                                        <p>Sell</p>
                                    </div>
                                </div>
                                <div className='d-flex justify-content-between'>
                                    <div className='counter-part1'>
                                        <CountUp delay={5} end={500} suffix="+" />
                                        <p>Happy Customers</p>
                                    </div>
                                    <div className='counter-part1'>
                                        <CountUp delay={5} end={100} suffix="K+" />
                                        <p>Clicked</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='col-lg-3 col-md-6 col-sm-6 col-12 my-2 text-start'>
                        <div className='apna-pro-bg' id="hello">
                            <img src="/assets/images/apna-pro-4.png" />
                           
                            <div className='d-flex justify-content-between text-product'>
                            <h6>Startup</h6>
                            <h4 className='mt-3'><FaShareSquare /></h4>
                            </div>
                            <p className='apna-pro-pera'>Lorem ipsum dolor sit amet, con ut enium minim , qui rel nostlor sectetur adipiscing elit.</p>
                            <div>
                                <div className='d-flex justify-content-between'>
                                    <div className='counter-part1'>
                                        <CountUp delay={5} start={1000} end={5000} suffix="+" />
                                        <p>Products</p>
                                    </div>
                                    <div className='counter-part1'>
                                        <CountUp delay={5} end={20} suffix="K+" />
                                        <p>Sell</p>
                                    </div>
                                </div>
                                <div className='d-flex justify-content-between'>
                                    <div className='counter-part1'>
                                        <CountUp delay={5} end={500} suffix="+" />
                                        <p>Happy Customers</p>
                                    </div>
                                    <div className='counter-part1'>
                                        <CountUp delay={5} end={100} suffix="K+" />
                                        <p>Clicked</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div> */}
                </div>
            </div>
        </>
    )
}

export default ProductsCard;