import axios from 'axios';
import React, { useState, useEffect } from 'react';
import { BASE_URL } from '../../../config';
import home1 from '../../../assets/d1.jpg'
import home2 from '../../../assets/d2.jpg'
import home3 from '../../../assets/h3.jpg'

const EmpowerBussinessdirectory = () => {
  const [allwidget, setAllwidget] = useState([]);
  const [selectedCard, setSelectedCard] = useState(0); // Tracks which card is selected
  const [images, setImages] = useState([
    home3, home2,home1]);

  const widgetData = async () => {
    try {
      const wgtData = await axios.get(BASE_URL + '/homepagewidget');
      setAllwidget(wgtData.data.data);
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    widgetData();
  }, []);

  return (
    <>
    <div className='desktop-view-empowering'>
      <div className="container">
        <div className="empower-bussiness">
          <div
            className="empower-bussiness-content"
           
          >
            <h1>Unlock Global Opportunities for Your Business</h1>

<p>List your business on ApnaShaher.com and connect with buyers and sellers from around the world. Expand your market reach and take your business to new heights!</p>
          </div>
          <div className="empowering-section">
            {/* Top Image Section */}
            <div className="empowering-image-section">
              <img src={images[selectedCard]} alt="Empower" className="mb-1" />
            </div>

            {/* Bottom Card Section */}
            <div className="empowering-bottom-card">
              {[
               
                {
                  title: 'Wide Audience Reach',
                  text:
                    'List your business on ApnaShaher.com and connect with thousands of potential customers. Expand your visibility and increase your chances of making successful business connections.',
                },
                {
                  title: 'Easy-to-Use Platform',
                  text:
                    'Our user-friendly interface makes it simple for you to list your business and manage your profile. Spend less time navigating and more time growing your business!',
                },
                {
                    title: 'Targeted Business Listings',
                    text:
                      'ApnaShaher.com ensures your business is categorized appropriately, helping you attract the right buyers. Showcase your offerings to the most relevant audience for maximum impact.',
                  },
              ].map((card, index) => (
                <div
                  key={index}
                  className={`empowering-card-tab ${
                    selectedCard === index ? 'active-card' : ''
                  }`}
                  style={{
                    background: selectedCard === index ? '#f0f8ff' : '#fff',
                    cursor: 'pointer',
                    padding: '10px',
                    borderRadius: '8px',
                    border: selectedCard === index ? '2px solid #007bff' : '1px solid #ddd',
                    transition: 'background 0.3s, border 0.3s',
                  }}
                  onClick={() => setSelectedCard(index)}
                >
                  <h4>{card.title}</h4>
                  <p>{card.text}</p>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
      </div>
      <div className='mobile-view-empowering'>
                <div className="container">
                    <div
                        className="empower-bussiness-content"
                        dangerouslySetInnerHTML={{ __html: allwidget.widget5 }}
                    ></div>
                    <div className='emp-mobile'>

                        {[

                            
                            {
                                img:home1,
                                title: 'Wide Audience Reach',
                                text:
                                  'List your business on ApnaShaher.com and connect with thousands of potential customers. Expand your visibility and increase your chances of making successful business connections.',
                              },
                              {
                                img:home2,
                                title: 'Easy-to-Use Platform',
                                text:
                                  'Our user-friendly interface makes it simple for you to list your business and manage your profile. Spend less time navigating and more time growing your business!',
                              },
                              {
                                img:home3,
                                  title: 'Targeted Business Listings',
                                  text:
                                    'ApnaShaher.com ensures your business is categorized appropriately, helping you attract the right buyers. Showcase your offerings to the most relevant audience for maximum impact.',
                                },
                        ].map((e, i) => (
                            <div className='emp-mobile-card'>
                                <img src={e.img} />
                                <h6 className='m-0'>{e.title}</h6>
                                <p>{e.text}</p>
                            </div>
                        ))}



                    </div>
                </div>
            </div>
      
    </>
  );
};

export default EmpowerBussinessdirectory;
