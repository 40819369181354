import React, { useContext, useState, useEffect } from 'react';
import { NavLink } from 'react-router-dom';
import Loader from './loader/Loader.component';
import axios from 'axios';
import { BASE_URL } from '../config';
import parse from 'html-react-parser';
import { IoIosStar } from 'react-icons/io';
const TruncHtml = require('trunc-html');

const ClientSection = ({ isLoading, heading }) => {
  const [image_base_url, setimage_base_url] = useState('')
  const [data, setdata] = useState('');
  const [datas, setdatas] = useState('');

  useEffect(() => {
    getclientdata();
    const timer = setTimeout(() => {
      getclientdata();
    }, 3000);
    // return () => clearTimeout(timer);
  }, []);
  const getclientdata = () => {
    axios.get(BASE_URL + '/getseller').then(({ data }) => {
      setdata(data.data);
      setdatas(data.datas);
      setimage_base_url(data.image_base_url);
    }).catch(({ response }) => {

    })
  }
  console.log("cliets data", datas);
  // const { clients } = useContext(StateContext);
  return (
    <>
      {/* <div className="container-fluid">
        <div className="feature-product-blk">
          <div className="feature-blkpro-header">
            <h4>{heading}</h4>
          </div>
          <div className="row">{isLoading ? <Loader /> :
            <div className="clients-section">

              <div className="our-clients-cols ">
                {data && data.map((item, i) => (
                  <div className="" key={i} style={{ borderRadius: "3px", overflow: "hidden", border: "1px solid #8080801f" }}>
                    <img height={80} width={160} src={item.logo ? image_base_url + item.logo : "./assets/images/image-27.png"} alt="" />
                    <h3 style={{ fontSize: "14px", textAlign: "center" }}>{item.name}</h3>
                  </div>
                ))}
              </div>
            </div>
          }
          </div>
        </div>
      </div> */}
      <div className=''>
        <div className='container'>
          <div className="feature-blkpro-header mt-5">
            <h4>Our Top Rated & Trusted Sellers</h4>
          </div>
        </div>
        <div className='preferd-seller-tab'>
          <h6 style={{borderBottom:'2px solid #1351c1'}}>Preferred Sellers</h6>
          <h6>Trusted Sellers</h6>
          <h6>Verified Sellers</h6>
          <h6>Global Sellers</h6>

        </div>
        <div className='clients-info-section'>
          {datas && datas.map((item, i) => (
            <div className='blog-grid-block mobile-blogs shadow-sm' key={i}>
              <NavLink className="client-read-more" to={"/seller-detail/" + item.slug}><img height={200} width={300} src={item.logo ? image_base_url + item.logo : "./assets/images/t4.jpg"} alt="" /></NavLink>
              <div className='popular-prefered'>
                <h3>Popular</h3>
                </div>
              <div>
                <h3>{item.name}</h3>
                 <h4><IoIosStar style={{marginTop:"-4px"}}/> <IoIosStar style={{marginTop:"-4px"}}/> <IoIosStar style={{marginTop:"-4px"}}/> <IoIosStar style={{marginTop:"-4px"}}/> <IoIosStar style={{marginTop:"-4px"}}/><span>(2 Reviews)</span></h4>
                 <div className='clientle-agreement'>
                
                 <img src="/assets/images/trustedseal.png"/>
                 <img src="/assets/images/indian-22.png"/>
                 <img src="/assets/images/verified13.png"/>
                 
                  </div>
                {/* <div className='blog-grid-block-content' dangerouslySetInnerHTML={{ __html: TruncHtml(item.comp_intr, 150).html }}></div> */}
                {/* <NavLink className="client-read-more" to={"/seller-detail/" + item.slug}>Read More</NavLink> */}
              </div>
            </div>
          ))}
        </div>
      </div>
    </>
  );
};

export default ClientSection;
