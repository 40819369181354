import React, { useEffect, useState,useContext } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import axios from "axios";
import { BASE_URL, IMAGE_BASE_URL } from "../../../config";
import StateContext from "../../../context/StateContext";
import DispatchContext from "../../../context/DispatchContext";



export const MobileFooter = () => {
  const appState = useContext(StateContext);
  const appDispatch = useContext(DispatchContext);
  const [seller, setseller] = useState("");
  const [ban, setban] = useState(1);
  const [logged, setLogged] = useState(null);
  const [servicedata, setservicedata] = useState([]);
  const pageLocation = window.location.pathname;
const navigate = useNavigate();
useEffect(() => {
  pageLocation === "/dashboard"
    ? setLogged(1)
    : pageLocation === "/vendor-dashboard"
    ? setLogged(2)
    : setLogged(null);

}, [pageLocation]);
useEffect(()=>{
getheader();
},[appState?.state?.user?.token])
const getheader = () => {
  if (appState?.state?.user?.token) {
    axios
      .get(BASE_URL + "/get-seller-detail", {
        headers: {
          Accept: "application/json",
          Authorization: "Bearer " + appState?.state?.user?.token,
        },
      })
      .then(({ data }) => {
        setseller(data.data);
        setban(data.user);
        if (data.user == 1) {
          setLogged(1);
        } else if (data.user == 0) {
          setLogged(2);
        } else {
          setLogged(null);
        }
      })
      .catch(({ response }) => {
        if (response.status === 401) {
          handleLogOut();
          console.log("------log2", response);
        }
      });
  }
};
async function handleLogOut() {
  // e.preventDefault();
  try {
    await axios.get(BASE_URL + "/log-out", {
      headers: {
        Authorization: `Bearer ${appState.state.user.token}`,
      },
    });
  } catch (error) {
    // console.log('error encountered');
  }
  navigate("/");
  appDispatch({ type: "logout" });
  localStorage.removeItem("avonToken");
  localStorage.removeItem("avonName");
  localStorage.removeItem("avonEmail");
  localStorage.removeItem("avonMobileNumber");
  localStorage.removeItem("avonProfileImage");
  localStorage.removeItem("avonLastLogin");
  setLogged(null);
}
  const openSidebar = () => {
    const sbar = document.querySelector(".msbar-parent");
    sbar.style.left = "0";
    // document.querySelector(".bg-color").style.display = "block";
  };
  const closeMenu = () => {
    const sbar = document.querySelector(".msbar-parent");
    sbar.style.left = "-100%";
    // document.querySelector(".bg-color").style.display = "none";
  };
  // service category
  const getservices = () => {
    axios
      .get(BASE_URL + "/servicecategoryheader")
      .then(({ data }) => {
        setservicedata(data.data);
      })
      .catch(({ response }) => {});
  };

  useEffect(() => {
    getservices();

  },[])
  return (
    <>
      <div className="mobile-footer">
        <div className="container bg-white">
          <div className="main-contnent d-flex  pt-1 bg-white w-100">
            <div style={{width:"15%"}}>
              <Link to="/">
                <img src="/assets/Icons/products-icon.png" alt="Locker" width = "21px" height = "21px" />
              </Link>
              <p>Home</p>
            </div>
            <div style={{width:"25%"}}>
              <Link to="/seller-benefits">
                <img src="/assets/Icons/service-icon.png" alt="icon" width = "21px" height = "21px" />
              </Link>
              <p style={{whiteSpace:"nowrap"}}>List Now</p>
            </div>

            <div className="home position-relative" style={{width:"20%"}}>
              <Link to="/">
                <div>
                  <img src="/assets/Icons/home-icon.png" alt="" width="23px" height="23px"/>
                </div>
              </Link>

              <p className="mt-5 mb-0 pt-2">Option</p>
            </div>

            <div style={{width:"25%"}}>
              <Link to="/post-buy-requirement">
                <img src="/assets/Icons/directory-icon.png" alt="" width = "21px" height = "21px" />
              </Link>
              <p style={{whiteSpace:"nowrap"}}>Request Quote</p>
            </div>
            <div className="more" onClick={openSidebar} style={{width:"15%"}}>
              <Link to="#">
                <div className="d-flex gap-1 my-2 justify-content-center">
                  <span></span>
                  <span></span>
                  <span></span>
                </div>
              </Link>
              <p>Account</p>
            </div>
          </div>
        </div>
      </div>
      <div className="msbar-parent">
        <div className="mobile-sidebar">
          <div className="sb-header">
            <div className="d-flex justify-content-between">
              <div className="user d-flex gap-3 align-items-center">
                {appState?.state?.user?.token ?(
                  <>
                   <img
                  src={appState?.state?.user?.profileImage}
                  alt=""
                  className="rounded-circle"
                  width="55px"
                  height="55px"
                />
                <div className="d-flex flex-column gap-1">
                  <h3>{appState?.state?.user?.name}</h3>
                  <Link to="/dashboard" onClick={closeMenu}>My Account</Link>
                </div>
                  
                  </>
                ):(
              <div className="d-flex flex-column gap-1">
                  
                  <Link to="/login" onClick={closeMenu}>
                      
                      Login / Signup
                    </Link>
                </div>

                )}
                
                
              </div>
              <div className="close">
                <button onClick={closeMenu}>
                  <img src="/assets/Icons/close-icon.png" alt="Close" />
                </button>
              </div>
            </div>

            {/* Ship to dropdown  ----- */}
            <div class="dropdown mt-2 ship-to">
              <button
                class="btn dropdown-toggle ps-0 border-0"
                type="button"
                id="dropdownMenuButton1"
                data-bs-toggle="dropdown"
                aria-expanded="false"
              >
                Ship to : <img src="/assets/images/flag-new.png" alt="" />
              </button>
              <ul
                className="dropdown-menu px-3"
                aria-labelledby="dropdownMenuButton1"
              >
                <h6 className="p-0 mt-2 mb-3">Specify your location</h6>
                <select class="form-select" aria-label="Default select example">
                  <option selected>Select Your location</option>
                  <option value="1">
                    {" "}
                    India{" "}
                    <img src="/assets/images/flag-new.png" className="ml-1" />
                  </option>
                  <option value="2">America</option>
                  <option value="3">UAE</option>
                </select>

                <div className="or-block2 my-2">
                  <span>OR</span>
                </div>
                <input
                  className=""
                  type="text"
                  placeholder="Enter City Name to Search"
                />
                <button className="save-btn w-100 py-1 my-2">Save</button>
              </ul>
            </div>
          </div>

          <div className=" my-4 sb-hero">
            <p>
              Your business is just one <span>Click</span> away from getting
              global exposure.
            </p>
              {appState?.state?.user?.token ?(
               
            <button className="sell-btn  px-5 w-100" onClick={()=>{navigate('/vendor-dashboard');closeMenu()}}>{seller ? "Business Dashboard" : "Sell on Apnashaher"}</button>

              ):(
                <button className="sell-btn  px-5 w-100" onClick={()=>{navigate('/seller-benefits');closeMenu()}}>Sell on Apnashaher</button>

              )}
          </div>

        

         
        </div>
      </div>
    </>
  );
};
