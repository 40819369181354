import React, { useState, useEffect } from 'react'
import { AboutComp4 } from './AboutComp4'
import AOS from 'aos';
import 'aos/dist/aos.css';
import { Link } from 'react-router-dom';


export const AboutComp2 = () => {
    const [actBtn, setActBtn] = useState(1);
    const [currTab, setCurrtab] = useState("our-comp")

    const chageTab = (btnId, comp) => {
        setActBtn(btnId);
        setCurrtab(comp)
    }

    const isActive = (btnId) => {
        return actBtn === btnId ? 'active-btn' : '';
    };



    useEffect(() => {
        AOS.init({
            duration: 2000
        });
    }, []);


    return (
        <>
            <div className={`about-comp2 px-lg-5 px-md-4 px-3 py-lg-5 py-md-4 py-3 `} style={{ background: (currTab === "our-comp" || currTab === "cvalue" || currTab === "milestone"||currTab === "journey"|| currTab === "history"|| currTab === "investortab") ? "#F7F7FD" : currTab === "investor" ? "linear-gradient(102deg, #3360B2 20.11%, #274B8D 96.24%)" : "" }}>

                <div className="abc-body  ps-lg-3 ps-2" style={{minHeight:"300px"}}>
                    <div className="overf-div">
                        <div className="btns d-flex align-items-center gap-lg-3 gap-2 ">
                            <button className={`ps-0 ${isActive(1)} ${currTab === "investor" ? "text-white" : ""}`} onClick={() => chageTab(1, "our-comp")}>Our Company</button>
                            <button className={`${isActive(2)} ${currTab === "investor" ? "text-white" : ""}`} onClick={() => chageTab(2, "history")}>Our History</button>
                            <button className={`${isActive(3)} ${currTab === "investor" ? "text-white" : ""}`} onClick={() => chageTab(3, "journey")}>Our Journey</button>
                            <button className={`${isActive(4)} ${currTab === "investor" ? "text-white" : ""}`} onClick={() => chageTab(4, "cvalue")}>Core Values</button>
                            <button className={`${isActive(5)} ${currTab === "investor" ? "text-white" : ""}`} onClick={() => chageTab(5, "milestone")}>Milestone Reached</button>
                            <button className={`${isActive(6)} ${currTab === "investor" ? "text-white" : ""}`} onClick={() => chageTab(6, "investortab")}>Our Investors</button>
                        </div>
                    </div>

                    {
                        currTab === "our-comp" ?
                            <div className="our-comp d-flex justify-content-between  py-md-4 py-3 p-3"

                            >
                                <div className="left" data-aos="fade-left">
                                    <h5>Connecting Businesses Worldwide</h5>
                                    {/* <h6>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut </h6> */}
                                    <p>The ApnaShaher.com Vision
                                        The dynamic nature of the business sector requires an adaptable platform, and ApnaShaher.com
                                        provides just that. Everyone from wholesalers to retailers to service providers to educational
                                        institutions to startups is accepted here, and we're dedicated to being a one-stop shop for all of
                                        your company's product and service needs. Our primary objective is to help businesses be
                                        recognised globally by connecting them to potential clients worldwide and creating a setting
                                        where worthwhile companies can flourish. Here at ApnaShaher.com, we've devoted ourselves to
                                        helping businesses of all shapes and sizes achieve global recognition.</p>
                                    <p>Businesses in today's market have many hurdles; our platform strives to help you solve these
                                        obstacles and embrace new possibilities.</p>
                                </div>
                                <div className="right">
                                    <img src="/assets/images/about-2.png" alt="" />
                                </div>
                            </div>
                            :
                            currTab === "history" ?
                                <div className="" data-aos="fade-left" style={{padding:"20px"}}>
                                    <p> Since beginning in 2008 as an aspirational concept, ApnaShaher.com has developed into a
                                        formidable rival in the digital directory industry. We set out in 2009 to make our goal come true
                                        by creating an ecosystem that would transform the way companies participate with one
                                        another. Our ambitious initiative began in the ancient city of Lucknow, famous as the City of
                                        Nawabs. It marked the starting point of a fresh chapter in our story.</p>
                                    <p>
                                        Our platform had grown by 2010 and was accessible in major Uttar Pradesh cities, including
                                        Kanpur, Agra, Allahabad, Varanasi, and Bareilly. In the following years, ApnaShaher.com grew
                                        into a flourishing business directory by 2012, altering the trajectory of hundreds of vendors&#39;
                                        livelihoods for the better as they joined our growing community. At this point, we reached a
                                        major milestone, we had grown into a reliable platform where businesses could interact,
                                        showcase their products, and form productive collaborations. Our steadfast dedication to
                                        developing worldwide commercial connections while assisting companies in all kinds of sectors
                                        to prosper keeps us going, and the groundwork we set during those early years makes
                                        ApnaShaher.com what it is today.</p>
                                    <p>
                                        In the following years, ApnaShaher.com set out to revolutionise the business landscape by
                                        broadening its scope beyond national boundaries and by 2022, we have achieved it. As part of
                                        our inclusive strategy, we invited educational institutions, service providers, entrepreneurs,
                                        exporters, importers, and manufacturers to become part of the vast ApnaShaher.com family.
                                        Our obvious goal was to position ourselves as a key player on the international stage, committed
                                        to connecting companies from all over the globe. Because of our unwavering dedication to
                                        fostering global connections, ApnaShaher.com has become the go-to site for businesses seeking
                                        to widen their reach internationally and build successful partnerships across industries.</p>
                                    <p>
                                        Launched with great delight in 2025, ApnaShaher.com has a solid mission: to unite millions of
                                        consumers and merchants worldwide. At its core, our objective is to promote global connections
                                        and drive business growth. If you own a business and want to broaden your reach and benefit
                                        from unparalleled chances in the constantly shifting global economy, ApnaShaher.com is ready
                                        to help.</p>
                                </div>
                                :
                                currTab === "journey" ?
                                    <div className="our-journey d-flex justify-content-center" >
                                        <img src="/assets/images/our-journey.png" alt="" width={"80%"} />
                                    </div>
                                    :
                                    currTab === "cvalue" ?

                                        <div className="core-value py-md-4 py-3 p-3" >
                                            {/* <h4 className='mb-3'>Lorem ipsum dolor sit amet, consectetur adipiscing elit</h4> */}
                                            <div className="cards d-flex flex-wrap jutify-content-between gap-3 my-lg-4 my-3">
                                                <div className="card border-0" data-aos="">
                                                    <img src="/assets/icons/customer-obs.png" alt="" />
                                                    <div className="card-body ps-0">
                                                        <h5 className="title">
                                                        Integrity
                                                        </h5>
                                                        <p>We are committed to maintaining transparency and honesty in all our dealings. Our platform ensures that both buyers and sellers can trust the information shared.</p>
                                                    </div>
                                                </div>
                                                <div className="card border-0" >
                                                    <img src="/assets/icons/collaborate.png" alt="" style={{ background: "#FF7474" }} />
                                                    <div className="card-body ps-0">
                                                        <h5 className="title">
                                                        Innovation
                                                        </h5>
                                                        <p>ApnaShaher.com constantly evolves to offer cutting-edge solutions for businesses. We strive to provide the best tools and features to empower startups and enterprises alike.</p>
                                                    </div>
                                                </div>
                                                <div className="card border-0" >
                                                    <img src="/assets/icons/excellence.png" alt="" style={{ background: "#0D2CD2" }} />
                                                    <div className="card-body ps-0">
                                                        <h5 className="title">
                                                        Connection
                                                        </h5>
                                                        <p>We focus on building strong connections between businesses and customers. By bringing buyers and sellers together, we create valuable networking opportunities.</p>
                                                    </div>
                                                </div>
                                                <div className="card border-0" >
                                                    <img src="/assets/icons/integrity.png" alt="" style={{ background: "#BD5DD5" }} />
                                                    <div className="card-body ps-0">
                                                        <h5 className="title">
                                                        Support
                                                        </h5>
                                                        <p>We provide continuous support to help businesses grow and succeed. Our team is always available to assist with any challenges you may encounter on the platform.</p>
                                                    </div>
                                                </div>
                                                <div className="card border-0" >
                                                    <img src="/assets/icons/mdeday.png" alt="" style={{ background: "#FF8B2A" }} />
                                                    <div className="card-body ps-0">
                                                        <h5 className="title">
                                                        Collaboration
                                                        </h5>
                                                        <p>We believe in the power of working together. ApnaShaher.com fosters a collaborative environment where businesses, entrepreneurs, and customers can interact, share knowledge, and create new opportunities for mutual growth.</p>
                                                    </div>
                                                </div>
                                                <div className="card border-0" >
                                                    <img src="/assets/icons/ownership.png" alt="" style={{ background: "#53C2D2" }} />
                                                    <div className="card-body ps-0">
                                                        <h5 className="title">
                                                        Sustainability
                                                        </h5>
                                                        <p>We are committed to sustainable business practices. ApnaShaher.com encourages eco-friendly and ethical business solutions, supporting enterprises that prioritize sustainability in their operations for a better future.</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        :
                                        currTab === "milestone" ?
                                            <div className="milestone  pb-md-4 pb-3 mt-5">
                                                <h1 className='text-center'>Comming Soon... </h1>
                                            </div>
                                            :
                                            currTab === "investortab" ?

                                                <div className="milestone  pb-md-4 pb-3 mt-5" >
                                                    <h1 className='text-center'>To give this business directory a global exposure we're looking for Investors to be a Part of APNASHAHER.Com</h1>
                                                    <div className="startup-deal-btn" style={{width:"100%", justifyContent:"center"}}>
                                                                                                
                                                                                                <Link to={'/'}>
                                                                                                <button className="raise-capital" style={{margin:"auto"}}>Invest Now</button>
                                                                                                </Link>
                                                                                                
                                                                            
                                                                                            </div>
                                                    {/* <AboutComp4 para="none" mbottom={"mb-3"} twhite={"white"} /> */}
                                                </div>
                                                : null
                    }









                </div>
            </div>
        </>
    )
}
