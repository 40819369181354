import React, { useContext, useState, useEffect } from "react";
import { Accordion, Form } from 'react-bootstrap';
import SidebarFilterAttributeContainer from './sidebar-filter-attributes-container.component';
import SidebarFilterCategoryItem from './sidebar-filter-category-item.component';
import Drawer from 'react-modern-drawer';
import 'react-modern-drawer/dist/index.css'
import axios from "axios";
import { BASE_URL } from "../../config";
import { IoIosArrowForward } from "react-icons/io";
import { RiFilterLine } from "react-icons/ri";
import { Link, useParams } from "react-router-dom";
import { BiCategory } from "react-icons/bi";
import { CiFilter } from "react-icons/ci";
import { MdOutlineSearch } from "react-icons/md";
import { FaSortAmountDownAlt } from "react-icons/fa";


const SidebarFilterContainer = ({ categories, attributes, categoryIds, setCategoryIds, attributeIds, setAttributeIds }) => {
  const [isOpen, setIsOpen] = React.useState(false)
  const [childCategories, setChildCategories] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState(null); // Track selected category
  const [parentCategory, setParentCategory] = useState(null);
  const [singlefilter,setSinglefilter] = useState('');
  const [singlefilterdata,setSinglefilterdata] = useState('');
  const { slug } = useParams();
  const toggleDrawer = () => {
    setIsOpen((prevState) => !prevState)
  }
  const [categoriesdata, setCategoriesdata] = useState([]);
  const [loading, setloading] = useState(true)


  useEffect(() => {
    if (slug) {
      FetchCategories(slug);
    }
  }, [slug]);
  
  const FetchCategories = async (slug) => {
    setloading(true);
    try {
      const { data } = await axios.get(BASE_URL + "/getcategoriesbytype/products-page");
  
      // Slug ke basis pe data filter karna
      const filteredData = data.data.filter(item => item.slug === slug);
  
      console.log("Filter data:", filteredData);
  
      if (filteredData.length > 0) {
        setSinglefilter(filteredData[0]);
        setSinglefilterdata(filteredData[0].childrens || []);
      } else {
        setSinglefilter(null);
        setSinglefilterdata([]);
      }
    } catch (error) {
      console.error("Error fetching categories:", error);
    } finally {
      setloading(false); // Loading state false karna
    }
  };
  

  const handleCategoryClick = (category) => {
    setSelectedCategory(category.slug);
    setParentCategory(category.parent); // Store parent category
    setChildCategories(category.childrens); // Store children data
  };
  const [openfilter,setOpenfilter] = useState(false)
  const handleopenfilter =()=>{
    setOpenfilter(true)
  }
  return (
    <div>
      <div className="sidebar-block hide-in-mobile hide-on-768">
        <div className="side-head-blk mb-1" onClick={()=>handleopenfilter()}>
          <h4 ><RiFilterLine style={{ marginTop: "-6px" }} /> Filter By</h4>

          <h4><IoIosArrowForward /></h4>

        </div>
        {openfilter==true?
        <div className="side-accordian">
        
          <Accordion defaultActiveKey="0" className="" >
          {attributes.map((attribute) => (
              <SidebarFilterAttributeContainer key={attribute.id} attribute={attribute} attributeIds={attributeIds} setAttributeIds={setAttributeIds} />
            ))}
            <Accordion.Item eventKey="1">
              <Accordion.Header>Price</Accordion.Header>
              <Accordion.Body>
                <Form.Label>&#8377; 0 - &#8377; 1,00,000</Form.Label>
                <Form.Range
                  min="0"
                  max="100"
                  step="1"

                />

              </Accordion.Body>
            </Accordion.Item>
          </Accordion>
        </div>:null}
        <div className="side-head-blk mb-1 mt-3">
          <h4 ><BiCategory  style={{ marginTop: "-5px", marginRight:"5px" }} />All Categories</h4>

          <h4><IoIosArrowForward /></h4>

        </div>
        <div className="side-accordian">
          <Accordion defaultActiveKey="0" >

            {categoriesdata && categoriesdata.map((e, index) => (
              <Accordion.Item key={e.id} eventKey={e.id}>
                <Accordion.Header>{e.name}</Accordion.Header>
                <Accordion.Body>
                  <Form>
                    {e.childrens.map((category) => (
                      <div className="form-group form-check customcheckbox">

                        <Link to={`/category/${category.slug}`} style={{
                          textDecoration: "none",
                          color:
                            selectedCategory === category.slug
                              ? "blue"
                              : "#000", // Highlight selected category
                        }}
                          onClick={() => handleCategoryClick(category)}>
                          {/* <input type="checkbox" className="form-check-input" id={category.slug} onChange={(e) => setChecked(e)} value={category.id} /> */}
                          <label className="form-check-label" >
                            {category.name}
                            <span>({category.product_count})</span>
                          </label>
                        </Link>
                      </div>
                      // <SidebarFilterCategoryItem
                      //   key={category.id}
                      //   category={category}
                      //   categoryIds={categoryIds}
                      //   setCategoryIds={setCategoryIds}
                      // />
                    ))}
                  </Form>
                </Accordion.Body>
              </Accordion.Item>
            ))}

          </Accordion>

        
        </div>
      </div>
      <div className="sidebar-block section-768">
        <div className="side-head-blk">FILTER BY</div>
        <div className="side-accordian">
          <Accordion defaultActiveKey="0">
            <Accordion.Item eventKey="0">
              <Accordion.Header>Category</Accordion.Header>
              <Accordion.Body>
                <Form>
                  {categories.map((category) => (
                    <SidebarFilterCategoryItem key={category.id} category={category} categoryIds={categoryIds} setCategoryIds={setCategoryIds} />
                  ))}
                </Form>
              </Accordion.Body>
            </Accordion.Item>
            {attributes.map((attribute) => (
              <SidebarFilterAttributeContainer key={attribute.id} attribute={attribute} attributeIds={attributeIds} setAttributeIds={setAttributeIds} />
            ))}
            <Accordion.Item eventKey="1">
              <Accordion.Header>Price</Accordion.Header>
              <Accordion.Body>
                <Form.Label>&#8377; 0 - &#8377; 1,00,000</Form.Label>
                <Form.Range
                  min="0"
                  max="100"
                  step="1"
                // value={selectedRange}
                // onChange={handleRangeChange}
                />
                {/* {priceData.map((price) => 
               <div className='priceVal' key={price.id}>
                 {price.value}
               </div>
              )}     */}
              </Accordion.Body>
            </Accordion.Item>
          </Accordion>
        </div>
      </div>


      <div className="hide-in-desktop hide-on-768">
        {/* <div className="side-head-blk" onClick={toggleDrawer} style={{ marginBottom: "20px" }}>FILTER BY</div> */}
       
        <div class="filter-card-letest mb-3">
                         <div className="filter--sorting-card ">
                           <div className="fil-btn-name " onClick={toggleDrawer}>
                             <h6 className="m-0"><CiFilter style={{ marginTop: "-4px" }} /> Filter By</h6>
       
                           </div>
                           <div className="fil-btn-name1">
                             <h6 className="m-0"><MdOutlineSearch style={{ marginTop: "-4px" }}  /> Search</h6>
       
                           </div>
                           <div className="fil-btn-name-right" data-bs-toggle="offcanvas" data-bs-target="#offcanvasBottom" aria-controls="offcanvasBottom">
                             <h6 className="m-0"><FaSortAmountDownAlt style={{ marginTop: "-4px" }} /> Sorting</h6>
       
                           </div>
       
                         </div>
       
                       </div>
                       <div class="offcanvas offcanvas-bottom" tabindex="-1" id="offcanvasBottom" aria-labelledby="offcanvasBottomLabel" style={{ height: "230px" }}>
                  <div class="offcanvas-header" style={{ borderBottom: "1px solid #f5f5f5" }}>
                    <h5 class="offcanvas-title" id="offcanvasBottomLabel">Sorting</h5>
                    <button type="button" class="btn-close text-reset" data-bs-dismiss="offcanvas" aria-label="Close"></button>
                  </div>
                  <div class="offcanvas-body " >
                    <h6 className="m-0 " style={{ paddingBottom: "5px" }}>Newest First</h6>
                    <h6 className="m-0" style={{ paddingBottom: "5px" }}>Premium Listing</h6>
                    <h6 className="m-0" style={{ paddingBottom: "5px" }}>Top Rated</h6>
                    <h6 className="m-0" style={{ paddingBottom: "5px" }} >A - Z</h6>
                    <h6 className="m-0">Z - A</h6>

                  </div>
                </div>
       
        {/* <div className="d-flex justify-content-between"
          style={{ alignItems: "baseline", background: "#ffb800", padding: "10px", marginBottom: "20px" }}
          onClick={toggleDrawer}>
          <h4 style={{ marginBottom: "0px" }}>FILTERjj BY</h4>
          <i class="fa fa-bars" aria-hidden="true" style={{ color: "#fff", fontSize: "22px" }}></i>
        </div> */}
      </div>
      {loading ? (
  <p>Loading...</p>
) : (
  singlefilterdata.length > 0 ? (
    <div className="breadcrumb-new">
        <p>{singlefilter.name}/ demolition hammers</p>
        <div className="related-category">
      

        {singlefilterdata?.map((category) => (
          <Link to={`/category/${category.slug}`}>
                     <div className="related-category-card">
                      <img src={category.icon} width="15px"></img>
                     
                      <p className="m-0 text-center " style={{whiteSpace:"nowrap"}}>{category.name}</p>
                      </div>
                      </Link>
                    ))}
        </div>
      </div>
  ) : (
    <p>No data found</p>
  )
)}
     
      <div id="filterSidebar" className='hide-in-desktop '>
        <Drawer
          open={isOpen}
          onClose={toggleDrawer}
          direction='left'
          className='cart-drawer'
          size="300px"

        >

          <div className="side-accordian">

            <Accordion defaultActiveKey="0">
              <Accordion.Item eventKey="0">
                <Accordion.Header>Category</Accordion.Header>
                <Accordion.Body>
                  <Form>
                    {categories.map((category) => (
                      <SidebarFilterCategoryItem key={category.id} category={category} categoryIds={categoryIds} setCategoryIds={setCategoryIds} />
                    ))}
                  </Form>
                </Accordion.Body>
              </Accordion.Item>
              {attributes.map((attribute) => (
                <SidebarFilterAttributeContainer key={attribute.id} attribute={attribute} attributeIds={attributeIds} setAttributeIds={setAttributeIds} />
              ))}
              <Accordion.Item eventKey="1">
                <Accordion.Header>Price</Accordion.Header>
                <Accordion.Body>
                  <Form.Label>&#8377; 0 - &#8377; 1,00,000</Form.Label>
                  <Form.Range
                    min="0"
                    max="100"
                    step="1"

                  />

                </Accordion.Body>
              </Accordion.Item>
            </Accordion>
          </div>
        </Drawer>
      </div>
    </div>
  );
};

export default SidebarFilterContainer;
