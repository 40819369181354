import { Link, useParams } from "react-router-dom";

const SidebarFilterCategoryItem = ({ category, categoryIds, setCategoryIds }) => {
  const {slug} = useParams();
  function setChecked(e) {
    let tempCategoryIds = [...categoryIds];
    if (e.target.checked) {
      tempCategoryIds.push(e.target.value);
    } else {
      tempCategoryIds = tempCategoryIds.filter((tempCategoryId) => tempCategoryId !== e.target.value);
    }
    setCategoryIds(tempCategoryIds);
  }
  console.log(category)
  return (
    <div className="form-group form-check customcheckbox">
      
      <a href={`/category/${category.slug}`} style={{textDecoration:"none", color:"#000"}}>
      <input type="checkbox" className="form-check-input" id={category.slug} onChange={(e) => setChecked(e)} value={category.id} />
      <label className="form-check-label" >
        {category.name}
        <span>({category.product_count})</span>
      </label>
      </a>
    </div>
  );
};
export default SidebarFilterCategoryItem;
