import React from "react";
import "../../style/Faqsellerbuyer.css";
import { Link } from "react-router-dom";
export const Faqbanner = () => {
  return (
    <>
      <div className="faq-sb-banner">
        <div className="new-startup-top-banner" style={{ background: "url(/assets/images/faq.jpg)" }}>
          <div className="startup-right-content-banner">
            <h1>Have Questions? We’ve Got Answers!</h1>
            <p>Explore our FAQ section to find clear and quick solutions to your queries. Get all the information you need about ApnaShaher.com in one place!</p>

          </div>
        </div>
      </div>
    </>
  );
};
