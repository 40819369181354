import React,{useState, useEffect} from 'react'
import "../../style/Startup.css"
import ReactOwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import { Link } from "react-router-dom";
import axios from 'axios';
import { BASE_URL } from '../../config';


export const StartupCategory = () => {
 const [categories, setCategories] = useState([])
 useEffect(() => {
  FetchCategories();
}, [])

const FetchCategories = async () => {
 
 
  await axios.get(BASE_URL + "/startupcategory")
    .then(({ data }) => {
      setCategories(data.data);
    });

};

const generateRandomColor = () => {
  // window.location.reload();
  const randomColor = Math.floor(Math.random() * 16777215).toString(16); // Generate random hexadecimal color
  const color = "#" + randomColor;

  // Convert hexadecimal color to RGB
  const r = parseInt(color.substr(1, 2), 16);
  const g = parseInt(color.substr(3, 2), 16);
  const b = parseInt(color.substr(5, 2), 16);

  // Set opacity to 30%
  return `rgba(${r},${g},${b},0.05)`;
};
  return (
    <>
      <div className="startup-category ">

        <div className="cat-cards px-lg-4 px-3 py-lg-4 py-3 mb-2">
          <div className='startup-category-section'>
          {categories.map((product) => (
            <div className='startup-category-card' style={{ backgroundColor: generateRandomColor() }}>
              
                <img src={product.category_icon}  style={{width:"40%"}}/>
              
                <h5 style={{fontWeight:"700"}}>{product.label}</h5>

            </div>
          ))}

          </div>
          {/* <ReactOwlCarousel className="owl-theme owl-feature-slider owl-slider-arrows" {...recent_options}>
            {Categories.map((product) => (
              <div className="card border-0 text-center shadow-sm mb-2">
                <div className="logo-div">
                <img src={product.cate1} />
                </div>
                <h5>{product.name}</h5>
               
              </div>
            ))}
          </ReactOwlCarousel> */}
        </div>

      </div>

    </>
  )
}
