import React, { useEffect, useState } from "react";
import { Banner } from "../components/startup/Banner";
import "../style/Startup.css";
import { AllStartup } from "../components/startup/AllStartup";
import { Subscribe } from "../components/startup/Subscribe";
import { InvertorSlider } from "../components/startup/InvertorSlider";
import { RegisterMarketplace } from "../components/startup/RegisterMarketplace";
import { StartupCategory } from "../components/startup/StartupCategory";
import { KeyFeature } from "../components/startup/KeyFeature";
import { Sbsection4 } from "../components/seller-benefites/Sbsection4";
import SeoStartup from "../seo page/SeoStartup";
import ThirdSection from "../components/products/Components/ThirdSection";
import { Link } from "react-router-dom";
import whychoose from "../assets/why-choose-us.jpg"
import { IoIosArrowRoundForward } from "react-icons/io";
import { FaBusinessTime } from 'react-icons/fa'
import { Startuphighlights } from './Buildmystartupheilights'
import axios from "axios";
import { BASE_URL } from "../config";

export const Startuppage = () => {

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [])
    const [widget, setwidget] = useState("")
    const [reportdata, setreportdata] = useState([])
    const [isLoading, setIsLoading] = useState(true);
    useEffect(() => {
        widgetData();

    }, []);
    const widgetData = async () => {
        setIsLoading(true);
        try {
            const wgtData = await axios.get(BASE_URL + "/startupwidget");
            setwidget(wgtData.data.data);
            setIsLoading(false);
        } catch (err) {
            console.log(err);
            setIsLoading(false);
        }
    };
    return (
        <div className="new-startup-page">
            <div className="new-startup-top-banner" style={{ background: "url(/assets/images/s2.jpg)" }}>
                <div className="startup-right-content-banner">
                    <h1>Your Startup Journey Starts Here – Build, List, Buy, and Discover!</h1>
                    <p>Join our platform to showcase your startup, get expert assistance in building your vision, and explore an ecosystem of innovative startups. Everything you need, all in one place!</p>
                    <div className="startup-deal-btn" style={{ width: "100%" }}>
                        <Link to={'/startup'}>
                            <button className="raise-capital mobile-btn">Show All Startup</button>
                        </Link>
                    </div>
                </div>
            </div>

            <div className="start-up-top-card startupcard-mobile">
                <h1>Worth Building, Worth Growing</h1>
                <p className="col-8 text-center">List your startup on ApnaShaher.com and lay the foundation for long-term success. Connect with potential customers, investors, and partners to bring your vision to life!</p>
                <div className="startup-deal-btn">
                    <Link to={'/startup'}>
                        <button className="raise-capital mobile-btn">View Startups</button>
                    </Link>
                    <Link to={'/'}>
                        <button className="raise-capital mobile-btn">Startup for Sale</button>
                    </Link>
                    <Link to={'/startup-form'}>
                        <button className="raise-capital mobile-btn">List Your Startup</button>
                    </Link>
                    <Link to={'/build-my-startup'}>
                        <button className="raise-capital mobile-btn">Build My Startup</button>
                    </Link>

                </div>
                <p style={{ fontSize: "12px", marginTop: "20px" ,padding:"20px"}}>Private investments are highly risky, illiquid and may result in total loss of capital. Learn more</p>


            </div>
            <ThirdSection />
            <div className="container mb-4 mt-5">
    <div className="row">
        <div className="col-12 col-lg-4">
            <img
                src="https://images.pexels.com/photos/6620161/pexels-photo-6620161.jpeg?auto=compress&cs=tinysrgb&w=600"
                style={{ borderRadius: "10px", width: "100%" }}
                alt="Startup Image"
            />
        </div>
        <div className="col-12 col-lg-8">
            <div className="startup-right-content">
                <h1>Empowering Startups with Stunning Websites, Mobile Application & Digital Growth!</h1>
                <p>
                    Launch your startup with a strong digital foundation! We specialize in cutting-edge website design,
                    seamless development, and result-driven digital marketing tailored for startups. From creating your
                    online presence to driving your growth, we’re here to turn your vision into reality. Let’s build your
                    dream together!
                </p>
                <div className="startup-deal-btn" style={{ width: "100%" }}>
                    <Link to={'/build-my-startup'}>
                        <button className="raise-capital mobile-btn">Build My Startup</button>
                    </Link>
                </div>
            </div>
        </div>
    </div>
</div>

            <div className="container mb-4 mt-5">
                <div className="row">


                    <div className=" col-12 col-lg-7">
                        <div className="startup-right-content1">
                            <h1>Showcase Your Startup to the World – List with Us!"</h1>
                            <p>Gain visibility and connect with potential clients and investors by listing your startup on our portal. Let’s help you grow and stand out in the competitive startup ecosystem!</p>
                            <div className="startup-deal-btn" style={{ width: "100%" }}>
                                <Link to={'/startup-form'}>
                                    <button className="raise-capital mobile-btn">List My Startup</button>
                                </Link>
                            </div>
                        </div>
                    </div>
                    <div className=" col-12 col-lg-5">
                        <img src="/assets/images/startuprightimage.jpg" style={{ borderRadius: "10px" }}></img>
                    </div>
                </div>
            </div>
            <div className="container mb-5">
                <div className="row">
                    <div className="col-12">
                        <div className="empower-bussiness-content-vertical mt-5 mb-5 col-12">
                            <h1>Why Invest in Startups Listed with ApnaShaher.com?</h1>

                            <p>Managed portfolios are built by experts to target sustainable, long-term results and the highest returns for your risk level.</p>
                            <button>Register Now</button>
                        </div>
                    </div>
                    <div className="why-investdesktop">
                        <div className="col-12 d-flex gap-3">
                            <div className=" col-3 mb-4">
                                <div className="why-invest">
                                    <div className="why-invest-card">
                                        <img src="/assets/images/earnheigh.svg"></img>
                                        <h4 className="m-0">Proven Growth Potential</h4>
                                        <p>Invest in startups with a high potential for success and scalability.</p>

                                    </div>

                                </div>
                            </div>
                            <div className="col-3 mb-4">
                                <div className="why-invest">
                                    <div className="why-invest-card">
                                        <img src="/assets/images/divercity.svg"></img>
                                        <h4 className="m-0">Wide Market Reach</h4>
                                        <p>Gain access to a global network of buyers, sellers, and entrepreneurs.
                                        </p>
                                    </div>

                                </div>
                            </div>
                            <div className="col-3 ">
                                <div className="why-invest">
                                    <div className="why-invest-card">
                                        <img src="/assets/images/divercity.svg"></img>
                                        <h4 className="m-0">Investment Opportunities</h4>
                                        <p>Explore various sectors and find the best investment opportunities. </p>

                                    </div>

                                </div>
                            </div>
                            <div className="col-3 ">
                                <div className="why-invest">
                                    <div className="why-invest-card">
                                        <img src="/assets/images/divercity.svg"></img>
                                        <h4 className="m-0">Expert Support and Guidance</h4>
                                        <p>Benefit from expert advice and support throughout your investment journey. </p>

                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="why-investmobile">
                        <div className="why-investcard">

                            <img src="/assets/images/earnheigh.svg"></img>
                            <div className="why-invest-content">
                                <h4 className="m-0">Proven Growth Potential</h4>
                                <p>Invest in startups with a high potential for success and scalability.</p>
                            </div>

                        </div>
                        <div className="why-investcard">

                            <img src="/assets/images/earnheigh.svg"></img>
                            <div className="why-invest-content">
                                <h4 className="m-0">Wide Market Reach</h4>
                                <p>Gain access to a global network of buyers, sellers, and entrepreneurs.
                                </p>
                            </div>

                        </div>
                        <div className="why-investcard">

                            <img src="/assets/images/earnheigh.svg"></img>
                            <div className="why-invest-content">
                                <h4 className="m-0">Investment Opportunities</h4>
                                <p>Explore various sectors and find the best investment opportunities. </p>

                            </div>

                        </div>
                        <div className="why-investcard">

                            <img src="/assets/images/earnheigh.svg"></img>
                            <div className="why-invest-content">
                                <h4 className="m-0">Expert Support and Guidance</h4>
                                <p>Benefit from expert advice and support throughout your investment journey. </p>

                            </div>

                        </div>

                    </div>

                </div>
            </div>
            <div dangerouslySetInnerHTML={{ __html: widget.widget4 }}></div>
            <div className='container'>
                <div className='startup-recent-list-card'>
                    <div className='startup-recent-card-design'>
                        <img src={whychoose}></img>
                        <div className='startup-recent-logo'>

                        </div>
                        <div className='startup-recent-content'>
                            <div className='startup-recent-heading'>
                                <h4 className=''>Reality Games</h4>
                                <p>Discover innovative startups with high growth potential</p>
                                <div className='startup-category-list'>
                                    <div className='startup-contry'>
                                        <p>Startup</p>
                                    </div>
                                    <div className='startup-contry'>
                                        <p>Fintech & Finance</p>
                                    </div>
                                    <div className='startup-contry'>
                                        <p>Food & Drinks</p>
                                    </div>
                                    {/* <div className='startup-contry'>
                      <p>Startup</p>
                    </div> */}

                                </div>

                                <div className='startup-visitor-section mt-3'>
                                    <p className='m-0'>Visitors</p>
                                    <h6>1.5K View -  Last 30 Days</h6>
                                    <div class="progress mb-2">
                                        <div class="progress-bar progress-bar-striped progress-bar-animated" role="progressbar" aria-valuenow="75" aria-valuemin="0" aria-valuemax="100" style={{ width: "75%" }}></div>
                                    </div>
                                    <div className='startup-view-sec'>
                                        <div className='startup-count'>
                                            <p>Established Year</p>
                                            <h6>2024</h6>
                                        </div>
                                        <div className='startup-line'>

                                        </div>
                                        <div className='startup-count'>
                                            <p>Valuation</p>
                                            <h6>$5.9M</h6>
                                        </div>
                                        <div className='startup-line'>

                                        </div>
                                        <div className='startup-count'>
                                            <p>Revenue</p>
                                            <h6>$5.9M</h6>
                                        </div>


                                    </div>


                                </div>

                            </div>

                        </div>

                    </div>
                    <div className='startup-recent-card-design'>
                        <img src={whychoose}></img>
                        <div className='startup-recent-logo'>

                        </div>
                        <div className='startup-recent-content'>
                            <div className='startup-recent-heading'>
                                <h4 className=''>Reality Games</h4>
                                <p>Discover innovative startups with high growth potential</p>
                                <div className='startup-category-list'>
                                    <div className='startup-contry'>
                                        <p>Startup</p>
                                    </div>
                                    <div className='startup-contry'>
                                        <p>Fintech & Finance</p>
                                    </div>
                                    <div className='startup-contry'>
                                        <p>Food & Drinks</p>
                                    </div>
                                    {/* <div className='startup-contry'>
                      <p>Startup</p>
                    </div> */}

                                </div>

                                <div className='startup-visitor-section mt-3'>
                                    <p className='m-0'>Visitors</p>
                                    <h6>1.5K View -  Last 30 Days</h6>
                                    <div class="progress mb-2">
                                        <div class="progress-bar progress-bar-striped progress-bar-animated" role="progressbar" aria-valuenow="75" aria-valuemin="0" aria-valuemax="100" style={{ width: "75%" }}></div>
                                    </div>
                                    <div className='startup-view-sec'>
                                        <div className='startup-count'>
                                            <p>Established Year</p>
                                            <h6>2024</h6>
                                        </div>
                                        <div className='startup-line'>

                                        </div>
                                        <div className='startup-count'>
                                            <p>Valuation</p>
                                            <h6>$5.9M</h6>
                                        </div>
                                        <div className='startup-line'>

                                        </div>
                                        <div className='startup-count'>
                                            <p>Revenue</p>
                                            <h6>$5.9M</h6>
                                        </div>


                                    </div>


                                </div>

                            </div>

                        </div>

                    </div>
                    <div className='startup-recent-card-design'>
                        <img src={whychoose}></img>
                        <div className='startup-recent-logo'>

                        </div>
                        <div className='startup-recent-content'>
                            <div className='startup-recent-heading'>
                                <h4 className=''>Reality Games</h4>
                                <p>Discover innovative startups with high growth potential</p>
                                <div className='startup-category-list'>
                                    <div className='startup-contry'>
                                        <p>Startup</p>
                                    </div>
                                    <div className='startup-contry'>
                                        <p>Fintech & Finance</p>
                                    </div>
                                    <div className='startup-contry'>
                                        <p>Food & Drinks</p>
                                    </div>
                                    {/* <div className='startup-contry'>
                      <p>Startup</p>
                    </div> */}

                                </div>

                                <div className='startup-visitor-section mt-3'>
                                    <p className='m-0'>Visitors</p>
                                    <h6>1.5K View -  Last 30 Days</h6>
                                    <div class="progress mb-2">
                                        <div class="progress-bar progress-bar-striped progress-bar-animated" role="progressbar" aria-valuenow="75" aria-valuemin="0" aria-valuemax="100" style={{ width: "75%" }}></div>
                                    </div>
                                    <div className='startup-view-sec'>
                                        <div className='startup-count'>
                                            <p>Established Year</p>
                                            <h6>2024</h6>
                                        </div>
                                        <div className='startup-line'>

                                        </div>
                                        <div className='startup-count'>
                                            <p>Valuation</p>
                                            <h6>$5.9M</h6>
                                        </div>
                                        <div className='startup-line'>

                                        </div>
                                        <div className='startup-count'>
                                            <p>Revenue</p>
                                            <h6>$5.9M</h6>
                                        </div>


                                    </div>


                                </div>

                            </div>

                        </div>

                    </div>

                </div>
                <div className="startup-deal-btn mb-5" style={{ width: "100%", justifyContent: "center" }}>
                    <Link to={'/'}>
                        <button className="raise-capital mobile-btn">View All Startups</button>
                    </Link>
                </div>
            </div>
            <div className="startup-footer">
                <h1 className="col-12 col-lg-6 text-center">Explore Startups for Sale – Your Next Big Opportunity Awaits!</h1>
                <div className="startup-deal-btn" style={{ width: "10%" }}>
                    <Link to={'/'}>

                        <button className="raise-capital mobile-btn">Startup For sell</button>
                    </Link>
                </div>
            </div>
        </div>
    );
};
