import React from "react";
import { Link, useNavigate } from "react-router-dom";
import ReactStars from "react-stars";
import { FaPlus } from "react-icons/fa";


export const Itservices = (props) => {
  console.log(props)
  const navigate = useNavigate();
  return (
    <div className="it-services mt-3 mb-3">
      <div className="container">
        <div className="main-cont my-lg-4 my-3">
          <div className="d-flex top-heading justify-content-between  align-items-center">
            {/* <h6 className="">{props.heading}</h6> */}
            <h6 className="cate-head mt-5 mb-4 heading-bline heading-it-services position-relative">{props.heading}</h6>
            <Link to={"/service-category/" + props?.data?.slug}>
             {/* <Link to={`/comming-soon`} > */}
            View more</Link>
          </div>
          <div style={{ overflowX: "auto", maxWidth: "100%" }}>
            <div
              className="scards d-flex  gap-lg-3 gap-md-3 gap-2 mb-4  "
              style={{ minWidth: "992px" }}
            >
              {/* {props.data.map((item, i) => ( */}
                  <div
                    class="card mr-3"
                    // key={i}
                    
                  >
                    <img
                      src={'https://demo.martvill.techvill.net/public/uploads/20221205/2dcff5a961eb2a3127b2fe3b87861d99.webp'}
                      className="card-img-top rounded-top-3"
                      alt="..."
                      width="328px"
                      height="170px"
                      // onClick={() => navigate("/service-detail/" + item.slug)}
                    />
                    <div class="card-body px-0 pb-0 bg-white" style={{overflow:"hidden"}}>
                      <div className="  px-2 pb-0">
                        <div className="sprovider d-flex gap-2 pb-2">
                          <img
                            src={'https://apnashaher.com/admin-login/public/storage/uploads/all/rX8cT08AdvxqVT50Vv93tfzP2pxELuJSvcn4O8Je.png'}
                            alt=""
                            width={"31px"}
                            height={"31px"}
                            style={{borderRadius:"50%"}}
                            // onClick={()=> navigate("/seller-detail/" + item.business_slug)}
                          />
                          <div className="d-flex  flex-column ml-1">
                            <h5 class="card-title ">Jhon Fix</h5>
                            <p className="mb-0" style={{marginTop:"-5px"}}>Lucknow</p>
                          </div>
                        </div>
                        <div className="hpara">Healthcare Animation Services</div>
                        <div className="p-category-name">
                        <p>IT & Software</p>
                        <p>Hardware & Networking</p>
                        <p style={{fontWeight:"600"}}><FaPlus style={{fontSize:"14px"}}/></p>
                        </div>
                       
                        <div className="sp-rating d-flex gap-2 py-2">
                          <span className="star">
                            <ReactStars
                              count={5}
                              value={4}
                              size={15}
                              edit={false}
                              color2={"#ffd700"}
                            />
                            {/* <i className="fa fa-star fs-5" style={{ color: "#FFCE31" }}></i> */}
                          </span>
                          
                          <p className="rating-people mb-0 mt-0 ml-2 align-item-center">
                            (123)
                          </p>
                        </div>
                      </div>
                      <div className="price-cont  px-2  d-flex align-items-center justify-content-between">
                        <div>
                          <span>Starting At</span>
                          <div className="d-flex gap-2">
                            <p className="act-price">
                            ₹ 200
                            </p>
                            <p className="dprice ml-2">
                            ₹ 200
                            </p>
                          </div>
                        </div>
                        <div>
                          <span className="heart" >
                            <i className=" fa fa-heart"></i>
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div
                    class="card mr-3"
                    // key={i}
                    
                  >
                    <img
                      src={'https://demo.martvill.techvill.net/public/uploads/20221205/2dcff5a961eb2a3127b2fe3b87861d99.webp'}
                      className="card-img-top rounded-top-3"
                      alt="..."
                      width="328px"
                      height="170px"
                      // onClick={() => navigate("/service-detail/" + item.slug)}
                    />
                    <div class="card-body px-0 pb-0 bg-white" style={{overflow:"hidden"}}>
                      <div className="  px-2 pb-0">
                        <div className="sprovider d-flex gap-2 pb-2">
                          <img
                            src={'https://apnashaher.com/admin-login/public/storage/uploads/all/rX8cT08AdvxqVT50Vv93tfzP2pxELuJSvcn4O8Je.png'}
                            alt=""
                            width={"31px"}
                            height={"31px"}
                            style={{borderRadius:"50%"}}
                            // onClick={()=> navigate("/seller-detail/" + item.business_slug)}
                          />
                          <div className="d-flex  flex-column ml-1">
                            <h5 class="card-title ">Jhon Fix</h5>
                            <p className="mb-0" style={{marginTop:"-5px"}}>Lucknow</p>
                          </div>
                        </div>
                        <div className="hpara">Healthcare Animation Services</div>
                        <div className="p-category-name">
                        <p>IT & Software</p>
                        <p>Hardware & Networking</p>
                        <p style={{fontWeight:"600"}}><FaPlus style={{fontSize:"14px"}}/></p>
                        </div>
                        <div className="sp-rating d-flex gap-2 py-2">
                          <span className="star">
                            <ReactStars
                              count={5}
                              value={4}
                              size={15}
                              edit={false}
                              color2={"#ffd700"}
                            />
                            {/* <i className="fa fa-star fs-5" style={{ color: "#FFCE31" }}></i> */}
                          </span>
                         
                          <p className="rating-people mb-0 mt-0 ml-2 align-item-center">
                            (123)
                          </p>
                        </div>
                      </div>
                      <div className="price-cont  px-2  d-flex align-items-center justify-content-between">
                        <div>
                          <span>Starting At</span>
                          <div className="d-flex gap-2">
                            <p className="act-price">
                            ₹ 200
                            </p>
                            <p className="dprice ml-2">
                            ₹ 200
                            </p>
                          </div>
                        </div>
                        <div>
                          <span className="heart" >
                            <i className=" fa fa-heart"></i>
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div
                    class="card mr-3"
                    // key={i}
                    
                  >
                    <img
                      src={'https://demo.martvill.techvill.net/public/uploads/20221205/2dcff5a961eb2a3127b2fe3b87861d99.webp'}
                      className="card-img-top rounded-top-3"
                      alt="..."
                      width="328px"
                      height="170px"
                      // onClick={() => navigate("/service-detail/" + item.slug)}
                    />
                    <div class="card-body px-0 pb-0 bg-white" style={{overflow:"hidden"}}>
                      <div className="  px-2 pb-0">
                        <div className="sprovider d-flex gap-2 pb-2">
                          <img
                            src={'https://apnashaher.com/admin-login/public/storage/uploads/all/rX8cT08AdvxqVT50Vv93tfzP2pxELuJSvcn4O8Je.png'}
                            alt=""
                            width={"31px"}
                            height={"31px"}
                            style={{borderRadius:"50%"}}
                            // onClick={()=> navigate("/seller-detail/" + item.business_slug)}
                          />
                          <div className="d-flex  flex-column ml-1">
                            <h5 class="card-title ">Jhon Fix</h5>
                            <p className="mb-0" style={{marginTop:"-5px"}}>Lucknow</p>
                          </div>
                        </div>
                        <div className="hpara">Healthcare Animation Services</div>
                        <div className="p-category-name">
                        <p>IT & Software</p>
                        <p>Hardware & Networking</p>
                        <p style={{fontWeight:"600"}}><FaPlus style={{fontSize:"14px"}}/></p>
                        </div>
                        <div className="sp-rating d-flex gap-2 py-2">
                          <span className="star">
                            <ReactStars
                              count={5}
                              value={4}
                              size={15}
                              edit={false}
                              color2={"#ffd700"}
                            />
                            {/* <i className="fa fa-star fs-5" style={{ color: "#FFCE31" }}></i> */}
                          </span>
                     
                          <p className="rating-people mb-0 mt-0 ml-2 align-item-center">
                            (123)
                          </p>
                        </div>
                      </div>
                      <div className="price-cont  px-2  d-flex align-items-center justify-content-between">
                        <div>
                          <span>Starting At</span>
                          <div className="d-flex gap-2">
                            <p className="act-price">
                            ₹ 200
                            </p>
                            <p className="dprice ml-2">
                            ₹ 200
                            </p>
                          </div>
                        </div>
                        <div>
                          <span className="heart" >
                            <i className=" fa fa-heart"></i>
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div
                    class="card "
                    // key={i}
                    
                  >
                    <img
                      src={'https://demo.martvill.techvill.net/public/uploads/20221205/2dcff5a961eb2a3127b2fe3b87861d99.webp'}
                      className="card-img-top rounded-top-3"
                      alt="..."
                      width="328px"
                      height="170px"
                      // onClick={() => navigate("/service-detail/" + item.slug)}
                    />
                    <div class="card-body px-0 pb-0 bg-white" style={{overflow:"hidden"}}>
                      <div className="  px-2 pb-0">
                        <div className="sprovider d-flex gap-2 pb-2">
                          <img
                            src={'https://apnashaher.com/admin-login/public/storage/uploads/all/rX8cT08AdvxqVT50Vv93tfzP2pxELuJSvcn4O8Je.png'}
                            alt=""
                            width={"31px"}
                            height={"31px"}
                            style={{borderRadius:"50%"}}
                            // onClick={()=> navigate("/seller-detail/" + item.business_slug)}
                          />
                          <div className="d-flex  flex-column ml-1">
                            <h5 class="card-title ">Jhon Fix</h5>
                            <p className="mb-0" style={{marginTop:"-5px"}}>Lucknow</p>
                          </div>
                        </div>
                        <div className="hpara">Healthcare Animation Services</div>
                        <div className="p-category-name">
                        <p>IT & Software</p>
                        <p>Hardware & Networking</p>
                        <p style={{fontWeight:"600"}}><FaPlus style={{fontSize:"14px"}}/></p>
                        </div>
                        <div className="sp-rating d-flex gap-2 py-2">
                          <span className="star">
                            <ReactStars
                              count={5}
                              value={4}
                              size={15}
                              edit={false}
                              color2={"#ffd700"}
                            />
                            {/* <i className="fa fa-star fs-5" style={{ color: "#FFCE31" }}></i> */}
                          </span>
                         
                          <p className="rating-people mb-0 mt-0 ml-2 align-item-center">
                            (123)
                          </p>
                        </div>
                      </div>
                      <div className="price-cont  px-2  d-flex align-items-center justify-content-between">
                        <div>
                          <span>Starting At</span>
                          <div className="d-flex gap-2">
                            <p className="act-price">
                            ₹ 200
                            </p>
                            <p className="dprice ml-2">
                            ₹ 200
                            </p>
                          </div>
                        </div>
                        <div>
                          <span className="heart" >
                            <i className=" fa fa-heart"></i>
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                {/* ))} */}
            </div>
          </div>
        </div>
        
      </div>
    </div>
  );
};
