import React, { useEffect } from 'react'
import "../style/Investor.css"
import { InvestorBanner } from '../components/investor/InvestorBanner'
import { Highlight } from '../components/investor/Highlight'
import { Risk } from '../components/investor/Risk'
import { AboutComp3 } from '../components/about-us/AboutComp3'
import { Communicate } from '../components/investor/Communicate'
import { TabComp } from '../components/investor/TabComp'
import SeoPartnerandinvester from '../seo page/Seopartner&investerpage'
import { Link } from 'react-router-dom'
import { IoMdCheckmarkCircleOutline } from 'react-icons/io'
import feature from '../assets/about-feature.jpg'

export const Investor = () => {
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  return (
    <>
      <SeoPartnerandinvester />
      <div className="invsetor-parent" >
        <div className="new-startup-top-banner" style={{ background: "url(/assets/images/partner-with-us.jpg)" }}>
          <div className="startup-right-content-banner">

            <h1 >Invest in ApnaShaher.com: Fuel the Future of Innovation!</h1>
            <p >Join hands with ApnaShaher.com and empower emerging businesses to thrive. Your investment fuels innovation and unlocks endless growth opportunities!</p>
            <div className="startup-deal-btn" style={{ width: "100%" }}>
              <Link to={''}>
                <button className="raise-capital11">Invest Now</button>
              </Link>
            </div>
          </div>
        </div>
        <div className="start-up-top-card">
          <h1>Worth building</h1>
          <p className="col-8 text-center">Merging private market assets with the latest web3 technologies — redefining how people invest and how businesses connect with their customers</p>
          <div className="startup-deal-btn">
            <Link to={'/'}>
              <button className="raise-capital">Pitch Deck</button>
            </Link>
            <Link to={'/'}>
              <button className="raise-capital">Presentation</button>
            </Link>
            <Link to={'/'}>
              <button className="raise-capital">About Us</button>
            </Link>
            <Link to={'/'}>
              <button className="raise-capital">Contact Us</button>
            </Link>

          </div>
          <p style={{ fontSize: "12px", marginTop: "20px" }}>Private investments are highly risky, illiquid and may result in total loss of capital. Learn more</p>


        </div>

        {/* <section>
          <InvestorBanner />
        </section> */}

        <section style={{backgroundColor:"#002a5b", paddingTop:"50px", paddingBottom:"50px" }}>
          <Highlight />
        </section>

        <section>
          <Risk />
        </section>

        {/* <section>
          <TabComp />
        </section> */}

        <section>
        <div className="about-comp3 mx-lg-5 mx-md-4 mx-3 my-lg-5 my-md-4 my-3">

<div className="d-flex justify-content-between align-items-center">
    <div className="left">

        <h3>Discover the standout features that make us exceptional for businesses!</h3>
        <h6 className='m-0' style={{ color: "#002a5b" }}><strong><IoMdCheckmarkCircleOutline style={{ marginTop: "-4px", marginRight: "10px", color: "green" }} /> Global Reach<br /> &nbsp;</strong></h6>
        <div className='feature-content'>
            <p >Businesses can showcase their goods to a large and varied worldwide
                audience on ApnaShaher.com, a platform that defies geographical boundaries. We
                encourage cross-border inquiries by offering a user-friendly interface and advanced
                technologies that enable businesses to interact with prospective clients all around the
                globe easily.</p>
        </div>
        <h6 className='m-0' style={{ color: "#002a5b" }}><strong><IoMdCheckmarkCircleOutline style={{ marginTop: "-4px", marginRight: "10px", color: "green" }} /> Comprehensive Listings<br /> &nbsp;</strong></h6>
        <div className='feature-content'>
            <p >Here at ApnaShaher.com, we take immense pride in providing an approachable platform to many businesses. Our platform is intended to help any business shine, regardless of what you do: manufacturing, import, export, distribution, supply, retail, service, freelancing, educational, or startup.</p>
        </div>
        <h6 className='m-0' style={{ color: "#002a5b" }}><strong><IoMdCheckmarkCircleOutline style={{ marginTop: "-4px", marginRight: "10px", color: "green" }} />  Business Inquiries<br /> &nbsp;</strong></h6>
        <div className='feature-content'>
            <p >Understanding the significance of promoting the growth of companies, ApnaShaher.com has state-of-the-art resources and amenities developed to generate top-notch company inquiries. By facilitating the procedure of connecting with clients, partners, and consumers, our network helps businesses survive in today's continually evolving industry.</p>
        </div>
        <h6 className='m-0' style={{ color: "#002a5b" }}><strong><IoMdCheckmarkCircleOutline style={{ marginTop: "-4px", marginRight: "10px", color: "green" }} /> User-Friendly Interface<br /> &nbsp;</strong></h6>
        <div className='feature-content'>
            <p > You will have no difficulty navigating ApnaShaher.com. The main objective for creating this platform is the simplicity of use for vendors and buyers in examining, connecting, and transacting.</p>
        </div>
        <h6 className='m-0' style={{ color: "#002a5b" }}><strong><IoMdCheckmarkCircleOutline style={{ marginTop: "-4px", marginRight: "10px", color: "green" }} /> Innovation & Adaptability<br /> &nbsp;</strong></h6>
        <div className='feature-content'>
            <p > Innovation is essential in today's competitive corporate world. ApnaShaher.com is committed to being creative and innovative to provide businesses with a leg up in the marketplace.</p>
        </div>
        <h6 className='m-0' style={{ color: "#002a5b" }}><strong><IoMdCheckmarkCircleOutline style={{ marginTop: "-4px", marginRight: "10px", color: "green" }} /> Dedicated Support Team<br /> &nbsp;</strong></h6>
        <div className='feature-content'>
            <p > You can count on our support team to be available at every turn. Feel free to message our specialised support team if you have any inquiries, require help, or stumble into any problem.</p>
        </div>
        
    </div>
    <div className="right position-relative">
        <img src={feature} alt="" />
        <div className='r-content'>
            <p> <span>12 Year</span> <br />Experience </p>
        </div>
    </div>
</div>

</div>
        </section>
        <section className='' style={{paddingLeft:"50px", paddingRight:"50px"}}>
                <div className="row">
                    <div className="col-12">
                        <div className="empower-bussiness-content-vertical mt-5 mb-5 col-12">
                            <h1>Why invest with us?</h1>

                            <p>Managed portfolios are built by experts to target sustainable, long-term results and the highest returns for your risk level.</p>
                            <button>Register Now</button>
                        </div>
                    </div>
                    <div className="col-3">
                        <div className="why-invest">
                            <div className="why-invest-card">
                                <img src="/assets/images/earnheigh.svg"></img>
                                <h4 className="m-0">High Growth Potential</h4>
                                <p>Be part of a platform revolutionizing how startups and businesses connect. </p>

                            </div>

                        </div>
                    </div>
                    <div className="col-3">
                        <div className="why-invest">
                            <div className="why-invest-card">
                                <img src="/assets/images/divercity.svg"></img>
                                <h4 className="m-0">Innovative Platform</h4>
                                <p>Cutting-edge features ensure scalability and sustained user engagement.</p>

                            </div>

                        </div>
                    </div>
                    <div className="col-3">
                        <div className="why-invest">
                            <div className="why-invest-card">
                                <img src="/assets/images/divercity.svg"></img>
                                <h4 className="m-0">Impactful Returns</h4>
                                <p>Support emerging businesses while securing lucrative investment opportunities. </p>

                            </div>

                        </div>
                    </div>
                    <div className="col-3">
                        <div className="why-invest">
                            <div className="why-invest-card">
                                <img src="/assets/images/divercity.svg"></img>
                                <h4 className="m-0">Trusted Brand</h4>
                                <p>Built on a foundation of credibility and proven success in fostering business growth. </p>

                            </div>

                        </div>
                    </div>
                </div>
            </section>
        <section>
          <Communicate />
        </section>

      </div>

    </>
  )
}
