import React, { useState, useEffect } from "react";
import "../../style/Directory.css"
import axios from "axios";
import { BASE_URL } from "../../config";
import { Link } from "react-router-dom";
import ReactOwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import { BiCategory } from "react-icons/bi";
export const DirectoryComp2 = (props) => {
  const [directorydata, setdirectorydata] = useState([])
  // useEffect(() => {
  //   getdirectorydata();
  // }, []);
  useEffect(() => {
    FetchCategories();
  }, [directorydata])
  const FetchCategories = async () => {

   
    await axios.get(BASE_URL + "/all-categories-for-directory-listing")
      .then(({ data }) => {
        setdirectorydata(data.data);
        
       
      });
  };

 
  const recent_options = {
    margin: 10,
    responsiveClass: true,
    nav: false,
    dots: false,
    autoplay: true,
    loop: true,
    responsive: {
      0: {
        items: 3.4,
      },
      460: {
        items: 4.2,
      },
      575: {
        items: 3.8,
      },
      768: {
        items: 5,
      },
      1080: {
        items: 6.3,
      },
      1200: {
        items: 8,
      },
    },
  };
  return (
    <>
      <div className="container mt-3">
        <div className="slider-section categories-slider product-categories">
          <div className="d-flex justify-content-between align-items-center" style={{ width: "100%" }}>
            <h6 className="cate-head mb-5 heading-bline position-relative">Explore Categories</h6>
            <Link to={'/directory-all-category'}>
            {/* <Link to={'/comming-soon'}> */}
              <h6 style={{ cursor: "pointer" }}><BiCategory style={{marginTop:"-4px"}}/> Show All Categories</h6>
            </Link>
          </div>

          <ReactOwlCarousel className="owl-theme owl-feature-slider owl-slider-arrows" {...recent_options}>
            {directorydata && directorydata.map((data, i) => (
              data.showondirectoryhome === "no" ? null : (
                <div className="mt-3 mb-3" key={i}>
                  <Link to={`/directory-listing/${data.slug}`}>
                  {/* <Link to={'/comming-soon'}> */}
                    <img src={data.icon} alt={data.title} />
                    <h5>{data.name}</h5>
                  </Link>
                </div>
              )
            ))}

          </ReactOwlCarousel>
        </div>
      </div>
      {/* <div className="directory-comp-2nd my-lg-5 my-4">
        <div className="container">
          <div className="top-content text-center my-3 mb-lg-5 mb-4 " dangerouslySetInnerHTML={{__html:props?.data?.widget1}}>
            
          </div>

          <div className="cards-container">
            <div className="cards d-flex justify-content-between gap-3">
              {directorydata && directorydata.map((data,i)=>(
                <div class="card mr-3 shadow-sm">
                <img src={data.image} class="card-img-top" alt="..." />
                <div class="card-body pt-0">
                  <h5 class="card-title">{data.title}</h5>
                  <p class="card-text" dangerouslySetInnerHTML={{__html:data.description}}>
                    
                  </p>
                  <div className="d-flex justify-content-between card-links">
                    <Link to={"/"+data.slug} class="btn">
                    Explore Now
                    </Link>
                    <Link to="/sign-up" class="btn">
                    Register Now
                    </Link>
                  </div>
                </div>
              </div>
              ))}
              
             
            </div>
          </div>
        </div>
      </div> */}
    </>
  );
};
