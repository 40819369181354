import React, { useState, useEffect } from 'react'
import "../style/BuildmyStartup.css"
import { Sellerbanner } from '../components/seller-benefites/Sellerbanner'
import { BuildmsComp2 } from '../components/build-my-startup/BuildmsComp2'
import { Services } from '../components/build-my-startup/Services'
import { BuildmsForm } from '../components/build-my-startup/BuildmsForm'
import { BuildsmBenefits } from '../components/build-my-startup/BuildsmBenefits'
import { RecentStartup } from '../components/build-my-startup/RecentStartup'
import { BuildmsCart } from '../components/build-my-startup/BuildmsCart'
import axios from 'axios';
import { BASE_URL } from '../config'
import Loader from '../components/loader/Loader.component'
import { Link } from 'react-router-dom'
import topimage from "../assets/build-img.jpg"
import idea from "../assets/idea.jpg"
import whychoose from "../assets/why-choose-us.jpg"
import { IoIosArrowRoundForward } from "react-icons/io";
import { FaBusinessTime } from 'react-icons/fa'
import { Startuphighlights } from './Buildmystartupheilights'
export const BuildmyStartup = () => {
  const [widget, setwidget] = useState("")
  const [reportdata, setreportdata] = useState([])
  const [isLoading, setIsLoading] = useState(true);
  useEffect(() => {
    widgetData();
    getstartupreportData();
  }, []);
  const widgetData = async () => {
    setIsLoading(true);
    try {
      const wgtData = await axios.get(BASE_URL + "/startupwidget");
      setwidget(wgtData.data.data);
      setIsLoading(false);
    } catch (err) {
      console.log(err);
      setIsLoading(false);
    }
  };
  const getstartupreportData = async () => {
    setIsLoading(true);
    try {
      const Data = await axios.get(BASE_URL + "/getstartupreport");
      setreportdata(Data.data.data);
      setIsLoading(false);
    } catch (err) {
      console.log(err);
      setIsLoading(false);
    }
  };
  if (isLoading) {
    return <Loader />;
  }


  return (
    <>

      <div className='pb-2' >
        <div className="new-startup-top-banner" style={{ background: "url(/assets/images/startup-img.jpg)" }}>
          <div className="startup-right-content-banner">
            <h5 style={{ color: "#fff" }}>HAVE STARTUP IDEA?</h5>
            <h1 style={{ color: "#fff" }}>Turn Your Startup Idea into a Thriving Business with APNASHAHER.COM</h1>
            <p style={{ color: "#fff" }}>Kickstart your Startup journey with us to connect with potential customer worldwide, we help startup to transform their idea's to build Unicorn.</p>
            <div className="startup-deal-btn" style={{ width: "100%" }}>
              <Link to={''}>
                <button className="raise-capital1">Register Now</button>
              </Link>
            </div>
          </div>
        </div>

        <div className="container mb-4 mt-5">
          <div className="row">
            <div className="col-12 col-lg-4">
              <img src={idea} style={{ borderRadius: "10px" }}></img>
            </div>
            <div className="col-12 col-lg-8">
              <div className="startup-right-content">
                <h1 style={{ width: "100%" }}>Why build a startup?</h1>
                <p>Your Startup, Our Expertise: Apna Shaher Guides You to Success
                  Apna Shaher is an ideal strategic partner for your entrepreneurial initiatives, so begin your
                  adventure with us. We aim to support you in handling the complex journey of turning a reality if
                  you are an entrepreneurial visionary with dreams of creating the next great thing. Apna Shaher
                  is more than a business; we are committed companions on the road to entrepreneurship. Our
                  only goal is to assist entrepreneurs like you in transforming your revolutionary concepts into
                  profitable enterprises that make an impact. You can count on Apna Shaher as your success
                  catalyst, providing the help you need and skills for success in the constantly shifting startup
                  industry.</p>
                <div className="startup-deal-btn" style={{ width: "100%" }}>
                  <Link to={'/build-my-startup'}>
                    <button className="raise-capital mobile-btn">Build My Startup</button>
                  </Link>
                </div>
              </div>
            </div>


          </div>
        </div>
        {/* <div className='build_service-bg'>
        <div className='container mt-5'>
        <div className='build-my-startup-services-card'>
          <div className='left-side-build'>
            <div className='build-left-side-content'>
              <div className='build-left-number'>
                <h1 style={{color:"gray", fontSize:"50px", fontWeight:"300"}}><IoIosArrowRoundForward  style={{marginTop:"-18px"}}/></h1>
                <div className='build_text'>
                  <h1>1</h1>
                  <h5>Company Formation</h5>
                </div>

              </div>
              <div className='build-left-number'>
                <h1 style={{color:"gray", fontSize:"50px", fontWeight:"300"}}><IoIosArrowRoundForward  style={{marginTop:"-18px"}}/></h1>
                <div className='build_text'>
                  <h1>2</h1>
                  <h5>Trademark & Patent Services</h5>
                </div>

              </div>
              <div className='build-left-number'>
                <h1 style={{color:"gray", fontSize:"50px", fontWeight:"300"}}><IoIosArrowRoundForward  style={{marginTop:"-18px"}}/></h1>
                <div className='build_text'>
                  <h1>3</h1>
                  <h5> Website Designing & Development</h5>
                </div>

              </div>
              <div className='build-left-number'>
                <h1 style={{color:"gray", fontSize:"50px", fontWeight:"300"}}><IoIosArrowRoundForward  style={{marginTop:"-18px"}}/></h1>
                <div className='build_text'>
                  <h1>4</h1>
                  <h5>Mobile Applications</h5>
                </div>

              </div>

            </div>

          </div>

        </div>
        </div>
        </div> */}
        {/* <section>
          <BuildmsCart data={reportdata} />
        </section> */}
        <div dangerouslySetInnerHTML={{ __html: widget.widget1 }}></div>
        {/* <section>
        <Sellerbanner/>
    </section>

    <section>
        <BuildmsComp2/>
    </section>

    <section>
        <Services/>
    </section> */}

        <section>
          <BuildmsForm data={widget.widget2} />
        </section>
        <div className='container mb-4 mt-5'>
          <div className='vertical-stepper-section vertical-stepper-section1'>
            <div className='vertical-stteper-left'>
              <div className='empower-bussiness-content-vertical mb-4' >
                <h1>Why Choose ApnaShaher.com?</h1>
                <p>ApnaShaher.com connects your business with a wide audience, driving genuine inquiries and increasing your visibility. It's the perfect platform to grow your brand and reach local customers effectively!</p>
              </div>
              <div className='col-md-12 mt-5' >
                <p><img src={whychoose}></img></p>

              </div>

            </div>
            <div className='vertical-steper-right'>
              <div className='vertical-steper-card' style={{ height: "140px" }}>
                <h4><FaBusinessTime style={{ fontSize: "35px" }} /></h4>
                <div className=''>
                  <h4>Expert Guidance</h4>
                  <p>We brings years of startup expertise and a highly skilled team to support your entrepreneurial journey. With their extensive experience, you’ll have the guidance you need to succeed.</p>
                </div>
              </div>
              <div className='vertical-steper-card' style={{ height: "140px" }}>
                <h4><FaBusinessTime style={{ fontSize: "35px" }} /></h4>
                <div className=''>
                  <h4>Tailored Solutions</h4>
                  <p>Understanding that every business is unique, we tailored our services to meet your specific needs. Our personalized solutions are designed to help your enterprise thrive and grow.</p>
                </div>
              </div>
              <div className='vertical-steper-card' style={{ height: "140px" }}>
                <h4><FaBusinessTime style={{ fontSize: "35px" }} /></h4>
                <div className=''>
                  <h4>Comprehensive Support</h4>
                  <p>From shaping your idea to securing funding and beyond, ApnaShaher.com provides complete support at every stage of your entrepreneurial journey.</p>
                </div>
              </div>
              <div className='vertical-steper-card' style={{ height: "140px" }}>
                <h4><FaBusinessTime style={{ fontSize: "35px" }} /></h4>
                <div className=''>
                  <h4>Network of Investors</h4>
                  <p>Connect with investors, form partnerships, and gain expertise through our platform—your gateway to entrepreneurial success.</p>
                </div>
              </div>


            </div>

          </div>
        </div>
        <div className="container mb-4 mt-5">
          <div className="row">

            <div className="col-12 col-lg-8">
              <div className="startup-right-content">
                <h1 style={{ width: "100%" }}>Listing Your Startup for Success on Our Website</h1>
                <p>Established companies can boost their exposure and attract prospective financiers through
                  ApnaShaher.com, offering an original chance. By displaying your business on our platform, you not
                  only get a chance to highlight your achievements but also enhance your likelihood of attracting
                  investors who agree with the objectives you have set for your company. The purpose of our
                  platform is to serve as a catalyst, uniting you with the right investors who have trust in the
                  potential and success of your growing business.</p>
                <div className="startup-deal-btn" style={{ width: "100%" }}>
                  <Link to={'/startup-form'}>
                    <button className="raise-capital mobile-btn">List Startup</button>
                  </Link>
                </div>
              </div>
            </div>
            <div className="col-12 col-lg-4">
              <img src={topimage} style={{ borderRadius: "10px" }}></img>
            </div>


          </div>
        </div>
        {/* <div dangerouslySetInnerHTML={{ __html: widget.widget3 }}></div> */}
        {/* <section>
        <BuildsmBenefits/>
    </section>

    <section>
        <BuildmsComp2/>
    </section> */}

        {/* <section>
          <BuildmsCart data={reportdata} />
        </section> */}

        <div dangerouslySetInnerHTML={{ __html: widget.widget4 }}></div>
        <div className='container'>
          <div className='startup-recent-list-card'>
            <div className='startup-recent-card-design'>
              <img src={whychoose}></img>
              <div className='startup-recent-logo'>

              </div>
              <div className='startup-recent-content'>
                <div className='startup-recent-heading'>
                  <h4 className=''>Reality Games</h4>
                  <p>Discover innovative startups with high growth potential</p>
                  <div className='startup-category-list'>
                    <div className='startup-contry'>
                      <p>Startup</p>
                    </div>
                    <div className='startup-contry'>
                      <p>Fintech & Finance</p>
                    </div>
                    <div className='startup-contry'>
                      <p>Food & Drinks</p>
                    </div>
                    {/* <div className='startup-contry'>
                      <p>Startup</p>
                    </div> */}

                  </div>

                  <div className='startup-visitor-section mt-3'>
                    <p className='m-0'>Visitors</p>
                    <h6>1.5K View -  Last 30 Days</h6>
                    <div class="progress mb-2">
                      <div class="progress-bar progress-bar-striped progress-bar-animated" role="progressbar" aria-valuenow="75" aria-valuemin="0" aria-valuemax="100" style={{ width: "75%" }}></div>
                    </div>
                    <div className='startup-view-sec'>
                      <div className='startup-count'>
                        <p>Established Year</p>
                        <h6>2024</h6>
                      </div>
                      <div className='startup-line'>

                      </div>
                      <div className='startup-count'>
                        <p>Valuation</p>
                        <h6>$5.9M</h6>
                      </div>
                      <div className='startup-line'>

                      </div>
                      <div className='startup-count'>
                        <p>Revenue</p>
                        <h6>$5.9M</h6>
                      </div>


                    </div>


                  </div>

                </div>

              </div>

            </div>
            <div className='startup-recent-card-design'>
              <img src={whychoose}></img>
              <div className='startup-recent-logo'>

              </div>
              <div className='startup-recent-content'>
                <div className='startup-recent-heading'>
                  <h4 className=''>Reality Games</h4>
                  <p>Discover innovative startups with high growth potential</p>
                  <div className='startup-category-list'>
                    <div className='startup-contry'>
                      <p>Startup</p>
                    </div>
                    <div className='startup-contry'>
                      <p>Fintech & Finance</p>
                    </div>
                    <div className='startup-contry'>
                      <p>Food & Drinks</p>
                    </div>
                    {/* <div className='startup-contry'>
                      <p>Startup</p>
                    </div> */}

                  </div>

                  <div className='startup-visitor-section mt-3'>
                    <p className='m-0'>Visitors</p>
                    <h6>1.5K View -  Last 30 Days</h6>
                    <div class="progress mb-2">
                      <div class="progress-bar progress-bar-striped progress-bar-animated" role="progressbar" aria-valuenow="75" aria-valuemin="0" aria-valuemax="100" style={{ width: "75%" }}></div>
                    </div>
                    <div className='startup-view-sec'>
                      <div className='startup-count'>
                        <p>Established Year</p>
                        <h6>2024</h6>
                      </div>
                      <div className='startup-line'>

                      </div>
                      <div className='startup-count'>
                        <p>Valuation</p>
                        <h6>$5.9M</h6>
                      </div>
                      <div className='startup-line'>

                      </div>
                      <div className='startup-count'>
                        <p>Revenue</p>
                        <h6>$5.9M</h6>
                      </div>


                    </div>


                  </div>

                </div>

              </div>

            </div>
            <div className='startup-recent-card-design'>
              <img src={whychoose}></img>
              <div className='startup-recent-logo'>

              </div>
              <div className='startup-recent-content'>
                <div className='startup-recent-heading'>
                  <h4 className=''>Reality Games</h4>
                  <p>Discover innovative startups with high growth potential</p>
                  <div className='startup-category-list'>
                    <div className='startup-contry'>
                      <p>Startup</p>
                    </div>
                    <div className='startup-contry'>
                      <p>Fintech & Finance</p>
                    </div>
                    <div className='startup-contry'>
                      <p>Food & Drinks</p>
                    </div>
                    {/* <div className='startup-contry'>
                      <p>Startup</p>
                    </div> */}

                  </div>

                  <div className='startup-visitor-section mt-3'>
                    <p className='m-0'>Visitors</p>
                    <h6>1.5K View -  Last 30 Days</h6>
                    <div class="progress mb-2">
                      <div class="progress-bar progress-bar-striped progress-bar-animated" role="progressbar" aria-valuenow="75" aria-valuemin="0" aria-valuemax="100" style={{ width: "75%" }}></div>
                    </div>
                    <div className='startup-view-sec'>
                      <div className='startup-count'>
                        <p>Established Year</p>
                        <h6>2024</h6>
                      </div>
                      <div className='startup-line'>

                      </div>
                      <div className='startup-count'>
                        <p>Valuation</p>
                        <h6>$5.9M</h6>
                      </div>
                      <div className='startup-line'>

                      </div>
                      <div className='startup-count'>
                        <p>Revenue</p>
                        <h6>$5.9M</h6>
                      </div>


                    </div>


                  </div>

                </div>

              </div>

            </div>

          </div>
          <div className="startup-deal-btn mb-5" style={{ width: "100%", justifyContent: "center" }}>
            <Link to={'/'}>
              <button className="raise-capital mobile-btn">View All Startups</button>
            </Link>
          </div>
        </div>

        <Startuphighlights data={reportdata} />
        <div className="startup-footer">
          <h2 className="col-10 text-center">Are you prepared to dive in for your business success? To begin the exciting adventure of
            starting and growing your company, contact ApnaShaher.com.</h2>
          <div className="startup-deal-btn" style={{ width: "32%" }}>
            <Link to={'/'}>

              <button className="raise-capital mobile-btn">Startup For sell</button>
            </Link>
          </div>
        </div>

        {/* <section>
        <RecentStartup/>
    </section> */}


      </div>
    </>
  )
}
