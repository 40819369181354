import React from 'react';

const Discover = () => {
    return (
        <>
            <div className='container discover-section '  style={{marginTop:"60px"}}>
                <h1 >Discover your next business opportunity</h1>
                <div className='row gap-sm-2 gap-2 gap-lg-0 gap-md-0'>
                    <div className='col-md-4'>
                        <div className='discover-ist-col'>
                            <h2>Most popular</h2>
                            <div className='d-flex justify-content-between'>
                                <p>Paper Box</p>
                                <p style={{ textDecoration: "underline" ,cursor:"pointer"}}>View all products</p>
                            </div>
                            <img src="/assets/images/discover-1.png" />
                            <div className='row mt-3'>
                                <div className='col-lg-4  col-4'>
                                    <img src="/assets/images/discover-2.png" />
                                </div>
                                <div className='col-lg-4  col-4'>
                                    <img src="/assets/images/discover-3.png" />
                                </div>
                                <div className='col-lg-4 col-4'>
                                    <img src="/assets/images/discover-4.png" />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='col-md-4'>
                        <div className='discover-ist-col'>
                            <h2 className='text-center'>12,000+ products added today</h2>
                            <div className='row mt-2'>
                                <div className='col-md-6 col-6'>
                                    <img src="/assets/images/discover-5.png" />
                                </div>
                                <div className='col-md-6 col-6'>
                                    <img src="/assets/images/discover-6.png" />
                                </div>
                            </div>
                            <div className='row mt-3'>
                                <div className='col-md-4 col-4'>
                                    <img src="/assets/images/discover-7.png" />
                                </div>
                                <div className='col-md-4 col-4'>
                                    <img src="/assets/images/discover-8.png" />
                                </div>
                                <div className='col-md-4 col-4'>
                                    <img src="/assets/images/discover-9.png" />
                                </div>
                            </div>

                        </div>
                        <div className='discover-ist-col mt-3'>
                            <div className='row mt-2'>
                                <div className='col-md-4 col-4'>
                                    <img src="/assets/images/discover-5.png" width="100%" />
                                </div>
                                <div className='col-md-8 col-8 discover-mob'>
                                    <h2>New to this week</h2>
                                    <p>Products from verified supplier</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='col-md-4'>
                        <div className='discover-ist-col'>
                            <div className='row mt-2'> 
                                <div className='col-md-4 col-4' >
                                    <img src="/assets/images/discover-10.png" width="100%" />
                                </div>
                                <div className='col-md-8 col-8 discover-mob'>
                                    <h2 >New to this week</h2>
                                    <p>Products from verified supplier</p>
                                </div>
                            </div>
                        </div>
                        <div className='discover-ist-col last-child mt-2'>
                            <h2>Best deal for home decor </h2>
                            <img src="/assets/images/discover-11.png" width="100%" />
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Discover;