import axios from 'axios';
import React, { useState, useEffect } from 'react';
import { BASE_URL } from '../../../config';
import home1 from '../../../assets/h1.jpg'
import home2 from '../../../assets/h2.jpg'
import home3 from '../../../assets/h3.jpg'


const EmpowerBussiness = () => {
    const [allwidget, setAllwidget] = useState([]);
    const [selectedCard, setSelectedCard] = useState(0);

    const [images, setImages] = useState([
        home3, home1, home2]);

    const widgetData = async () => {
        try {
            const wgtData = await axios.get(BASE_URL + '/homepagewidget');
            setAllwidget(wgtData.data.data);
        } catch (err) {
            console.log(err);
        }
    };

    useEffect(() => {
        widgetData();
    }, []);

    return (
        <>
            <div className='desktop-view-empowering'>
                <div className="container">
                    <div className="empower-bussiness">
                        <div
                            className="empower-bussiness-content"
                            dangerouslySetInnerHTML={{ __html: allwidget.widget5 }}
                        ></div>
                        <div className="empowering-section">
                            {/* Top Image Section */}
                            <div className="empowering-image-section">
                                <img src={images[selectedCard]} alt="Empower" className="mb-1" />
                            </div>

                            {/* Bottom Card Section */}
                            <div className="empowering-bottom-card">
                                {[

                                    {
                                        title: 'List your business online to reach global buyers.',
                                        text:
                                            'Expand your business beyond local boundaries with ApnaShaher.com. Connect with global buyers and showcase your products and services to a worldwide audience!',
                                    },
                                    {
                                        title: 'Connect with Millions Searching for Services Like Yours',
                                        text:
                                            'Put your business in front of the right audience at the right time. List on ApnaShaher.com and attract customers actively searching for your services!',
                                    },
                                    {
                                        title: 'Find Trusted Sellers and Vendors Near You',
                                        text:
                                            'Easily search for local sellers and vendors in your area on ApnaShaher.com. Connect quickly and conveniently to meet all your business needs!',
                                    },
                                ].map((card, index) => (
                                    <div
                                        key={index}
                                        className={`empowering-card-tab ${selectedCard === index ? 'active-card' : ''
                                            }`}
                                        style={{
                                            background: selectedCard === index ? '#f0f8ff' : '#fff',
                                            cursor: 'pointer',
                                            padding: '10px',
                                            borderRadius: '8px',
                                            border: selectedCard === index ? '2px solid #007bff' : '1px solid #ddd',
                                            transition: 'background 0.3s, border 0.3s',
                                        }}
                                        onClick={() => setSelectedCard(index)}
                                    >
                                        <h4>{card.title}</h4>
                                        <p>{card.text}</p>
                                    </div>
                                ))}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className='mobile-view-empowering'>
                <div className="container">
                    <div
                        className="empower-bussiness-content"
                        dangerouslySetInnerHTML={{ __html: allwidget.widget5 }}
                    ></div>
                    <div className='emp-mobile'>

                        {[

                            {
                                img:home1,
                                title: 'List your business online to reach global buyers.',
                                text:
                                    'Expand your business beyond local boundaries with ApnaShaher.com. Connect with global buyers and showcase your products and services to a worldwide audience!',
                            },
                            {
                                img:home2,
                                title: 'Connect with Millions Searching for Services Like Yours',
                                text:
                                    'Put your business in front of the right audience at the right time. List on ApnaShaher.com and attract customers actively searching for your services!',
                            },
                            {
                                img:home3,
                                title: 'Find Trusted Sellers and Vendors Near You',
                                text:
                                    'Easily search for local sellers and vendors in your area on ApnaShaher.com. Connect quickly and conveniently to meet all your business needs!',
                            },
                        ].map((e, i) => (
                            <div className='emp-mobile-card'>
                                <img src={e.img} />
                                <h6 className='m-0'>{e.title}</h6>
                                <p>{e.text}</p>
                            </div>
                        ))}



                    </div>
                </div>
            </div>
        </>
    );
};

export default EmpowerBussiness;
