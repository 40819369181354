import React, { useState, useEffect } from "react";
import "../style/Directory.css";
import BannerPage from "../components/apna-shaher/components/Banner";
import { DirectoryComp2 } from "../components/directory/DirectoryComp2";
import { DirectoryComp3 } from "../components/directory/DirectoryComp3";
import { Directorybanner } from "../components/directory/Directorybanner";
import { Sellertestimonial } from "../components/seller-benefites/Sellertestimonial";
import axios from "axios";
import { BASE_URL } from "../config";
import Loader from "../components/loader/Loader.component";
import ProfessionalClient from "../components/Services/Components/ProfessionalClient";
import { BannerNew } from "../components/apna-shaher/components/BannerNew";
import SeoDirectoryPage from "../seo page/SeoDirectorypage";
import UpdatedBannerPage from "../components/apna-shaher/components/Updatedbanner";
import ProfessionalServices from "../components/Services/Components/ProfessionalServices";
import EmpowerBussiness from "../components/apna-shaher/components/EmpowerBussiness";
import { MdHorizontalDistribute, MdMiscellaneousServices, MdOutlineArrowForwardIos, MdOutlinePrecisionManufacturing } from "react-icons/md";
import { HiOutlineRocketLaunch } from "react-icons/hi2";
import { TbPackageExport } from "react-icons/tb";
import { CiShop } from "react-icons/ci";
import { IoBookOutline } from "react-icons/io5";
import { Link } from "react-router-dom";
import EmpowerBussinessdirectory from "../components/apna-shaher/components/Empoweringbusinessdirectorypage";
export const Directory = () => {
  const [widget, setwidget] = useState("")
  const [isLoading, setIsLoading] = useState(true);
  const [sellerdata, setsellerdata] = useState([]);
  const [pdata, setpdata] = useState([]);
  const [premdata, setpremdata] = useState([]);
  const [searchdata, setsearchdata] = useState([]);

  const [servicedata, setservicedata] = useState([]);
  const [allwidget, setAllwidget] = useState([]);
  const widgetData1 = async () => {
    try {
      const wgtData = await axios.get(BASE_URL + "/homepagewidget");
      setAllwidget(wgtData.data.data)
    }
    catch (err) {
      console.log(err)
    }
  }



  useEffect(() => {
    widgetData1()

  }, [])
  const getservices = () => {
    axios
      .get(BASE_URL + "/popularcategoriesservices")
      .then(({ data }) => {
        setpdata(data.data);


      })
      .catch(({ response }) => { });
    axios
      .get(BASE_URL + "/premiumcategoriesservices")
      .then(({ data }) => {
        setpremdata(data.data);


      })
      .catch(({ response }) => { });
    axios
      .get(BASE_URL + "/servicecategorysearch")
      .then(({ data }) => {
        setsearchdata(data.data);


      })
      .catch(({ response }) => { });
    axios
      .get(BASE_URL + "/getcategorywithservice")
      .then(({ data }) => {
        setservicedata(data.data);


      })
      .catch(({ response }) => { });

  }
  useEffect(() => {
    window.scrollTo(0, 0);
    widgetData();
    getservices();
  }, []);

  useEffect(() => {
    const timer = setTimeout(() => {
      getprefredsellerdata();
    }, 3000);
    return () => clearTimeout(timer);
  }, [])
  const getprefredsellerdata = () => {
    axios
      .get(BASE_URL + "/getprefredsellerdata")
      .then(({ data }) => {
        setsellerdata(data);


      })
      .catch(({ response }) => { });
  }
  const widgetData = async () => {
    try {
      const wgtData = await axios.get(BASE_URL + "/directorywidget");
      setwidget(wgtData.data.data);
      setIsLoading(false);
    } catch (err) {
      console.log(err);
      setIsLoading(false);
    }
  };
  const [directorydata, setdirectorydata] = useState([])
  useEffect(() => {
    getdirectorydata();
  }, []);
  const getdirectorydata = async () => {
    try {
      const dirData = await axios.get(BASE_URL + "/getdirectory");
      setdirectorydata(dirData.data.data);
    } catch (err) {
      console.log(err);
    }
  };
  const generateRandomColor = () => {
    // window.location.reload();
    const randomColor = Math.floor(Math.random() * 16777215).toString(16); // Generate random hexadecimal color
    const color = "#" + randomColor;

    // Convert hexadecimal color to RGB
    const r = parseInt(color.substr(1, 2), 16);
    const g = parseInt(color.substr(3, 2), 16);
    const b = parseInt(color.substr(5, 2), 16);

    // Set opacity to 30%
    return `rgba(${r},${g},${b},0.05)`;
  };
  if (isLoading) {
    return <Loader />;
  }


  return (
    <>
      <SeoDirectoryPage />
      <div style={{ background: "#ffffff" }}>


        <section>
          <div className="show-desktop-banner">
            <div className="new-startup-top-banner" style={{ background: "url(/assets/images/btop.jpg)" }}>
              <div className="startup-right-content-banner">
                <h1>Explore a World of Businesses – List Your Business or Find Exactly What You Need!</h1>
                <p>Explore businesses in our directory or register to showcase your own. Whether you're a customer or a seller, find what you need or get noticed today!</p>
                <div className="startup-deal-btn" style={{ width: "100%" }}>
                  <Link to={'/directory-all-category'}>
                    {/* <Link to={'/comming-soon'}> */}
                    <button className="raise-capital">Show Directory</button>
                  </Link>
                </div>
              </div>
            </div>


            <div className="product-banner-bottom">
              <div className="container">
                <div className="d-flex justify-content-between align-items-center" >
                  <h6 className="cate-head mb-5 heading-bline position-relative">Search By Business Model</h6>

                </div>
                <div className="product_filter">
                  {directorydata.map((e, index) => (
                    <Link to={`/directory-listing/${e.title}`} >
                    {/*    <Link to={`/comming-soon`} > */}
                      <div className="product_filter_card" style={{ backgroundColor: generateRandomColor() }} >

                        <h2 className="d-flex align-items-center "><img src={e.icon} style={{ width: "35px", height: "35px", marginRight: "15px" }}></img> {e.title}
                        </h2>
                        <div className="product_filter_card_text">
                          <h2 ><MdOutlineArrowForwardIos /></h2>
                        </div>
                      </div>
                    </Link>
                  ))}

                  {/* <Link to={'/directory-listing/Exporters & Importers'} >
                    <div className="product_filter_card" style={{ background: "#fcf2cd" }}>
                      <h2><TbPackageExport style={{ marginTop: "-6px", marginRight: "10px" }} /></h2>
                      <h2 className=""> Exporters &  Importers
                      </h2>
                      <h2><MdOutlineArrowForwardIos /></h2>
                    </div>
                  </Link>
                  <Link to={'/directory-listing/Suppliers'} >
                    <div className="product_filter_card" style={{ background: "#e0f3f0" }}>
                      <h2><MdOutlinePrecisionManufacturing style={{ marginTop: "-6px" }} /> Suppliers                                      </h2>
                      <h2><MdOutlineArrowForwardIos /></h2>
                    </div>
                  </Link>
                  <Link to={'/directory-listing/Distributors'} >
                    <div className="product_filter_card" style={{ background: "#f3e0e8" }} >
                      <h2><MdHorizontalDistribute style={{ marginTop: "-6px" }} /> Distributors</h2>
                      <h2><MdOutlineArrowForwardIos /></h2>
                    </div>
                  </Link>
                  <Link to={'/directory-listing/Retailers'} >
                    <div className="product_filter_card" style={{ background: "#e0f3f0" }} >
                      <h2>
                        <CiShop style={{ marginTop: "-6px" }} /> Retailers</h2>
                      <h2><MdOutlineArrowForwardIos /></h2>
                    </div>
                  </Link>
                  <Link to={'/directory-listing/Service Providers'} >
                    <div className="product_filter_card" style={{ background: "#f3e0e8" }} >
                      <h2>
                        <MdMiscellaneousServices style={{ marginTop: "-6px" }} /> Service Provider</h2>
                      <h2><MdOutlineArrowForwardIos /></h2>
                    </div>
                  </Link>
                  <Link to={'/directory-listing/Educational Institutes'} >
                    <div className="product_filter_card" style={{ background: "#fcf2cd" }} >
                      <h2>
                        <IoBookOutline style={{ marginTop: "-6px" }} /> Education</h2>
                      <h2><MdOutlineArrowForwardIos /></h2>
                    </div>
                  </Link>
 */}


                </div>
              </div>
            </div>
            <section>
              <DirectoryComp2 data={widget} />
            </section>
            {/* <div className="banner-bottom-part mt-5">
                    <div className="" dangerouslySetInnerHTML={{ __html: allwidget.widget1 }}></div>
                </div> */}
            {/* <UpdatedBannerPage /> */}
          </div>
          <div className="show-mobile-banner">

            {/* <div className="new-startup-top-banner" style={{ background: "url(/assets/images/btop.jpg)" }}>
              <div className="startup-right-content-banner">
                <h1>Explore a World of Businesses – List Your Business or Find Exactly What You Need!</h1>
                <p>Explore businesses in our directory or register to showcase your own. Whether you're a customer or a seller, find what you need or get noticed today!</p>
                <div className="startup-deal-btn" style={{ width: "100%" }}>
                  <Link to={'/directory-all-category'}>
                   
                    <button className="raise-capital mobile-btn">Show Directory</button>
                  </Link>
                </div>
              </div>
            </div> */}


            <div className="product-banner-bottom">
              <div className="container">
                <div className="d-flex justify-content-between align-items-center" >
                  <h6 className="cate-head mb-5 heading-bline position-relative">Search By Business Model</h6>

                </div>
                <div className="product_filter">
                  {directorydata.map((e, index) => (
                    <Link to={`/directory-listing/${e.title}`} >
                    {/* // <Link to={`/comming-soon`} > */}
                      <div className="product_filter_card" style={{ backgroundColor: generateRandomColor() }} >

                        <h2 className="d-flex align-items-center"><img src={e.icon} style={{ width: "35px", height: "35px" }}></img> {e.title}
                        </h2>

                        <h2 className="product_filter_card_text"><MdOutlineArrowForwardIos /></h2>
                      </div>
                    </Link>
                  ))}




                </div>
              </div>
            </div>
            <section>
              <DirectoryComp2 data={widget} />
            </section>


          </div>
        </section>


        <ProfessionalServices data={premdata} />
        {/* <div dangerouslySetInnerHTML={{__html:widget.widget2}}>

      </div> */}
        <EmpowerBussinessdirectory />

        {/* <section> 
        <DirectoryComp3/>
      </section>

      <section>
        <Directorybanner/>
      </section> */}
        <div className="container">
          <div className="row">
            <div className="col-12">
              <div className="empower-bussiness-content-vertical mt-5 mb-5 col-12 col-lg-8">
                <h1>Why List on ApnaShaher.com?</h1>

                <p>Listing on ApnaShaher.com helps your business gain increased visibility and attract a steady stream of customers. Reach local and global buyers effortlessly, expand your network, and drive growth with ease!</p>
                <button>Register Now</button>
              </div>
            </div>
            <div className="why-investdesktop">
              <div className="col-12 d-flex gap-3">
                <div className=" col-3 mb-4">
                  <div className="why-invest">
                    <div className="why-invest-card">
                      <img src="/assets/images/earnheigh.svg"></img>
                      <h4 className="m-0 m-font">Increased Visibility</h4>
                      <p className="m-font-p">Reach a wider audience and get discovered by potential customers. </p>

                    </div>

                  </div>
                </div>
                <div className="col-3 mb-4">
                  <div className="why-invest">
                    <div className="why-invest-card">
                      <img src="/assets/images/divercity.svg"></img>
                      <h4 className="m-0">Targeted Exposure</h4>
                      <p>Attract relevant customers by being listed in the right categories. </p>

                    </div>

                  </div>
                </div>
                <div className="col-3 ">
                  <div className="why-invest">
                    <div className="why-invest-card">
                      <img src="/assets/images/divercity.svg"></img>
                      <h4 className="m-0">Easy Profile Management</h4>
                      <p>Update your business information quickly and hassle-free. </p>

                    </div>

                  </div>
                </div>
                <div className="col-3 ">
                  <div className="why-invest">
                    <div className="why-invest-card">
                      <img src="/assets/images/divercity.svg"></img>
                      <h4 className="m-0">24/7 Online Presence</h4>
                      <p>Stay accessible to buyers anytime. </p>

                    </div>

                  </div>
                </div>
              </div>
            </div>
            <div className="why-investmobile">
              <div className="why-investcard">

                <img src="/assets/images/earnheigh.svg"></img>
                <div className="why-invest-content">
                  <h4 className="m-0 m-font">Increased Visibility</h4>
                  <p className="m-font-p">Reach a wider audience and get discovered by potential customers. </p>
                </div>

              </div>
              <div className="why-investcard">

                <img src="/assets/images/earnheigh.svg"></img>
                <div className="why-invest-content">
                  <h4 className="m-0">Targeted Exposure</h4>
                  <p>Attract relevant customers by being listed in the right categories. </p>

                </div>

              </div>
              <div className="why-investcard">

                <img src="/assets/images/earnheigh.svg"></img>
                <div className="why-invest-content">
                  <h4 className="m-0">Easy Profile Management</h4>
                  <p>Update your business information quickly and hassle-free. </p>

                </div>

              </div>
              <div className="why-investcard">

                <img src="/assets/images/earnheigh.svg"></img>
                <div className="why-invest-content">
                  <h4 className="m-0">24/7 Online Presence</h4>
                  <p>Stay accessible to buyers anytime. </p>
                </div>

              </div>

            </div>
          </div>
        </div>

        <ProfessionalClient data={sellerdata} heading="Preferred Sellers" />
        {/* <section>
        <Sellertestimonial data={widget}/>
      </section> */}

      </div>
    </>
  );
};
