import React, { useEffect, useState } from 'react'
import "../style/Blogs1.css"
import SeoOurteam from '../seo page/SeoOurteam'
import teamimg from '../assets/business-growth.jpg'
import { Link, NavLink } from 'react-router-dom';
import Axios from 'axios';

export const OurTeam = () => {
    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])
    const [teamdata, setTeamdata] = useState([])


    useEffect(() => {

        getourteam();
    }, [])
    const getourteam = () => {
        Axios.get('/team').then(({ data }) => {
            setTeamdata(data.data.teams);
        }).catch(({ response }) => {
            console.log(response.error)
        })
    }
   
    return (
        <>
            <SeoOurteam />
            <div className="our-team">
                <div className="px-lg-5 px-md-4 px-3 py-3">
                    <div className="start-up-top-card" style={{ height: "230px" }}>
                        <h1>Meet Our Team</h1>
                        <p className="col-11 col-lg-8 text-center">Our team is a blend of experienced professionals and passionate innovators dedicated to empowering businesses. With expertise across various fields, we work tirelessly to drive success for our clients and partners</p>



                    </div>

                    <div className="cards-container d-flex flex-wrap gap-3 ">
                        {teamdata.map((e, i) => (
                            <div class="card" key={e.id}>
                                <div className="img-div mx-lg-3 mx-2 my-lg-3 my-3">
                                    <img src={e.image} class="card-img-top" alt="..." />
                                </div>

                                <div class="card-body text-center pt-0">
                                    <h5 class="card-title">{e.name}</h5>
                                    <p className='bold-para'>{e.designation}</p>
                                    <p class="card-text hover-show">Lorem nisi ut aliquip ex ea commodo consequat.Lorem ipsum dolor sit amet, consectetur adipiscing elit. Lorem nisi ut aliquip ex.</p>
                                </div>
                            </div>
                        ))}





                    </div>
                </div>
            </div>
        </>
    )
}
