import React, { useEffect } from "react";
import "../style/Faqsellerbuyer.css";
import { Faqbanner } from "../components/faq-seller-buyer/Faqbanner";
import { Faqbody } from "../components/faq-seller-buyer/Faqbody";

export const Faqsellerbuyer = () => {
  
  useEffect(() => {
    window.scrollTo(0, 0);
  },[])

  return (
    <>
      <section className="faq-banner-sec">
        
        <div className="new-startup-top-banner" style={{ background: "url(/assets/images/faq.jpg)" }}>
                       <div className="startup-right-content-banner">
                           <h1>Have Questions? Get Answers to your Questions with Ease</h1>
                           <p>Find everything you need to successfully sell and grow your business on ApnaShaher.com. Browse frequently asked questions to get quick answers to your queries!</p>
                          
                       </div>
                   </div>
      </section>
      <section className="faq-body-sec">
        <Faqbody />
      </section>
    </>
  );
};
