// import React, { useState } from "react";
// import "../../style/SellerDetails.css";
// import { Link } from "react-router-dom";
// import { IMAGE_BASE_URL } from "../../config";
// export const SdComp2 = (props) => {
//   const [isTrusted, setisTrusted] = useState(true);
//   const [isVarified, setisVarified] = useState(true);
//   return (
//     <>
//       <div className="seller-detials-comp2 mb-lg-5 mb-4">
//         <div className="container d-flex justify-content-between gap-3">
//           <div className="company-logo-div d-flex flex-column gap-lg-3 gap-md-3 gap-2 align-items-center ">
//             <img
//               src={IMAGE_BASE_URL + props?.data?.data?.logo}
//               className="logo"
//               alt=""
//               width="213px"
//             />
//             <div className="d-flex gap-2 justify-contetn-between">
//               {props?.data?.seller?.trust_seal == "Yes" && (
//                 <img
//                   src="/assets/images/trust.png"
//                   className="verified-logo"
//                   alt=""
//                   width="111px"
//                   height={"55px"}
//                 />
//               )}
//               {props?.data?.seller?.verified_seller == "Yes" && (
//                 <img
//                   src="/assets/images/verify.png"
//                   className="verified-logo"
//                   alt=""
//                   width="111px"
//                   height={"55px"}
//                 />
//               )}
//             </div>
//           </div>
//           <div className="company-name pt-4">
//             <h1>{props?.data?.data?.business_name}</h1>
//             <div className="d-flex gap-3 align-items-center my-lg-3 my-3">
//               <div className="d-flex gap-2 align-items-center">
//                 <img
//                   src={`https://flagsapi.com/${props?.data?.data?.countries?.code}/flat/64.png`}
//                   alt="Country Logo"
//                 />
//                 <h4 className="mb-0">{props?.data?.data?.countries?.code}</h4>
//               </div>

//               <p className="mb-0 ps-2">{props?.data?.data?.full_address}</p>
//             </div>
//             {props?.data?.data &&
//               props?.data?.data?.business_category?.map((item, i) => (
//                 <button className="mx-1">{item?.label}</button>
//               ))}
//           </div>

//           <div className="contact-details d-flex flex-column gap-2 pt-4">
//             {props?.data?.data?.business_mobilenumber && (
//               <Link
//                 to={"tel:" + props?.data?.data?.business_mobilenumber[0]}
//                 style={{ backgroundColor: "#1351C1", color: "#fff" }}
//               >
//                 <span className="pe-2 ">
//                   <i class="fa fa-phone"></i>
//                 </span>{" "}
//                 Call Now
//               </Link>
//             )}

//             <Link to={"mailto:" + props?.data?.data?.business_email}>
//               <span className="pe-2 ">
//                 <i class="fa fa-share"></i>
//               </span>{" "}
//               Send Enquiry
//             </Link>
//             <Link
//               to={
//                 "https://api.whatsapp.com/send?phone=" +
//                 props?.data?.data?.whatsapp_number
//               }
//               target="_blank"
//             >
//               <span className="pe-2 ">
//                 <i class="fa fa-whatsapp"></i>
//               </span>{" "}
//               Chat
//             </Link>
//           </div>
//         </div>
//       </div>
//     </>
//   );
// };
import React, { useState } from 'react'
import "../../style/SellerDetails.css"
import { Link } from 'react-router-dom'
import { IMAGE_BASE_URL } from '../../config'
import { CiLocationOn } from "react-icons/ci";
import { IoLocationSharp } from 'react-icons/io5';
import { IoIosStar, IoIosStarHalf } from 'react-icons/io';
export const SdComp2 = (props) => {
    const [isTrusted,setisTrusted] = useState(true)
    const [isVarified,setisVarified] = useState(true)
    return (
        <>

            <div className="seller-detials-comp2 mb-lg-5 mb-4">
                <div className="container d-flex gap-3">
                    <div className="company-logo-div d-flex flex-column gap-lg-3 gap-md-3 gap-2 align-items-center ">
                        <img src={IMAGE_BASE_URL + props?.data?.data?.logo} className='logo' alt="" />
                        <div className="d-flex gap-2 justify-contetn-between">
                            {
                                props?.data?.trust_seal == "Yes" && <img src="/assets/images/verified.png" className='verified-logo' alt="" width="111px" height={"55px"} />
                            }
                            {
                                props?.data?.verified_seller == "Yes" && <img src="/assets/images/verified.png" className='verified-logo' alt="" width="111px" height={"55px"} />  
                            }
                        </div>
                    </div>
                    <div className='d-flex justify-content-between ' style={{width:"100%"}}>
                    <div className="company-name pt-4">
                        <h1>{props?.data?.data?.business_name}<span style={{fontSize:"14px", fontWeight:"600", color:"gray", marginLeft:"15px"}}><span style={{color:"orange", marginTop:"-4px"}}><IoIosStar style={{marginTop:"-4px"}} /> <IoIosStar style={{marginTop:"-4px"}} /> <IoIosStar style={{marginTop:"-4px"}} /> <IoIosStar style={{marginTop:"-4px"}} /> <IoIosStarHalf style={{marginTop:"-4px"}} /></span>(128 Reviews)</span></h1>
                        <div className="d-flex gap-3 align-items-center mt-3 mb-1">
                            <div className="d-flex gap-2 align-items-center">
                                <img src={`https://flagsapi.com/${props?.data?.data?.countries?.code}/flat/64.png`} alt="Country Logo" style={{width:"20px", borderRadius:"50%", marginTop:"-5px"}} />
                                <h4 className='mb-0'>{props?.data?.data?.countries?.code}</h4>
                            </div>

                            <p className='mb-0 ps-2'><IoLocationSharp style={{marginTop:"-4px"}} /> {props?.data?.data?.address} | <span style={{fontSize:"14px", fontWeight:"600", color:"blue", marginLeft:"5px"}}><i class="fa fa-edit"></i> Claim this Listing</span></p>
                        </div>
                        <div className='company_categories'>
                            {props?.data?.data &&
               props?.data?.data?.business_category?.map((item,i)=>(
                                <button className='mx-1'>{item?.label}</button>
                            ))}
                        </div>
                        <div className='company_contact'>
                        <Link to={"tel:"+props?.data?.data?.mobile_number} className='call-now-button '><span className='pe-2 '><i class="fa fa-phone"></i></span> Call Now</Link>
                        <Link to={"https://api.whatsapp.com/send?phone=" + props?.data?.data?.whatsapp_number} target="_blank" className='chat-now-button '><span className='pe-2 '><i class="fa fa-whatsapp"></i></span> WhatsApp</Link>
                        <Link to={"https://api.whatsapp.com/send?phone=" + props?.data?.data?.whatsapp_number} target="_blank" className='live-chat-now-button '><span className='pe-2 '><i class="fa fa-comments"></i></span> Live Chat</Link>
                        
                        <Link to={"mailto:"+props?.data?.data?.email} className='send-enquiry '><span className='pe-2 '><i class="fa fa-share"></i></span> Send Enquiry</Link>
                        <Link to={"mailto:"+props?.data?.data?.email} className='share-page '><span ><i class="fa fa-share-alt"></i></span> </Link>

                        </div>
                    </div>

                    <div className="contact-details d-flex flex-column gap-2 pt-4">
                        {/* <Link to={"tel:"+props?.data?.mobile_number} style={{  }}><span className='pe-2 '><i class="fa fa-phone"></i></span> Call Now</Link> */}
                        {/* <Link to={""} style={{padding:"10px 20px", marginTop:"-5px"}}><span className='pe-2 '></span> Pitch Deck</Link> */}
                        {/* <Link to={"https://api.whatsapp.com/send?phone=" + props?.data?.data?.whatsapp_number} target="_blank"><span className='pe-2 '><i class="fa fa-whatsapp"></i></span> Chat</Link> */}
                    </div>
                    </div>
                </div>
            </div>
        </>
    )
}
