import React, { useState,useEffect } from "react";
import "../../style/BuildmyStartup.css";
import axios from "axios";
import { BASE_URL } from "../../config";
import { ToastContainer,toast } from "react-toastify";
import { Link } from "react-router-dom";

export const BuildmsForm = (props) => {
  const [startupData, setStartupData] = useState({
    name: null,
    email: null,
    mobile_number: null,
    business_name: null,
    confirm: null,
    business_category:null,
    company_register_id:null,
    full_address: null,
    country: null,
    state:null,
    city:null,
    pin_code:null,
    have_website: null,
    website_url:null,
    term_condition:null,
    services:[]
  });
  const [category, setCategory] = useState([])
  const [error, seterror] = useState([])
  const[ToggleForm, setToggleForm] = useState(0);
  const [fetchCountry, setFetchedCountry] = useState([]);
  const [fetchState, setFetchedState] = useState([]);
  const [fetchCity, setFetchedCity] = useState([]);
useEffect(()=>{
  FetchBusinessCategory();
  getCountry();
},[])
  const handleData = (e) => {
    const { name, value,checked } = e.target;
    setStartupData((prevData) => ({
      ...prevData,
      [name]: name === "mobile_number" ? Number(value) : (name === "notified_complaint" ? 
        (checked ? [...prevData.notified_complaint, value] : prevData.notified_complaint.filter((item) => item !== value)) 
        : value),
    }));
    
  };
  const FetchBusinessCategory = async () => {
    await axios.get(BASE_URL + '/getbusinesscategory').then(({ data }) => {
      setCategory(data.data)
      // console.log(data.data)
    })
  }
  const getCountry = () => {

    axios.get(`${BASE_URL}/country`)
      .then(function (res) {
        setFetchedCountry(res.data.data)
      })
      .catch(function (error) {
        // console.log(error);
      })
  }
  const getState = (name) => {

    axios.get(`${BASE_URL}/state/${name}`)
      .then(function (res) {
        setFetchedState(res.data.data)
      })
      .catch(function (error) {
        // console.log(error);
      })
  }
  const getCity = (id) => {
    axios.get(`${BASE_URL}/city/${id}`)
      .then(function (res) {
        setFetchedCity(res.data.data)
      })
      .catch(function (error) {
        // console.log(error);
      })
  };
  const handleForm = (event) => {
      event.preventDefault();
      axios
      .post(BASE_URL + `/addstartupenquirys`, startupData)
      .then((res) => {
        if (res.status == "201") {
          // console.log("set enqiury called");
          toast.success("Submitted Successfully");
          document.getElementsByClassName("startuppform")[0].reset();
          setStartupData("");
          setToggleForm(0)
        }
      })
      .catch(({ response }) => {
        seterror(response.data.errors);
        if(response.data.errors.name || response.data.errors.mobile_number || response.data.errors.email || response.data.errors.business_name || response.data.errors.confirm){
          setToggleForm(0)
        }else if(response.data.errors.business_category || response.data.errors.company_register_id || response.data.errors.full_address || response.data.errors.country || response.data.errors.state || response.data.errors.city || response.data.errors.pin_code || response.data.errors.have_website || response.data.errors.website_url){
          setToggleForm(1)
        }else{
          setToggleForm(2)
        }
      });
      // setToggleForm(2)
      // if(startupData.name && startupData.email && startupData.mobile_number && startupData.business_name !== null ){
      //   setToggleForm(1)
      // }else {
      //   document.querySelector(".alert-para").style.display = "block"
      // }
      
      // if(startupData.business_category && startupData.company_register_id && startupData.full_address && startupData.country && startupData.state && startupData.city && startupData.pin_code !== null ){
      //   setToggleForm(2)
      // }else {
      //   document.querySelector(".alert-para").style.display = "block"
      // }
  };

  return (
    <div className="build-ms-form my-lg-5 my-4 ">
      <div className="container d-flex flex-lg-row flex-column justify-content-between gap-4">

        <div className="col-lg-6" >
          <p className="d-flex " dangerouslySetInnerHTML={{__html:props?.data}}  style={{display:"flex",flexDirection:"column",justifyContent:"center",alignItems:"center", height:"100%"}}></p>
          
        </div>
        <div className="col-lg-5 form-div">
          <h4>
            Fill the Form & Start your Startup with{" "}
            <Link to="/">APNASHAHER.COM</Link>
          </h4>
          <div className="stage mt-4">
            {/* <span className="active">Basic Details</span> */}
            <span className={`${ToggleForm ===0 ? "active":""}`} >Basic Details</span>
            <span className={`${ToggleForm ===1 ? "active":""}`}>Company Details</span>
            <span className={`${ToggleForm ===2 ? "active":""}`}>Service</span>
          </div>
          <form action="" className="mt-4 startuppform">
            <h6 className="text-danger my-2 fw-medium alert-para ">All Fields are Required*</h6>
            {ToggleForm ===0 ? (
              <>
                <div className="d-flex my-2 flex-wrap gap-2 justify-content-between align-items-center">
                  <input
                    type="text"
                    className="col-6"
                    name="name"
                    placeholder="Enter Full Name"
                    onChange={handleData}
                  />
                  {error && error.name && (
                  <div className="text-danger">{error.name}</div>
                )}
                  <input
                    type="text"
                    className="col-6"
                    name="email"
                    placeholder="Enter Email Id"
                    onChange={handleData}
                  />
                  {error && error.email && (
                  <div className="text-danger">{error.email}</div>
                )}
                </div>
                <div className="d-flex my-2 flex-wrap gap-2 justify-content-between align-items-center ">
                  <input
                    type="number"
                    name="mobile_number"
                    max={10}
                    className="col-6"
                    placeholder="Mobile Number"
                    onChange={handleData}
                  />
                  {error && error.mobile_number && (
                  <div className="text-danger">{error.mobile_number}</div>
                )}
                  <div
                    className="col-6 px-4 company-confirm "
                    
                  >
                    <p className="mb-1">Company Registered</p>
                    <input
                      type="radio"
                      name="confirm"
                      value="yes"
                      onChange={handleData}
                      className="mx-3"
                      style={{ width: "fit-content" }}
                    />
                    Yes
                    <input
                      type="radio"
                      name="confirm"
                      onChange={handleData}
                      value="no"
                      className="mx-3"
                      style={{ width: "fit-content" }}
                    />
                    No
                  </div>
                  {error && error.confirm && (
                  <div className="text-danger">{error.confirm}</div>
                )}
                </div>
                {startupData.confirm == "yes" &&(
                  <>
                  <input
                  type="text"
                  name="business_name"
                  id=""
                  className="d-block my-2 mb-4"
                  placeholder="Business Name"
                  onChange={handleData}
                />
              
                  
                  {error && error.business_name && (
                  <div className="text-danger">{error.business_name}</div>
                )}
                  </>
                    )}
                  
                
                <button type="submit" onClick={handleForm}>
                  Next
                </button>
              </>
            ) : ToggleForm === 1 ?
             (
                <>
                  <div className="d-flex my-2 flex-wrap gap-2 justify-content-between align-items-center">
                    <select
                      class="form-select rounded-0" aria-label="Default select example" name="business_category" onChange={handleData} >
                      <option value="">Business Category</option>
                    {category && category.map((item,i)=>(
                      <option value={item.value}>{item.label}</option>
                    ))}
                      
                      
                    </select>
                    {error && error.business_category && (
                  <div className="text-danger">{error.business_category}</div>
                )}
                    <input
                      type="text"
                      className="col-6"
                      name="company_register_id"
                      value={startupData.company_register_id}
                      placeholder="Company Register Number"
                      onChange={handleData}
                    />
                    {error && error.company_register_id && (
                  <div className="text-danger">{error.company_register_id}</div>
                )}
                  </div>
                  <div>
                      <input type="text" className="full-address" value={startupData.full_address} name="full_address" placeholder="Full Address" onChange={handleData} />
                      {error && error.full_address && (
                  <div className="text-danger">{error.full_address}</div>
                )}
                  </div>
                  <div className="d-flex my-2 flex-wrap gap-2 justify-content-between align-items-center ">
                  <select
                      class="form-select rounded-0" aria-label="Default select example" name="country" onChange={(e) => { handleData(e); getState(e.target.value) }} >
                      <option value="">Select Country</option>
                      {fetchCountry && fetchCountry.map((data) => (
                              <option value={data.id}>{data.name}</option>
                            ))}
                      
                      
                    </select>
                    {error && error.country && (
                  <div className="text-danger">{error.country}</div>
                )}
                    <select
                      class="form-select rounded-0" aria-label="Default select example" name="state" onChange={(e) => { handleData(e); getCity(e.target.value) }}  >
                      <option value="">Select State</option>
                      {fetchState && fetchState.map((data) => (
                              <option value={data.id}>{data.name}</option>
                            ))}
                      
                      
                    </select>
                    {error && error.state && (
                  <div className="text-danger">{error.state}</div>
                )}
                    
                    

                  </div>
                  <div className="d-flex my-2 flex-wrap gap-2 justify-content-between align-items-center ">
                  <select
                      class="form-select rounded-0" aria-label="Default select example" name="city" onChange={handleData} >
                      <option value="">Select City</option>
                    {fetchCity && fetchCity.map((item,i)=>(
                      <option value={item.id}>{item.name}</option>
                    ))}
                      
                      
                    </select>
                    {error && error.city && (
                  <div className="text-danger">{error.city}</div>
                )}

                    <input
                      type="text"
                      name="pin_code"

                      className="col-6"
                      placeholder="Pin Code"
                      onChange={handleData}
                    />
                      {error && error.pin_code && (
                      <div className="text-danger">{error.pin_code}</div>
                    )}
                    <div
                      className="col-6 px-4 company-confirm "
                      
                    >
                      <p className="mb-1">Already have website</p>
                      <input
                        type="radio"
                        name="have_website"
                        onChange={handleData}
                        value="yes"
                        className="mx-3"
                        style={{ width: "fit-content" }}
                      />
                      Yes
                      <input
                        type="radio"
                        name="have_website"
                        onChange={handleData}
                        value="no"
                        className="mx-3"
                        style={{ width: "fit-content" }}
                      />
                      No
                      {error && error.have_website && (
                      <div className="text-danger">{error.have_website}</div>
                    )}
                    </div>
                  {startupData.have_website == "yes" ? <input
                      type="text"
                      name="website_url"

                      className="col-6"
                      placeholder="Website Url"
                      onChange={handleData}
                    />: null}
                         {error && error.website_url && (
                      <div className="text-danger">{error.website_url}</div>
                    )}
                  </div>
                  <button type="button" onClick={handleForm}>
                    Next
                  </button>
                </>):

              ToggleForm ===2 ?
              (<>
                <h5 className="mb-3">
                  Please select the services you are interested in
                </h5>
                <div className="d-flex  flex-lg-nowrap flex-md-nowrap flex-sm-nowrap flex-wrap my-2  gap-2 justify-content-between align-items-center ">
                  <div className="col-lg-6 col-md-6 col-sm-6 col-12 d-flex  gap-3 align-items-center">
                    <input
                      type="checkbox"
                      name="interested_services"
                      style={{ width: "fit-content" }}
                      value="Business Incorporation"
                      onChange={handleData}
                    />
                    <p className="mb-0">Business Incorporation</p>
                  </div>
                  <div className="col-lg-6 col-md-6 col-sm-6 col-12 d-flex gap-3 align-items-center">
                    <input type="checkbox" value="Legal & Compliances Services" name="interested_services" onChange={handleData} style={{ width: "fit-content" }} />
                    <p className="mb-0">Legal & Compliances Services</p>
                  </div>
                </div>
                <div className="d-flex flex-lg-nowrap flex-md-nowrap flex-sm-nowrap flex-wrap my-2  gap-2 justify-content-between align-items-center ">
                  <div className="col-lg-6 col-md-6 col-sm-6 col-12 d-flex gap-3 align-items-center">
                    <input
                      type="checkbox"
                      name="interested_services"
                      style={{ width: "fit-content" }}
                      value=" Digital Marketing & Strategy Planning"
                      onChange={handleData}
                    />
                    <p className="mb-0">
                      Digital Marketing & Strategy Planning
                    </p>
                  </div>
                  <div className="col-lg-6 col-md-6 col-sm-6 col-12 d-flex gap-3 align-items-center">
                    <input type="checkbox" value="Due-Diligence" name="interested_services" onChange={handleData} style={{ width: "fit-content" }} />
                    <p className="mb-0">Due-Diligence</p>
                  </div>
                </div>
                <div className="d-flex flex-lg-nowrap flex-md-nowrap flex-sm-nowrap flex-wrap my-2  gap-2 justify-content-between align-items-center ">
                  <div className="col-lg-6 col-md-6 col-sm-6 col-12 d-flex gap-3 align-items-center">
                    <input
                      type="checkbox"
                      name="interested_services"
                      value="Business Plan or Pitch Douments"
                      style={{ width: "fit-content" }}
                      onChange={handleData}
                    />
                    <p className="mb-0">Business Plan or Pitch Douments</p>
                  </div>
                  <div className="col-lg-6 col-md-6 col-sm-6 col-12 d-flex gap-3 align-items-center">
                    <input type="checkbox" value="Valuation Reports" name="interested_services" onChange={handleData} style={{ width: "fit-content" }} />
                    <p className="mb-0">Valuation Reports</p>
                  </div>
                </div>
                <div className="d-flex flex-lg-nowrap flex-md-nowrap flex-sm-nowrap flex-wrap my-2  gap-2 justify-content-between align-items-center ">
                  <div className="col-lg-6 col-md-6 col-sm-6 col-12 d-flex gap-3 align-items-center">
                    <input
                      type="checkbox"
                      name="interested_services"
                      style={{ width: "fit-content" }}
                      onChange={handleData}
                      value="Mobile Application Design & Development"
                    />
                    <p className="mb-0">
                      Mobile Application Design & Development
                    </p>
                  </div>
                  <div className="col-lg-6 col-md-6 col-sm-6 col-12 d-flex gap-3 align-items-center">
                    <input type="checkbox" value="Agreements & Reporting" name="interested_services" onChange={handleData} style={{ width: "fit-content" }} />
                    <p className="mb-0">Agreements & Reporting</p>
                  </div>
                </div>
                <div className="d-flex flex-lg-nowrap flex-md-nowrap flex-sm-nowrap flex-wrap my-2  gap-2 justify-content-between align-items-center ">
                  <div className="col-lg-6 col-md-6 col-sm-6 col-12 d-flex gap-3 align-items-center">
                    <input
                      type="checkbox"
                      style={{ width: "fit-content" }}
                      name="interested_services"
                      onChange={handleData}
                      value="License's, Patents and Trademark"
                    />
                    <p className="mb-0">License's, Patents and Trademark</p>
                  </div>
                  <div className="col-lg-6 col-md-6 col-sm-6 col-12 d-flex gap-3 align-items-center">
                    <input type="checkbox" value="Website Designing & Development" name="interested_services" onChange={handleData} style={{ width: "fit-content" }} />
                    <p className="mb-0">Website Designing & Development</p>
                  </div>
                </div>
                {error && error.interested_services && (
                      <div className="text-danger">{error.interested_services}</div>
                    )}
                <div className="d-flex my-3  gap-3 align-items-center ">
                  <input type="checkbox" name="term_condition" value="yes" onChange={handleData}  style={{ width: "fit-content" }}/>
                  <p className="mb-0">
                    I Accept & Acknowledge all the Terms & Conditions at
                    APNASHAHER.COM
                  </p>
                </div>
                {error && error.term_condition && (
                      <div className="text-danger">{error.term_condition}</div>
                    )}
                

                <button type="submit" onClick={handleForm}>Submit</button>
              </>
            ):null }
          </form>
        </div>
      </div>
    </div>
  );
};
