import React, { useState, useEffect } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import { useParams } from "react-router-dom";
import axios from "axios";
import { BASE_URL } from "../config";
import Page from "../components/Page";
import Loader from "../components/loader/Loader.component";
import { Reportabuse } from "./Reportabuse";

const Terms = () => {
  const { slug } = useParams(); // Access the slug parameter from the URL
  const [data, setData] = useState(""); // State for page data
  const [isLoading, setIsLoading] = useState(true); // State for loader

  const widgetData = async () => {
    try {
      setIsLoading(true); // Show loader before fetching data
      const wgtData = await axios.get(BASE_URL + `/getpage/${slug}`);
      setData(wgtData.data.data);
      setIsLoading(false); // Hide loader once data is fetched
    } catch (err) {
      console.log(err);
      setIsLoading(false); // Hide loader in case of error
    }
  };

  useEffect(() => {
    if (slug !== "report-an-abuse") {
      widgetData();
    }
  }, [slug]);

  // Handle "report-an-abuse" condition
  if (slug === "report-an-abuse") {
    return (
      <Reportabuse/>
    );
  }

  // Show loader if data is being fetched
  if (isLoading) {
    return <Loader />;
  }

  // Main component rendering for other slugs
  return (
    <Page title={data.title}>
      <div className="inner-pages-wrapper1 ">
        <div
          className="top-common-bredcrumb-details"
          style={{ background: "url(/assets/images/btop.jpg)" }}
        >
          <h1>{data.title}</h1>
        </div>

        <section className="about-cont-block">
          <div className="container">
            <div className="row">
              <div className="col-md-12">
                <div className="card-block">
                  <div className="card-head-blk">
                    <h4>{data.title}</h4>
                  </div>
                  <div
                    className="card-body-blk"
                    dangerouslySetInnerHTML={{ __html: data.content }}
                  ></div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </Page>
  );
};

export default Terms;
