import React, { useContext, useState, useEffect } from 'react';
import StateContext from '../context/StateContext';
import FeaturedProductBlock from './featured-product/featured-product-block';
import Loader from './loader/Loader.component';
import axios from 'axios';
import { BASE_URL } from '../config';
const TwoFeaturedCategorySection = ({ isLoading }) => {
  const { homepageSetting } = useContext(StateContext);
  const [image_base_url, setimage_base_url] = useState('')
  const { featured_category_3_image, featured_category_3, locationdata, countrycity, featured_category_4, featured_category_4_image } = homepageSetting;
  const [data, setdata] = useState('');
  useEffect(() => {
    getverticalcategoryproduct();
  }, [])
  const getverticalcategoryproduct = () => {
    axios.get(BASE_URL + '/verticalcategoryproduct', {
      headers: {
        "countrycity": countrycity,
        "countrycityold": locationdata && locationdata.country_name
      },
    }).then(({ data }) => {
      setdata(data.data);
      setimage_base_url(data.image_base_url);
    }).catch(({ response }) => {

    })
  }
  return (
    <div className="container-fluid">
      
      <div className="row">
        {data && data.map((item, i) => (
          <>
            {item.products_count >= 4 && (
              <div className="col-md-12">{isLoading ? <Loader /> : <FeaturedProductBlock category={item} image={item.banner} />}</div>
            )}

          </>

        ))}

        {/* <div className="col-md-6">{isLoading ? <Loader /> : <FeaturedProductBlock category={featured_category_4} image={featured_category_4_image} />}</div> */}
      </div>
    </div>
  );
};

export default TwoFeaturedCategorySection;
