import ReactOwlCarousel from 'react-owl-carousel';
import { Link } from 'react-router-dom';
import React, { useContext, useEffect, useState, useRef } from "react";
import ProductStarRating from '../product-star/product-star-rating.component';
import StateContext from '../../context/StateContext';
import ProductItem from '../product/product-item.component';
import { Carousel } from 'react-responsive-carousel';

const ProductCarouselContainer = ({ products }) => {
  const { currencysymbol } = useContext(StateContext);
  const recent_options = {
    margin: 10,
    responsiveClass: true,
    nav: true,
    dots: false,
    autoplay: true,
    loop: true,
    responsive: {
      0: {
        items: 2,
      },
      600: {
        items: 3,
      },
      1000: {
        items: 4,
      },
    },
  };
  console.log(products)
  return (
    <>
    {/* <Carousel {...recent_options}>
    {products.map((product) => (
        <ProductItem product={product} key={product.id} className1="item" className2="product-block product-margin-carousel" />
      ))}
    </Carousel> */}
    <ReactOwlCarousel className="owl-theme owl-feature-slider owl-slider-arrows" {...recent_options}>
      {products.map((product) => (
         <div className='item'>
         <div className="product-block product-margin-carousel">
           <Link to={`/product/${product.slug}`}>
            {/* <Link to={`/comming-soon`} > */}
             <div className="product-img-blk">
               {product.base_discounted_price < product.base_price ? <span className="sale-blk">Sale</span> : ''}
               <img src={product.thumbnail ? product.thumbnail : 'assets/images/image-13.jpg'} alt={product.name} />
             </div>
             <div className="product-cont-blk">
               <h4>{product.name}</h4>
               <div style={{ display: 'flex', justifyContent: 'center' }}>
                 <ProductStarRating rating={product.rating} />
               </div>
               <div style={{ display: 'flex', justifyContent: 'center', gap: "2px" }}>
                 <p className="product-price-home" style={{ fontSize: '20px', marginLeft: "4px", letterSpacing: "-2px" }}>{currencysymbol} {product.saleprice1}</p> &nbsp;&nbsp;
                 <p className='ml-2' style={{ color: 'gray', fontSize: '17px', textDecoration: 'line-through', marginTop: '3px', }}>{currencysymbol} {product.unit_price}</p>
               </div>
             </div>
           </Link>
         </div>
       </div>
        // <ProductItem product={product} key={product.id} className1="item" className2="product-block product-margin-carousel" />
      ))}
    </ReactOwlCarousel>
    </>
  );
};

export default ProductCarouselContainer;
