import React, { useEffect } from "react";
import "../style/Faqsellerbuyer.css";
import { Faqbanner } from "../components/faq-seller-buyer/Faqbanner";
import { Faqbody } from "../components/faq-seller-buyer/Faqbody";

export const Faqcustomer = () => {
  
  useEffect(() => {
    window.scrollTo(0, 0);
  },[])

  return (
    <>
      <section className="faq-banner-sec">
      <Faqbanner />
      </section>
      <section className="faq-body-sec">
        <Faqbody />
      </section>
    </>
  );
};
