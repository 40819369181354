import React, { useState } from 'react'
import "../../style/SuccessStory.css"
import { Height } from '@mui/icons-material'

export const SuccessComp2 = () => {
    const [isliked, setIsliked] = useState(true)
    const [isdislike, setIsdislike] = useState(false)

    return (
        <>
            <div className="success-comp2 d-flex justify-content-between" >
                <div className="left-content">
                    <div className=" d-flex mb-3 justify-content-between aling-items-center shadow-sm">
                        <div className="col-md-6 bg-white px-4 py-4">
                            <div className="story-details" >
                                <div className="d-flex comma">
                                    <img src="/assets/images/comma.png" width="30px" />
                                    <img src="/assets/images/comma.png" width="30px" />
                                </div>
                                <p className='story-desc'>
                                    If you look at the Verified Suppliers, you'll be able to
                                    see that they've been verified by independent third
                                    parties, and factory visits. It gives you inside look into
                                    the supplier.
                                </p>

                                <div className="user-details-1 user-img-name text-start  d-flex justify-content-between ">
                                    <div className=" d-flex align-items-center gap-3">
                                        <img src="/assets/images/user-2.png" width="50px" />
                                        <div className=''>
                                            <h4 className="mb-0">Dr. Green</h4>
                                            <h3 className="mb-0">Seller Owner</h3>
                                            <h3 className="mb-0 share-now" >Web Mingo IT Solutions</h3>
                                        </div>
                                    </div>
                                </div>
                                {/* <div className="user-details-1 user-location mt-3 mb-2  d-flex flex-column">
                                    <div className="d-flex align-items-center  ">
                                        <img src="/assets/icons/mdi_company.png" alt="" width={"30px"} />&nbsp;
                                        <h5 className="mb-0">Web Mingo IT Solutions</h5>

                                    </div>
                                    <div className="d-flex align-items-center  ">
                                        <img src='/assets/images/flag.png' width="30px" />&nbsp;
                                        <h5 className="mb-0">Chaina</h5>

                                    </div>
                                </div> */}

                                <div className="like-dislike d-flex align-items-center justify-content-end gap-3 mt-4">
                                    <div className="d-flex gap-2 align-items-center">
                                        <span><i className='fa fa-eye'></i></span>
                                        <p className='mb-0 '>466</p> &nbsp;&nbsp;
                                    </div>
                                    <div className="d-flex gap-2 align-items-center">
                                        <span><i className='fa fa-thumbs-up'></i></span>
                                        <p className='mb-0 '>42</p>&nbsp;&nbsp;
                                    </div>
                                    <div className="d-flex gap-2 align-items-center">
                                        <span><i className='fa fa-thumbs-down'></i></span>
                                        <p className='mb-0 '>10</p>
                                    </div>

                                </div>
                            </div>
                        </div>
                        <div className="col-md-6">
                            <img src="/assets/images/investor-1.png" width="100%" style={{ maxHeight: "435px" }} />
                        </div>
                    </div>
                    <div className=" d-flex mb-3 justify-content-between aling-items-center shadow-sm">
                        <div className="col-md-6 bg-white px-4 py-4">
                            <div className="story-details" >
                                <div className="d-flex comma">
                                    <img src="/assets/images/comma.png" width="30px" />
                                    <img src="/assets/images/comma.png" width="30px" />
                                </div>
                                <p className='story-desc'>
                                    If you look at the Verified Suppliers, you'll be able to
                                    see that they've been verified by independent third
                                    parties, and factory visits. It gives you inside look into
                                    the supplier.
                                </p>

                                <div className="user-details-1 user-img-name text-start  d-flex justify-content-between ">
                                    <div className=" d-flex align-items-center gap-3">
                                        <img src="/assets/images/user-2.png" width="50px" />
                                        <div className=''>
                                            <h4 className="mb-0">Dr. Green</h4>
                                            <h3 className="mb-0">Seller Owner</h3>
                                            <h3 className="mb-0 share-now" >Web Mingo IT Solutions</h3>
                                        </div>
                                    </div>
                                </div>
                                {/* <div className="user-details-1 user-location mt-3 mb-2  d-flex flex-column">
                                    <div className="d-flex align-items-center  ">
                                        <img src="/assets/icons/mdi_company.png" alt="" width={"30px"} />&nbsp;
                                        <h5 className="mb-0">Web Mingo IT Solutions</h5>

                                    </div>
                                    <div className="d-flex align-items-center  ">
                                        <img src='/assets/images/flag.png' width="30px" />&nbsp;
                                        <h5 className="mb-0">Chaina</h5>

                                    </div>
                                </div> */}

                                <div className="like-dislike d-flex align-items-center justify-content-end gap-3 mt-4">
                                    <div className="d-flex gap-2 align-items-center">
                                        <span><i className='fa fa-eye'></i></span>
                                        <p className='mb-0 '>466</p> &nbsp;&nbsp;
                                    </div>
                                    <div className="d-flex gap-2 align-items-center">
                                        <span><i className='fa fa-thumbs-up'></i></span>
                                        <p className='mb-0 '>42</p>&nbsp;&nbsp;
                                    </div>
                                    <div className="d-flex gap-2 align-items-center">
                                        <span><i className='fa fa-thumbs-down'></i></span>
                                        <p className='mb-0 '>10</p>
                                    </div>

                                </div>
                            </div>
                        </div>
                        <div className="col-md-6">
                            <img src="/assets/images/investor-1.png" width="100%" style={{ maxHeight: "435px" }} />
                        </div>
                    </div>
                    <div className=" d-flex mb-3 justify-content-between aling-items-center shadow-sm">
                        <div className="col-md-6 bg-white px-4 py-4">
                            <div className="story-details" >
                                <div className="d-flex comma">
                                    <img src="/assets/images/comma.png" width="30px" />
                                    <img src="/assets/images/comma.png" width="30px" />
                                </div>
                                <p className='story-desc'>
                                    If you look at the Verified Suppliers, you'll be able to
                                    see that they've been verified by independent third
                                    parties, and factory visits. It gives you inside look into
                                    the supplier.
                                </p>

                                <div className="user-details-1 user-img-name text-start  d-flex justify-content-between ">
                                    <div className=" d-flex align-items-center gap-3">
                                        <img src="/assets/images/user-2.png" width="50px" />
                                        <div className=''>
                                            <h4 className="mb-0">Dr. Green</h4>
                                            <h3 className="mb-0">Seller Owner</h3>
                                            <h3 className="mb-0 share-now" >Web Mingo IT Solutions</h3>
                                        </div>
                                    </div>
                                </div>
                                {/* <div className="user-details-1 user-location mt-3 mb-2  d-flex flex-column">
                                    <div className="d-flex align-items-center  ">
                                        <img src="/assets/icons/mdi_company.png" alt="" width={"30px"} />&nbsp;
                                        <h5 className="mb-0">Web Mingo IT Solutions</h5>

                                    </div>
                                    <div className="d-flex align-items-center  ">
                                        <img src='/assets/images/flag.png' width="30px" />&nbsp;
                                        <h5 className="mb-0">Chaina</h5>

                                    </div>
                                </div> */}

                                <div className="like-dislike d-flex align-items-center justify-content-end gap-3 mt-4">
                                    <div className="d-flex gap-2 align-items-center">
                                        <span><i className='fa fa-eye'></i></span>
                                        <p className='mb-0 '>466</p> &nbsp;&nbsp;
                                    </div>
                                    <div className="d-flex gap-2 align-items-center">
                                        <span><i className='fa fa-thumbs-up'></i></span>
                                        <p className='mb-0 '>42</p>&nbsp;&nbsp;
                                    </div>
                                    <div className="d-flex gap-2 align-items-center">
                                        <span><i className='fa fa-thumbs-down'></i></span>
                                        <p className='mb-0 '>10</p>
                                    </div>

                                </div>
                            </div>
                        </div>
                        <div className="col-md-6">
                            <img src="/assets/images/investor-1.png" width="100%" style={{ maxHeight: "435px" }} />
                        </div>
                    </div>
                   
                  

                </div>

                <div className=" right-content bg-whtie shadow-sm">
                        <div className="heading px-3 py-2 ">
                        <h4>Trending Stories</h4>
                        </div>
                   
                    <div className=' tStory pb-3 bg-white mb-2 ' style={{borderBottom:"1px solid #E7E7E7"}}>
                        <div className="col-md-12 bg-white px-4 py-4">
                            <div className="story-details" >
                                <div className="d-flex comma">
                                    <img src="/assets/images/comma.png" width="30px" />
                                    <img src="/assets/images/comma.png" width="30px" />
                                </div>
                                <p className='story-desc w-100'>
                                    If you look at the Verified Suppliers, you'll be able to
                                    see that they've been verified by independent third
                                    parties, and factory visits. It gives you inside look into
                                    the supplier.
                                </p>

                                <div className="user-details-1 user-img-name text-start  d-flex justify-content-between ">
                                <div className=" d-flex align-items-center gap-3">
                                        <img src="/assets/images/user-2.png" width="50px" />
                                        <div className=''>
                                            <h4 className="mb-0">Dr. Green</h4>
                                            <h3 className="mb-0">Seller Owner</h3>
                                            <h3 className="mb-0 share-now" >Web Mingo IT Solutions</h3>
                                        </div>
                                    </div>
                                </div>
                                {/* <div className="user-details-1 user-location mt-3 mb-2 d-flex flex-column">
                                    <div className="d-flex align-items-center gap-2">
                                        <img src="/assets/icons/mdi_company.png" alt="" width={"30px"} />
                                        <h5 className="mb-0">Sill Farm Supplier</h5>

                                    </div>
                                    <div className="d-flex align-items-center gap-2 ">
                                        <img src='/assets/images/flag.png' width="30px" />
                                        <h5 className="mb-0">Chaina</h5>

                                    </div>
                                </div> */}

                                <div className="like-dislike d-flex align-items-center justify-content-end gap-3 mt-3">
                                    <div className="d-flex gap-2 align-items-center">
                                        <span><i className='fa fa-eye'></i></span>
                                        <p className='mb-0 '>466</p>&nbsp;&nbsp;
                                    </div>
                                    <div className="d-flex gap-2 align-items-center">
                                        <span><i className='fa fa-thumbs-up'></i></span>
                                        <p className='mb-0 '>42</p>&nbsp;&nbsp;
                                    </div>
                                    <div className="d-flex gap-2 align-items-center">
                                        <span><i className='fa fa-thumbs-down'></i></span>
                                        <p className='mb-0 '>10</p>
                                    </div>

                                </div>
                            </div>
                        </div>
                        <div className="col-md-12 large-img text-center">
                            <img src="/assets/images/investor-1.png" width="90%" style={{ maxHeight: "255px",margin:"auto"}} />
                        </div>

                    </div>
                    <div className=' tStory pb-3 bg-white mb-2 ' style={{borderBottom:"1px solid #E7E7E7"}}>
                        <div className="col-md-12 bg-white px-4 py-4">
                            <div className="story-details" >
                                <div className="d-flex comma">
                                    <img src="/assets/images/comma.png" width="30px" />
                                    <img src="/assets/images/comma.png" width="30px" />
                                </div>
                                <p className='story-desc w-100'>
                                    If you look at the Verified Suppliers, you'll be able to
                                    see that they've been verified by independent third
                                    parties, and factory visits. It gives you inside look into
                                    the supplier.
                                </p>

                                <div className="user-details-1 user-img-name text-start  d-flex justify-content-between ">
                                    <div className=" d-flex align-items-center gap-3">
                                        <img src="/assets/images/user-2.png" width="50px" />
                                        <div className='ml-2'>
                                            <h4 className="mb-0">Dr. Green</h4>
                                            <h3 className="mb-0">Owner</h3>
                                        </div>
                                    </div>
                                </div>
                                <div className="user-details-1 user-location mt-3 mb-2 d-flex flex-column">
                                    <div className="d-flex align-items-center gap-2">
                                        <img src="/assets/icons/mdi_company.png" alt="" width={"30px"} />
                                        <h5 className="mb-0">Sill Farm Supplier</h5>

                                    </div>
                                    <div className="d-flex align-items-center gap-2 ">
                                        <img src='/assets/images/flag.png' width="30px" />
                                        <h5 className="mb-0">Chaina</h5>

                                    </div>
                                </div>

                                <div className="like-dislike d-flex align-items-center justify-content-end gap-3">
                                    <div className="d-flex gap-2 align-items-center">
                                        <span><i className='fa fa-eye'></i></span>
                                        <p className='mb-0 '>466</p>&nbsp;&nbsp;
                                    </div>
                                    <div className="d-flex gap-2 align-items-center">
                                        <span><i className='fa fa-thumbs-up'></i></span>
                                        <p className='mb-0 '>42</p>&nbsp;&nbsp;
                                    </div>
                                    <div className="d-flex gap-2 align-items-center">
                                        <span><i className='fa fa-thumbs-down'></i></span>
                                        <p className='mb-0 '>10</p>
                                    </div>

                                </div>
                            </div>
                        </div>
                        <div className="col-md-12 large-img text-center">
                            <img src="/assets/images/investor-1.png" width="90%" style={{ maxHeight: "255px",margin:"auto"}} />
                        </div>

                    </div>

                   

                   
                </div>
            </div>
        </>
    )
}
