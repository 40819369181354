import React, { useRef, useState, useEffect, useContext } from "react";
import "../../style/StartupDetail.css";
import "../../style/SellerDetails.css";
import { Link, useNavigate, useParams } from "react-router-dom";
import { IMAGE_BASE_URL } from "../../config";
import { BASE_URL } from "../../config";
import axios from "axios";
import ReactStars from "react-stars";
import { ToastContainer, toast } from "react-toastify";
import truncHtml from "trunc-html";
import StateContext from "../../context/StateContext";
import { Button } from "react-bootstrap";
import { maskNumber, maskEmail } from "../../pages/MaskData";
import { IoIosStar, IoIosStarHalf, IoMdCall } from "react-icons/io";
import Box from '@mui/material/Box';
import Rating from '@mui/material/Rating';
import { FaLocationDot } from "react-icons/fa6";
import { MdOutlineMail } from "react-icons/md";
export const SdComp3 = (props) => {
  const appState = useContext(StateContext);
  const token = localStorage.getItem("avonToken");
  // const [showContent, setShowContent] = useState(0);
  const [rating, setRating] = useState(5);
  const [ratingInp, setRatingInp] = useState(0);
  const [rateus, setRateus] = useState(false);
  const [currPage, setCurrPage] = useState("products");
  const [isLogged, setIsLogged] = useState(false);
  const [feedback, setfeedback] = useState("");
  const [readmoreproduct, setreadmoreproduct] = useState("");
  const [readmorereview, setreadmorereview] = useState("");
  const [image, setimage] = useState("");
  const [imagep, setimagep] = useState("");
  const [errorrev, seterrorrev] = useState([]);
  const [error, seterror] = useState([]);
  const { slug } = useParams();
  const [fetchCountry, setFetchedCountry] = useState([]);
  const [fetchState, setFetchedState] = useState([]);
  const [fetchCity, setFetchedCity] = useState([]);
  const [category, setCategory] = useState([]);
  const [visibleDatareview, setVisibleDatareview] = useState([]);
  const [itemsPerPagereview, setItemsPerPagereview] = useState(4);
  const [visibleDataproduct, setVisibleDataproduct] = useState([]);
  const [visibleDataservices, setVisibleDataservices] = useState([]);
  const [itemsPerPageproduct, setItemsPerPageproduct] = useState(4);
  const navigate = useNavigate();
  const pageLocation = window.location.pathname;
  const [quotedata, setquotedata] = useState({
    name: "",
    email: "",
    mobile_number: "",
    country: "",
    state: "",
    city: "",
    message: "",
    term: "",
  });
  const [editdata, seteditdata] = useState({
    company_name: "",
    business_category: "",
    address: "",
    country: "",
    state: "",
    city: "",
    pincode: "",
    mobile_number: "",
    website_url: "",
    message: "",
  });
  useEffect(() => {
    getCountry();
    FetchBusinessCategory();
    loadMorereview();
    loadMoreproduct();
    loadMoreservices();
  }, []);
  const loadMorereview = () => {
    const currentLength = visibleDatareview.length;
    if (props?.data?.reviews) {
      const newVisibleData = props?.data?.reviews?.slice(
        0,
        currentLength + itemsPerPagereview
      );
      setVisibleDatareview(newVisibleData);
    }

  };
  const loadMoreproduct = () => {
    const currentLength = visibleDataproduct.length;
    if (props?.data?.product) {
      const newVisibleData = props?.data?.product.slice(
        0,
        currentLength + itemsPerPageproduct
      );
      setVisibleDataproduct(newVisibleData);
    }

  };
  const loadMoreservices = () => {
    const currentLength = visibleDataservices.length;
    if (props?.data?.services) {
      const newVisibleData = props?.data?.services.slice(
        0,
        currentLength + itemsPerPageproduct
      );
      setVisibleDataservices(newVisibleData);
    }

  };
  const FetchBusinessCategory = async () => {
    await axios.get(BASE_URL + "/getbusinesscategory").then(({ data }) => {
      setCategory(data.data);
      // console.log(data.data)
    });
  };
  const getCountry = () => {
    axios
      .get(`${BASE_URL}/country`)
      .then(function (res) {
        setFetchedCountry(res.data.data);
      })
      .catch(function (error) {
        // console.log(error);
      });
  };
  const getState = (name) => {
    axios
      .get(`${BASE_URL}/state/${name}`)
      .then(function (res) {
        setFetchedState(res.data.data);
      })
      .catch(function (error) {
        // console.log(error);
      });
  };
  const getCity = (id) => {
    axios
      .get(`${BASE_URL}/city/${id}`)
      .then(function (res) {
        setFetchedCity(res.data.data);
      })
      .catch(function (error) {
        // console.log(error);
      });
  };
  const handleInput = (e) => {
    const { name, value, checked } = e.target;

    setquotedata({
      ...quotedata,
      [name]: value,
    });
  };
  const handleEditInput = (e) => {
    const { name, value, checked } = e.target;

    seteditdata({
      ...editdata,
      [name]: value,
    });
  };
  const handleClick = (index) => {
    setRating(index + 1);
  };
  const handlerating = (e) => {
    if (e > 1) {
      setRating(e);
    } else {
      setRating(1);
    }
  };
  const submitRequest = (event) => {
    event.preventDefault();
    seterror("");

    axios
      .post(
        BASE_URL +
        `/seller/addsellerrequestquote
      `,
        quotedata,
        {
          headers: {
            slug: slug,
          },
        }
      )
      .then((res) => {
        if (res.status == "200") {
          // console.log("set enqiury called");
          toast.success("Quote Send Successfully");
          document.getElementsByClassName("quoteform")[0].reset();
          setquotedata("");
        }
      })
      .catch(({ response }) => {
        seterror(response?.data?.message);
      });
  };
  const submitfeedback = () => {
    seterrorrev("");
    const formData = new FormData();
    formData.append("rating", rating);
    formData.append("feedback", feedback);
    formData.append("image", image);
    axios
      .post(BASE_URL + `/seller/addreviewseller`, formData, {
        headers: {
          slug: slug,
          Authorization: "Bearer " + token,
        },
      })
      .then((res) => {
        console.log(res);
        if (res.status == "200") {
          toast.success("Review Send Successfully");
          setRateus(false);
          setfeedback("");
          setimage("");
          document.getElementsByClassName("reviewform")[0].reset();
        }
      })
      .catch(({ response }) => {
        seterrorrev(response?.data?.message);
      });
  };

  const handlesubmitrequestedit = () => {
    seterror("");
    const formData = new FormData();
    Object.entries(editdata).forEach(([key, value]) => {
      formData.append(key, value);
    });
    formData.append("image", image);
    axios
      .post(BASE_URL + `/seller/addsuggestedit`, formData, {
        headers: {
          slug: slug,
        },
      })
      .then((res) => {
        if (res.status == "200") {
          toast.success("Suggest Edit Send Successfully");
          setRateus(false);
          seteditdata("");
          setimage("");
          setimagep("");
          document.getElementsByClassName("requesteditform")[0].reset();
          closePopup();
        }
      })
      .catch(({ response }) => {
        seterror(response?.data?.message);
      });
  };
  const handleImage = (e) => {
    setimage(e.target.files[0]);
    setimagep(URL.createObjectURL(e.target.files[0]));
  };
  function removeActiveClass() {
    const tabButtons = document.getElementsByClassName("toggle-link");
    const spanElm = document.querySelectorAll(".toggle-link span");

    for (const lis of tabButtons) {
      lis.classList.remove("active-build-ms-stab");
    }

    for (const spans of spanElm) {
      spans.classList.remove("active-build-ms-stab");
    }
  }

  const targetRef = useRef(null);
  const handleLinkTab = (event, _id) => {
    removeActiveClass();
    event.stopPropagation();

    let targetElem = document.getElementById(_id);

    if (_id === "popup_option") {
      let showElem = document.querySelector("#popup_option");
      showElem.style.display = "block";
    }

    const offset = -250;
    const elementPosition =
      targetElem.getBoundingClientRect().top + window.pageYOffset;
    const offsetPosition = elementPosition + offset;

    window.scrollTo({
      top: offsetPosition,
      behavior: "smooth",
    });

    const clickedButton = event.currentTarget;
    clickedButton.classList.add("active-build-ms-stab");
  };

  // show rating form database
  const stars = [...Array(5)].map((_, index = 3) => (
    <span key={index} className={index < rating ? "star-golden" : "star"}>
      <i className="bi bi-star-fill"></i>
    </span>
  ));

  // get rating from user

  const stars2 = [...Array(5)].map((_, index) => (
    <span
      key={index}
      className={index < rating ? "rate-star-golden" : "rate-star"}
      onClick={() => handleClick(index)}
    >
      <i class="bi bi-star-fill"></i>
    </span>
  ));

  const showEditForm = () => {
    document.querySelector(".suggest-edit").style.display = "block";
    document.querySelector("#popup_option").style.display = "none";
  };

  const closePopup = () => {
    document.querySelector(".suggest-edit").style.display = "none";
    document.querySelector("#popup_option").style.display = "none";
  };

  const [value, setValue] = React.useState(0);

  return (
    <>
      <div className="seller-details-comp3">
        <div className="container d-flex justify-content-between gap-3">
          {/* left  */}
          <div className="col-lg-3 col-md-3 col-sm-3 col-12 left position-relative">
            <div className="fix-content">
              <div className="rounded-div ms-2"></div>
              <div className="left-content">
                <ul className="ps-0">
                  <li
                    className="toggle-link d-flex align-items-center justify-content-between active-build-ms-stab"
                    onClick={(event) => handleLinkTab(event, "company_info")}
                  >
                    <span>Company Information</span>{" "}
                    <span className="arrow-icon" style={{ color: "#555" }}>
                      <i className="fa fa-arrow-right"></i>
                    </span>
                  </li>
                  <li
                    className="toggle-link d-flex align-items-center justify-content-between "
                    onClick={(event) => handleLinkTab(event, "what_do")}
                  >
                    <span>What We Do</span>{" "}
                    <span className="arrow-icon" style={{ color: "#555" }}>
                      <i className="fa fa-arrow-right"></i>
                    </span>
                  </li>
                  {props?.data?.seller?.package_type == "Product" && (
                    <li
                      className="toggle-link d-flex align-items-center justify-content-between "
                      onClick={(event) => handleLinkTab(event, "product")}
                    >
                      <span>Our Products</span>{" "}
                      <span className="arrow-icon" style={{ color: "#555" }}>
                        <i className="fa fa-arrow-right"></i>
                      </span>
                    </li>
                  )}
                  {props?.data?.seller?.package_type == "Services" && (
                    <li
                      className="toggle-link d-flex align-items-center justify-content-between "
                      onClick={(event) => handleLinkTab(event, "services")}
                    >
                      <span>Our Services</span>{" "}
                      <span className="arrow-icon" style={{ color: "#555" }}>
                        <i className="fa fa-arrow-right"></i>
                      </span>
                    </li>
                  )}

                  <li
                    className="toggle-link d-flex align-items-center justify-content-between "
                    onClick={(event) => handleLinkTab(event, "review_rating")}
                  >
                    <span>Review and Rating</span>{" "}
                    <span className="arrow-icon" style={{ color: "#555" }}>
                      <i className="fa fa-arrow-right"></i>
                    </span>
                  </li>
                  <li
                    className="toggle-link d-flex align-items-center justify-content-between "
                    onClick={(event) => handleLinkTab(event, "request")}
                  >
                    <span>Request Quote</span>{" "}
                    <span className="arrow-icon" style={{ color: "#555" }}>
                      <i className="fa fa-arrow-right"></i>
                    </span>
                  </li>
                  <li
                    className="toggle-link d-flex align-items-center justify-content-between "
                    onClick={(event) => handleLinkTab(event, "contact_info")}
                  >
                    <span>Contact Us</span>{" "}
                    <span className="arrow-icon" style={{ color: "#555" }}>
                      <i className="fa fa-arrow-right"></i>
                    </span>
                  </li>
                  <li
                    className="toggle-link d-flex align-items-center justify-content-between "
                    onClick={(event) => handleLinkTab(event, "popup_option")}
                  >
                    <span>Suggest an Edit</span>{" "}
                    <span className="arrow-icon" style={{ color: "#555" }}>
                      <i className="fa fa-arrow-right"></i>
                    </span>
                  </li>
                </ul>
              </div>
            </div>
          </div>

          {/* right  */}
          <div className="col-lg-9 right">
            {/* 1st right component   */}
            <div
              ref={targetRef}
              id="company_info"
              className="company-info px-5 py-4 col-lg-12"
            >
              <div className="d-flex  flex-column align-items-center justify-content-between gap-2">
                <div className="left-div col-lg-12">
                  <h2>Company Information</h2>
                  <div
                    dangerouslySetInnerHTML={{
                      __html: props?.data?.shop?.comp_intr,
                    }}
                  ></div>
                </div>
                <div className="right-div col-lg-12">
                  <img
                    className="rounded-4"
                    src={IMAGE_BASE_URL + props?.data?.shop?.comp_intr_image}
                    alt=""
                    height={"200px"}
                  />
                </div>
              </div>
              {/* Cards--- */}
              {/* <div className="companyinfo-cards  my-3 w-100" style={{ overflowX: "auto" }}>

              <div className=" d-flex  gap-2 justify-content-between">
                <div className="card text-center px-2 py-2">
                  <p>NATURE OF BUSINESS</p>
                  <span>Import & Expor</span>
                </div>
                <div className="card text-center px-2 py-2">
                  <p>ESTABLISHED YEAR</p>
                  <span style={{ color: "#00DC23" }}> 2010</span>
                </div>
                <div className="card text-center px-2 py-2">
                  <p>GST NUMBER</p>
                  <span style={{ color: "#A2D60D" }}>09AABCW9983A1Z7</span>
                </div>
                </div>

              </div> */}


              <div
                className="companyinfo-cards my-3 w-100"
                style={{ overflowX: "auto" }}
              >
                <div className="d-flex  justify-content-between">
                  <div
                    className="card text-center px-2 py-2"

                  >
                    <p>NATURE OF BUSINESS</p>
                    <span>{props?.data?.data?.business_nature}</span>
                  </div>
                  <div
                    className="card text-center px-2 py-2"

                  >
                    <p>ESTABLISHED YEAR</p>
                    <span style={{ color: "#00DC23" }}>
                      {props?.data?.data?.estblish_year}
                    </span>
                  </div>
                  <div
                    className="card text-center px-2 py-2"

                  >
                    <p>GST NUMBER</p>
                    <span style={{ color: "#A2D60D" }}>
                      {props?.data?.taxbank?.gst_number}
                    </span>
                  </div>
                  <div
                    className="card text-center px-2 py-2"

                  >
                    <p>Business Type</p>
                    <span style={{ color: "#A2D60D" }}>
                      {props?.data?.data?.business_type}
                    </span>
                  </div>
                </div>
              </div>

              {/* ----- */}
            </div>

            {/* 2nd right componnet  */}
            <div
              ref={targetRef}
              id="what_do"
              className="product-and-services   px-5 py-4 gap-2"
            >
              <div className="d-flex flex-lg-row flex-column-reverse gap-2 align-items-center justify-content-center">
                <div className="left-div col-lg-12">
                  <h2>What We Do</h2>
                  <div
                    dangerouslySetInnerHTML={{
                      __html: props?.data?.shop?.productserviecs,
                    }}
                  ></div>
                </div>
              </div>
            </div>

            {/* Right Our Products */}
            {props?.data?.seller?.package_type == "Product" && (
              <div
                ref={targetRef}
                id="product"
                className="our-products   px-5 py-4 gap-2"
              >
                <h2 className="mb-lg-4  mb-3">Our Products</h2>

                <div className="stock-clearance">
                  {/* <button className='my-2'>Stock Clearance</button> */}
                  <div className="sc-cards d-flex gap-2 flex-wrap justify-content-between">
                    {visibleDataproduct.map((item, index) => (
                      <div class="card px-2 py-2 " key={index}>
                        {item.stock_clearance_deal == 1 && (
                          <span class="badge">Stock Clearance</span>
                        )}
                        <img
                          src={item.thumbnail}
                          alt="..."
                          width="125px"
                          style={{ margin: "auto" }}
                        />
                        <div class="card-body px-1">
                          <h5 class="card-title">{item.name}</h5>
                          <div className="stars-box mb-1">
                            <ReactStars
                              count={5}
                              value={item.rating}
                              size={20}
                              edit={false}
                              color2={"#ffd700"}
                            />
                            {/* <span style={{ color: "goldenrod" }}><i className='fa fa-star pe-1' ></i></span>
                        <span style={{ color: "goldenrod" }}><i className='fa fa-star pe-1' ></i></span>
                        <span style={{ color: "goldenrod" }}><i className='fa fa-star pe-1' ></i></span>
                        <span style={{ color: "goldenrod" }}><i className='fa fa-star pe-1' ></i></span>
                        <span style={{ color: "goldenrod" }}><i className='fa fa-star' ></i></span> */}
                          </div>
                          <div
                            class="card-text"
                            dangerouslySetInnerHTML={{
                              __html:
                                readmoreproduct === index
                                  ? item.description
                                  : truncHtml(item.description, 60).html,
                            }}
                          ></div>
                          {item.description.length > 60 && (
                            <Link
                              to="#"
                              onClick={() =>
                                setreadmoreproduct(
                                  readmoreproduct === index ? "" : index
                                )
                              }
                            >
                              {readmoreproduct === index
                                ? " Read Less "
                                : " Read More"}
                            </Link>
                          )}

                          <div className="prices d-flex align-items-center justify-content-between">
                            <div className="d-flex gap-4">
                              <span> ₹ {item.base_discounted_price}</span>
                              <span
                                className="dprice"
                                style={{
                                  color: "#888",
                                  textDecoration: "line-through",
                                }}
                              >
                                ₹ {item.base_price}
                              </span>
                            </div>
                            <Link to={"/product/" + item.slug}>
                              {" "}
                              <span style={{ color: "#888" }}>
                                <i className="fa fa-eye fs-5"></i>
                              </span>
                            </Link>
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>
                </div>

                <div className="text-center mt-4">
                  <button
                    className="py-2 px-5 bg-transparent text-primary fs-6 fw-medium rounded"
                    style={{ border: "1px solid #1351C1" }}
                    onClick={loadMoreproduct}
                  >
                    Load more Products
                  </button>
                </div>
              </div>
            )}

            {props?.data?.seller?.package_type == "Services" && (
              <div
                ref={targetRef}
                id="services"
                className="our-services   px-5 py-4 gap-2"
              >
                <h2 className="mb-lg-4  mb-3">Our Services</h2>

                <div className="stock-clearance">
                  {/* <button className='my-2'>Stock Clearance</button> */}
                  <div className="sc-cards d-flex gap-2 flex-wrap justify-content-between">

                    {visibleDataservices &&
                      visibleDataservices.map((item, index) => (
                        <div class="card px-2 py-2 " key={index}>
                          <img
                            src={item.service_image}
                            alt="..."
                            width="125px"
                            style={{ margin: "auto" }}

                          />
                          <div class="card-body px-1">
                            <h5 class="card-title">{item.service_name}</h5>
                            <div className="stars-box mb-1">
                              <ReactStars
                                count={5}
                                value={item.rating}
                                size={20}
                                edit={false}
                                color2={"#ffd700"}
                              />
                            </div>
                            <div class="card-text" dangerouslySetInnerHTML={{
                              __html: readmoreproduct === index
                                ? item.short_descriptions
                                : truncHtml(item.short_descriptions, 60).html,
                            }}>

                            </div>
                            {item.short_descriptions.length > 60 && (
                              <Link
                                to="#"
                                onClick={() =>
                                  setreadmoreproduct(
                                    readmoreproduct === index ? "" : index
                                  )
                                }
                              >
                                {readmoreproduct === index
                                  ? " Read Less "
                                  : " Read More"}
                              </Link>
                            )}
                            <div className="prices d-flex align-items-center justify-content-between">
                              <div className="d-flex gap-4">
                                <span> ₹ {item?.packages[0]?.package_price}</span>
                                <span
                                  className="dprice"
                                  style={{
                                    color: "#888",
                                    textDecoration: "line-through",
                                  }}
                                >
                                  ₹ {item?.packages[0]?.package_mrp}
                                </span>
                              </div>
                              <span style={{ color: "#888" }}>
                                <i className="fa fa-eye fs-5" onClick={() => navigate("/service-detail/" + item.slug)}></i>
                              </span>
                            </div>
                          </div>
                        </div>
                      ))}
                  </div>
                </div>

                <div className="text-center mt-4">
                  <button
                    onClick={loadMoreservices}
                    className="py-2 px-5 bg-transparent text-primary fs-6 fw-medium rounded"
                    style={{ border: "1px solid #1351C1" }}
                  >
                    Load more Services
                  </button>
                </div>
              </div>
            )}

            {/* 3rd right  Component */}

            <div ref={targetRef} id="rate_us" className="review-and-rating rate-us  px-5 pt-4 gap-2">
              <h3>Reviews & Ratings </h3>
              <div className='review-rating-section'>
                <div className='review-rating-left'>
                  <h2><span style={{ fontSize: "50px" }}>4.4</span> <span style={{ fontSize: "30px", fontWeight: "600", color: "gray", marginLeft: "15px" }}>
                    <span style={{ color: "orange", marginTop: "-4px" }}><IoIosStar style={{ marginTop: "-10px" }} /> <IoIosStar style={{ marginTop: "-10px" }} /> <IoIosStar style={{ marginTop: "-10px" }} /> <IoIosStar style={{ marginTop: "-10px" }} /> <IoIosStarHalf style={{ marginTop: "-10px" }} /></span></span></h2>
                  <h1 style={{ fontWeight: "700", color: "gray", fontSize: "20px" }}>1.3K Ratings & 297 Reviews</h1>
                  {/* <button onClick={() => setRateus(true)}>Write a review</button> */}
                  <div className="startup-deal-btn" style={{ width: "40%" }}>

                    <button className="raise-capital" onClick={() => setRateus(true)}>Write Review</button>

                  </div>

                </div>
                <div className='review-rating-center'>

                </div>
                <div className='review-rating-left'>
                  <div className='review-rating-section1'>
                    <h3>5 <IoIosStar style={{ marginTop: "-6px", color: "orange" }} /></h3>
                    <div class="progress mb-2" style={{ width: "70%", height: "10px" }}>
                      <div class="progress-bar " role="progressbar" aria-valuenow="75" aria-valuemin="0" aria-valuemax="100" style={{ width: "70%" }}></div>
                    </div>
                    <p className='' style={{ marginTop: "5px" }}>70%</p>

                  </div>
                  <div className='review-rating-section1'>
                    <h3>4 <IoIosStar style={{ marginTop: "-6px", color: "orange" }} /></h3>
                    <div class="progress mb-2" style={{ width: "70%", height: "10px" }}>
                      <div class="progress-bar " role="progressbar" aria-valuenow="75" aria-valuemin="0" aria-valuemax="100" style={{ width: "40%" }}></div>
                    </div>
                    <p className='' style={{ marginTop: "5px" }}>40%</p>

                  </div>
                  <div className='review-rating-section1'>
                    <h3>3 <IoIosStar style={{ marginTop: "-6px", color: "orange" }} /></h3>
                    <div class="progress mb-2" style={{ width: "70%", height: "10px" }}>
                      <div class="progress-bar " role="progressbar" aria-valuenow="75" aria-valuemin="0" aria-valuemax="100" style={{ width: "30%" }}></div>
                    </div>
                    <p className='' style={{ marginTop: "5px" }}>30%</p>

                  </div>
                  <div className='review-rating-section1'>
                    <h3>2 <IoIosStar style={{ marginTop: "-6px", color: "orange" }} /></h3>
                    <div class="progress mb-2" style={{ width: "70%", height: "10px" }}>
                      <div class="progress-bar " role="progressbar" aria-valuenow="75" aria-valuemin="0" aria-valuemax="100" style={{ width: "35%" }}></div>
                    </div>
                    <p className='' style={{ marginTop: "5px" }}>35%</p>

                  </div>
                  <div className='review-rating-section1'>
                    <h3>1 <IoIosStar style={{ marginTop: "-6px", color: "orange" }} /></h3>
                    <div class="progress mb-2" style={{ width: "70%", height: "10px" }}>
                      <div class="progress-bar " role="progressbar" aria-valuenow="75" aria-valuemin="0" aria-valuemax="100" style={{ width: "10%" }}></div>
                    </div>
                    <p className='' style={{ marginTop: "5px" }}>10%</p>

                  </div>

                </div>


              </div>

              {rateus === false ? null

                :
                <div id="" className="user-reviews  my-3">
                  <h6>Rate Us</h6>
                  <form action="">
                    <Box sx={{ '& > legend': { mt: 2 } }}>

                      <Rating
                        name="simple-controlled"
                        value={value}
                        onChange={(event, newValue) => {
                          setValue(newValue);
                        }}
                      />


                    </Box>
                    {/* <div className="stars-div my-3">
                                            {stars}
                                        </div> */}
                    <div className='my-3'>
                      <h6>Tell us about your experience</h6>
                      <textarea className='w-100 rounded px-2 py-2 fs-6' style={{ border: "1px solid #5555552e" }} name="" id="" cols="30" rows="6" placeholder='Please share your experience with the business. Things you can talk about: service, product and price'></textarea>
                    </div>
                    <div className='my-3 '>
                      <h6>Upload Img</h6>
                      <div className="position-relative  px-4 py-3 rounded" style={{ border: "1px solid #5555552e" }} >
                        <label className="position-absolute fs-6" style={{ color: "#5555552e" }}><i className='fa fa-camera fs-5 pe-3'></i> Upload Img</label>
                        <input type="file" className='opacity-0' />
                      </div>
                    </div>

                    <button className='blue-bg-button' >Submit Review</button>
                  </form>
                </div>
              }

            </div>
            <div className='review-get-section'>
              <div className='user-review-card'>
                <div className='user-review-details'>

                  <div className='user-card-pic'>
                    <img src='https://cdn-icons-png.flaticon.com/512/219/219988.png'></img>

                  </div>
                  <div className='user-card-pic-details'>
                    <h6 className='m-0'>Guest User</h6>
                    <p className='m-0' style={{ fontSize: "12px", lineHeight: "13px" }}>Web Mingo IT Solutions</p>
                    <p className='m-0' style={{ fontSize: "12px", lineHeight: "13px" }}><span style={{ color: "orange", marginTop: "-4px" }}><IoIosStar /> <IoIosStar /> <IoIosStar /> <IoIosStar /> <IoIosStarHalf /></span></p>

                  </div>

                </div>
                <p>Page Conducting thorough software development is essential in creating effective and efficient applications. The process involves multiple stages, including planning, designing, coding, testing, and deployment.</p>


              </div>
              <div className='user-review-card'>
                <div className='user-review-details'>

                  <div className='user-card-pic'>
                    <img src='https://cdn-icons-png.flaticon.com/512/219/219988.png'></img>

                  </div>
                  <div className='user-card-pic-details'>
                    <h6 className='m-0'>Guest User</h6>
                    <p className='m-0' style={{ fontSize: "12px", lineHeight: "13px" }}>Web Mingo IT Solutions</p>
                    <p className='m-0' style={{ fontSize: "12px", lineHeight: "13px" }}><span style={{ color: "orange", marginTop: "-4px" }}><IoIosStar /> <IoIosStar /> <IoIosStar /> <IoIosStar /> <IoIosStarHalf /></span></p>

                  </div>

                </div>
                <p>Page Conducting thorough software development is essential in creating effective and efficient applications. The process involves multiple stages, including planning, designing, coding, testing, and deployment.</p>


              </div>
              <div className='user-review-card'>
                <div className='user-review-details'>

                  <div className='user-card-pic'>
                    <img src='https://cdn-icons-png.flaticon.com/512/219/219988.png'></img>

                  </div>
                  <div className='user-card-pic-details'>
                    <h6 className='m-0'>Guest User</h6>
                    <p className='m-0' style={{ fontSize: "12px", lineHeight: "13px" }}>Web Mingo IT Solutions</p>
                    <p className='m-0' style={{ fontSize: "12px", lineHeight: "13px" }}><span style={{ color: "orange", marginTop: "-4px" }}><IoIosStar /> <IoIosStar /> <IoIosStar /> <IoIosStar /> <IoIosStarHalf /></span></p>

                  </div>

                </div>
                <p>Page Conducting thorough software development is essential in creating effective and efficient applications. The process involves multiple stages, including planning, designing, coding, testing, and deployment.</p>


              </div>
              <div className='user-review-card'>
                <div className='user-review-details'>

                  <div className='user-card-pic'>
                    <img src='https://cdn-icons-png.flaticon.com/512/219/219988.png'></img>

                  </div>
                  <div className='user-card-pic-details'>
                    <h6 className='m-0'>Guest User</h6>
                    <p className='m-0' style={{ fontSize: "12px", lineHeight: "13px" }}>Web Mingo IT Solutions</p>
                    <p className='m-0' style={{ fontSize: "12px", lineHeight: "13px" }}><span style={{ color: "orange", marginTop: "-4px" }}><IoIosStar /> <IoIosStar /> <IoIosStar /> <IoIosStar /> <IoIosStarHalf /></span></p>

                  </div>

                </div>
                <p>Page Conducting thorough software development is essential in creating effective and efficient applications. The process involves multiple stages, including planning, designing, coding, testing, and deployment.</p>


              </div>

            </div>
            {/* 4th right Component */}
            <div
              ref={targetRef}
              id="request"
              className="request px-5 py-4 gap-2"
            >
              <h2>Request Quote</h2>
              <p className="bold-para mt-4">
                Fill the Form & Connect with "{props?.data?.data?.business_name}
              </p>
              <form action="" className="quoteform">
                <div className="d-flex my-2 flex-wrap gap-2 align-items-center justify-content-between">
                  <input
                    type="text"
                    placeholder="Full Name"
                    name="name"
                    onChange={handleInput}
                  />
                  {error && error.name && (
                    <span className="text-danger">{error.name}</span>
                  )}
                  <input
                    type="email"
                    placeholder="Email ID"
                    name="email"
                    onChange={handleInput}
                  />
                  {error && error.email && (
                    <span className="text-danger">{error.email}</span>
                  )}
                </div>
                <div className="d-flex my-2 flex-wrap gap-2  align-items-center justify-content-between">
                  <input
                    type="number"
                    placeholder="Mobile Number"
                    name="mobile_number"
                    onChange={handleInput}
                  />
                  {error && error.mobile_number && (
                    <span className="text-danger">{error.mobile_number}</span>
                  )}
                   <select
                      class="form-select rounded-0"
                      aria-label="Default select example"
                      name="country"
                      onChange={(e) => {
                        handleInput(e);
                        getState(e.target.value);
                      }}
                    >
                      <option value="">Select Country</option>
                      {fetchCountry &&
                        fetchCountry.map((data) => (
                          <option value={data.id}>{data.name}</option>
                        ))}
                    </select>
                    {error && error.country && (
                      <span className="text-danger">{error.country}</span>
                    )}
                  
                  <div
                    className="d-flex flex-wrap gap-1 align-items-center justify-content-between"
                    style={{ width: "100%" }}
                  >
                    
                    <select
                      class="form-select rounded-0"
                      aria-label="Default select example"
                      name="state"
                      onChange={(e) => {
                        handleInput(e);
                        getCity(e.target.value);
                      }}
                    >
                      <option value="">Select State</option>
                      {fetchState &&
                        fetchState.map((data) => (
                          <option value={data.id}>{data.name}</option>
                        ))}
                    </select>
                    {error && error.state && (
                      <span className="text-danger">{error.state}</span>
                    )}
                    <div
                    className="d-flex flex-wrap gap-1 align-items-center justify-content-between"
                    style={{ width: "48%" }}
                  >
                   
                   <select
                      class="form-select rounded-0"
                      aria-label="Default select example"
                      name="city"
                      onChange={handleInput}
                    >
                      <option value="">Select City</option>
                      {fetchCity &&
                        fetchCity.map((item, i) => (
                          <option value={item.id}>{item.name}</option>
                        ))}
                    </select>
                    {error && error.city && (
                      <span className="text-danger">{error.city}</span>
                    )}
                    <input type="number" placeholder='Pin Code' />
                  </div>
                    
                  </div>
                </div>

                <div className="my-2">
                  <textarea
                    style={{ border: "1px solid #BCBCBC" }}
                    className="w-100 px-2 py-2 fs-6"
                    name="message"
                    onChange={handleInput}
                    id=""
                    cols="30"
                    rows="5"
                    placeholder="Type Detail"
                  ></textarea>
                  {error && error.message && (
                    <span className="text-danger">{error.message}</span>
                  )}
                </div>

                <div className="d-flex my-2 pb-2 align-items-center gap-3">
                  <input
                    type="checkbox"
                    style={{ width: "fit-content" }}
                    name="term"
                    value="yes"
                    onChange={handleInput}
                  />
                  <p className="mb-0 term-cond">
                    I Accept & Acknowledge that my contact information will be
                    shared with the registered sellers at APNASHAHER.COM
                  </p>
                  {error && error.term && (
                    <span className="text-danger">{error.term}</span>
                  )}
                </div>

                <button
                  className="submit-btn"
                  type="button"
                  onClick={submitRequest}
                >
                  Submit
                </button>
              </form>
            </div>

            {/* 5th right Component  */}

            {/*after login*/}
            {isLogged ? (
              <div
                id="contact_info"
                className="contact-info d-flex align-items-center justify-content-center px-5 py-4 gap-2"
              >
                <div className="left-div col-lg-8  col-12">
                  <h2>Contact Us</h2>
                  <h3>{props?.data?.data?.business_name}</h3>
                  <div className="cont-details before-log mt-4  ">
                    <div className="address my-lg-3 my-2">
                      <h5>Address</h5>
                      <p>{props?.data?.data?.full_address}</p>
                    </div>

                    <div className="d-flex justify-content-between w-100 pe-5 ">
                      <Link to="">
                        <img
                          src="/assets/images/live-chat.png"
                          alt=""
                          className="me-2"
                        />{" "}
                        Live Chat
                      </Link>
                      <Link to="">Send Enquiry</Link>
                    </div>

                    <Link to="" className="call-now mt-4">
                      <span>
                        {" "}
                        <i className="fa fa-phone pe-2 fs-5"></i>
                      </span>{" "}
                      Call Now
                    </Link>
                  </div>
                </div>

                {/* <div
                  className="right-div col-lg-4 col-12"
                  dangerouslySetInnerHTML={{
                    __html: props?.data?.data?.map_url,
                  }}
                ></div> */}
              </div>
            ) : (
              // before loged in
              <div id="contact_info" className="contact-info d-flex align-items-center justify-content-center px-5 py-4 gap-2 mt-4">
              <div className="left-div col-lg-6  col-12">
                  <h3>Contact Us</h3>
                  {/* <h3>WEB MINGO IT SOLUTIONS PVT LTD</h3> */}
                  <div className="cont-details d-flex flex-wrap justify-content-between">
                      <div className="address my-lg-1 my-2">

                          <p><FaLocationDot /> &nbsp;{props?.data?.data?.full_address}</p>
                      </div>
                      <div className="mobile my-lg-1 my-2">


                          <p className="d-flex"><IoMdCall style={{marginTop:"4px"}}/>&nbsp;&nbsp; {appState?.state?.user.token ? (
                        <>
                          {props?.data?.data?.business_mobilenumber &&
                            props?.data?.data?.business_mobilenumber.map(
                              (item, i) => (
                                <p onClick={() => navigate("/login", {
                                  state: {
                                    redirectpath: pageLocation
                                  }
                                })} key={i}>
                                  {item}
                                </p>
                              )
                            )}
                        </>
                      ) : (
                        <>
                          {props?.data?.data?.business_mobilenumber &&
                            props?.data?.data?.business_mobilenumber.map(
                              (item, i) => (
                                <p onClick={() => navigate("/login", {
                                  state: {
                                    redirectpath: pageLocation
                                  }
                                })} key={i}>
                                  {maskNumber(item)}
                                </p>
                              )
                            )}
                        </>
                      )}</p>

                      </div>
                      <div className="emails my-lg-1 my-2">

                          <p className="d-flex"><MdOutlineMail style={{marginTop:"4px"}}/>&nbsp;&nbsp; {appState?.state?.user.token ? (
                        <>
                          {props?.data?.data?.business_email &&
                            props?.data?.data?.business_email.map((item, i) => (
                              <p onClick={() => navigate("/login", {
                                state: {
                                  redirectpath: pageLocation
                                }
                              })} key={i}>
                                {item}
                              </p>
                            ))}
                        </>
                      ) : (
                        <>
                          {props?.data?.data?.business_email &&
                            props?.data?.data?.business_email.map((item, i) => (
                              <p key={i} onClick={() => navigate("/login", {
                                state: {
                                  redirectpath: pageLocation
                                }
                              })}>
                                {maskEmail(item)}
                              </p>
                            ))}
                        </>
                      )}</p>
                      </div>
                      <div className="social-link my-lg-1 my-2">
                      {/* <ul className="d-flex gap-2 ps-0">
                        {props?.data?.shop?.facebook && (
                          <li>
                            <Link to={props?.data?.shop?.facebook}>
                              <i class="fa fa-facebook"></i>
                            </Link>
                          </li>
                        )}
                        {props?.data?.shop?.instagram && (
                          <li>
                            <Link to={props?.data?.shop?.instagram}>
                              <i class="fa fa-instagram"></i>
                            </Link>
                          </li>
                        )}
                        {props?.data?.shop?.facebook && (
                          <li>
                            <Link to={props?.data?.shop?.facebook}>
                              <i class="fa fa-whatsapp"></i>
                            </Link>
                          </li>
                        )}
                        {props?.data?.shop?.linkedin && (
                          <li>
                            <Link to={props?.data?.shop?.linkedin}>
                              <i class="fa fa-linkedin"></i>
                            </Link>
                          </li>
                        )}
                      </ul> */}

                          <ul className='d-flex gap-2 ps-0'>
                              <li>
                                  <Link to={props?.data?.data?.facebook_url}><i class="fa fa-facebook"></i></Link>
                              </li>
                              <li>
                                  <Link to={props?.data?.data?.twitter_url}><i class="fa fa-instagram"></i></Link>
                              </li>

                              <li>
                                  <Link to={props?.data?.data?.linkedin_url}><i class="fa fa-linkedin"></i></Link>
                              </li>
                              <li>
                                  <Link to={props?.data?.data?.mobile_number}><i class="fa fa-whatsapp"></i></Link>
                              </li>

                              <li>
                                  <Link to={props?.data?.data?.youtube_url}><i class="fa fa-youtube"></i></Link>
                              </li>
                              <li>
                                  <Link to={props?.data?.data?.linkedin_url}><i class="fa fa-google-plus"></i></Link>
                              </li>
                              <li>
                                  <Link to={props?.data?.data?.pinterest_url}><i class="fa fa-pinterest"></i></Link>
                              </li>
                          </ul>
                      </div>
                  </div>
              </div>

              <div className="right-div col-lg-6 col-12">
                  <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d50589.9631670406!2d77.33510599757896!3d28.619082772305685!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x390ce5a035689a39%3A0x3d9564cd104c4b57!2sWeb%20Mingo%20IT%20Solutions%20Pvt.%20Ltd.%20-%20Website%20Designing%20%26%20Digital%20Marketing%20Company!5e0!3m2!1sen!2sin!4v1701432480563!5m2!1sen!2sin" width="fit-content" height="fit-content" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
              </div>

          </div>


              // <div
              //   id="contact_info"
              //   className="contact-info d-flex align-items-center justify-content-center px-5 py-4 gap-2"
              // >
              //   <div className="left-div col-lg-12  col-12">
              //     <h2>Contact Us</h2>
              //     <h3>{props?.data?.data?.business_name}</h3>
              //     <div className="cont-details mt-4  d-flex flex-wrap justify-content-between">
              //       <div className="address my-lg-3 my-2">
              //         <h5>Address</h5>
              //         <p>{props?.data?.data?.full_address}</p>
              //       </div>
              //       <div className="mobile my-lg-3 my-2">
              //         <h5>Mobile</h5>
              //         {appState?.state?.user.token ? (
              //           <>
              //             {props?.data?.data?.business_mobilenumber &&
              //               props?.data?.data?.business_mobilenumber.map(
              //                 (item, i) => (
              //                   <p onClick={() => navigate("/login", {
              //                     state: {
              //                       redirectpath: pageLocation
              //                     }
              //                   })} key={i}>
              //                     {item}
              //                   </p>
              //                 )
              //               )}
              //           </>
              //         ) : (
              //           <>
              //             {props?.data?.data?.business_mobilenumber &&
              //               props?.data?.data?.business_mobilenumber.map(
              //                 (item, i) => (
              //                   <p onClick={() => navigate("/login", {
              //                     state: {
              //                       redirectpath: pageLocation
              //                     }
              //                   })} key={i}>
              //                     {maskNumber(item)}
              //                   </p>
              //                 )
              //               )}
              //           </>
              //         )}
              //       </div>
              //       <div className="emails my-lg-3 my-2">
              //         <h5>Email</h5>
              //         {appState?.state?.user.token ? (
              //           <>
              //             {props?.data?.data?.business_email &&
              //               props?.data?.data?.business_email.map((item, i) => (
              //                 <p onClick={() => navigate("/login", {
              //                   state: {
              //                     redirectpath: pageLocation
              //                   }
              //                 })} key={i}>
              //                   {item}
              //                 </p>
              //               ))}
              //           </>
              //         ) : (
              //           <>
              //             {props?.data?.data?.business_email &&
              //               props?.data?.data?.business_email.map((item, i) => (
              //                 <p key={i} onClick={() => navigate("/login", {
              //                   state: {
              //                     redirectpath: pageLocation
              //                   }
              //                 })}>
              //                   {maskEmail(item)}
              //                 </p>
              //               ))}
              //           </>
              //         )}
              //       </div>
              //       <div className="social-link my-lg-3 my-2">
              //         <h5>Social</h5>
              //         <ul className="d-flex gap-2 ps-0">
              //           {props?.data?.shop?.facebook && (
              //             <li>
              //               <Link to={props?.data?.shop?.facebook}>
              //                 <i class="fa fa-facebook"></i>
              //               </Link>
              //             </li>
              //           )}
              //           {props?.data?.shop?.instagram && (
              //             <li>
              //               <Link to={props?.data?.shop?.instagram}>
              //                 <i class="fa fa-instagram"></i>
              //               </Link>
              //             </li>
              //           )}
              //           {props?.data?.shop?.facebook && (
              //             <li>
              //               <Link to={props?.data?.shop?.facebook}>
              //                 <i class="fa fa-whatsapp"></i>
              //               </Link>
              //             </li>
              //           )}
              //           {props?.data?.shop?.linkedin && (
              //             <li>
              //               <Link to={props?.data?.shop?.linkedin}>
              //                 <i class="fa fa-linkedin"></i>
              //               </Link>
              //             </li>
              //           )}
              //         </ul>
              //       </div>
              //     </div>
              //   </div>

              //   {/* <div
              //     className="right-div col-lg-4 col-12"
              //     dangerouslySetInnerHTML={{
              //       __html: props?.data?.data?.map_url,
              //     }}
              //   ></div> */}
              // </div>
            )}
          </div>
        </div>

        {/* Popop Option Container */}
        <div id="popup_option">
          <div className="popup-content px-4 py-4 bg-white">
            <div className="top-content d-flex justify-content-between pe-2">
              <div>
                <h2>Help us improve this listing</h2>
                <p>Select from the option below</p>
              </div>

              <span onClick={closePopup}>✕</span>
            </div>
            <div className="tab-links d-flex gap-3 flex-column justify-content-start">
              <button
                className="border-0 bg-transparent "
                onClick={showEditForm}
              >
                Edit /Modify Business
              </button>
              <Link to="/report-abuse">Report Abuse</Link>
            </div>
          </div>
        </div>

        {/* 6th Popup Component  */}
        <div id="suggest_edit" className="suggest-edit">
          <div className="bg-blur"></div>
          <div className="suggest-form px-4 py-4 bg-white">
            <div className="content d-flex  justify-content-between">
              <div>
                <h2>Suggest an Edit</h2>
                <p>{props?.data?.data?.business_name}</p>
              </div>

              <span onClick={closePopup}>✕</span>
            </div>

            {/* form  ------------------- */}

            <form action="" className="requesteditform">
              <div className="my-2 d-flex gap-2 flex-wrap justify-content-between align-items-center">
                <input
                  type="text"
                  placeholder="Company Name"
                  name="company_name"
                  onChange={handleEditInput}
                />
                {error && error.company_name && (
                  <div className="text-danger">{error.company_name}</div>
                )}
                <select
                  class="form-select rounded-0"
                  aria-label="Default select example"
                  name="business_category"
                  onChange={handleEditInput}
                >
                  <option value="">Business Category</option>
                  {category &&
                    category.map((item, i) => (
                      <option value={item.value}>{item.label}</option>
                    ))}
                </select>
                {error && error.business_category && (
                  <div className="text-danger">{error.business_category}</div>
                )}
              </div>
              <div className="my-2 full-address">
                <input
                  type="text"
                  placeholder="Full Address"
                  name="address"
                  onChange={handleEditInput}
                />
              </div>
              <div className="my-2 d-flex flex-wrap gap-2 justify-content-between align-items-center">
                <select
                  class="form-select rounded-0"
                  aria-label="Default select example"
                  name="country"
                  onChange={(e) => {
                    handleEditInput(e);
                    getState(e.target.value);
                  }}
                >
                  <option value="">Select Country</option>
                  {fetchCountry &&
                    fetchCountry.map((data) => (
                      <option value={data.id}>{data.name}</option>
                    ))}
                </select>
                {error && error.country && (
                  <div className="text-danger">{error.country}</div>
                )}
                <select
                  class="form-select rounded-0"
                  aria-label="Default select example"
                  name="state"
                  onChange={(e) => {
                    handleEditInput(e);
                    getCity(e.target.value);
                  }}
                >
                  <option value="">Select State</option>
                  {fetchState &&
                    fetchState.map((data) => (
                      <option value={data.id}>{data.name}</option>
                    ))}
                </select>
                {error && error.state && (
                  <div className="text-danger">{error.state}</div>
                )}
              </div>
              <div className="my-2 gap-2 d-flex flex-wrap justify-content-between align-items-center">
                <select
                  class="form-select rounded-0"
                  aria-label="Default select example"
                  name="city"
                  onChange={handleEditInput}
                >
                  <option value="">Select City</option>
                  {fetchCity &&
                    fetchCity.map((item, i) => (
                      <option value={item.id}>{item.name}</option>
                    ))}
                </select>
                {error && error.city && (
                  <div className="text-danger">{error.city}</div>
                )}
                <input
                  type="number"
                  placeholder="Pin Code"
                  name="pincode"
                  onChange={handleEditInput}
                />
              </div>

              <div className="my-2 gap-2 d-flex flex-wrap justify-content-between align-items-center">
                <input
                  type="number"
                  placeholder="Mobile Number"
                  name="mobile_number"
                  onChange={handleEditInput}
                />
                <input
                  type="text"
                  placeholder="Website Url"
                  name="website_url"
                  onChange={handleEditInput}
                />
              </div>

              <div className="my-3 d-flex flex-column gap-2">
                <h6>Other</h6>
                <textarea
                  id=""
                  cols="30"
                  rows="4"
                  name="message"
                  onChange={handleEditInput}
                  placeholder="Details here..."
                ></textarea>
              </div>

              <div className="my-2 upload-logo position-relative">
                <label className="position-absolute">Upload Logo</label>
                <input type="file" onChange={handleImage} />
                {imagep && <img src={imagep} height={50} />}
              </div>

              <div className="my-3 form-btns d-flex flex-wrap justify-content-between">
                <button className="cancle-btn" onClick={closePopup}>
                  Cancel
                </button>
                <button
                  className="submit-btn"
                  type="button"
                  onClick={handlesubmitrequestedit}
                >
                  Submit
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
      <ToastContainer />
    </>
  );
};
