import React, { useContext, useState, useEffect } from 'react';
import { Col } from 'react-bootstrap';
import { Link } from 'react-router-dom';

import Axios from 'axios';
import ProductStarRating from '../product-star/product-star-rating.component';
import StateContext from '../../context/StateContext';
import DispatchContext from '../../context/DispatchContext';
import { BsCurrencyRupee } from 'react-icons/bs';
import { IoIosStar } from 'react-icons/io';
import newitem from '../../assets/new.png'
import sale from '../../assets/sale.png'
import megasale from '../../assets/megasale.png'

const ProductGridItem = ({ image, product, wishlist, setProducts }) => {
  const appState = useContext(StateContext);
  const appDispatch = useContext(DispatchContext);
  const { locationdata, gcurrency, currencysymbol } = useContext(StateContext);
  const [currency, setcurrency] = useState("");
  const removeFromWishlist = (id) => {
    async function wishlistProduct() {
      try {
        const response = await Axios.post(
          `/remove-from-wishlist/${id}`,
          {},
          {
            headers: {
              Authorization: `Bearer ${appState.state.user.token}`,
              'Content-Type': 'multipart/form-data',
            },
          }
        );
        const responseData = response.data.data;
        setProducts(responseData.wishlisted_products);
        appDispatch({ type: 'setWishlistCount', value: responseData.wishlistCount });
        appDispatch({ type: 'flashMessage', value: 'Product Removed' });
      } catch (e) {
        console.log('Error encountered');
      }
    }
    wishlistProduct();
  };
  useEffect(() => {
    getcurrencybycode();
  }, [])
  const getcurrencybycode = async () => {
    await Axios.get(`/getcurrencybycode/${gcurrency}`).then(({ data }) => {
      setcurrency(data.data)
    })
  }
  console.log(product);
  return (
    <div className='col-md-3 col-sm-3 col-xs-6 col-6'>
      {/* <div className="product-block product-shoppage"> */}
      <Link to={`/product/${product.slug}`}>
      <div className='new-deal-arrive'>
            <div className='new-arrival'>
              <div className='stock-clerance-text-card'>
                <img src={newitem} style={{width:"40px"}}></img>
              </div>
              <div className='stock-clerance-text-card1'>
                {/* <img src={sale} style={{width:"40px"}}></img> */}
                <h4>12:30:24 Left</h4>
              </div>

            </div>
          </div>
        <div className="product_new-arival" style={{marginTop:"-47px"}}>
         

          {product.photos.map((item, index) => (
            <img src={item.photo} alt={product.name} />
          ))}

          <div className='stock-clerance'>
            <div className='stock-clerance-text'>
              <h6>Stock Clearance</h6>
            </div>

          </div>
          <h5 style={{ marginTop: "-35px" }}>{product.name}</h5>
          <h4 className='d-flex m-0' style={{ fontSize: "14px" }}><IoIosStar style={{ marginTop: "-4px" }} /> <IoIosStar style={{ marginTop: "-4px" }} /> <IoIosStar style={{ marginTop: "-4px" }} /> <IoIosStar style={{ marginTop: "-4px" }} /> <IoIosStar style={{ marginTop: "-4px" }} /><span>(2 Reviews)</span></h4>
          <div className='d-flex gap-1'>
            <h3><BsCurrencyRupee style={{ marginTop: "-4px" }} />{product.saleprice1}/- </h3>
            <p className='m-0 d-flex' style={{ textDecoration: "line-through", alignItems: "center", height: "43px", fontSize: "12px" }}><BsCurrencyRupee style={{ marginTop: "-4px" }} />{product.unit_price}/- </p>
          </div>
        </div>
      </Link>


      {/* <Link to={`/product/${product.slug}`}>
          <div className="product-img-blk">
            {product.base_discounted_price < product.base_price ? <span className="sale-blk">Sale</span> : ''}
            {product.photos.map((item, index) => (
              <img src={item.photo} alt={product.name} />
            ))}
          </div>
          <div className="product-cont-blk">
            <h6 style={{ textAlign: 'center', color: 'black', fontSize: '15px', fontWeight: '600', fontFamily: 'Helvetica', overflow: 'hidden', whiteSpace: 'nowrap', textOverflow: 'ellipsis' }}>{product.name}</h6>
            <div className='' style={{ textAlign: 'center', display: 'flex', justifyContent: 'center', alignItems: "baseline", gap: "0px" }}>
              <p style={{ fontSize: '18px', letterSpacing: "-2px" }}> {currencysymbol} {product.saleprice1} </p>
              {<span style={{ paddingLeft: '10px', letterSpacing: "-2px" }}><del style={{ color: 'grey' }}>{currencysymbol} {product.unit_price}</del></span>}
              {
                product.discount_type == "percentage" && (
                  <span style={{ paddingLeft: '10px', color: 'green', fontSize: '17px' }}>{product.discount}%</span>
                )
              }
              {
                product.discount_type == "amount" && (
                  <span style={{ paddingLeft: '10px', color: 'green', fontSize: '17px' }}>{Math.round(((product.unit_price - product.saleprice1) / product.saleprice1) * 100)}%</span>
                )
              }
            </div>
            
            {
              product.discount_type == "percentage" && (
                <p style={{ color: 'green', fontSize: '14px', marginTop: "-6px" }}>You Saved  <span style={{ marginLeft: "5px" }}>{currencysymbol} {Math.round(product.unit_price - product.saleprice1)}</span></p>

              )
            }
            {
              product.discount_type == "amount" && (
                <p style={{ color: 'green', fontSize: '14px', marginTop: "-6px" }}>You Saved <span style={{ marginLeft: "5px" }}>{currencysymbol} {Math.round(product.unit_price - product.saleprice1)}</span></p>

              )
            }
          </div>
        </Link>
        {wishlist ? (
          <span
            className="btn btn-danger"
            onClick={() => {
              removeFromWishlist(product.id);
            }}
          >
            Remove
          </span>
        ) : (
          ''
        )} */}
      {/* </div> */}
    </div>
  );
};

export default ProductGridItem;
