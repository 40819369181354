import React from "react";
import { Carousel } from 'react-responsive-carousel';
import ReactOwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import { IoIosStar } from "react-icons/io";
import { BsCurrencyRupee } from "react-icons/bs";
import { Link, useNavigate } from "react-router-dom";

const Digital = (props) => {
    const recent_options = {
        margin: 10,
        responsiveClass: true,
        nav: true,
        dots: false,
        autoplay: true,
        loop: true,
        responsive: {
            0: {
                items: 2,
            },
            600: {
                items: 2.8,
            },
            1000: {
                items: 4,
            },
        },
    };
    const Categories = [
        {
            name: 'USB 3.0 Type A Female to A Female Connector Adapter',
            quantity: "4",
            cate1: "https://demo.martvill.techvill.net/public/uploads/sizes/medium/20221116/23c4d1b84785337722f69736b86ff959.webp"
        },
        {
            name: 'USB 2.0 Flash Pen Drive 32GB 16GB 64GB 128GB Squid Gam',
            quantity: "2",
            cate1: "https://demo.martvill.techvill.net/public/uploads/sizes/medium/20221116/8e4f7f9cffd21f3f267147d2cf3e79b1.webp"
        },
        {
            name: 'Smartwatch Men Support 118 Sports Women Smart Watch',
            quantity: "4",
            cate1: "https://demo.martvill.techvill.net/public/uploads/sizes/medium/20221116/12a3bfa2188bc16c1bbabbe2c69553ff.webp"
        },
        {
            name: 'Modern Design Four Legs Chair withou Arms',
            quantity: "2",
            cate1: "https://demo.martvill.techvill.net/public/uploads/sizes/medium/20221123/f8256ae374c1432ad6b8ac1e81fde25e.webp"
        },
        {
            name: 'Waist straight Slouchy jeans',
            quantity: "4",
            cate1: "https://demo.martvill.techvill.net/public/uploads/sizes/medium/20221120/abc930448a9f08de458b70e58e9f894e.webp"
        },
       
    ]
    const navigate = useNavigate();
    return (
        <>
            <div className="container   " style={{marginTop:"40px"}}>
                <div className="new-arrivals products-nr">
                    <div className="slider-section new-arrival-slider position-relative">
                        <h6 className="cate-head mb-4 heading-bline position-relative">{props.heading}</h6>
                        <ReactOwlCarousel className="owl-theme owl-feature-slider owl-slider-arrows mt-5 mb-2 " {...recent_options}>
                        {props?.data?.map((product,i) => (
                                <div className="product_new-arival" key={i} onClick={()=>navigate("/service-categorys/"+product.slug)} style={{cursor:"pointer"}}>
                                    <img src={product.banner} />
                                    <h5>{product.name}</h5>
                                    <h4 className="m-0"><IoIosStar style={{marginTop:"-4px"}}/> <IoIosStar style={{marginTop:"-4px"}}/> <IoIosStar style={{marginTop:"-4px"}}/> <IoIosStar style={{marginTop:"-4px"}}/> <IoIosStar style={{marginTop:"-4px"}}/><span>(2 Reviews)</span></h4>
                                    {/* <p className="service_post">{product.product_count} Post</p> */}
                                     <h3 style={{marginTop:"-6px"}}><BsCurrencyRupee style={{marginTop:"-4px"}}/> 200/- </h3>
                                </div>
                              
                            ))}
                        </ReactOwlCarousel>
                    </div>
                </div>
            </div>
            
        </>
    )
}

export default Digital;


// import React from "react";
// import { Carousel } from 'react-responsive-carousel';
// import ReactOwlCarousel from 'react-owl-carousel';
// import 'owl.carousel/dist/assets/owl.carousel.css';
// import 'owl.carousel/dist/assets/owl.theme.default.css';
// import { Link, useNavigate } from "react-router-dom";
// import { IMAGE_BASE_URL } from "../../../config";
// const Digital = (props) => {
//     const recent_options = {
//         margin: 10,
//         responsiveClass: true,
//         nav: true,
//         dots: false,
//         autoplay: false,
//         loop: true,
//         responsive: {
//             0: {
//                 items: 1.4,
//             },
//             460: {
//                 items: 1.8,
//             },
//             650: {
//                 items: 2,
//             },
//             700: {
//                 items: 2.5,
//             },
//             1000: {
//                 items: 4,
//             },
//         },
//     };
//     const Categories = [
//         {
//             name: 'Ear buds',
//             quantity: "4",
//             cate1: "/assets/images/digital-1.png"
//         },
//         {
//             name: 'New Books',
//             quantity: "2",
//             cate1: "/assets/images/digital-2.png"
//         },
//         {
//             name: 'Ear buds',
//             quantity: "4",
//             cate1: "/assets/images/digital-3.png"
//         },
//         {
//             name: 'New Books',
//             quantity: "2",
//             cate1: "/assets/images/digital-4.png"
//         },
//         {
//             name: 'Ear buds',
//             quantity: "4",
//             cate1: "/assets/images/digital-1.png"
//         },
//         {
//             name: 'New Books',
//             quantity: "2",
//             cate1: "/assets/images/digital-2.png"
//         },
//         {
//             name: 'Ear buds',
//             quantity: "4",
//             cate1: "/assets/images/product-2.png"
//         }
//     ]
//     const navigate = useNavigate();

//     return (
//         <>
//             <div className="container mt-lg-5 mt-md-4 mt-3">
//                 <div className="new-arrivals">
//                     <div className="slider-section digital-slider-img">
//                         <h6 className="cate-head mb-3">{props.heading}</h6>
//                         <ReactOwlCarousel className="owl-theme owl-feature-slider owl-slider-arrows" {...recent_options}>
//                             {props?.data?.map((product,i) => (
//                                 <div className="text-center shadow-sm " key={i} onClick={()=>navigate("/service-categorys/"+product.slug)}>
                                   
//                                     <img src={product.banner} />
//                                     <h5>{product.name}</h5>
//                                     <h4 className="mb-2">{product.product_count} Post</h4>
//                                 </div>
//                             ))}
//                         </ReactOwlCarousel>
//                     </div>
//                 </div>
//             </div>
            
//         </>
//     )
// }

// export default Digital;