import React, { useContext, useState } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import { Container, Row, Col, Button, Form, Tabs, Tab } from "react-bootstrap";
import { Link, NavLink, useLocation, useNavigate } from "react-router-dom";
import Axios from "axios";
import Page from "../components/Page";
import DispatchContext from "../context/DispatchContext";
import { useGoogleLogin } from "@react-oauth/google";
import { BASE_URL } from "../config";
import "./Login.css";
import { ToastContainer,toast } from "react-toastify";
const Login = () => {
  const appDispatch = useContext(DispatchContext);
  const states = useLocation();
  console.log("---",states)
  const path = window.location.origin
  const [email, setEmail] = useState("");
  const [emailverified, setEmailverified] = useState("");
  const [type, settype] = useState("password");
  const [eyetype, seteyetype] = useState("fa fa-eye-slash");
  const [password, setPassword] = useState("");
  const [errors, setErrors] = useState([]);
  const [buttonText, setButtonText] = useState("Login");
  const [buttonDisabled, setButtonDisabled] = useState(false);
  const navigate = useNavigate();

  const login = useGoogleLogin({
    onSuccess: (response) => {
      Axios.get("https://www.googleapis.com/oauth2/v3/userinfo", {
        headers: {
          Authorization: `Bearer ${response.access_token}`,
        },
      }).then(({ data }) => {
        Axios.post(BASE_URL + "/googlelogin", {
          email: data.email,
          name: data.given_name,
          google_id: data.sub,
        })
          .then(({ data }) => {
            appDispatch({
              type: "login",
              data: {
                token: data.data.token,
                name: data.data.user.name,
                email: data.data.user.email,
                mobileNumber: data.data.user.mobile_number,
                profileImage: data.data.user.profile_image,
                step: data.data.user.step,
                lastLogin: data.data.user.last_login,
                is_email_verified: data.data.user.is_email_verified,
              },
            });
            setEmail("");
            setPassword("");
            setErrors([]);
            setButtonText("Submitted!");
            appDispatch({ type: "flashMessage", value: "Login Successful" });
            appDispatch({
              type: "setWishlistCount",
              value: data.data.wishlistCount,
            });
            if(states?.state?.redirectpath){
              navigate(states?.state?.redirectpath);
            }else{
              if (states.state) {
                if (data.data.packageid) {
                  navigate("/subscription");
                } else {
                  navigate("/vendor-checkout", { state: states.state });
                }
              } else {
                navigate("/dashboard");
              }
            }
           
          })
          .catch(({ response }) => {
            console.log("-----------", response.data);
            if (response.data.status_code == "422") {
              alert(response.data.message.email);
              navigate("/sign-up");
            }
          });
      });
    },
  });
  async function handleSubmit(e) {
    e.preventDefault();
    setButtonDisabled(true);
    setButtonText("Submitting...");
    try {
      const response = await Axios.post("/signin", {
        email,
        password,
      });

      if (response.data.success === false) {
        setEmailverified(response.data.message);
        // Swal.fire({
        //   text: data.message,
        //   icon: "error"
        // })
      }
      const responseData = response.data.data;
      console.log(responseData);
      appDispatch({
        type: "login",
        data: {
          token: responseData.token,
          name: responseData.user.name,
          email: responseData.user.email,
          mobileNumber: responseData.user.mobile_number,
          profileImage: responseData.user.profile_image,
          lastLogin: responseData.user.last_login,
          step: responseData.user.step,
          is_email_verified: responseData.user.is_email_verified,
        
        },
      });
      setEmail("");
      setPassword("");
      setErrors([]);
      setButtonText("Submitted!");
      appDispatch({ type: "flashMessage", value: "Login Successful" });
      appDispatch({
        type: "setWishlistCount",
        value: responseData.wishlistCount,
      });
      if(states?.state?.redirectpath){
        navigate(states?.state?.redirectpath);
      }else{
        if (states.state) {
          if (responseData.user.packageid) {
            navigate("/subscription");
          } else {
            navigate("/vendor-checkout", { state: states.state });
          }
        }else {
          navigate("/dashboard");
        }
      }
      
    } catch (e) {
      setButtonDisabled(false);
      setButtonText("Login");
      if (e.response.status === 422) {
        const response = await e.response.data;
        setErrors(response.errors);
      } else {
        console.log("Error encountered");
      }
    }
  }
  const viewpassword = () => {
    
    settype(type == "text" ? "password" : "text");
    seteyetype(type == "text" ? "fa fa-eye-slash" : "fa fa-eye");
  };
  const LoginTabs = () => {
    const [key, setKey] = useState("Customer");
    return (
      <Tabs activeKey={key} onSelect={(k) => setKey(k)} className="mb-3">
        <Tab eventKey="customer" title="Customer"></Tab>
        <Tab eventKey="business" title="Vendor"></Tab>
      </Tabs>
    );
  };
const sendemail= async()=>{
  const response = await Axios.post("/send-email-verification", {
    email,
    'url': path + '/verify-email/',  
  });
  if(response.data.status == true){
toast.success(response.data.message)
  }

}
  // document.title = "Login | Aladdin Direct";
  return (
    <Page title="Login">
      <div className="login-signup-section">
        {/* <LoginTabs /> */}
        <Container>
          <Row className="justify-content-center">
            <Col md="4" className="login-768">
              <div className="login-inner-block">
                <div className="heading-title-blk">
                  <div
                    className="googleButton "
                    style={{ cursor: "pointer" }}
                    onClick={() => login()}
                  >
                    {" "}
                    <div className="GoogleIconCont">
                      <img src="/Icons/google.png" className="GoogleIcon" />
                    </div>
                    <div className="googleText">Login with google</div>{" "}
                  </div>
                  <div className="or-block">
                    <span>OR</span>
                  </div>
                  <Button
                    variant="secondary full-btn"
                    onClick={() => navigate("/LoginOTP")}
                  >
                    Login using Mobile Number
                  </Button>

                  <div className="or-block">
                    <span>OR</span>
                  </div>
                  <h3>Login with Email</h3>
                </div>
                <div className="form-block">
                  <Form onSubmit={handleSubmit}>
                    <Form.Group className="mb-3">
                      <Form.Label>Email ID</Form.Label>
                      <Form.Control
                        type="email"
                        placeholder="Enter Email ID"
                        onChange={(e) => setEmail(e.target.value)}
                      />

                      <span className="text-danger">{errors.email}</span>
                      {emailverified && (
                        <>
                          <span className="text-danger">{emailverified}</span>
                          <span style={{ color: "blue", cursor: "pointer" }} onClick={sendemail}>
                            Send Email Now
                          </span>
                        </>
                      )}
                    </Form.Group>

                    <Form.Group className="mb-3">
                      <Form.Label>Password</Form.Label>
                      <Form.Control
                        type={type}
                        placeholder="Enter your Password"
                        onChange={(e) => setPassword(e.target.value)}
                      />
                      <i
                        className={eyetype}
                        style={{
                          float: "right",
                          fontSize: 20,
                          marginRight: 10,
                          marginTop: -30,
                          top: 0,
                        }}
                        onClick={viewpassword}
                      ></i>
                      <span className="text-danger">{errors.password}</span>
                    </Form.Group>
                    <Form.Group className="mb-3">
                      <div className="forgot-password-blk">
                        <NavLink to="/forgot-password">
                          Forgot Password?
                        </NavLink>
                      </div>
                    </Form.Group>
                    <Button
                      variant="primary full-btn"
                      type="submit"
                      disabled={buttonDisabled}
                    >
                      {buttonText}
                    </Button>
                    <div className="login-bottom-blk">
                      <p>
                        By Logging In, you agree the following{" "}
                        <NavLink to="/page/terms">Terms & Conditions</NavLink>{" "}
                        and the{" "}
                        <Link to="/page/privacy-policy">Privacy Policy</Link>
                      </p>
                      <h6>
                        Don't have an account?{" "}
                        <NavLink to="/sign-up" state={states.state}>
                          Click here
                        </NavLink>
                      </h6>
                    </div>
                  </Form>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
      <ToastContainer />
    </Page>
  );
};
export default Login;
