import React, { useContext, useEffect, useState, useRef } from "react";
import { FiMenu } from "react-icons/fi";
import { IoMdArrowDropdown } from "react-icons/io";
import { RiArrowDropDownLine } from "react-icons/ri";
import { MdBuild, MdHome, MdKeyboardArrowRight, MdMiscellaneousServices, MdStore } from "react-icons/md";
import { CgMenuGridR } from "react-icons/cg";
import { FaBusinessTime, FaHandHoldingUsd, FaHome, FaQuoteRight } from "react-icons/fa";
import { GrServices } from "react-icons/gr";
import { VscFileSymlinkDirectory } from "react-icons/vsc";
import { Link } from "react-router-dom";

const Bannertoheader = () => {
    const categories = [
        "Directory",
        "Products",
        "Services",
        "Startups",
        "Sell on APNASHAHER",
        "Request a Quote",
        "Build Your Startup",
    ];
    const categoryIcons = {
        "Directory": <VscFileSymlinkDirectory style={{ fontSize: "1.2rem", marginRight: "5px", marginTop: "-4px" }} />,
        "Products": <MdStore style={{ fontSize: "1.2rem", marginRight: "5px", marginTop: "-4px" }} />,
        "Services": <MdMiscellaneousServices style={{ fontSize: "1.2rem", marginRight: "5px", marginTop: "-4px" }} />,
        "Startups": <FaHandHoldingUsd style={{ fontSize: "1.2rem", marginRight: "5px", marginTop: "-4px" }} />,
        "Sell on APNASHAHER": <MdStore style={{ fontSize: "1.2rem", marginRight: "5px", marginTop: "-4px" }} />,
        "Request a Quote": <FaQuoteRight style={{ fontSize: "1.2rem", marginRight: "5px", marginTop: "-4px" }} />,
        "Build Your Startup": <FaBusinessTime style={{ fontSize: "1.2rem", marginRight: "5px", marginTop: "-4px" }} />,
    };
    // Initialize with the first category
    const [selectedCategory, setSelectedCategory] = useState(categories[0]);

    const rightNavContent = {
        Directory: ["Manufacturer", "Exporters & Importers",
            "Suppliers",
            "Distributors",
            "Retailers",

        ],
        Products: ["All Product", "Flash deal", "New Arrivals", "Stock Clearance"],
        Services: ["Show All Services", "IT & Softwares"],
        Startups: ["View Startups", "Startup for Sale", "Startup Registration", "Build My Startup"],
        "Sell on APNASHAHER": ["Sell Home", "Sell Blogs", "Sell Coupon"],
        "Request a Quote": ["Request Home", "Request Pages", "Request Blogs"],
        "Build Your Startup": ["Build Home", "Build Blogs", "Build Pages"],
    };

    const handleCategorySelect = (category) => {
        setSelectedCategory(category);
    };
    return (
        <div className="banner-top-header">
            <div className="container">
                <div className="top-banner-category">
                <div className="top-banner-category-name" >
                        <Link to={'/'}>
                        <h4><FaHome style={{ fontSize: "1.2rem", marginRight: "5px", marginTop: "-4px" , borderBottom:"none"}} /></h4>
                        <h5 >
                         Home
                        </h5>
                        </Link>
                     </div>
                    <div className="top-banner-category-name" >
                        <Link to={"/directory-home"}>
                        <h4><VscFileSymlinkDirectory style={{ fontSize: "1.2rem", marginRight: "5px", marginTop: "-4px" }} /></h4>
                        <h5 >
                         Directory
                        </h5>
                        </Link>
                     </div>
                     <div className="top-banner-category-name" >
                     <Link to={"/product-home"}>
                     <h4><MdStore style={{ fontSize: "1.2rem", marginRight: "5px", marginTop: "-4px" }} /></h4>
                        <h5 >
                         Products
                        </h5>
                        </Link>
                     </div>
                     <div className="top-banner-category-name" >
                     <Link to={"/services-home"}>
                     <h4><MdMiscellaneousServices style={{ fontSize: "1.2rem", marginRight: "5px", marginTop: "-4px" }} /></h4>
                        <h5 >
                         Services
                        </h5>
                        </Link>
                     </div>
                     <div className="top-banner-category-name" >
                     <Link to={"/startup-home"}>
                     <h4><FaHandHoldingUsd style={{ fontSize: "1.2rem", marginRight: "5px", marginTop: "-4px" }} /> </h4>
                        <h5 >
                         Startups
                        </h5>
                        </Link>
                     </div>
                     <div className="top-banner-category-name" >
                     <Link to={'/seller-benefits'}>
                     <h4><MdStore style={{ fontSize: "1.2rem", marginRight: "5px", marginTop: "-4px" }} /> </h4>
                        <h5 >
                        Sell on APNASHAHER
                        </h5>
                        </Link>
                     </div>
                     <div className="top-banner-category-name" >
                     <Link to={'/post-buy-requirement'}>
                     <h4><FaQuoteRight style={{ fontSize: "1.2rem", marginRight: "5px", marginTop: "-4px" }} /></h4>
                        <h5 >
                         Request a Quote
                        </h5>
                        </Link>
                     </div>
                     <div className="top-banner-category-name" >
                     <Link to={'/build-my-startup'}>
                     <h4><FaBusinessTime style={{ fontSize: "1.2rem", marginRight: "5px", marginTop: "-4px" }} /></h4>
                        <h5 >
                          Build Your Startup
                        </h5>
                        </Link>
                     </div>

                </div>
            </div>

        </div>
    );
};
export default Bannertoheader;
