import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { MobileFooter } from '../../apna-shaher/components/MobileFooter';
import { FaFacebook, FaInstagram, FaLinkedin, FaYoutube } from "react-icons/fa";
import { FaXTwitter } from 'react-icons/fa6';
import axios from 'axios';
import { BASE_URL } from '../../../config'
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
};

const FooterPage = () => {
     const [open, setOpen] = React.useState(false);
      const handleOpen = () => setOpen(true);
      const handleClose = () => setOpen(false);
    const [page,setPage] = useState([])

    const handleClick = (event) => {
        event.preventDefault();
        window.scrollTo(0, 0);
    };
    useEffect(() => {
        widgetData();
   
      }, []);
      const widgetData = async () => {
      
        try {
          const wgtData = await axios.get(BASE_URL + "/get-all-pages");
          setPage(wgtData.data.data);
          
          
        } catch (err) {
          console.log(err);
         
        }
      };
    return (
        <>
            <MobileFooter />
            <div className='container-fluid footer-bg '>
                <div className='container footer-section pd-6'>
                    <h1>Popular Cities</h1>
                    <div className='popular_cities'>
                        <div className='popularcity_name'>
                            <div className='popular-city-img'>
                                <img src="https://t4.ftcdn.net/jpg/05/40/30/71/360_F_540307170_qg8R8Seug9NYJzL7FpbBuW5St0lIyAvv.jpg"></img>
                            </div>
                            <p className='m-0'>Lucknow</p>

                        </div>
                        <div className='popularcity_name'>
                            <div className='popular-city-img'>
                                <img src="https://st3.depositphotos.com/1141099/13866/i/450/depositphotos_138665290-stock-photo-charminar-in-hyderabad.jpg"></img>
                            </div>
                            <p className='m-0'>Noida</p>

                        </div>
                        <div className='popularcity_name'>
                            <div className='popular-city-img'>
                                <img src="https://t4.ftcdn.net/jpg/05/40/30/71/360_F_540307170_qg8R8Seug9NYJzL7FpbBuW5St0lIyAvv.jpg"></img>
                            </div>
                            <p className='m-0'>Delhi</p>

                        </div>
                        <div className='popularcity_name'>
                            <div className='popular-city-img'>
                                <img src="https://t4.ftcdn.net/jpg/05/40/30/71/360_F_540307170_qg8R8Seug9NYJzL7FpbBuW5St0lIyAvv.jpg"></img>
                            </div>
                            <p className='m-0'>Mumbai</p>

                        </div>
                        <div className='popularcity_name'>
                            <div className='popular-city-img'>
                                <img src="https://t4.ftcdn.net/jpg/05/40/30/71/360_F_540307170_qg8R8Seug9NYJzL7FpbBuW5St0lIyAvv.jpg"></img>
                            </div>
                            <p className='m-0'>Hyderabad</p>

                        </div>
                        <div className='popularcity_name'>
                            <div className='popular-city-img'>
                                <img src="https://t4.ftcdn.net/jpg/05/40/30/71/360_F_540307170_qg8R8Seug9NYJzL7FpbBuW5St0lIyAvv.jpg"></img>
                            </div>
                            <p className='m-0'>Chennai</p>

                        </div>
                        <div className='popularcity_name'>
                            <div className='popular-city-img'>
                                <img src="https://t4.ftcdn.net/jpg/05/40/30/71/360_F_540307170_qg8R8Seug9NYJzL7FpbBuW5St0lIyAvv.jpg"></img>
                            </div>
                            <p className='m-0'>Kolkata</p>

                        </div>
                        <div className='popularcity_name'>
                            <div className='popular-city-img'>
                                <img src="https://t4.ftcdn.net/jpg/05/40/30/71/360_F_540307170_qg8R8Seug9NYJzL7FpbBuW5St0lIyAvv.jpg"></img>
                            </div>
                            <p className='m-0'>Varanasi
                            </p>

                        </div>
                        <div className='popularcity_name'>
                            <div className='popular-city-img'>
                                <img src="https://t4.ftcdn.net/jpg/05/40/30/71/360_F_540307170_qg8R8Seug9NYJzL7FpbBuW5St0lIyAvv.jpg"></img>
                            </div>
                            <p className='m-0'>Ahmedabad</p>

                        </div>
                        <div className='popularcity_name'>
                            <div className='popular-city-img'>
                                <img src="https://t4.ftcdn.net/jpg/05/40/30/71/360_F_540307170_qg8R8Seug9NYJzL7FpbBuW5St0lIyAvv.jpg"></img>
                            </div>
                            <p className='m-0'>Jaipur</p>

                        </div>
                        <div className='popularcity_name'>
                            <div className='popular-city-img'>
                                <img src="https://t4.ftcdn.net/jpg/05/40/30/71/360_F_540307170_qg8R8Seug9NYJzL7FpbBuW5St0lIyAvv.jpg"></img>
                            </div>
                            <p className='m-0'>Bengaluru</p>

                        </div>
                        
                    </div>

                    <hr />

                    <h1>Products Categories</h1>
                    <ul className='popular-category'>
                        <li>Machinery & Tools | </li>
                        <li>Electronics & Electrical | </li>
                        <li>Plastic & Plastic Products | </li>
                        <li>Industrial Products | </li>
                        <li>Scientific & Surgical | </li>
                        <li>Sporting Goods & Equipments </li>
                    </ul>
                    <hr />
                    <h1>Services Categories</h1>
                    <ul className='popular-category'>
                        <li>IT & Softwares | </li>
                        <li>Professional Services | </li>
                        <li>Medical & Health | </li>
                        <li>Loan & Finance | </li>
                        <li>Tours & Travels | </li>
                        <li>Transportation & Logistics </li>
                    </ul>
                    <hr />
                    <h2 className='social-hide-on-desktop'>Follow us :
                        <span className='mr-2 ml-4'><img src="/assets/Icons/facebook-1.svg" /></span>
                        <span className='mr-2'><img src="/assets/Icons/insta-1.svg" width={"20px"} /></span>
                        <span className='mr-2'><img src="/assets/Icons/youtube-1.svg" width={"20px"} /></span>
                        <span className='mr-2'><img src="/assets/Icons/twitter-icon.png" width={"20px"} /></span>
                        <span className='mr-2'><img src="/assets/Icons/linkedIn-icon-3.png" width={"20px"} /></span>
                    </h2>
                    <div className='footer-cols'>

                        <div>



                            <h2>Explore APNASHAHER</h2>
                            <ul className='footer-links'>
                                <li> <Link to="/product-home">Products</Link></li>
                                <li> <Link to="/services-home">Services</Link></li>
                                <li><Link to="/directory-home" >Business Directory</Link></li>
                                <li><Link to="/startup" scroll={true}>Startups</Link></li>
                            </ul>
                            <h2 className='social-hide-on-tab d-flex'>Follow us :</h2>
                                
                            <h2 className='d-flex' style={{marginLeft:"-20px"}}><span className='mr-2 ml-4'><h4><FaFacebook /></h4></span>
                                <span className='mr-2'><h4><FaInstagram/></h4></span>
                                <span className='mr-2'><h4><FaLinkedin /></h4></span>
                                <span className='mr-2'><h4><FaYoutube /></h4></span>
                                <span className='mr-2'><h4><FaXTwitter /></h4></span></h2>
                        </div>
                        <div>
                            <h2>Buyer</h2>
                            <ul className='footer-links'>
                                <li><Link to="/post-buy-requirement">Request a Quote</Link></li>
                                <li><Link to="/customer-faq">FAQ</Link></li>
                                <li onClick={handleOpen}>Login / Signup</li>
                                <li><Link to="/">What's New</Link></li>
                            </ul>
                        </div>
                        <div>
                            <h2>Seller</h2>
                            <ul className='footer-links'>
                                <li><Link to="/seller-benefits" scroll={true}>Seller Benefits</Link></li>
                                <li><Link to="/advertise-with-us" scroll={true}> Advertise with Us</Link></li>
                                <li onClick={handleOpen}> Login / Signup</li>
                                <li><Link to="/seller-faq"> FAQ</Link></li>
                                <li><Link to="/">Learning Centre</Link></li>
                            </ul>
                        </div>
                        <div>
                            <h2>Quick link</h2>
                            <ul className='footer-links'>
                                <li><Link to="/about-us-new">About Us</Link></li>
                                <li><Link to="/our-team">Our Team</Link></li>
                                <li><Link to="/partners-and-investors">Partners & Investors</Link></li>
                                <li><Link to="/join-our-team">Join our Team</Link></li>
                                <li><Link to="/success-story">Success Stories</Link></li>
                                <li><Link to="/blogs1">Blogs & Articles</Link></li>
                                <li><Link to="/faq">FAQ</Link></li>
                                <li><Link to="/contact-us">Contact Us</Link></li>
                            </ul>
                        </div>
                        <div>
                            <h2>Important Links</h2>
                            <ul className='footer-links'>
                                {page.map((e,i)=>(
                                    <li><Link to={`/pages/${e.slug}`}>{e.menu_name}</Link></li>
                                ))}
                                {/* <li><Link to="/">Terms of Use</Link></li>
                                <li><Link to="/">Privacy Policy</Link></li>
                                <li><Link to="/">Refund & Cancellations</Link></li>
                                <li><Link to="/">Product Listing Policy</Link></li>
                                <li><Link to="/">Disclaimer</Link></li>
                                <li><Link to="/">Risk Policy</Link></li>
                                <li><Link to="/">Cookies Policy</Link></li>
                                <li><Link to="/">SiteMap</Link></li>
                                <li><Link to="/report-abuse">Report Abuse</Link></li> */}
                            </ul>
                        </div>
                    </div>
                    <hr />
                    <div className='discalimer'>
                        <p><b>DISCLAIMER:</b> Information is provided for general purposes only. We do not guarantee accuracy or reliability. Use at your own risk. We are not liable for any loss or damage incurred.</p>
                    </div>
                    <hr />
                    <p className='copy-right mb-0'>© 2008-2025 Apnashaher.com. All rights reserved.</p>
                </div>
            </div>
            <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Typography id="modal-modal-title" variant="h6" component="h2">
          Stay Tunes, We are working on it...
          </Typography>
         
        </Box>
      </Modal>
        </>
    )
}

export default FooterPage;