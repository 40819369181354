import React, { useState, useEffect } from "react";
import { Carousel } from 'react-responsive-carousel';
import Dropdown from 'react-bootstrap/Dropdown';
import Slider from "react-slick";
import Form from 'react-bootstrap/Form';
import { ButtonGroup } from "react-bootstrap";
import { BASE_URL } from "../config";
import axios from "axios";
import { Link, useNavigate, useParams } from "react-router-dom";
import ReactStars from "react-stars";
import SeoServicecategoriesSoftware from "../seo page/SeoServiceCategoriesItsoftware";
import { FaPlus } from "react-icons/fa";
import { MdOutlineArrowForwardIos } from "react-icons/md";

import ReactOwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
const Bottomlevelcategory = () => {
    const [subcategorydata, setsubcategorydata] = useState([]);
    const [servicesdata, setservicesdata] = useState([]);
    const navigate = useNavigate();
    const { slug } = useParams();
    useEffect(() => {
        getservices();
    }, [])
    const getservices = () => {
        axios
            .get(BASE_URL + "/getsubcategoryservice/" + slug)
            .then(({ data }) => {
                setsubcategorydata(data?.data[0].childrens);
                setservicesdata(data.services)


            })
            .catch(({ response }) => { });

    }
    const slider = [
        { id: 1 }, { id: 2 }, { id: 3 }
    ];

  
   


  
      const generateRandomColor = () => {
        // window.location.reload();
        const randomColor = Math.floor(Math.random() * 16777215).toString(16); // Generate random hexadecimal color
        const color = "#" + randomColor;

        // Convert hexadecimal color to RGB
        const r = parseInt(color.substr(1, 2), 16);
        const g = parseInt(color.substr(3, 2), 16);
        const b = parseInt(color.substr(5, 2), 16);

        // Set opacity to 30%
        return `rgba(${r},${g},${b},0.05)`;
    };
const subchilddata= ["Seo", "Social Media Market"]
    return (
        <div className="mb-5">
            <SeoServicecategoriesSoftware />
            <div className="new-startup-top-banner" style={{ background: "url(/assets/images/btop.jpg)",height:"250px" }}>
                          <div className="startup-right-content-banner">
                            <h1>Explore a World of Businesses – List Your Business or Find Exactly What You Need!</h1>
                            <p>Explore businesses in our directory or register to showcase your own. Whether you're a customer or a seller, find what you need or get noticed today!</p>
                           
                          </div>
                        </div>
            <div className="menu-cont digital-services-parent mt-3">
               
                <div className="digital-category">
                    <div className="register-prof-section d-flex justify-content-between">
                        <h1>Digital Marketing</h1>
                       
                    </div>
              
                            <div className="sub-child-data">
                             <div className="product_filter">
                                               {subchilddata.map((e,index)=>(
                                                 <Link to={`/directory-listing/${e.slug}`} >
                                                 <div className="product_filter_card" style={{ backgroundColor: generateRandomColor() }} >
                             
                                                   <h2 className="d-flex align-items-center"><img src={e.icon} style={{width:"35px",height:"35px", marginRight:"15px"}}></img> {e}
                                                   </h2>
                             
                                                   <h2><MdOutlineArrowForwardIos /></h2>
                                                 </div>
                                               </Link>
                                               ))}
                                           
                             
                                             </div>
                    
                             
                            </div>
                          {/* </div> */}
                    {/* <div className="houzey-mt-1 hide-in-mobile" id="our-client-slider" >
                        <Slider {...settings}>
                            {subcategorydata.map((item, index) => (
                                <div className="digi-service-card cursor-pointer container-fluid">
                                    <div className="Data-And-Form-Container">
                                        <div className="Form-Content-Container">
                                            <img src={item.icon} />
                                            <p>{item.name}</p>
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </Slider>
                    </div> */}
                </div>
                <div className="overf-drpsec">
                    <div className="dropdown-section">
                        <Dropdown>
                            <Dropdown.Toggle className="dropdown-btn-color" id="dropdown-basic">
                                Service Options
                            </Dropdown.Toggle>
                            <Dropdown.Menu>
                                <Dropdown.Item href="#/action-1">Action</Dropdown.Item>
                                <Dropdown.Item href="#/action-2">Another action</Dropdown.Item>
                                <Dropdown.Item href="#/action-3">Something else</Dropdown.Item>
                            </Dropdown.Menu>
                        </Dropdown>
                        <Dropdown>
                            <Dropdown.Toggle className="dropdown-btn-color" id="dropdown-basic">
                                Rating
                            </Dropdown.Toggle>

                            <Dropdown.Menu>
                                <Dropdown.Item href="#/action-1">Action</Dropdown.Item>
                                <Dropdown.Item href="#/action-2">Another action</Dropdown.Item>
                                <Dropdown.Item href="#/action-3">Something else</Dropdown.Item>
                            </Dropdown.Menu>
                        </Dropdown>
                        <Dropdown>
                            <Dropdown.Toggle className="dropdown-btn-color" id="dropdown-basic">
                                Budgets
                            </Dropdown.Toggle>

                            <Dropdown.Menu>
                                <Dropdown.Item href="#/action-1">Action</Dropdown.Item>
                                <Dropdown.Item href="#/action-2">Another action</Dropdown.Item>
                                <Dropdown.Item href="#/action-3">Something else</Dropdown.Item>
                            </Dropdown.Menu>
                        </Dropdown>
                        <div className="ad-verified-btn">
                            <button >Ad Verified</button>
                        </div>
                        <div className="ad-verified-btn">
                            <button>Ad Trust</button>
                        </div>
                        <Form.Check // prettier-ignore
                            type="switch"
                            id="custom-switch"
                            label="Premium Services"
                        />
                        <Form.Check // prettier-ignore
                            type="switch"
                            id="custom-switch"
                            label="Priority Delivery"
                        />
                        <div className="view-all-services-btn">
                            <button>View All Service Providers</button>
                        </div>
                    </div>
                </div>

                <div>
                    <div className="row mt-4" style={{display:"flex",justifyContent:"center"}}>
                        <div className="service-detail-item">

                        {servicesdata.map((item, i) => (
                            <div
                                class="card  p-0"
                            // key={i}

                            >
                                <img
                                    src={item.service_image}
                                    className="card-img-top rounded-top-3"
                                    alt="..."
                                    width="328px"
                                    height="170px"
                                // onClick={() => navigate("/service-detail/" + item.slug)}
                                />
                                <div class="card-body px-0 pb-0 bg-white" style={{ overflow: "hidden" }}>
                                    <div className="  px-2 pb-0">
                                        <div className="sprovider d-flex gap-2 pb-2">
                                            <img
                                                src={'https://apnashaher.com/admin-login/public/storage/uploads/all/rX8cT08AdvxqVT50Vv93tfzP2pxELuJSvcn4O8Je.png'}
                                                alt=""
                                                width={"31px"}
                                                height={"31px"}
                                                style={{ borderRadius: "50%" }}
                                            // onClick={()=> navigate("/seller-detail/" + item.business_slug)}
                                            />
                                            <div className="d-flex  flex-column ml-1">
                                                <h5 class="card-title ">{item.seller_name}</h5>
                                                <p className="mb-0" style={{ marginTop: "-5px" }}>Lucknow</p>
                                            </div>
                                        </div>
                                        <div className="hpara">Healthcare Animation Services</div>
                                        <div className="p-category-name">
                                            <p>IT & Software</p>
                                            <p>Hardware & Networking</p>
                                            <p style={{ fontWeight: "600" }}><FaPlus style={{ fontSize: "14px" }} /></p>
                                        </div>

                                        <div className="sp-rating d-flex gap-2 py-2">
                                            <span className="star">
                                                <ReactStars
                                                    count={5}
                                                    value={4}
                                                    size={15}
                                                    edit={false}
                                                    color2={"#ffd700"}
                                                />
                                                {/* <i className="fa fa-star fs-5" style={{ color: "#FFCE31" }}></i> */}
                                            </span>

                                            <p className="rating-people mb-0 mt-0 ml-2 align-item-center">
                                                (123)
                                            </p>
                                        </div>
                                    </div>
                                    <div className="price-cont  px-2  d-flex align-items-center justify-content-between">
                                        <div>
                                            <span>Starting At</span>
                                            <div className="d-flex gap-2">
                                                <p className="act-price">
                                                    ₹ 200
                                                </p>
                                                <p className="dprice ml-2">
                                                    ₹ 200
                                                </p>
                                            </div>
                                        </div>
                                        <div>
                                            <span className="heart" >
                                                <i className=" fa fa-heart"></i>
                                            </span>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        ))}
                        </div>
                        

                    </div>
                </div>

            </div>
        </div>
    )
}


export default Bottomlevelcategory;