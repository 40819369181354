import React, { useState, useEffect } from "react";
import Slider from "react-slick";
import { BASE_URL } from "../../../config";
import axios from "axios";

const settings = {
  dots: true,
  infinite: true,
  autoplay: false,
  arrows: true,
  speed: 300,
  slidesToShow: 1,
  slidesToScroll: 1,
  responsive: [
    {
      breakpoint: 400,
      breakpoint: 800,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
        centerMode: false,
      },
    },
  ],
};

const TopInvestors = () => {
  const [allwidget, setAllwidget] = useState([]);

  const widgetData = async () => {
    try {
      const wgtData = await axios.get(BASE_URL + "/homepagewidget");
      setAllwidget(wgtData.data.data);
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    widgetData();
  }, []);

  return (
    <div className="become-seller-bg">
      <div className="container apna-investors-section">
      
        <div>
          {/* <div className="container text-center mt-6">
            <Slider {...settings}>
              <div className="investory-slider shadow-sm">
                <div className="col-md-6 ">
                  <div className="investor-details">
                    <div className="d-flex">
                      <img src="/assets/images/comma.png" width="20px" />
                      <img src="/assets/images/comma.png" width="20px" />
                    </div>
                    <p>
                      If you look at the Verified Suppliers, you'll be able to
                      see that they've been verified by independent third
                      parties, and factory visits. It gives you inside look into
                      the supplier.
                    </p>
                    <div className="user-details-1 text-start  d-flex justify-content-between ">
                      <div className="d-flex align-items-center gap-3">
                        <img src="/assets/images/user-2.png" width="50px" />
                        <div className="ml-3">
                          <h4 className="mb-0">Dr. Green</h4>
                          <h3 className="mb-0">Owner</h3>
                        </div>
                      </div>
                    </div>
                    <div className="user-details-1 ms-5 ps-3 ">
                    <img src='/assets/images/flag.png' width="30px" />
                      <h5 className="mb-0 ml-1">Sill Farm Supplier</h5>
                      
                    </div>
                  </div>
                </div>
                <div className="col-md-6">
                  <img src="/assets/images/investor-1.png" width="100%" />
                </div>
              </div>
              <div className="investory-slider shadow-sm">
                <div className="col-md-6">
                  <div className="investor-details">
                    <div className="d-flex">
                      <img src="/assets/images/comma.png" width="20px" />
                      <img src="/assets/images/comma.png" width="20px" />
                    </div>
                    <p>
                      If you look at the Verified Suppliers, you'll be able to
                      see that they've been verified by independent third
                      parties, and factory visits. It gives you inside look into
                      the supplier.
                    </p>

                    <div className="user-details-1 text-start  d-flex justify-content-between ">
                      <div className="d-flex align-items-center gap-3">
                        <img src="/assets/images/user-2.png" width="50px" />
                        <div className="ml-3">
                          <h4 className="mb-0">Dr. Green</h4>
                          <h3 className="mb-0">Owner</h3>
                        </div>
                      </div>
                    </div>
                    <div className="user-details-1 ms-5 ps-3 ">
                    <img src='/assets/images/flag.png' width="30px" /> 
                      <h5 className="mb-0 ml-2">Sill Farm Supplier</h5>
                      
                    </div>
                  </div>
                </div>
                <div className="col-md-6">
                  <img src="/assets/images/investor-1.png" width="100%" />
                </div>
              </div>
            </Slider>
          </div> */}
        </div>
        <div dangerouslySetInnerHTML={{ __html: allwidget.widget12 }}></div>
        {/* <h6>Backed by Top Investors</h6>
                <h2>Accel</h2>
                <p className='text-center'>trusted by some of the most well known entrepreneur in India</p> */}
        <div className="overf-div3">
          <div className="apna-investors">
            <div className="apna-user">
              <img src="/assets/images/user-1.png" />
              <h3>Siddharth</h3>
              <p>Co-Founder Pharmeasy</p>
            </div>
            <div className="apna-user">
              <img src="/assets/images/user-1.png" />
              <h3>Siddharth</h3>
              <p>Co-Founder Pharmeasy</p>
            </div>
            <div className="apna-user">
              <img src="/assets/images/user-1.png" />
              <h3>Siddharth</h3>
              <p>Co-Founder Pharmeasy</p>
            </div>
            <div className="apna-user">
              <img src="/assets/images/user-1.png" />
              <h3>Siddharth</h3>
              <p>Co-Founder Pharmeasy</p>
            </div>
            <div className="apna-user">
              <img src="/assets/images/user-1.png" />
              <h3>Siddharth</h3>
              <p>Co-Founder Pharmeasy</p>
            </div>
            <div className="apna-user">
              <img src="/assets/images/user-1.png" />
              <h3>Siddharth</h3>
              <p>Co-Founder Pharmeasy</p>
            </div>
            <div className="apna-user">
              <img src="/assets/images/user-1.png" />
              <h3>Siddharth</h3>
              <p>Co-Founder Pharmeasy</p>
            </div>
            <div className="apna-user">
              <img src="/assets/images/user-1.png" />
              <h3>Siddharth</h3>
              <p>Co-Founder Pharmeasy</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TopInvestors;
