import React, { useState, useEffect } from 'react'
import "../../style/CategoryPage.css"

import { Link } from 'react-router-dom'
import { FaPlus } from "react-icons/fa6";
import { FaMinus } from 'react-icons/fa';
import { BASE_URL } from '../../config';
import axios from 'axios';

export const SecondCommon = (props) => {
    const [category, setcategory] = useState(props?.category)
    
        const [categories, setCategories] = useState([]);
        const [selectedCategory, setSelectedCategory] = useState(null); // Now storing as a string
        const [filteredData, setFilteredData] = useState('');
    var slug = props?.slug;
    if (slug == "products-page") {
        slug = "category"
    }
    const generateRandomColor = () => {
        // window.location.reload();
        const randomColor = Math.floor(Math.random() * 16777215).toString(16); // Generate random hexadecimal color
        const color = "#" + randomColor;

        // Convert hexadecimal color to RGB
        const r = parseInt(color.substr(1, 2), 16);
        const g = parseInt(color.substr(3, 2), 16);
        const b = parseInt(color.substr(5, 2), 16);

        // Set opacity to 30%
        return `rgba(${r},${g},${b},0.05)`;
    };
    const [showAll, setShowAll] = useState(false);

    const handleToggle = () => {
        setShowAll(!showAll);
    };

    const maxVisible = 5; // Show up to 5 items initially
    
    useEffect(() => {
        FetchCategories();
    }, []);

    const FetchCategories = async () => {
        try {
            const { data } = await axios.get(BASE_URL + "/all-categories-for-directory-listing");
            setCategories(data.data);
            console.log("All Categories:", data.data);
        } catch (error) {
            console.error("Error fetching categories:", error);
        }
    };

    const handleCategoryClick = (categoryName) => {
        setSelectedCategory(categoryName);
        console.log("Selected Category:", categoryName);
    };

    useEffect(() => {
        if (selectedCategory) {
            const filtered = categories.filter((e) => e.name === selectedCategory);
            setFilteredData(filtered);
            console.log("Filtered Data:", filtered);
        }
    }, [selectedCategory, categories]); 
    return (
        <>
         <div className='directory_all_list'>
        < div className='cat-bg'>
            
            <div className="second-common paramedicals  ">
                <div className="container categories px-4 pt-4 pb-5 bg-white  ">
                    <Link to={"/" + slug + "/" + category.slug}>
                        <h2>{category.name}({category.product_count})</h2>
                    </Link>
                    <div className="row ">
                        <div className="left col-lg-3">
                            <Link to={"/" + slug + "/" + category.slug}>
                                <img src={category.banner} alt="" />
                            </Link>
                        </div>

                        <div className=" col-lg-9 right d-flex flex-wrap gap-2  ">
                            {/* cards  */}
                            {category?.childrens_count > 0 && category?.childrens.map((subcategory, i) => {
                                if (!showAll && i >= maxVisible) return null;

                                return (
                                    <div className="card py-3 px-4 " style={{ backgroundColor: generateRandomColor() }} key={i}>
                                        <div className="heading mb-2 d-flex justify-content-between ">
                                            <Link to={"/" + slug + "/" + subcategory.slug}>
                                                <h4 className=''>{subcategory?.name}({subcategory.product_count})</h4>
                                            </Link>
                                            <Link to={"/" + slug + "/" + subcategory.slug} style={{ whiteSpace: "nowrap" }}>View All</Link>
                                        </div>
                                        <div className="content-img d-flex justify-content-between ">
                                            <div className="content1">
                                                {subcategory?.childrens_count > 0 && subcategory?.childrens.map((subsubcateory, key) => {
                                                    // Show all items if 'showAll' is true or only the first 5 otherwise
                                                    if (!showAll && key >= maxVisible) return null;

                                                    return (
                                                        <Link to={`/${slug}/${subcategory.slug}`} key={key} style={{ textDecoration: "none" }}>
                                                            <p>{subsubcateory.name} ({subsubcateory.product_count})</p>
                                                        </Link>
                                                    );
                                                })}


                                                {subcategory?.childrens_count > maxVisible && (
                                                    <button onClick={handleToggle} className="toggle-more" style={{ color: "blue", paddingLeft: '0px' }}>
                                                        {showAll ? "Show less" : `+More Categories`}
                                                    </button>
                                                )}
                                            </div>
                                            <div className="image" style={{ width: "35%" }}>
                                                <Link to={"/" + slug + "/" + subcategory.slug}>
                                                    <img src={subcategory?.banner} alt="" />
                                                </Link>
                                            </div>
                                        </div>
                                    </div>
                                )
                            }


                            )}
                            {category?.childrens_count > maxVisible && (
                                <div className="card py-3 px-4 " style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                                    <button onClick={handleToggle} className="toggle-more" style={{ color: "blue", paddingLeft: '0px' }}>
                                        {showAll ? <div ><h3 style={{ fontSize: "25px" }}><FaMinus style={{ marginTop: "-4px" }} /> View Less</h3></div> : <div ><h3 style={{ fontSize: "25px" }}><FaPlus style={{ marginTop: "-4px" }} /> View More</h3></div>}
                                    </button>
                                </div>
                            )}


                        </div>
                    </div>
                </div>
            </div>
        </div>
        </div>
        <div className='directory_all_list_mobile'>
                <div className='container'>
                    <div className='row '>
                        <div className='directory_list_main_cat_card ' style={{ backgroundColor: generateRandomColor() }}>
                            <div className='directory_list_main_cat' data-bs-toggle="offcanvas" href="#offcanvasExample"
                                role="button" aria-controls="offcanvasExample"
                                onClick={() => handleCategoryClick(category.name)}>
                                <img src={category.icon} alt="" />
                                <h2>{category.name} ({category.product_count})</h2>
                            </div>
                        </div>

                        {/* Offcanvas Panel */}
                        <div className="offcanvas offcanvas-start" tabIndex="-1" id="offcanvasExample" aria-labelledby="offcanvasExampleLabel">
                            <div className="offcanvas-header">
                                <h5 className="offcanvas-title" id="offcanvasExampleLabel">Category Details</h5>
                                <button type="button" className="btn-close text-reset" data-bs-dismiss="offcanvas" aria-label="Close"></button>
                            </div>
                            <div className="offcanvas-body">
                                <h6>Selected Category: {filteredData.name}</h6>
                                {/* {filteredData.childrens.length > 0 ? (
                                    filteredData.childrens.map((e, i) => (
                                        <h6 key={i}>{e.name} ({e.product_count})</h6>
                                    ))
                                ) : (
                                    <p>No matching category</p>
                                )} */}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
