import React, { useState,useContext,useEffect } from "react";
import Form from "react-bootstrap/Form";
import { BASE_URL } from "../../../config";
import axios from "axios";
import { ToastContainer,toast } from "react-toastify";
import DispatchContext from "../../../context/DispatchContext";
import { useNavigate } from "react-router-dom";
const Help = () => {
  const appDispatch = useContext(DispatchContext);
  const navigate = useNavigate();
  // const [name, setName] = useState("");
  // const [email, setEmail] = useState("");
  // const [mobileNumber, setMobileNumber] = useState("");
  // const [image, setImage] = useState("");
  // const [feedback, setFeedback] = useState("");

  const [seller, setSeller] = useState({
    name: null,
    email: null,
    mobile_number: null,
    business_name: null,
    business_type: null,
    password: null,
    password_confirmation: null,
  });
const [error, seterror] = useState([]);
const [toggleForm, setToggleForm] = useState(false);
const[allwidget, setAllwidget] = useState([])



  const widgetData = async () => {
    try {
      const wgtData = await axios.get(BASE_URL + "/homepagewidget");
      setAllwidget(wgtData.data.data);
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    widgetData();
  }, []);



    // const handleImage = e => {
  //     setImage(e.target.files[0]);
  // }
  
  const toggleSellerForm = () => {
    submitRequest();
    
}

// setTimeout(() => {
//     document.querySelector("#formAlert").style.display = "none";
// },5000);


  const handleSellerInput = (e) => {
    const { name, value } = e.target;
    setSeller({
      ...seller,
      [name]: name === "mobile_number" ? Number(value) : value,
    });
  };

  const submitRequest = () => {
    seterror("");
    axios
      .post(BASE_URL + `/addseller`, seller)
      .then((res) => {
        if (res.status == "201") {
          appDispatch({
            type: "login",
            data: {
              token: res.data.data.token,
              name: res.data.data.user.name,
              email: res.data.data.user.email,
              mobileNumber: res.data.data.user.mobile_number,
              profileImage: res.data.data.user.profile_image,
              step: res.data.data.user.step,
              lastLogin: res.data.data.user.last_login,
            },
          });
          navigate("/dashboard");
        }
      })
      .catch(({ response }) => {
        seterror(response.data.errors);
        if(response.data.errors.name || response.data.errors.mobile_number || response.data.errors.email || response.data.errors.business_name){
        }else{
          setToggleForm(true)
    
        }
      });
  };


  return (
    < div className="become-seller-bg">
      <div className="container ">
        <div className="d-flex become-seller-p justify-content-between gap-4">
          <div className="form-section-apna">
            <h6>Become a Seller</h6>
            <p>Join #1 Business Directory to Grow your business in an <span style={{color: "#ffb800", fontSize:"16px",fontWeight:"500"}}>ECONOMICAL</span> Way</p>
            <p id="formAlert" style = {{color:"#ffb800", fontWeight: "600", display:"none" }}>All Fields are required</p>
            
            <div>
                { toggleForm === false ?   <div>
            <Form.Control
                size="sm"
                type="text"
                name="name"
                value={seller.name}
                placeholder="Full Name"
                onChange={handleSellerInput}
              />
              {error && error.name &&(
                <div className="text-danger">{error.name}</div>
              )}

              <Form.Control
                size="sm"
                type="number"
                value={seller.mobile_number}
                name="mobile_number"
                placeholder="Mobile Number"
                onChange={handleSellerInput}
              />
              {error && error.mobile_number &&(
                <div className="text-danger">{error.mobile_number}</div>
              )}
              <Form.Control
                size="sm"
                type="text"
                name="email"
                value={seller.email}
                placeholder="Email ID"
                onChange={handleSellerInput}
              />
              {error && error.email &&(
                <div className="text-danger">{error.email}</div>
              )}
              <Form.Control
                size="sm"
                type="text"
                name="business_name"
                value={seller.business_name}
                placeholder="Business Name"
                onChange={handleSellerInput}
              />
              {error && error.business_name &&(
                <div className="text-danger">{error.business_name}</div>
              )}
              <button onClick={toggleSellerForm}>Continue</button>
              </div> : <div>
              <Form.Select
                aria-label="Default select example"
                name="business_type"
                value={seller.business_type}
                onChange={handleSellerInput}
              >
                <option value="">Select Business Type</option>
                <option value="Individual">Individual</option>
                <option value="Sole Proprietorships">Sole Proprietorships</option>
                <option value="Partnerships Firm">Partnerships Firm</option>
                <option value="Private Limited Company">Private Limited Company</option>
                <option value="Limited Liability Company (LLC)">Limited Liability Company (LLC)</option>
                <option value="Corporations">Corporations</option>
                <option value="Others">Others</option>
              </Form.Select>
              {error && error.business_type &&(
                <div className="text-danger">{error.business_type}</div>
              )}

              <Form.Select
                aria-label="Default select example"
                name="gender"
                value={seller.gender}
                onChange={handleSellerInput}
              >
                <option>Gender</option>
                <option value="male">Male</option>
                <option value="female">Female </option>
                <option value="other">Other</option>
              </Form.Select>
              {error && error.gender &&(
                <div className="text-danger">{error.gender}</div>
              )}
              <Form.Control
                size="sm"
                type="password"
                value={seller.password}
                name="password"
                placeholder="Enter Password"
                onChange={handleSellerInput}
              />
              {error && error.password &&(
                <div className="text-danger">{error.password}</div>
              )}
              <Form.Control
                size="sm"
                type="password"
                value={seller.password_confirmation}
                name="password_confirmation"
                placeholder="Re-enter Password"
                onChange={handleSellerInput}
              />
              {error && error.password_confirmation &&(
                <div className="text-danger">{error.password_confirmation}</div>
              )}

              <div className="d-flex justify-conent-between align-items-center gap-2">
                <button onClick={()=> setToggleForm(false)}>Back</button>
                <button onClick={submitRequest}>Send Request</button>
                </div>

              </div>

}

              {/* <button onClick={submitRequest}>Send Request</button> */}

              <p>
              Once you post the inquiry you will receive a call from the Expert to understand your requirements.
              </p>

            </div>
          </div>

          {/* Content Section Apna */}
          <div className="ps-lg-5 ps-md-3 ps-0" dangerouslySetInnerHTML={{ __html: allwidget.widget6}}>

          </div>
          {/* <div className="content-section-apna"  >
            <p>What service do ypu need?</p>
            <h3>APNASHAHER.COM IS HERE TO HELP YOU</h3>
            <p>
              Tell us more about your requirements so that we can connect you to
              the right service provider.
            </p>
            <div className="help-points">
              <img src="/assets/images/rocket.png" />
              <div>
                <h5>We connect with right service provider</h5>
                <p>
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
                  do eiusmod tempor incididunt ut labore et dolore magna aliqua.
                </p>
              </div>
            </div>
            <div className="help-points">
              <img src="/assets/images/rocket-1.png" />
              <div>
                <h5>Stand out of Competition</h5>
                <p>
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
                  do eiusmod tempor incididunt ut labore et dolore magna aliqua.
                </p>
              </div>
            </div>
            <div className="help-points">
              <img src="/assets/images/rocket.png" />
              <div>
                <h5>Tell us more about your requirements</h5>
                <p>
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
                  do eiusmod tempor incididunt ut labore et dolore magna aliqua.
                </p>
              </div>
            </div>
          </div> */}
        </div>
      </div>
      <ToastContainer />
    </div>
  );
};

export default Help;
