import React, { useContext } from 'react';
import StateContext from '../context/StateContext';
import BrandItemContainer from './brand/brand-item-container.component';
import Loader from './loader/Loader.component';

const BrandSection = ({ isLoading }) => {
  const { brands } = useContext(StateContext);
  return (
    <div className="container-fluid">
      <div className="feature-product-blk ">
        <div className="feature-blkpro-header ">
          <h4>SHOP BY BRANDS</h4>
        </div>
        {isLoading ? <Loader /> : <BrandItemContainer brands={brands} />}
      </div>
      <div className="container  " >
        <img src="https://demo.martvill.techvill.net/public/uploads/20221205/2dcff5a961eb2a3127b2fe3b87861d99.webp" width="100%" style={{ borderRadius: "10px", marginTop: "20px" }} />
      </div>
    </div>
  );
};

export default BrandSection;
