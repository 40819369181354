import React, { useEffect } from 'react'
import "../style/Aboutus.css"
import { AboutBanner } from '../components/about-us/AboutBanner'
import { AboutComp2 } from '../components/about-us/AboutComp2'
import { AboutComp3 } from '../components/about-us/AboutComp3'
import { AboutComp4 } from '../components/about-us/AboutComp4'
import { AboutComp5 } from '../components/about-us/AboutComp5'
import SeoAboutusPage from '../seo page/Seoaboutuspsge'

export const Aboutus = () => {
  useEffect(() =>{
    window.scrollTo(0,0)
  },[])
  return (
    <div className="about-us">
      <SeoAboutusPage/>
      <section>
        <AboutBanner/>
      </section>

      <section>
        <AboutComp2/>
      </section>

      <section>
        <AboutComp3 imgUrl={"/assets/images/coo-user.png"}/>
      </section>

      {/* <section>
        <AboutComp4/>
      </section> */}
<div className="become-seller-bg1">
          <div className='container mt-5 mb-4'>
            {/* <div className='container mt-5 mb-4' dangerouslySetInnerHTML={{ __html: allwidget.widget4}} > */}
            <div className='third-section1 '>
              <div className='explore-millions11' style={{width:"100%"}}>
                <h2 className='text-center' style={{color:"#ffffff"}}>Keep exploring APNASHAHER.com for the best business opportunities, to learn more contact us</h2>
              </div>
              
            </div>
          </div>
        </div>
      <section>
        <AboutComp5/>
      </section>
      
    </div>
  )
}
