import React, { useRef, useState } from 'react'
import "../../style/SellerDetails.css"
import "../../style/StartupDetail.css"
import { Link } from 'react-router-dom'

import { IoIosStar, IoIosStarHalf, IoMdCall } from "react-icons/io";
import { FaArrowRightLong, FaLocationDot } from 'react-icons/fa6'
import Box from '@mui/material/Box';
import Rating from '@mui/material/Rating';

import { MdOutlineMail } from 'react-icons/md'

export const StartupdComp3 = (props) => {
    const [rating, setRating] = useState(0);
    const [rateus, setRateus] = useState(false)
    const [value, setValue] = React.useState(0);

    const handleClick = (index) => {
        setRating(index + 1);
    };

    function removeActiveClass() {
        const tabButtons = document.getElementsByClassName("toggle-link");
        const spanElm = document.querySelectorAll(".toggle-link span")

        for (const lis of tabButtons) {
            lis.classList.remove("active-build-ms-stab");
        }

        for (const spans of spanElm) {
            spans.classList.remove("active-build-ms-stab")
        }
    }

    const targetRef = useRef(null)
    const handleLinkTab = (event, _id) => {
        removeActiveClass();
        event.stopPropagation()

        let targetElem = document.getElementById(_id)

        if (_id === "popup_option") {
            let showElem = document.querySelector("#popup_option");
            showElem.style.display = "block";
        }

        const offset = -250;
        const elementPosition = targetElem.getBoundingClientRect().top + window.pageYOffset;
        const offsetPosition = elementPosition + offset;

        window.scrollTo({
            top: offsetPosition,
            behavior: 'smooth'
        });

        // const offset = "-150px"
        // targetElem.scrollIntoView({ behavior: 'smooth', top: offset });

        const clickedButton = event.currentTarget;
        clickedButton.classList.add("active-build-ms-stab");

    };


    const stars = [...Array(5)].map((_, index) => (
        <span
            key={index}
            className={index < rating ? "rate-star-golden" : "rate-star"}
            onClick={() => handleClick(index)}
        >
            <i class="bi bi-star-fill"></i>
        </span>
    ));


    const showEditForm = () => {
        document.querySelector(".suggest-edit").style.display = "block"
        document.querySelector("#popup_option").style.display = "none"

    }

    const closePopup = () => {
        document.querySelector(".suggest-edit").style.display = "none"
        document.querySelector("#popup_option").style.display = "none"
    }

    return (
        <>
            <div className="seller-details-comp3 startup-detail-comp3">
                <div className="container d-flex justify-content-between gap-3">
                    {/* left  */}
                    <div className="col-lg-3 col-md-3 col-sm-3 col-12 left position-relative">
                        <div className="fix-content">
                            <div className="rounded-div"></div>
                            <div className="left-content" style={{ marginTop: "-80px" }}>
                                <ul className="ps-0">
                                    <li
                                        className="toggle-link d-flex align-items-center justify-content-between active-build-ms-stab"
                                        onClick={(event) => handleLinkTab(event, "company_info")}
                                    >
                                        <span>Company Information</span>{" "}
                                        <span className="arrow-icon" style={{ color: "#555" }}>
                                            <FaArrowRightLong />
                                        </span>
                                    </li>
                                    <li
                                        className="toggle-link d-flex align-items-center justify-content-between "
                                        onClick={(event) => handleLinkTab(event, "product_services")}
                                    >
                                        <span>Product & Services</span>{" "}
                                        <span className="arrow-icon" style={{ color: "#555" }}>
                                            <FaArrowRightLong />
                                        </span>
                                    </li>
                                    <li
                                        className="toggle-link d-flex align-items-center justify-content-between "
                                        onClick={(event) => handleLinkTab(event, "highlights_features")}
                                    >
                                        <span>Highlights & Features</span>{" "}
                                        <span className="arrow-icon" style={{ color: "#555" }}>
                                            <FaArrowRightLong />
                                        </span>
                                    </li>
                                    {/* <li
                                        className="toggle-link d-flex align-items-center justify-content-between "
                                        onClick={(event) => handleLinkTab(event, "pitch_deck")}
                                    >
                                        <span>Pitch Deck</span>{" "}
                                        <span className="arrow-icon" style={{ color: "#555" }}>
                                            <FaArrowRightLong />
                                        </span>
                                    </li> */}
                                    <li
                                        className="toggle-link d-flex align-items-center justify-content-between "
                                        onClick={(event) => handleLinkTab(event, "rate_us")}
                                    >
                                        <span>Rating & Review</span>{" "}
                                        <span className="arrow-icon" style={{ color: "#555" }}>
                                            <FaArrowRightLong />
                                        </span>
                                    </li>
                                    <li
                                        className="toggle-link d-flex align-items-center justify-content-between "
                                        onClick={(event) => handleLinkTab(event, "subscribe")}
                                    >
                                        <span>Send Enquiry</span>{" "}
                                        <span className="arrow-icon" style={{ color: "#555" }}>
                                            <FaArrowRightLong />
                                        </span>
                                    </li>
                                    {/* <li
                                        className="toggle-link d-flex align-items-center justify-content-between "
                                        onClick={(event) => handleLinkTab(event, "request")}
                                    >
                                        <span>Request Quote</span>{" "}
                                        <span className="arrow-icon" style={{ color: "#555" }}>
                                            <i className="fa fa-arrow-right"></i>
                                        </span>
                                    </li> */}
                                    <li
                                        className="toggle-link d-flex align-items-center justify-content-between "
                                        onClick={(event) => handleLinkTab(event, "contact_info")}
                                    >
                                        <span>Contact Us</span>{" "}
                                        <span className="arrow-icon" style={{ color: "#555" }}>
                                            <FaArrowRightLong />
                                        </span>
                                    </li>
                                    <li
                                        className="toggle-link d-flex align-items-center justify-content-between "
                                        onClick={(event) => handleLinkTab(event, "popup_option")}
                                    >
                                        <span>Suggest an Edit</span>{" "}
                                        <span className="arrow-icon" style={{ color: "#555" }}>
                                            <FaArrowRightLong />
                                        </span>
                                    </li>
                                </ul>

                            </div>
                        </div>

                    </div>

                    {/* right  */}
                    <div className="col-lg-9 right">

                        {/* 1st right component   */}
                        <div ref={targetRef} id="company_info" className="company-info  px-5 pt-5 gap-2">
                            <div className="d-flex flex-lg-row gap-2 flex-column-reverse align-items-center justify-content-center">

                                <div className="left-div col-lg-12">

                                    <h3>{props?.data?.company_name}</h3>
                                    <p dangerouslySetInnerHTML={{ __html: props?.data?.about_startup }}></p>
                                </div>


                            </div>

                            <div className="cards d-flex flex-wrap gap-2 justify-content-between my-3 w-100" style={{ overflowX: "auto" }}>

                                <div className="card text-center px-2 py-2">
                                    <p>ESTABLISHED YEAR</p>
                                    <span>{props?.data?.establish_year}</span>
                                </div>
                                <div className="card text-center px-2 py-2">
                                    <p>MARKET VALUATION</p>
                                    <span style={{ color: "#00DC23" }}> {props?.data?.market_valuation}</span>
                                </div>
                                <div className="card text-center px-2 py-2">
                                    <p>REVENUE</p>
                                    <span style={{ color: "#A2D60D" }}>{props?.data?.revenue} %</span>
                                </div>
                                <div className="card text-center px-2 py-2">
                                    <p>NET PROFIT</p>
                                    <span>{props?.data?.net_profit} %</span>
                                </div>

                            </div>

                        </div>

                        {/* 2nd right componnet  */}
                        <div ref={targetRef} id="product_services" className="product-and-services   px-5 pt-4 gap-2">
                            <div className='d-flex flex-lg-row flex-column-reverse gap-2 align-items-center justify-content-center'>


                                <div className="left-div col-lg-12">
                                    <h3>Product & Services</h3>
                                    <div dangerouslySetInnerHTML={{ __html: props?.data?.about_product_service }}></div>
                                </div>


                            </div>


                        </div>

                        {/*3rd right compnent   */}
                        <div ref={targetRef} id="highlights_features" className="product-and-services    px-5 pt-4 gap-2">
                            <div className='d-flex flex-lg-row flex-column-reverse gap-2 align-items-center justify-content-center'>


                                <div className="left-div col-lg-12">
                                    <h3>Highlights & Features</h3>
                                    <div dangerouslySetInnerHTML={{ __html: props?.data?.feature_highlight }}></div>
                                </div>


                            </div>


                        </div>


                        {/* 3.1 right Component */}
                        {/* <div ref={targetRef} id="pitch_deck" className="product-and-services pitch-desk  text-center px-5 pt-4 gap-2">
                            <h3>Pitch Desk</h3>

                           
                            <Link to={IMAGE_BASE_URL+props?.data?.pitchdeck} className="my-3 py-3 btn btn-primary" target='_blank'>Download Pitch Desk</Link>

                        </div> */}

                        {/* 3.2 right Component */}
                        <div ref={targetRef} id="rate_us" className="review-and-rating rate-us  px-5 pt-4 gap-2">
                            <h3>Reviews & Ratings </h3>
                            <div className='review-rating-section'>
                                <div className='review-rating-left'>
                                    <h2><span style={{ fontSize: "50px" }}>4.4</span> <span style={{ fontSize: "30px", fontWeight: "600", color: "gray", marginLeft: "15px" }}>
                                        <span style={{ color: "orange", marginTop: "-4px" }}><IoIosStar style={{ marginTop: "-10px" }} /> <IoIosStar style={{ marginTop: "-10px" }} /> <IoIosStar style={{ marginTop: "-10px" }} /> <IoIosStar style={{ marginTop: "-10px" }} /> <IoIosStarHalf style={{ marginTop: "-10px" }} /></span></span></h2>
                                    <h1 style={{ fontWeight: "700", color: "gray", fontSize: "20px" }}>1.3K Ratings & 297 Reviews</h1>
                                    {/* <button onClick={() => setRateus(true)}>Write a review</button> */}
                                    <div className="startup-deal-btn" style={{ width: "40%" }}>

                                        <button className="raise-capital" onClick={() => setRateus(true)}>Write Review</button>

                                    </div>

                                </div>
                                <div className='review-rating-center'>

                                </div>
                                <div className='review-rating-left'>
                                    <div className='review-rating-section1'>
                                        <h3>5 <IoIosStar style={{ marginTop: "-6px", color: "orange" }} /></h3>
                                        <div class="progress mb-2" style={{ width: "70%", height: "10px" }}>
                                            <div class="progress-bar " role="progressbar" aria-valuenow="75" aria-valuemin="0" aria-valuemax="100" style={{ width: "70%" }}></div>
                                        </div>
                                        <p className='' style={{ marginTop: "5px" }}>70%</p>

                                    </div>
                                    <div className='review-rating-section1'>
                                        <h3>4 <IoIosStar style={{ marginTop: "-6px", color: "orange" }} /></h3>
                                        <div class="progress mb-2" style={{ width: "70%", height: "10px" }}>
                                            <div class="progress-bar " role="progressbar" aria-valuenow="75" aria-valuemin="0" aria-valuemax="100" style={{ width: "40%" }}></div>
                                        </div>
                                        <p className='' style={{ marginTop: "5px" }}>40%</p>

                                    </div>
                                    <div className='review-rating-section1'>
                                        <h3>3 <IoIosStar style={{ marginTop: "-6px", color: "orange" }} /></h3>
                                        <div class="progress mb-2" style={{ width: "70%", height: "10px" }}>
                                            <div class="progress-bar " role="progressbar" aria-valuenow="75" aria-valuemin="0" aria-valuemax="100" style={{ width: "30%" }}></div>
                                        </div>
                                        <p className='' style={{ marginTop: "5px" }}>30%</p>

                                    </div>
                                    <div className='review-rating-section1'>
                                        <h3>2 <IoIosStar style={{ marginTop: "-6px", color: "orange" }} /></h3>
                                        <div class="progress mb-2" style={{ width: "70%", height: "10px" }}>
                                            <div class="progress-bar " role="progressbar" aria-valuenow="75" aria-valuemin="0" aria-valuemax="100" style={{ width: "35%" }}></div>
                                        </div>
                                        <p className='' style={{ marginTop: "5px" }}>35%</p>

                                    </div>
                                    <div className='review-rating-section1'>
                                        <h3>1 <IoIosStar style={{ marginTop: "-6px", color: "orange" }} /></h3>
                                        <div class="progress mb-2" style={{ width: "70%", height: "10px" }}>
                                            <div class="progress-bar " role="progressbar" aria-valuenow="75" aria-valuemin="0" aria-valuemax="100" style={{ width: "10%" }}></div>
                                        </div>
                                        <p className='' style={{ marginTop: "5px" }}>10%</p>

                                    </div>

                                </div>


                            </div>

                            {rateus === false ? null

                                :
                                <div id="" className="user-reviews  my-3">
                                    <h6>Rate Us</h6>
                                    <form action="">
                                        <Box sx={{ '& > legend': { mt: 2 } }}>

                                            <Rating
                                                name="simple-controlled"
                                                value={value}
                                                onChange={(event, newValue) => {
                                                    setValue(newValue);
                                                }}
                                            />


                                        </Box>
                                        {/* <div className="stars-div my-3">
                                            {stars}
                                        </div> */}
                                        <div className='my-3'>
                                            <h6>Tell us about your experience</h6>
                                            <textarea className='w-100 rounded px-2 py-2 fs-6' style={{ border: "1px solid #5555552e" }} name="" id="" cols="30" rows="6" placeholder='Please share your experience with the business. Things you can talk about: service, product and price'></textarea>
                                        </div>
                                        <div className='my-3 '>
                                            <h6>Upload Img</h6>
                                            <div className="position-relative  px-4 py-3 rounded" style={{ border: "1px solid #5555552e" }} >
                                                <label className="position-absolute fs-6" style={{ color: "#5555552e" }}><i className='fa fa-camera fs-5 pe-3'></i> Upload Img</label>
                                                <input type="file" className='opacity-0' />
                                            </div>
                                        </div>

                                        <button className='blue-bg-button' >Submit Review</button>
                                    </form>
                                </div>
                            }

                        </div>
                        <div className='review-get-section'>
                            <div className='user-review-card'>
                                <div className='user-review-details'>

                                    <div className='user-card-pic'>
                                        <img src='https://cdn-icons-png.flaticon.com/512/219/219988.png'></img>

                                    </div>
                                    <div className='user-card-pic-details'>
                                        <h6 className='m-0'>Guest User</h6>
                                        <p className='m-0' style={{ fontSize: "12px", lineHeight: "13px" }}>Web Mingo IT Solutions</p>
                                        <p className='m-0' style={{ fontSize: "12px", lineHeight: "13px" }}><span style={{ color: "orange", marginTop: "-4px" }}><IoIosStar /> <IoIosStar /> <IoIosStar /> <IoIosStar /> <IoIosStarHalf /></span></p>

                                    </div>

                                </div>
                                <p>Page Conducting thorough software development is essential in creating effective and efficient applications. The process involves multiple stages, including planning, designing, coding, testing, and deployment.</p>


                            </div>
                            <div className='user-review-card'>
                                <div className='user-review-details'>

                                    <div className='user-card-pic'>
                                        <img src='https://cdn-icons-png.flaticon.com/512/219/219988.png'></img>

                                    </div>
                                    <div className='user-card-pic-details'>
                                        <h6 className='m-0'>Guest User</h6>
                                        <p className='m-0' style={{ fontSize: "12px", lineHeight: "13px" }}>Web Mingo IT Solutions</p>
                                        <p className='m-0' style={{ fontSize: "12px", lineHeight: "13px" }}><span style={{ color: "orange", marginTop: "-4px" }}><IoIosStar /> <IoIosStar /> <IoIosStar /> <IoIosStar /> <IoIosStarHalf /></span></p>

                                    </div>

                                </div>
                                <p>Page Conducting thorough software development is essential in creating effective and efficient applications. The process involves multiple stages, including planning, designing, coding, testing, and deployment.</p>


                            </div>
                            <div className='user-review-card'>
                                <div className='user-review-details'>

                                    <div className='user-card-pic'>
                                        <img src='https://cdn-icons-png.flaticon.com/512/219/219988.png'></img>

                                    </div>
                                    <div className='user-card-pic-details'>
                                        <h6 className='m-0'>Guest User</h6>
                                        <p className='m-0' style={{ fontSize: "12px", lineHeight: "13px" }}>Web Mingo IT Solutions</p>
                                        <p className='m-0' style={{ fontSize: "12px", lineHeight: "13px" }}><span style={{ color: "orange", marginTop: "-4px" }}><IoIosStar /> <IoIosStar /> <IoIosStar /> <IoIosStar /> <IoIosStarHalf /></span></p>

                                    </div>

                                </div>
                                <p>Page Conducting thorough software development is essential in creating effective and efficient applications. The process involves multiple stages, including planning, designing, coding, testing, and deployment.</p>


                            </div>
                            <div className='user-review-card'>
                                <div className='user-review-details'>

                                    <div className='user-card-pic'>
                                        <img src='https://cdn-icons-png.flaticon.com/512/219/219988.png'></img>

                                    </div>
                                    <div className='user-card-pic-details'>
                                        <h6 className='m-0'>Guest User</h6>
                                        <p className='m-0' style={{ fontSize: "12px", lineHeight: "13px" }}>Web Mingo IT Solutions</p>
                                        <p className='m-0' style={{ fontSize: "12px", lineHeight: "13px" }}><span style={{ color: "orange", marginTop: "-4px" }}><IoIosStar /> <IoIosStar /> <IoIosStar /> <IoIosStar /> <IoIosStarHalf /></span></p>

                                    </div>

                                </div>
                                <p>Page Conducting thorough software development is essential in creating effective and efficient applications. The process involves multiple stages, including planning, designing, coding, testing, and deployment.</p>


                            </div>

                        </div>

                        {/* 4th right Component */}
                        <div ref={targetRef} id="subscribe" className="request px-5 pt-4 gap-2">
                            <h3>Subscribe</h3>
                            <p className='bold-para mt-4'>Fill the Form & Connect with "{props?.data?.company_name}"</p>
                            <form action="">
                                <div className="d-flex my-2 flex-wrap gap-2 align-items-center justify-content-between">
                                    <input type="text" placeholder='Full Name' />
                                    <input type="email" placeholder='Email ID' />
                                </div>
                                <div className="d-flex my-2 flex-wrap gap-2 align-items-center justify-content-between">
                                    <input type="number" placeholder='Mobile Number' />
                                    <select className="form-select rounded-0" aria-label="Default select example">
                                        <option selected>Country</option>
                                        <option value="1">India</option>
                                        <option value="2">USA</option>
                                        <option value="3">UK</option>
                                    </select>
                                </div>
                                <div className="three-inp d-flex my-2 flex-wrap gap-2  align-items-center justify-content-between">
                                    <select className="form-select rounded-0" aria-label="Default select example">
                                        <option selected>State</option>
                                        <option value="1">UP</option>
                                        <option value="2">MP</option>
                                        <option value="3">UK</option>
                                    </select>
                                    <div className='d-flex  flex-wrap gap-1 align-items-center justify-content-between' style={{ width: "48%" }}>

                                        <input type="text" placeholder='City' />
                                        <input type="number" placeholder='Pin Code' />
                                    </div>

                                    <div className="my-2" style={{ width:"100%" }}>
                                        <textarea
                                            style={{ border: "1px solid #BCBCBC", width:"100%" }}
                                            className="w-100 px-2 py-2 fs-6"
                                            name="message"

                                            id=""
                                            cols="30"
                                            rows="5"
                                            placeholder="Type Detail"
                                        ></textarea>

                                    </div>
                                </div>



                                <button className='submit-btn mt-lg-4 mt-3'>Submit</button>
                            </form>

                        </div>

                        {/* 5th right Component  */}
                        <div id="contact_info" className="contact-info d-flex align-items-center justify-content-center px-5 py-4 gap-2 mt-4">
                            <div className="left-div col-lg-6  col-12">
                                <h3>Contact Us</h3>
                                {/* <h3>WEB MINGO IT SOLUTIONS PVT LTD</h3> */}
                                <div className="cont-details d-flex flex-wrap justify-content-between">
                                    <div className="address my-lg-1 my-2">

                                        <p><FaLocationDot /> &nbsp;{props?.data?.address}</p>
                                    </div>
                                    <div className="mobile my-lg-1 my-2">


                                        <p><IoMdCall /> &nbsp;{props?.data?.mobile_number}</p>

                                    </div>
                                    <div className="emails my-lg-1 my-2">

                                        <p><MdOutlineMail />&nbsp; {props?.data?.email}</p>
                                    </div>
                                    <div className="social-link my-lg-1 my-2">

                                        <ul className='d-flex gap-2 ps-0'>
                                            <li>
                                                <Link to={props?.data?.facebook_url}><i class="fa fa-facebook"></i></Link>
                                            </li>
                                            <li>
                                                <Link to={props?.data?.twitter_url}><i class="fa fa-instagram"></i></Link>
                                            </li>

                                            <li>
                                                <Link to={props?.data?.linkedin_url}><i class="fa fa-linkedin"></i></Link>
                                            </li>
                                            <li>
                                                <Link to={props?.data?.mobile_number}><i class="fa fa-whatsapp"></i></Link>
                                            </li>

                                            <li>
                                                <Link to={props?.data?.youtube_url}><i class="fa fa-youtube"></i></Link>
                                            </li>
                                            <li>
                                                <Link to={props?.data?.linkedin_url}><i class="fa fa-google-plus"></i></Link>
                                            </li>
                                            <li>
                                                <Link to={props?.data?.pinterest_url}><i class="fa fa-pinterest"></i></Link>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>

                            <div className="right-div col-lg-6 col-12">
                                <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d50589.9631670406!2d77.33510599757896!3d28.619082772305685!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x390ce5a035689a39%3A0x3d9564cd104c4b57!2sWeb%20Mingo%20IT%20Solutions%20Pvt.%20Ltd.%20-%20Website%20Designing%20%26%20Digital%20Marketing%20Company!5e0!3m2!1sen!2sin!4v1701432480563!5m2!1sen!2sin" width="fit-content" height="fit-content" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
                            </div>

                        </div>



                    </div>

                </div>


                {/* Popop Option Container */}
                <div id="popup_option">
                    <div className="popup-content px-4 py-4 bg-white">
                        <div className='top-content d-flex justify-content-between pe-2'>
                            <div>
                                <h2>Help us improve this listing</h2>
                                <p>Select from the option below</p>
                            </div>

                            <span onClick={closePopup}>✕</span>
                        </div>
                        <div className='tab-links d-flex gap-3 flex-column justify-content-start'>
                            <button className='border-0 bg-transparent ' onClick={showEditForm} >Edit /Modify Business</button>
                            <Link to="/report-abuse">Report Abuse</Link>
                        </div>
                    </div>
                </div>

                {/* 6th Popup Component  */}
                <div id="suggest_edit" className="suggest-edit">
                    <div className="bg-blur"></div>
                    <div className="suggest-form px-4 py-4 bg-white">
                        <div className="content d-flex  justify-content-between">
                            <div>
                                <h2>Suggest an Edit</h2>
                                <p>Web Mingo Pvt Ltd</p>
                            </div>

                            <span onClick={closePopup}>✕</span>
                        </div>



                        <form action="">
                            <div className="my-2 d-flex gap-2 flex-wrap justify-content-between align-items-center">
                                <input type="text" placeholder='Company Name' />
                                <select className="form-select " aria-label="Default select example">
                                    <option selected>Business Cagetory</option>
                                    <option value="1">IT</option>
                                    <option value="2">Finanace</option>
                                    <option value="3">HealthCse</option>
                                </select>
                            </div>
                            <div className="my-2 full-address">
                                <input type="text" placeholder='Full Address' />
                            </div>
                            <div className="my-2 d-flex flex-wrap gap-2 justify-content-between align-items-center">
                                <select className="form-select " aria-label="Default select example">
                                    <option selected>Country</option>
                                    <option value="1">India</option>
                                    <option value="2">Nepal</option>
                                    <option value="3">USA</option>
                                </select>
                                <select className="form-select " aria-label="Default select example">
                                    <option selected>State</option>
                                    <option value="1">UP</option>
                                    <option value="2">MP</option>
                                    <option value="3">UK</option>
                                </select>
                            </div>
                            <div className="my-2 gap-2 d-flex flex-wrap justify-content-between align-items-center">
                                <input type="text" placeholder='City' />
                                <input type="number" placeholder='Pin Code' />
                            </div>

                            <div className="my-2 gap-2 d-flex flex-wrap justify-content-between align-items-center">
                                <input type="number" placeholder='Mobile Number' />
                                <input type="text" placeholder='Website Url' />
                            </div>

                            <div className="my-3 d-flex flex-column gap-2">
                                <h6>Other</h6>
                                <textarea name="" id="" cols="30" rows="4" placeholder='Details here...'></textarea>
                            </div>

                            <div className="my-2 upload-logo position-relative">
                                <label className='position-absolute' >Upload Logo</label>
                                <input type="file" />
                            </div>

                            <div className="my-3 form-btns d-flex flex-wrap justify-content-between">
                                <button className='cancle-btn' onClick={closePopup}>Cancel</button>
                                <button className='submit-btn'>Submit</button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </>
    )
}
