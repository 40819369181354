import React, { useState, useEffect } from "react";
import Form from "react-bootstrap/Form";
import { BASE_URL } from "../../../config";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";

const BecomeBuyer = () => {
  // const [name, setName] = useState("");
  // const [email, setEmail] = useState("");
  // const [mobileNumber, setMobileNumber] = useState("");
  // const [image, setImage] = useState("");
  // const [feedback, setFeedback] = useState("");

  const [error, seterror] = useState([]);
  const [buyer, setBuyer] = useState({
    name: null,
    email: null,
    mobile_number: null,
    buyer_type: null,
    detail: null,
    term_condition: null,
    type: "buyer",
  });
  const [allwidget, setAllwidget] = useState([])


  // Get Data form Api 

  const widgetData = async () => {
    try {
      const wgtData = await axios.get(BASE_URL + "/homepagewidget");
      setAllwidget(wgtData.data.data)
    } catch (err) {
      console.log(err);
    }
  }

  useEffect(() => {
    widgetData();
  }, []);


  // const handleImage = e => {
  //     setImage(e.target.files[0]);
  // }

  const handleBuyerInput = (e) => {
    const { name, value } = e.target;

    setBuyer({
      ...buyer,
      [name]: name === "mobile_number" ? Number(value) : value,
    });
  };

  const submitRequest = (event) => {
    event.preventDefault();
    seterror("");
    const formData = new FormData();
    // formData.append('name', name);
    // formData.append('email', email);
    // formData.append('mobile_number', mobileNumber);
    // formData.append('type', "buyer");
    // formData.append('service_type', "hello");
    console.log("Buyer", buyer);

    axios
      .post(BASE_URL + `/addbuyerquery`, buyer)
      .then((res) => {
        if (res.status == "201") {
          // console.log("set enqiury called");
          toast.success("Query Added Successfully");
          document.getElementsByClassName("formbuyer")[0].reset();
          setBuyer("");
        }
      })
      .catch(({ response }) => {
        seterror(response.data.errors);
      });
  };
  return (
    <div className="become-seller-bg">
      <div className="container mt-5">
        <div className="d-flex become-buyer-p justify-content-between"  >

          <div className="pe-lg-5 pge-md-3 pe-0" dangerouslySetInnerHTML={{ __html: allwidget.widget10 }}>

          </div>

          <div className="form-section-apna">

            <h6>For Buyers</h6>
            <p>
              Fill the Form & Connect with Top rated Sellers only at
              <span
                style={{
                  color: "#ffb800",
                  fontSize: "16px",
                  fontWeight: "500",
                }}
              >
                {" "}
                APNASHAHER.COM
              </span>
            </p>
            <div>
              <Form className="formbuyer">
                <Form.Control
                  size="sm"
                  type="text"
                  name="name"
                  placeholder="Full Name"
                  onChange={handleBuyerInput}
                />
                {error && error.name && (
                  <div className="text-danger">{error.name}</div>
                )}
                <Form.Control
                  size="sm"
                  type="number"
                  name="mobile_number"
                  placeholder="Mobile Number"
                  onChange={handleBuyerInput}
                />
                {error && error.mobile_number && (
                  <div className="text-danger">{error.mobile_number}</div>
                )}
                <Form.Control
                  size="sm"
                  type="text"
                  name="email"
                  placeholder="E-mail ID"
                  onChange={handleBuyerInput}
                />
                {error && error.email && (
                  <div className="text-danger">{error.email}</div>
                )}
                

                <Form.Select className="mb-3" aria-label="Default select example"  onChange={handleBuyerInput} >
                  <option >Select Products or Services</option>
                  <option value="product">Products</option>
                  <option value="service">Services</option>
                 
                </Form.Select>

                {/* <div className="d-flex flex-column  sel-product-service justify-content-between ">
                  <p className="m-0 mt-2">Please Select: </p>
                  <div className="d-flex  align-items-center gap-3 ">
                    <Form.Check
                      type="radio"
                      aria-label="radio 1"
                      label=" Product"
                      value="product"
                      name="type"
                      onChange={handleBuyerInput}
                      className="d-flex align-items-center text-white gap-2 "
                    />
                    <Form.Check
                      type="radio"
                      aria-label="radio 1"
                      label="Service"
                      value="service"
                      name="type"
                      onChange={handleBuyerInput}
                      className="d-flex align-items-center text-white gap-2"
                    />
                  </div>
                </div> */}
                {error && error.type && (
                  <div className="text-danger">{error.type}</div>
                )}

                <Form.Control
                  as="textarea"
                  name="description"
                  placeholder="Detail"
                  onChange={handleBuyerInput}
                />
                {error && error.description && (
                  <div className="text-danger">{error.description}</div>
                )}
                <Form.Check
                  type="checkbox"
                  name="term_condition"
                  aria-label="radio 1"
                  label="I Accept & Acknowledge that my contact information will be shared with the registered sellers at APNASHAHER.COM"
                  className=" d-flex mt-2 text-white gap-3 "
                  onChange={handleBuyerInput}
                />
                {error && error.term_condition && (
                  <div className="text-danger">{error.term_condition}</div>
                )}
                <button onClick={submitRequest}>Send Request</button>
              </Form>

              <p className="text-center">
                Once you post the inquiry you will receive a call from the
                Expert to understand your requirements.
              </p>
            </div>
          </div>
        </div>
      </div>
      <ToastContainer />
    </div>
  );
};

export default BecomeBuyer;
