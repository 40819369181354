import React, { useState } from 'react'
import "../../style/JoinOurTeam.css"

import { FormCom } from './FormCom'
import { Link } from 'react-router-dom';

export const JotCom2 = () => {
    const [showForm, setShowForm] = useState(false);

    return (
        <>
            <div className="start-up-top-card" style={{ height: "280px" }}>
                <h1>Join Our Team</h1>
                <p className="col-8 text-center">Join our dynamic team and be part of a journey that drives innovation and success. At ApnaShaher.com, we value talent, passion, and creativity—let's grow together!</p>
                <div className="startup-deal-btn" style={{ width: "10%" }}>
                    <Link to={'/'}>
                        <button className="raise-capital">View Openings</button>
                    </Link>


                </div>
            </div>
            <div className="become-seller-bg1">
                <div className='container mt-4 mb-4'>
                    {/* <div className='container mt-5 mb-4' dangerouslySetInnerHTML={{ __html: allwidget.widget4}} > */}
                    <div className='third-section third-section1 justify-content-between'>
                        <div className='explore-millions flex-column text-start'>
                            <h2 className='text-start'>Be part of our creative community</h2>
                            <p style={{ color: "#fff" }}>Join a vibrant community of innovators, dreamers, and doers at ApnaShaher.com. Collaborate, share ideas, and grow alongside like-minded individuals who are shaping the future of entrepreneurship.</p>
                        </div>
                        <div className='explore-millions-count d-flex ' style={{ width: "200px", alignItems: "center" }}>
                            <div className="startup-deal-btn" >
                                <Link to={'/'}>
                                    <button className="raise-capital-partner" style={{ height: "40px" }} onClick={() => setShowForm(true)}>Join Us</button>
                                </Link>


                            </div>
                            {/* <button onClick={() => setShowForm(true)}>Join Us</button> */}
                        </div>
                    </div>
                </div>
            </div>
            <div className="jot-com2 my-lg-5 my-md-4 my-3 me-lg-5 me-md-4 me-3">

                {showForm === true ?
                    <FormCom />
                    :
                    null}


            </div>

        </>
    )
}
