import React, { useState, useEffect } from 'react'
import "../style/Sellerbenefites.css"
import { Sellerbanner } from '../components/seller-benefites/Sellerbanner'
import { Whysell } from '../components/seller-benefites/Whysell'
import { Spmbanner } from '../components/seller-benefites/Spmbanner'
import { Sbsection4 } from '../components/seller-benefites/Sbsection4'
import { ApnashaherSecond } from '../components/apna-shaher/components/ApnashaherSecond'
import Help from '../components/apna-shaher/components/BecomeSeller'
import { Sellertestimonial } from '../components/seller-benefites/Sellertestimonial'
import { Sellerhelp } from '../components/seller-benefites/Sellerhelp'
import axios from 'axios';
import { BASE_URL } from '../config'
import BecomeSellerSellerBenefit from '../components/apna-shaher/components/BecomeSellerSellerBenefit';
import Loader from '../components/loader/Loader.component'
import { renderToString } from 'react-dom/server';
import { Link, useNavigate } from 'react-router-dom';
import { FiMinus, FiPlus } from "react-icons/fi";
import SeoSellerBenifitPage from '../seo page/SeoSellerbenifit';
import globalImage1 from "../assets/a1.jpg"
import globalImage2 from "../assets/add-page.jpg"
import globalImage3 from "../assets/free-website.jpg"
import globalImage4 from "../assets/free-website1.jpg"
import globalImage5 from "../assets/user-friendly.jpg"
import globalImage6 from "../assets/why-choose-us.jpg"

import userfriendly from "../assets/business-growth.jpg"
import { IoMdCheckmarkCircleOutline } from 'react-icons/io'


export const Sellerbenefites = () => {
  const [widget, setwidget] = useState("")
  const [isLoading, setIsLoading] = useState(true);
  const navigate = useNavigate();

  const [activeTab, setActiveTab] = useState("Global Reach");

  // const accordionData = [
  //   {
  //     title: "Global Reach",
  //     content: `By listing their products on APNASHAHER.COM, Sellers can reach buyers all over the globe. 
  //       This is an excellent chance for sellers to expand their consumer base, access a wider audience, 
  //       and diversify their revenue streams. It can assist their business expansion and boost sales.`,
  //     image: globalImage1,
  //   },
  //   {
  //     title: "User-Friendly Platform",
  //     content: `Our platform is highly intuitive and easy to use, enabling sellers to manage their 
  //       businesses efficiently. Whether you're new or experienced, the interface ensures a seamless experience.`,
  //     image: globalImage2,
  //   },
  //   {
  //     title: "Free Website",
  //     content: `APNASHAHER.COM offers sellers a free website to showcase their products and services, 
  //       saving costs and allowing customization to better connect with customers.`,
  //     image: globalImage3,
  //   },
  // ];
  const accordionData = [
    {
      title: "Global Reach",
      content: `By listing their products on APNASHAHER.COM, Sellers can reach 
        buyers all over the globe. This is an excellent chance for sellers to expand their 
        consumer base, access a wider audience, and diversify their revenue streams. It can 
        assist their business expansion and boost sales.`,
      image: globalImage1, // Replace with the correct image path
    },
    {
      title: "User-Friendly Platform",
      content: `Sellers can simply add their company profile, merchandise, or 
        services on the site due to the intuitive layout and accessibility of webinars and live 
        support teams. It includes a large group of people, not limited to freelancers, 
        manufacturers, exporters and importers, distributors, suppliers, retailers, service 
        providers, and educational institutions. These users can find what they're looking for on 
        APNASHAHER.COM, and anyone from buyers to sellers in their region can find ways to 
        advertise to a global audience.`,
      image: globalImage2, // Replace with the correct image path
    },
    {
      title: "Free Website",
      content: `With APNASHAHER.COM's free website service, sellers can turn to their 
        employers. Sellers enjoy independence, a professional web presence, and greater 
        control over their business by enabling them to develop and personalise their websites 
        on their own domains.`,
      image: globalImage3, // Replace with the correct image path
    },
    {
      title: "Premium Membership Benefits",
      content: `By subscribing to the premium membership benefits, 
        sellers on APNASHAHER.COM can boost their exposure and recognition, exceeding 
        competitors. With this premium alternative, sellers can display their profiles, products, 
        and services extensively, giving them an edge over competitors.`,
      image: globalImage4, // Replace with the correct image path
    },
    {
      title: "Banner Ads",
      content: `Sellers on APNASHAHER.COM can boost their visibility by carefully 
        choosing display banners on sites that are relevant to their brand. With this approach, 
        sellers can reach more people and communicate with their ideal clientele, increasing 
        their brand's engagement and potential for growth.`,
      image: globalImage5, // Replace with the correct image path
    },
    {
      title: "24/7 Business",
      content: `Businesses can create a constant presence on APNASHAHER.COM by 
        listing their products, services, or seller profiles. Customers can look into and interact 
        with companies at any time due to constant accessibility, improving convenience, and 
        potentially improving sales and customer satisfaction.`,
      image: globalImage6, // Replace with the correct image path
    },
  ];
  

  useEffect(() => {
    window.scrollTo(0, 0);
    widgetData();
  }, []);

  // useEffect(() => {
  //   const handleAnchorClick = (e) => {
  //     e.preventDefault();
  //     const href = e.target.getAttribute('href');
  //     navigate(href);
  //   };

  //   // Attach event listener to all anchor tags
  //   const anchors = document.querySelectorAll('a');
  //   anchors.forEach((anchor) => {
  //     anchor.addEventListener('click', handleAnchorClick);
  //   });

  //   // Cleanup: Remove event listeners on component unmount
  //   return () => {
  //     anchors.forEach((anchor) => {
  //       anchor.removeEventListener('click', handleAnchorClick);
  //     });
  //   };
  // }, [navigate]);
  const widgetData = async () => {
    try {
      const wgtData = await axios.get(BASE_URL + "/sellerbenefitwidget");
      setwidget(wgtData.data.data);
      setIsLoading(false);
    } catch (err) {
      console.log(err);
      setIsLoading(false);
    }
  };
  if (isLoading) {
    return <Loader />;
  }
  return (
    <div style={{ background: "#F5F5F5" }}>

      <SeoSellerBenifitPage />
      <div className="new-startup-top-banner" style={{ background: "url(/assets/images/ctop.jpg)" }}>
        <div className="startup-right-content-banner">
          <h1>Your first move will decide your business future & its Growth</h1>
          <p>Expand your reach, boost your sales, and grow with our powerful seller platform. Register today and start achieving your goals.</p>
          <div className="startup-deal-btn" style={{ width: "100%" }}>
            <Link to={''}>
              <button className="raise-capital">Register Now</button>
            </Link>
          </div>
        </div>
      </div>

      <section dangerouslySetInnerHTML={{ __html: widget.widget1 }}></section>
      <section className="seller-benifits-increase pt-5 bg-white">
      <div className="container">
        <div className="seller-heading">
          <h1 className="text-center"> Boost Your Online Presence and Attract New Customers!</h1>
          <p className="text-center">
            Sellers on APNASHAHER.COM have an edge in the worldwide marketplace because of the
            platform's heightened prominence and visibility. Businesses can thrive via this
            cutting-edge marketplace, which fosters growth, cooperation, and profitability on
            a global level.
          </p>
        </div>
        <div className="seller-accordian">
          <div className="seller-acco-left">
            {accordionData.map((item) => (
              <div className="seller-accordian-tab" key={item.title}>
                <div
                  className="seller-accordian-tab-heading d-flex justify-content-between align-items-center"
                  onClick={() =>
                    setActiveTab(activeTab === item.title ? "" : item.title)
                  }
                >
                  <h5 className="m-0">{item.title}</h5>
                  <h5 className="m-0">
                    {activeTab === item.title ? <FiMinus /> : <FiPlus />}
                  </h5>
                </div>
                {activeTab === item.title && (
                  <div className="seller-accordian-description">
                    <p className='px-4'>{item.content}</p>
                  </div>
                )}
              </div>
            ))}
          </div>
          <div className="seller-right-image">
            <img
              src={
                accordionData.find((item) => item.title === activeTab)?.image
              }
              alt={activeTab}
            />
          </div>
        </div>
      </div>
    </section>
      {/* <section>
    <Sellerbanner/>
   </section>

    <section className="why-sell-parent">
      <Whysell/>
    </section>

    <section className="spm-banner-parent">
      <Spmbanner/>
    </section>

    <section className="sbsection4-parent">
      <Sbsection4/>
    </section> */}

      <section>
        <BecomeSellerSellerBenefit data={widget} />
      </section>
      <div className='seller-section-bg'>
        <div className="container mb-4 mt-5">
          <div className="row">


            <div className="col-12 col-lg-7">
              <div className="startup-right-content11">
                <h6 style={{ fontWeight: "600" , textAlign:"start", fontSize:"16px", width:"100%"}}>WHY CHOOSE APNASHAHER.COM?</h6>
                <h1>Enabling enterprises through international commerce.</h1>
                <p>Selling on APNASHAHER.COM makes it a breeze for sellers to reach a broader demographic,
                  increasing sales and profits. In addition to fulfilling every need of its users, the platform acts as a
                  crucial channel to build partnerships amongst trustworthy suppliers throughout India and
                  internationally.</p>
                
                <div className='' style={{width:"100%"}}>
                <h6 style={{ fontWeight: "600" , textAlign:"start", fontSize:"16px", width:"100%"}}>Cost Effective</h6>
                  <p className='m-0'><IoMdCheckmarkCircleOutline style={{marginTop:"-4px", marginRight:"10px", color:"green"}}/> Finding the right Customers for your business</p>
                  <p className='m-0'><IoMdCheckmarkCircleOutline style={{marginTop:"-4px", marginRight:"10px", color:"green"}}/> Stand out of Competition</p>
                  <p className='m-0'><IoMdCheckmarkCircleOutline style={{marginTop:"-4px", marginRight:"10px", color:"green"}}/> User Friendly Panel</p>
                </div>
                <div className="startup-deal-btn" style={{ width: "100%" }}>
                  <Link to={'/'}>
                    <button className="raise-capital mobile-btn">Register Now</button>
                  </Link>
                </div>
              </div>
            </div>
            <div className="col-12 col-lg-5">
              <img src={userfriendly} style={{ borderRadius: "10px" }}></img>
            </div>
          </div>
        </div>
        <section className="testimonial-parent text-center">
          <Sellertestimonial data={widget} />
        </section>
      </div>
      {/* <section className="seller-help-parent">
      <Sellerhelp data={widget}/>
    </section> */}

    </div>
  )
}
