import React from 'react'
import { IoMdCheckmarkCircleOutline } from 'react-icons/io'
import feature from '../../assets/about-feature.jpg'

export const AboutComp3 = ({ imgUrl }) => {
    return (
        <>
            <div className="about-comp3 mx-lg-5 mx-md-4 mx-3 my-lg-5 my-md-4 my-3">

                <div className="d-flex justify-content-between align-items-center">
                    <div className="left">

                        <h3>Discover the standout features that make us exceptional for businesses!</h3>
                        <h6 className='m-0' style={{ color: "#002a5b" }}><strong><IoMdCheckmarkCircleOutline style={{ marginTop: "-4px", marginRight: "10px", color: "green" }} /> Global Reach<br /> &nbsp;</strong></h6>
                        <div className='feature-content'>
                            <p >Businesses can showcase their goods to a large and varied worldwide
                                audience on ApnaShaher.com, a platform that defies geographical boundaries. We
                                encourage cross-border inquiries by offering a user-friendly interface and advanced
                                technologies that enable businesses to interact with prospective clients all around the
                                globe easily.</p>
                        </div>
                        <h6 className='m-0' style={{ color: "#002a5b" }}><strong><IoMdCheckmarkCircleOutline style={{ marginTop: "-4px", marginRight: "10px", color: "green" }} /> Comprehensive Listings<br /> &nbsp;</strong></h6>
                        <div className='feature-content'>
                            <p >Here at ApnaShaher.com, we take immense pride in providing an approachable platform to many businesses. Our platform is intended to help any business shine, regardless of what you do: manufacturing, import, export, distribution, supply, retail, service, freelancing, educational, or startup.</p>
                        </div>
                        <h6 className='m-0' style={{ color: "#002a5b" }}><strong><IoMdCheckmarkCircleOutline style={{ marginTop: "-4px", marginRight: "10px", color: "green" }} />  Business Inquiries<br /> &nbsp;</strong></h6>
                        <div className='feature-content'>
                            <p >Understanding the significance of promoting the growth of companies, ApnaShaher.com has state-of-the-art resources and amenities developed to generate top-notch company inquiries. By facilitating the procedure of connecting with clients, partners, and consumers, our network helps businesses survive in today's continually evolving industry.</p>
                        </div>
                        <h6 className='m-0' style={{ color: "#002a5b" }}><strong><IoMdCheckmarkCircleOutline style={{ marginTop: "-4px", marginRight: "10px", color: "green" }} /> User-Friendly Interface<br /> &nbsp;</strong></h6>
                        <div className='feature-content'>
                            <p > You will have no difficulty navigating ApnaShaher.com. The main objective for creating this platform is the simplicity of use for vendors and buyers in examining, connecting, and transacting.</p>
                        </div>
                        <h6 className='m-0' style={{ color: "#002a5b" }}><strong><IoMdCheckmarkCircleOutline style={{ marginTop: "-4px", marginRight: "10px", color: "green" }} /> Innovation & Adaptability<br /> &nbsp;</strong></h6>
                        <div className='feature-content'>
                            <p > Innovation is essential in today's competitive corporate world. ApnaShaher.com is committed to being creative and innovative to provide businesses with a leg up in the marketplace.</p>
                        </div>
                        <h6 className='m-0' style={{ color: "#002a5b" }}><strong><IoMdCheckmarkCircleOutline style={{ marginTop: "-4px", marginRight: "10px", color: "green" }} /> Dedicated Support Team<br /> &nbsp;</strong></h6>
                        <div className='feature-content'>
                            <p > You can count on our support team to be available at every turn. Feel free to message our specialised support team if you have any inquiries, require help, or stumble into any problem.</p>
                        </div>
                        
                    </div>
                    <div className="right position-relative">
                        <img src={feature} alt="" />
                        <div className='r-content'>
                            <p> <span>12 Year</span> <br />Experience </p>
                        </div>
                    </div>
                </div>

            </div>
        </>
    )
}
