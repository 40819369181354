import React, { useState, useEffect } from "react";
import "../../style/Sellerbenefites.css";
import "../../style/Advertisewithus.css";
import axios from "axios";
import { BASE_URL } from "../../config";
import { ToastContainer, toast } from "react-toastify";
import contact from '../../assets/contact.jpg'
export const Advertisehelp = (props) => {
  const [data, setdata] = useState({
    name: null,
    email: null,
    mobile_number: null,
    business_name: null,
    advertisment_type: null,
    monthly_budget: null,
    message: null,
  });
  const [error, seterror] = useState([]);
  const handlesubmit = () => {
    seterror("");
    axios
      .post(BASE_URL + `/addsellerenquiry`, data)
      .then((res) => {
        if (res.data.status === 201) {
          toast.success("Enquiry Send Successfully!");
          document.getElementsByClassName("inquiryform")[0].reset();
        }
      })
      .catch(({ response }) => {
        seterror(response.data.errors);
      });
  };
  const handleInput = (e) => {
    const { name, value } = e.target;
    setdata({
      ...data,
      [name]: name === "mobile_number" ? Number(value) : value,
    });
  };
  return (
    <>
      <div className="seller-help advertise-help mt-lg-5 mt-md-4 mt-3 my-4 py-lg-5 py-4">
        <div className="container d-flex  flex-lg-row-reverse flex-md-column-reverse align-items-center justify-content-between">
          <div className="col-lg-6">
            {/* <div
              className="mb-4"
              dangerouslySetInnerHTML={{ __html: props?.data?.widget2 }}
            ></div> */}
            <div className="form-container" style={{backgroundColor:"#cccccc29", padding:"20px", borderRadius:"10px"}}>
              <form action="" className="inquiryform">
                <div className="d-flex gap-3 my-3 ">
                  <input type="text" placeholder="Business Owner Name" name="name"  onChange={handleInput} />
                  {error && error.name && (
                    <div className="text-danger">{error.name}</div>
                  )}
                  <input type="text" placeholder="Business Name" name="business_name" onChange={handleInput} />
                  {error && error.business_name && (
                    <div className="text-danger">{error.business_name}</div>
                  )}
                </div>
                <div className="d-flex gap-3 my-3">
                  <input type="email" placeholder=" Email Id" name="email" onChange={handleInput} />
                  {error && error.email && (
                    <div className="text-danger">{error.email}</div>
                  )}
                  <input type="number" placeholder="Mobile Number" name="mobile_number" onChange={handleInput} />
                  {error && error.mobile_number && (
                    <div className="text-danger">{error.mobile_number}</div>
                  )}
                </div>
                <div className="d-flex gap-3 my-3 select-content">
                  <select
                    class="form-select "
                    aria-label="Default select example"
                    name="advertisment_type" onChange={handleInput}
                  >
                    <option value="">Select Advertisement Type </option>
                    <option value="Pay Per Click">Pay Per Click</option>
                    <option value="No. Of Views">No. Of Views</option>
                  </select>
                  {error && error.advertisment_type && (
                    <div className="text-danger">{error.advertisment_type}</div>
                  )}
                  <select
                    class="form-select "
                    aria-label="Default select example"
                    name="monthly_budget" onChange={handleInput}
                  >
                    <option value="">Monthly Budget</option>
                    <option value=">10K">{">"}10K</option>
                    <option value="10K-25K">10K-25K</option>
                    <option value="25K-50K">25K-50K</option>
                    <option value="<50K"> {"<"}50K</option>
                  </select>
                  {error && error.monthly_budget && (
                    <div className="text-danger">{error.monthly_budget}</div>
                  )}
                </div>

                <div className="my-2">
                  <textarea
                    className="border-0 ps-2"
                   
                    id=""
                    cols="57"
                    rows="6"
                    name="message"
                    onChange={handleInput}
                    placeholder="Type message here..."
                  ></textarea>
                  {error && error.message && (
                    <div className="text-danger">{error.message}</div>
                  )}
                </div>


                <button type="button" onClick={handlesubmit}>Send Request</button>
              </form>
            </div>
          </div>

          <div
            className="col-lg-5 right-img"
         
          >
            <div
              className="mb-4"
              dangerouslySetInnerHTML={{ __html: props?.data?.widget2 }}
            ></div>
            <img src={contact} />
          </div>
        </div>
      </div>
      <ToastContainer />
    </>
  );
};
