import React from "react";
import { Carousel } from 'react-responsive-carousel';
import ReactOwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import { IoIosStar } from "react-icons/io";
import { BsCurrencyRupee } from "react-icons/bs";

const NewArrivals = () => {
    const recent_options = {
        margin: 10,
        responsiveClass: true,
        nav: true,
        dots: false,
        autoplay: true,
        loop: true,
        responsive: {
            0: {
                items: 2,
            },
            600: {
                items: 2.8,
            },
            1000: {
                items: 4.4,
            },
        },
    };
    const Categories = [
        {
            name: 'USB 3.0 Type A Female to A Female Connector Adapter',
            quantity: "4",
            cate1: "https://demo.martvill.techvill.net/public/uploads/sizes/medium/20221116/23c4d1b84785337722f69736b86ff959.webp"
        },
        {
            name: 'USB 2.0 Flash Pen Drive 32GB 16GB 64GB 128GB Squid Gam',
            quantity: "2",
            cate1: "https://demo.martvill.techvill.net/public/uploads/sizes/medium/20221116/8e4f7f9cffd21f3f267147d2cf3e79b1.webp"
        },
        {
            name: 'Smartwatch Men Support 118 Sports Women Smart Watch',
            quantity: "4",
            cate1: "https://demo.martvill.techvill.net/public/uploads/sizes/medium/20221116/12a3bfa2188bc16c1bbabbe2c69553ff.webp"
        },
        {
            name: 'Modern Design Four Legs Chair withou Arms',
            quantity: "2",
            cate1: "https://demo.martvill.techvill.net/public/uploads/sizes/medium/20221123/f8256ae374c1432ad6b8ac1e81fde25e.webp"
        },
        {
            name: 'Waist straight Slouchy jeans',
            quantity: "4",
            cate1: "https://demo.martvill.techvill.net/public/uploads/sizes/medium/20221120/abc930448a9f08de458b70e58e9f894e.webp"
        },
       
    ]

    return (
        <>
            <div className="container   " style={{marginTop:"40px"}}>
                <div className="new-arrivals products-nr">
                    <div className="slider-section new-arrival-slider position-relative">
                        <h6 className="cate-head mb-4 heading-bline position-relative">Recently Viewed</h6>
                        <ReactOwlCarousel className="owl-theme owl-feature-slider owl-slider-arrows mt-5 mb-2 " {...recent_options}>
                            {Categories.map((product) => (
                                <div className="product_new-arival">
                                    <img src={product.cate1} />
                                    <h5>{product.name}</h5>
                                    <h4><IoIosStar style={{marginTop:"-4px"}}/> <IoIosStar style={{marginTop:"-4px"}}/> <IoIosStar style={{marginTop:"-4px"}}/> <IoIosStar style={{marginTop:"-4px"}}/> <IoIosStar style={{marginTop:"-4px"}}/><span>(2 Reviews)</span></h4>
                                    <h3><BsCurrencyRupee style={{marginTop:"-4px"}}/> 200/- </h3>
                                </div>
                                // <div className="text-center shadow-sm">
                                //     <img src={product.cate1} />
                                //     <h5>{product.name}</h5>
                                //     <h4>{product.quantity}</h4>
                                // </div>
                            ))}
                        </ReactOwlCarousel>
                    </div>
                </div>
            </div>
           
        </>
    )
}

export default NewArrivals;