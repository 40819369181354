import React from 'react'
import "../../style/Sellerbenefites.css";
import "../../style/Investor.css";
import { Link } from 'react-router-dom';

export const Highlight = () => {
    return (
        <>
            <div className="highlights  mx-lg-5 mx-md-4 mx-3 my-lg-5 my-md-4 my-3" >
                <div className="">
                    <div className="top-content m-3">
                        <h3 style={{ color: "#fff" }}>Highlights </h3>
                        <div className="d-flex mt-3 justify-content-between">
                            <div className='ldiv'>
                                <p className='mb-0' style={{ color: "#fff" }}>
                                    Lorem nisi ut aliquip ex ea commodo consequat.Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.
                                </p>
                            </div>
                            <div className="startup-deal-btn" style={{width:"17%"}} >
                                <Link to={'/'}>
                                    <button className="raise-capital-partner"> Invest Now</button>
                                </Link>
                            </div>
                            
                        </div>
                    </div>

                    {/* <div className="why-sell ">
                        <div className=" d-flex my-lg-5 my-md-4 my-3 align-items-center ">
                            <div className="col-lg-4 text-center right-img">
                                <img src="/assets/images/growth.png" alt="" />
                            </div>

                            <div className="col-lg-8 ps-lg-5 ps-md-5 ps-4">
                          
                                <div className="cards d-flex flex-column gap-3">
                                    <div className="d-flex gap-3 justify-content-between">
                                        <div className="px-4 py-4 bg-white rounded shadow-sm m-3">
                                            <div className='d-flex gap-3 align-items-center'>
                                                <img src="/assets/images/why-sell-opportunity.png" alt="" /> &nbsp;
                                                <h5>Opportunity</h5>
                                            </div>
                                            <p>45 crore+ of customers across 19000+ pincodes, and access to shopping festivals like The Big Billion Days, and more.</p>
                                        </div>
                                        <div className="px-4 py-4 bg-white rounded shadow-sm m-3">
                                            <div className='d-flex gap-3 align-items-center'>
                                                <img src="/assets/images/why-sell-support.png" alt="" />&nbsp;
                                                <h5>Additional Support</h5>
                                            </div>
                                            <p>45 crore+ of customers across 19000+ pincodes, and access to shopping festivals like The Big Billion Days, and more.</p>
                                        </div>
                                    </div>

                                    <div className="d-flex gap-3 justify-content-between">
                                        <div className="px-4 py-4 bg-white rounded shadow-sm m-3">
                                            <div className='d-flex gap-3 align-items-center'>
                                                <img src="/assets/images/wy-sell-growth.png" alt="" />&nbsp;
                                                <h5>Growth</h5>
                                            </div>
                                            <p>45 crore+ of customers across 19000+ pincodes, and access to shopping festivals like The Big Billion Days, and more.</p>
                                        </div>
                                        <div className="px-4 py-4 bg-white rounded shadow-sm m-3">
                                            <div className='d-flex gap-3 align-items-center'>
                                                <img src="/assets/images/why-sell-ease.png" alt="" />&nbsp;
                                                <h5>Ease of Doing Business</h5>
                                            </div>
                                            <p>45 crore+ of customers across 19000+ pincodes, and access to shopping festivals like The Big Billion Days, and more.</p>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div> */}
                </div>
            </div>
        </>
    )
}
