// import axios from 'axios';
// import React,{useState,useEffect} from 'react'
// import { Link, useNavigate, useParams } from 'react-router-dom'
// import { BASE_URL } from '../config';

// export const Directoryall = () => {
//     const [category, setCategory] = useState([])
//     // const {slug}=useParams();
//     useEffect(() => {
//         FetchCategories();
//       }, [])
//       const FetchCategories = async () => {
    
       
//         await axios.get(BASE_URL + "/all-categories-for-directory-listing")
//           .then(({ data }) => {
//             setCategory(data.data);
            
           
//           });
//       };
   
//     const generateRandomColor = () => {
//            // window.location.reload();
//            const randomColor = Math.floor(Math.random() * 16777215).toString(16); // Generate random hexadecimal color
//            const color = "#" + randomColor;
   
//            // Convert hexadecimal color to RGB
//            const r = parseInt(color.substr(1, 2), 16);
//            const g = parseInt(color.substr(3, 2), 16);
//            const b = parseInt(color.substr(5, 2), 16);
   
//            // Set opacity to 30%
//            return `rgba(${r},${g},${b},0.05)`;
//        };
//        const [showAll, setShowAll] = useState(false);
   
//        const handleToggle = () => {
//            setShowAll(!showAll);
//        };
   
//        const maxVisible = 5; 
//        return (
//            < div className='cat-bg'>
//                <div className="second-common paramedicals  ">
//                    <div className="container categories px-4 pt-4 pb-5 bg-white  ">
//                        {/* <Link to={"/" + slug + "/" + category.slug}> */}
//                            <h2>{category.name}({category.product_count})</h2>
//                        {/* </Link> */}
//                        <div className="row ">
//                            <div className="left col-lg-3">
                              
//                                    <img src={category.banner} alt="" />
                              
//                            </div>
   
//                            <div className=" col-lg-9 right d-flex flex-wrap gap-2  ">
//                                {/* cards  */}
//                                {category.map((subcategory, i) => (
//                                 subcategory.status== 'active'?
//                                    <div className="card py-3 px-4 " style={{ backgroundColor: generateRandomColor() }} key={i}>
//                                        <div className="heading mb-2 d-flex justify-content-between ">
//                                        {/* <Link to={"/" + slug + "/" + subcategory.slug}> */}
//                                            <h4 className='col-8'>{subcategory?.name}</h4>
//                                            {/* </Link> */}
//                                            {/* <Link to={"/" + slug + "/" + subcategory.slug}>View All</Link> */}
//                                        </div>
//                                        <div className="content-img d-flex justify-content-between ">
//                                            <div className="content1">
//                                                {subcategory?.childrens_count > 0 && subcategory?.childrens.map((subsubcateory, key) => {
//                                                    // Show all items if 'showAll' is true or only the first 5 otherwise
//                                                    if (!showAll && key >= maxVisible) return null;
   
//                                                    return (
//                                                     //    <Link to={`/${slug}/${subcategory.slug}`} key={key} style={{textDecoration:"none"}}>
//                                                            <p>{subsubcateory.name} ({subsubcateory.product_count})</p>
//                                                     //    </Link>
//                                                    );
//                                                })}
   
//                                                {/* Show "+more" or "Show less" based on state */}
//                                                {subcategory?.childrens_count > maxVisible && (
//                                                    <button onClick={handleToggle} className="toggle-more" style={{color:"blue", paddingLeft:'0px'}}>
//                                                        {showAll ? "Show less" : `+More Categories`}
//                                                    </button>
//                                                )}
//                                            </div>
//                                            <div className="image" style={{ width: "35%" }}>
//                                                {/* <Link to={"/" + slug + "/" + subcategory.slug}> */}
//                                                    <img src={subcategory?.banner} alt="" />
//                                                {/* </Link> */}
//                                            </div>
//                                        </div>
//                                    </div>: null
//                                ))}
   
   
   
//                            </div>
//                        </div>
//                    </div>
//                </div>
//            </div>
//        )
//    }


   import React, { useContext,useState,useEffect } from "react";

import "../style/CategoryPage.css";
import BannerPage from "../components/apna-shaher/components/Banner";
import { SecondCommon } from "../components/category-page/SecondCommon";
import TwoBannerSection from "../components/TwoBannerSection";
import StateContext from "../context/StateContext";
import Discover from "../components/products/Components/Discover";
import { BASE_URL } from "../config";
import axios from "axios";
import { useParams } from "react-router-dom";
import Loader from "../components/loader/Loader.component";
import { BannerNew } from "../components/apna-shaher/components/BannerNew";
import parse from 'html-react-parser';
import { Link } from 'react-router-dom';
import { Directoryallview } from "./category-page/DIrectoryalllist";
export const Directoryall = () => {

    const { homepageSetting } = useContext(StateContext);
  const [categories,setCategories] = useState([]);

  

  
    // const {slug}=useParams();
    useEffect(() => {
        FetchCategories();
      }, [])
      const FetchCategories = async () => {
    
       
        await axios.get(BASE_URL + "/all-categories-for-directory-listing")
          .then(({ data }) => {
            setCategories(data.data);
            
           
          });
      };
   
    const generateRandomColor = () => {
           // window.location.reload();
           const randomColor = Math.floor(Math.random() * 16777215).toString(16); // Generate random hexadecimal color
           const color = "#" + randomColor;
   
           // Convert hexadecimal color to RGB
           const r = parseInt(color.substr(1, 2), 16);
           const g = parseInt(color.substr(3, 2), 16);
           const b = parseInt(color.substr(5, 2), 16);
   
           // Set opacity to 30%
           return `rgba(${r},${g},${b},0.05)`;
       };
       const [showAll, setShowAll] = useState(false);
   
       const handleToggle = () => {
           setShowAll(!showAll);
       };
   
       const maxVisible = 5; 
  return (
    <div className="category-pare-parent">
     
      {categories && categories.length > 0 ?(
        <>
        {categories && categories?.map((category,index)=>(
<>
      <section className="second-common-section" key={index}>
        <Directoryallview  category={category} />
      </section>
    
</>
))}
        </>
      ):(
        <>
         <section className="second-common-section" style={{marginLeft:"35%","margin-top":"100px"}}>
         
         
         </section>
        
        </>
      )}

      



    </div>
  );
};
