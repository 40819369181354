import React, { useEffect, useState } from "react";

import './Republic.css'


const Republicday = () => {
   
    return (
        <div className="commingsoonpage1">
            <div class="pattern0 fireworks fire0">
    <div class="ring_1"></div>
    <div class="ring_2"></div>
</div>
<div class="pattern1 fireworks fire1">
    <div class="ring_1"></div>
    <div class="ring_2"></div>
</div>
<div class="pattern2 fireworks fire2">
    <div class="ring_1"></div>
    <div class="ring_2"></div>
</div>
<div class="pattern3 fireworks fire3">
    <div class="ring_1"></div>
    <div class="ring_2"></div>
</div>
<div class="pattern4 fireworks fire4">
    <div class="ring_1"></div>
    <div class="ring_2"></div>
</div>
<div class="pattern5 fireworks fire5">
    <div class="ring_1"></div>
    <div class="ring_2"></div>
</div>
<div class="pattern6 fireworks fire6">
    <div class="ring_1"></div>
    <div class="ring_2"></div>
</div>
<div class="pattern7 fireworks fire7">
    <div class="ring_1"></div>
    <div class="ring_2"></div>
</div>
<div class="pattern8 fireworks fire8">
    <div class="ring_1"></div>
    <div class="ring_2"></div>
</div>
<div class="pattern9 fireworks fire9">
    <div class="ring_1"></div>
    <div class="ring_2"></div>
</div>
<div class="pattern10 fireworks fire10">
    <div class="ring_1"></div>
    <div class="ring_2"></div>
</div>
<div class="pattern11 fireworks fire11">
    <div class="ring_1"></div>
    <div class="ring_2"></div>
</div>
<div class="pattern12 fireworks fire12">
    <div class="ring_1"></div>
    <div class="ring_2"></div>
</div>
<div class="pattern13 fireworks fire13">
    <div class="ring_1"></div>
    <div class="ring_2"></div>
</div>
<div class="pattern14 fireworks fire14">
    <div class="ring_1"></div>
    <div class="ring_2"></div>
</div>
<div class="pattern15 fireworks fire15">
    <div class="ring_1"></div>
    <div class="ring_2"></div>
</div>
<div class="pattern16 fireworks fire16">
    <div class="ring_1"></div>
    <div class="ring_2"></div>
</div>
<div class="pattern17 fireworks fire17">
    <div class="ring_1"></div>
    <div class="ring_2"></div>
</div>
<div class="pattern18 fireworks fire18">
    <div class="ring_1"></div>
    <div class="ring_2"></div>
</div>
<div class="pattern19 fireworks fire19">
    <div class="ring_1"></div>
    <div class="ring_2"></div>
</div>

           
           
           

        </div>
    )
}

export default Republicday;