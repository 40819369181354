import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Stepper, Step, StepLabel, Typography, Box, Grid, StepIcon } from '@mui/material';
import { BASE_URL } from '../../../config';
// import StepIcon from '@material-ui/core/StepIcon';
// import  from '@material-ui/core/Grid';
import { FaBusinessTime } from "react-icons/fa6";


const steps = [
    'Search for matches',
    'Identify the right one',
    'Pay with confidence',
    'Quality Assure',
    'Variety of Products',
];

const descriptions = [
    "Description for Step 1",
    "Description for Step 2",
    "Description for Step 3",
];


function VerticalStepper() {
    const [activeStep, setActiveStep] = useState(0);
    const [allwidget, setAllwidget] = useState([])

    const handleStep = (step) => () => {
        setActiveStep(step);
    };


    // Get widget data from api
    const widgetData = async () => {
        try {
            const wgtData = await axios.get(BASE_URL + "/homepagewidget");
            setAllwidget(wgtData.data.data);
        } catch (err) {
            console.log(err);
        }
    };

    useEffect(() => {
        widgetData();
    }, []);


    return (
        <div className='container mt-5'>
            <div  >
                {/* <h1>Empowering businesses through global trade</h1>
                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore
                    magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo
                    consequat.</p> */}
            </div>
            <div className='vertical-stepper-section'>
                <div className='vertical-stteper-left'>
                    <div className='empower-bussiness-content-vertical mb-4' dangerouslySetInnerHTML={{ __html: allwidget.widget7 }} >

                    </div>
                    <div className='col-md-12 mt-5' dangerouslySetInnerHTML={{ __html: allwidget.widget8 }}>

                    </div>

                </div>
                <div className='vertical-steper-right'>
                    <div className='vertical-steper-card'>
                        <h4><FaBusinessTime style={{fontSize:"35px"}} /></h4>
                        <div className=''>
                        <h4>Connect with Millions of Buyers Globally and Showcase Your Offerings</h4>
                        <p>Take your business to new heights by reaching a worldwide audience. List on ApnaShaher.com and display your products and services to millions!</p>
                        </div>
                    </div>
                    <div className='vertical-steper-card'>
                        <h4><FaBusinessTime style={{fontSize:"35px"}} /></h4>
                        <div className=''>
                        <h4>Unlock New Opportunities for Your Business</h4>
                        <p>Expand your reach and discover untapped markets with ApnaShaher.com. Showcase your business to buyers actively searching for your products and services!</p>
                        </div>
                    </div>
                    <div className='vertical-steper-card'>
                        <h4><FaBusinessTime style={{fontSize:"35px"}} /></h4>
                        <div className=''>
                        <h4>Grow Beyond Boundaries with Ease</h4>
                        <p>Break free from local limitations and connect with buyers across the globe. ApnaShaher.com is your gateway to limitless growth opportunities.</p>
                        </div>
                    </div>
                    

                </div>

            </div>
            {/* <div className='row'>
                <div className='col-md-6 buss-stepper'>
                    <div style={{ display: 'flex', flexDirection: 'row' }}>
                        <Stepper activeStep={activeStep} orientation="vertical">
                            {steps.map((label, index) => (

                                <Step key={label}>
                                   
                                    <StepLabel onMouseEnter={handleStep(index)} onMouseLeave={handleStep(index)} >
                                        {label}
                                    </StepLabel>
                                    {activeStep === index && (
                                        <div style={{paddingLeft: "56px"}}>
                                            <p>{descriptions[index]}</p>
                                        </div>
                                    )}
                                </Step>
                            ))}
                        </Stepper>
                        
                    </div>
                </div>
                <div className='col-md-6'  dangerouslySetInnerHTML={{ __html: allwidget.widget8}}>
                    
                </div>
            </div> */}

        </div>
    );
}

export default VerticalStepper;
