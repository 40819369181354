import React from 'react';

const ServicesAdvertize = () => {
    return (
        <>
            <div className="container mt-4 mt-lg-6 mt-md-4 mt-3 px-0" >
                <img src="https://demo.martvill.techvill.net/public/uploads/20221205/2dcff5a961eb2a3127b2fe3b87861d99.webp" width="100%" style={{borderRadius:"10px", marginTop:"20px"}}/>
            </div>
        </>
    )
}

export default ServicesAdvertize;