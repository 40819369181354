import React, { useEffect, useState } from "react";
import "./HomePage.css"
import SecondSection from '../products/Components/SecondSection';
import ThirdSection from '../products/Components/ThirdSection';
import ProductsCard from './components/ProductsCard';
import EmpowerBussiness from './components/EmpowerBussiness';
import VerticalStepper from './components/BussinessSteps';
import Help from './components/BecomeSeller';
import BecomeBuyer from './components/BecomeBuyer';
import OurCompetitors from './components/OurCompetitors';
import TopInvestors from './components/TopInvestors';
import BannerPage from './components/Banner';
import { MobileFooter } from './components/MobileFooter';
import { ApnashaherSecond } from './components/ApnashaherSecond';
import { BannerNew } from './components/BannerNew';
import SeoHomepage from '../../seo page/SeoHomepage';
import UpdatedBannerPage from './components/Updatedbanner';
import axios from "axios";
import { BASE_URL } from "../../config";
import { Link } from "react-router-dom";
import Republicday from "../../Republicday";


const HomePage = () => {
    const [allwidget, setAllwidget] = useState([]);
    const widgetData = async () => {
        try {
            const wgtData = await axios.get(BASE_URL + "/homepagewidget");
            setAllwidget(wgtData.data.data)
        }
        catch (err) {
            console.log(err)
        }
    }

   

    useEffect(() => {
        widgetData()

    }, [])
    return (
        <>
            <SeoHomepage />
            
            {/* <Republicday/> */}
            <MobileFooter />
            <div className="show-desktop-banner">
                <div className="new-startup-top-banner" style={{ background: "url(/assets/images/btop.jpg)" }}>
                    <div className="startup-right-content-banner">
                        <h1>Discover Everything in One Place – Businesses, Products, Services & Startups!</h1>
                        <p>Explore our comprehensive web directory featuring businesses, innovative products, essential services, and thriving startups. Connect, discover, and grow all in one convenient platform!</p>
                        <div className="startup-deal-btn" style={{ width: "100%" }}>
                            <Link to={'/contact-us'}>
                            <button className="raise-capital">Contact Us</button>
                            </Link>
                        </div>
                    </div>
                </div>
                <div className="banner-bottom-part mt-5">
                    <div className="" dangerouslySetInnerHTML={{ __html: allwidget.widget1 }}></div>
                </div>
                {/* <UpdatedBannerPage/> */}
                {/* <BannerPage /> */}
            </div>
            <div className="show-mobile-banner">
                <BannerNew />
            </div>
            <ProductsCard />
            {/* <SecondSection /> */}
            <EmpowerBussiness />



            <Help />
            <ThirdSection />
            <VerticalStepper />

            {/* <ApnashaherSecond /> */}
            {/* <ThirdSection /> */}
            <BecomeBuyer />
            <OurCompetitors />
            {/* <TopInvestors /> */}

        </>
    )
}

export default HomePage;