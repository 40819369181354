import React, { useState, useEffect } from "react";
import { Sellerbanner } from '../components/seller-benefites/Sellerbanner'
import "../style/Advertisewithus.css"
import { AdvertiseComp2 } from '../components/advertise-with-us/AdvertiseComp2'
import { Whysell } from '../components/seller-benefites/Whysell'
import { AdvertiseComp4 } from '../components/advertise-with-us/AdvertiseComp4'
import { AdvertiseComp5 } from '../components/advertise-with-us/AdvertiseComp5'
import { Sbsection4 } from '../components/seller-benefites/Sbsection4'
import { Advertisehelp } from '../components/advertise-with-us/Advertisehelp'
import axios from "axios";
import { BASE_URL } from "../config";
import Loader from "../components/loader/Loader.component";
import SeoSellerBenifitPage from "../seo page/SeoSellerbenifit";
import { Link } from "react-router-dom";
import ThirdSection from "../components/products/Components/ThirdSection";
import CountUp from "react-countup";
import addimg from '../assets/add-page.jpg'

export const Advertisewithus = () => {
  const [widget, setwidget] = useState("")
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    window.scrollTo(0, 0)
    widgetData();
  }, []);
  const widgetData = async () => {
    try {
      const wgtData = await axios.get(BASE_URL + "/advertisewithuswidget");
      setwidget(wgtData.data.data);
      setIsLoading(false);
    } catch (err) {
      console.log(err);
      setIsLoading(false);
    }
  };
  if (isLoading) {
    return <Loader />;
  }
  return (
    <>
      <SeoSellerBenifitPage />
      <div >
        <div className="new-startup-top-banner add_bn" style={{ background: "url(/assets/images/advertise.jpg)" }}>
          <div className="startup-right-content-banner">

            <h1  >Connect with a Global Audience</h1>
            <p >By advertising on APNASHAHER.COM, you can reach more consumers and increase your
              business&#39;s exposure. Whether you&#39;re a manufacturer, service provider, or retailer, our platform
              has prepared you with unprecedented global reach. Make the most of this opportunity to show
              off your goods and services to interested consumers.</p>
            <div className="startup-deal-btn" style={{ width: "100%" }}>
              <Link to={''}>
                <button className="raise-capital11 mobile-btn">Advertise Now</button>
              </Link>
            </div>
          </div>
        </div>
        <div className="start-up-top-card" style={{ height: "525px" }}>
          <h1 className="p-2">Advertise Your Business on APNASHAHER.COM</h1>
          <p className="col-12 col-lg-8 text-center p-2">Our platform&#39;s advertising options link you with potential clients worldwide, whether you&#39;re a
            seasoned professional or just starting. This is your chance to reach a global audience, establish
            your brand, and set yourself up for unparalleled achievement like never before. To propel your
            business to new heights, you must be part of APNASHAHER.COM.</p>
          <div className="addver_bn startup-deal-btn " >
            <Link to={'/startup'}>
              <button className="raise-capital mobile-btn">Start Your Journey</button>
            </Link>
            <Link to={'/'}>
              <button className="raise-capital mobile-btn">Contact Us</button>
            </Link>


          </div>
          {/* <p style={{ fontSize: "12px", marginTop: "20px" }}>Private investments are highly risky, illiquid and may result in total loss of capital. Learn more</p> */}


        </div>
        <div className="become-seller-bg1">
          <div className='container mt-5 mb-4'>
            {/* <div className='container mt-5 mb-4' dangerouslySetInnerHTML={{ __html: allwidget.widget4}} > */}
            <div className='third-section '>
              <div className='explore-millions'>
                <h2>Explore & Unlock Global Opportunities by Advertising and Thriving on APNASHAHER.COM</h2>
              </div>
              <div className='explore-millions-count mt-3'>
                <div className='d-flex justify-content-between border-bottom'>
                  <div className='counter-part w-50 '>
                    <span style={{ fontSize: "22px" }}>Banner Ads</span>

                  </div>
                  <div className='counter-part w-50 '>

                    <span style={{ fontSize: "22px" }}>Pay Per Clicks</span>
                  </div>
                </div>
                <div className='d-flex justify-content-between'>
                  <div className='counter-part w-50'>
                    <span style={{ fontSize: "22px" }}>Ads by Impressions</span>

                  </div>
                  <div className='counter-part w-50 border-left'>
                    <span style={{ fontSize: "22px" }}>Maximum Views</span>

                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="container mb-4 mt-5">
          <div className="row">
            <div className="col-12 col-lg-4">
              <img src="https://images.pexels.com/photos/6620161/pexels-photo-6620161.jpeg?auto=compress&cs=tinysrgb&w=600" style={{ borderRadius: "10px" }}></img>
            </div>
            <div className="col-12 col-lg-8">
              <div className="startup-right-content">
                <h1 style={{ width: "100%" }}>Expand Your Business Globally</h1>
                <p>With APNASHAHER.COM's advertising, you can interact with a varied client base, reach a global
                  audience, and increase your advertising budget with targeted banner advertisements and other
                  customizable choices.</p>
                <div className="startup-deal-btn" style={{ width: "100%" }}>
                  <Link to={'/'}>
                    <button className="raise-capital">Register Now</button>
                  </Link>
                </div>
              </div>
            </div>


          </div>
        </div>

        <div className="container mb-5">
          <div className="row">
            <div className="col-12">
              <div className="empower-bussiness-content-vertical mt-5 mb-5 col-12">
                <h1>Why advertise your business with us?</h1>

                <p>We connects you with a diverse audience, maximizing your brand's visibility.</p>

              </div>
            </div>
            <div className="col-12">
              <div className="why-invest1">
                <div className="why-invest-card1">

                  <h4 className="m-0">Global Reach</h4>
                  <p className="m-0">Through our advertising solutions, you will have the possibility to access
                    a global audience, which will allow you to promote your company to potential customers
                    residing all over the world. With the help of our platform, you will be able to broaden
                    the geographic reach of your business and create connections with new clients on an
                    international level. </p>

                </div>

              </div>
            </div>
            <div className="col-12">
              <div className="why-invest1">
                <div className="why-invest-card1">

                  <h4 className="m-0">Targeted Banner Ads</h4>
                  <p className="m-0">We provide targeted banner advertising predicated on business
                    categories, subcategories, or search results. This guarantees that your advertisements
                    acquire the audience that is most relevant to them. You can increase your likelihood of
                    generating excellent leads for your company by employing this tailored method, which
                    maximizes the positive impact of your advertisements.
                  </p>

                </div>

              </div>
            </div>
            <div className="col-12">
              <div className="why-invest1">
                <div className="why-invest-card1">

                  <h4 className="m-0">Flexible Advertising Options</h4>
                  <p className="m-0">For those interested in marketing on APNASHAHER.COM, we offer various flexible options, such as pay-per-click and pay-per-
                    1000-view formats. Because of this versatility, you can select the advertising strategy
                    that is most compatible with your business&#39;s objectives and budgetary limitations. This
                    ensures that you get the most potential advantage from your investment in advertising.</p>

                </div>

              </div>
            </div>
            <div className="col-12">
              <div className="why-invest1">
                <div className="why-invest-card1">

                  <h4 className="m-0">Connect with a Diverse Customer Base</h4>
                  <p className="m-0">Our platform is in an excellent position to draw in an extensive range of customers, including individual consumers, corporations,
                    and startups. You can interact with this varied audience through our advertising
                    services and market your goods and services to prospective clients with a broad
                    spectrum of demands and interests. </p>

                </div>

              </div>
            </div>

          </div>
        </div>
        <div className="container mb-4 mt-5">
          <div className="row">


            <div className="col-12 col-lg-7">
              <div className="startup-right-content1">
                <h1>Why are businesses choosing ApnaShaher.com?</h1>
                <p style={{ width: "100%" }}>As a result of ApnaShaher.com's huge global reach, people like selling on the platform as it allows
                  them to communicate with an eclectic consumer base and sell their products and services to
                  prospective customers with various requirements and interests. In addition, the platform offers

                  tailored banner advertisements based on company categories, subcategories, or search results.
                  This makes sure their advertisements are visible to the audience that is most relevant to them.
                  In addition, ApnaShaher.com offers several advertising options, such as pay-per-click and pay-per-
                  1000-views models, which allow sellers to select the advertising strategy that is most suited to
                  their company objectives and budgetary limitations.</p>
                {/* <div className="startup-deal-btn" style={{ width: "100%" }}>
                  <Link to={'/'}>
                    <button className="raise-capital">Contact Us</button>
                  </Link>
                </div> */}
              </div>
            </div>
            <div className="col-12 col-lg-5">
              <img src={addimg} style={{ borderRadius: "10px" }}></img>
            </div>
          </div>
        </div>
        {/* <section dangerouslySetInnerHTML={{ __html: widget?.widget1 }}></section> */}
        {/* <section>
    <Sellerbanner/>
   </section>

   <section>
    <AdvertiseComp2/>
   </section>

   <section className="why-sell-parent">
      <Whysell/>
    </section>

    <section>
        <AdvertiseComp4/>
    </section>

    <section> 
        <AdvertiseComp5/>
    </section>

    <section> 
        <Sbsection4/>
    </section> */}

        <section>
          <Advertisehelp data={widget} />
        </section>

      </div>
    </>
  )
}
