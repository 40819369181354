import React, { useState, useEffect } from "react";
import "../../products/HomePage.css"
import { Carousel } from "react-responsive-carousel";
import ReactOwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import { Link, useNavigate } from "react-router-dom";
import { BASE_URL } from "../../../config";
import axios from "axios";
import { MdOutlineArrowForwardIos } from "react-icons/md";


const Professional = () => {
  const recent_options = {
    margin: 10,
    responsiveClass: true,
    nav: false,
    dots: false,
    autoplay: true,
    loop: true,
    responsive: {
      0: {
        items: 3.5,
      },
      460: {
        items: 4.2,
      },
      575: {
        items: 3.8,
      },
      768: {
        items: 5,
      },
      1080: {
        items: 6.3,
      },
      1200: {
        items: 8,
      },
    },
  };

  const [Categoriesdigital, setCategoriesdigital] = useState([]);
  const [Categoriesprofesional, setCategoriesprofesional] = useState([]);
  const navigate = useNavigate();
  useEffect(() => {
    FetchCategories();
  }, [])

  const FetchCategories = async () => {
    // await axios.get(BASE_URL + "/getcategoriesbytype/digital-services")
    //   .then(({ data }) => {
    //     setCategoriesdigital(data.data);
    //   });
    await axios.get(BASE_URL + "/getcategoriesbytype/professional-services")
      .then(({ data }) => {
        setCategoriesprofesional(data.data);
      });

  };

  // const Categories = [
  //   {
  //     name: "ShipHope",
  //     quantity: "4",
  //     cate1: "/assets/images/company-1.png",
  //   },
  //   {
  //     name: "ByteBit",
  //     quantity: "2",
  //     cate1: "/assets/images/company-2.png",
  //   },
  //   {
  //     name: "Terrestrio",
  //     quantity: "4",
  //     cate1: "/assets/images/company-3.png",
  //   },
  //   {
  //     name: "ZUACT",
  //     quantity: "2",
  //     cate1: "/assets/images/company-4.png",
  //   },
  //   {
  //     name: "Processfuel",
  //     quantity: "4",
  //     cate1: "/assets/images/company-5.png",
  //   },
  //   {
  //     name: "Finance Methods",
  //     quantity: "2",
  //     cate1: "/assets/images/company-6.png",
  //   },
  //   {
  //     name: "ShipHope",
  //     quantity: "4",
  //     cate1: "/assets/images/company-1.png",
  //   },
  // ];

  return (
    <>
      <div className="container mt-3">
        <div className="slider-section categories-slider product-categories">
          <div className="d-flex justify-content-between">   
            <h6 className="cate-head mb-5 heading-bline position-relative">Popular Categories</h6>
            <Link to={"/all-services-category"}>
             {/* <Link to={`/comming-soon`} > */}
          <h6 className="view-all-category">View All Categories <MdOutlineArrowForwardIos /></h6>
          </Link>
          </div>

          <ReactOwlCarousel className="owl-theme owl-feature-slider owl-slider-arrows" {...recent_options}>
            {Categoriesprofesional.map((product) => (
              < div className="mt-3 mb-3">
                <Link to={"/service-categorys/" + product.slug}>
                 {/* <Link to={`/comming-soon`} > */}
                <img src={product.icon} /><h5>{product.name}</h5>
                </Link>


              </div>


            ))}
          </ReactOwlCarousel>
        </div>
      </div>
      {/* <div className="container mt-lg-5 mt-md-4 mt-3 professional-parent">
        <div className="row">
          <div className="col-md-12">
            <div className="new-arrivals service-nr">
              <div className="slider-section">
                <h6 className="cate-head mb-3">
                  Categories 
                </h6>
                <ReactOwlCarousel
                  className="owl-theme owl-feature-slider owl-slider-arrows"
                  {...recent_options}

                >
                  {Categoriesprofesional.map((product, i) => (
                    <div className="d-flex gap-2 flex-column hr-2row" key={i} onClick={() => navigate("/service-categorys/" + product.slug)}>
                      <div className="text-center h-50">
                        <div className="prof-img">
                          <img src={product.icon} />
                        </div>
                        <h5>{product.name}</h5>

                      </div>

                    </div>
                  ))}
                </ReactOwlCarousel>
              </div>
            </div>
          </div>
          
        </div>
      </div> */}

    </>
  );
};

export default Professional;
