import React,{useState,useEffect} from 'react'
import "../style/PostbuyRequirement.css"
import { Sellerbanner } from '../components/seller-benefites/Sellerbanner'
import { PostbuyComp2 } from '../components/post-buy-requirement/PostbuyComp2'
import { PostbuyComp3 } from '../components/post-buy-requirement/PostbuyComp3'
import { Sellertestimonial } from '../components/seller-benefites/Sellertestimonial'
import axios from 'axios'
import { BASE_URL } from '../config'
import { SellertestimonialPost } from './SellertestimonialPost'
import Loader from '../components/loader/Loader.component'
import { Link } from 'react-router-dom'
export const PostbuyRequirement = () => {
  const[widget, setwidget] = useState("")
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    window.scrollTo(0, 0);
    widgetData();
  }, []);
    const widgetData = async () => {
        try {
          const wgtData = await axios.get(BASE_URL + "/postbyrequirementwidget");
          setwidget(wgtData.data.data);
          setIsLoading(false);
        } catch (err) {
          console.log(err);
          setIsLoading(false);
        }
      };
      if (isLoading) {
        return <Loader />;
      }
  return (
    <>
    <div className="new-startup-top-banner" style={{ background: "url(/assets/images/ctop.jpg)" }}>
            <div className="startup-right-content-banner">
              <h1>Your first move will decide your business future & its Growth</h1>
              <p>Expand your reach, boost your sales, and grow with our powerful seller platform. Register today and start achieving your goals.</p>
              <div className="startup-deal-btn" style={{ width: "100%" }}>
                <Link to={''}>
                  <button className="raise-capital">Register Now</button>
                </Link>
              </div>
            </div>
          </div>
 
  <div style={{background:"#fff"}}>
    {/* <section dangerouslySetInnerHTML={{__html:widget.widget1}}>

    </section> */}
    <section>
      <PostbuyComp2 data={widget}/>
    </section>
    <section>
      <PostbuyComp3 data={widget}/>
    </section>
    <section>
      {/* <SellertestimonialPost data={widget} /> */}
    </section>
    {/* <section>
      <Sellerbanner/>
    </section>
    
    

    

     */}

</div>
    </>
  )
}
