import React from 'react'
import "../../style/SellerDetails.css"
import { IMAGE_BASE_URL } from '../../config'

export const Sdbanner = (props) => {
  return (
    <>
    <div className="seller-detail-banner pt-2">
        <img src={props?.data?.company_banner ? IMAGE_BASE_URL+props?.data?.company_banner :"/assets/images/seller-detail-banner.png"} alt="Banner Img" height={"300px"} width={"100%"} />
    </div>
    </>
  )
}
