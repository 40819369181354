import React, { useEffect, useState } from "react";
import commingsoon from './assets/commingsonn.jpg'



const CommingSoon = () => {
   
    return (
        <div className="commingsoonpage">
            <img src={commingsoon}></img>
           
           
           

        </div>
    )
}

export default CommingSoon;