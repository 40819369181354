import React from 'react';
import parse from 'html-react-parser';
import { Link } from 'react-router-dom';

const TwoBannerSection = ({ homepageSetting }) => {
  return (
    <div className="container"  style={{marginTop:"60px"}}>

      <div className='top-banner '>
        <div className="row">
          <div className='col-12 mb-3'>
            <div className='trending-banner-section' style={{backgroundImage:`url(${homepageSetting.trending_banner})`}}>
            <div className="elctro-blk cosmatic-blk">
              <h4>{homepageSetting.trending_banner_title}</h4>
              {homepageSetting.trending_banner_content ? parse(homepageSetting.trending_banner_content) : ''}
              <Link className='btn btn-shop-white' to={`/category/${homepageSetting.trending_banner_category_link}`}>
              {/* <Link className='btn btn-shop-white' to={`/comming-soon`} > */}
              Shop Now</Link>
            </div>

            </div>
          </div>
          <div className='two-banner-section-desktop'>
          <div className=''>
            <div className='trending-banner-section1' style={{backgroundImage:`url(${homepageSetting.middle_banner_1})`}}>
            <div className="elctro-blk cosmatic-blk">
              <h4>{homepageSetting.middle_banner_1_title}</h4>
              {homepageSetting.middle_banner_1_content ? parse(homepageSetting.middle_banner_1_content) : ''}
              {/* <Link className='btn btn-shop-white' to={`/comming-soon`} > */}
              <Link className='btn btn-shop-white' to={`/category/${homepageSetting.middle_banner_1_category_link}`}>
              Shop Now
              </Link>
            </div>

            </div>
          </div>
          <div className=''>
            <div className='trending-banner-section1' style={{backgroundImage:`url(${homepageSetting.middle_banner_2})`}}>
            <div className="elctro-blk cosmatic-blk">
              <h4>{homepageSetting.middle_banner_2_title}</h4>
              {homepageSetting.middle_banner_2_content ? parse(homepageSetting.middle_banner_2_content) : ''}
              {/* <Link className='btn btn-shop-white' to={`/comming-soon`} > */}
              <Link className='btn btn-shop-white' to={`/category/${homepageSetting.middle_banner_2_category_link}`}>
              Shop Now
              </Link>
            </div>

            </div>
          </div>

          </div>
          
          <div className='two-banner-section-mobile'>
          <div className=''>
            <div className='trending-banner-section1' style={{backgroundImage:`url(${homepageSetting.middle_banner_1})`}}>
            <div className="elctro-blk cosmatic-blk">
              <h4>{homepageSetting.middle_banner_1_title}</h4>
              {homepageSetting.middle_banner_1_content ? parse(homepageSetting.middle_banner_1_content) : ''}
              {/* <Link className='btn btn-shop-white' to={`/comming-soon`} > */}
              <Link className='btn btn-shop-white' to={`/category/${homepageSetting.middle_banner_1_category_link}`}>
              Shop Now
              </Link>
            </div>

            </div>
          </div>
          <div className=''>
            <div className='trending-banner-section1' style={{backgroundImage:`url(${homepageSetting.middle_banner_2})`}}>
            <div className="elctro-blk cosmatic-blk">
              <h4>{homepageSetting.middle_banner_2_title}</h4>
              {homepageSetting.middle_banner_2_content ? parse(homepageSetting.middle_banner_2_content) : ''}
              {/* <Link className='btn btn-shop-white' to={`/comming-soon`} > */}
              <Link className='btn btn-shop-white' to={`/category/${homepageSetting.middle_banner_2_category_link}`}>
              Shop Now
              </Link>
            </div>

            </div>
          </div>

          </div>
        </div>

      </div>
       {/* <div className="container mt-4 mb-4 mt-lg-6 mt-md-4 mt-3 " >
                <img src={homepageSetting.trending_banner} width="100%" style={{ marginTop:"20px"}}/>
            </div> */}
            {/* <div className="container">
      <div className="row">
        <div className="col-md-6">
         
          <div className="elec-cos-blk" style={{ background: `url(${homepageSetting.middle_banner_1 ? homepageSetting.middle_banner_1 : 'assets/images/image-43.jpg'})` }}>
            <div className="elctro-blk cosmatic-blk">
              <h4>{homepageSetting.middle_banner_1_title}</h4>
              {homepageSetting.middle_banner_1_content ? parse(homepageSetting.middle_banner_1_content) : ''}
              <Link className='btn btn-shop-white' to={`/category/${homepageSetting.middle_banner_1_category_link}`}>Shop Now</Link>
            </div>
          </div>
        </div>
        <div className="col-md-6">
          <div className="elec-cos-blk" style={{ background: `url(${homepageSetting.middle_banner_2 ? homepageSetting.middle_banner_2 : 'assets/images/image-43.jpg'})` }}>
            <div className="elctro-blk cosmatic-blk">
              <h4>{homepageSetting.middle_banner_2_title}</h4>
              {homepageSetting.middle_banner_2_content ? parse(homepageSetting.middle_banner_2_content) : ''}
              <Link className='btn btn-shop-white' to={`/category/${homepageSetting.middle_banner_2_category_link}`}>Shop Now</Link>
            </div>
          </div>
        </div>
      </div>
    </div>  */}
    </div>

  );
};

export default TwoBannerSection;
