import React, { useState, useRef, useEffect, useContext } from "react";
import ReactStars from "react-stars";
import Form from "react-bootstrap/Form";
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import axios from "axios";
import { BASE_URL } from "../config";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Link, useLocation, useParams } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.min.css";
import ProductGridContainer from "../components/product/product-grid-container.component";
import ProfessionalClient from "../components/Services/Components/ProfessionalClient";
import 'bootstrap/dist/css/bootstrap.min.css';
// import "https://cdnjs.cloudflare.com/ajax/libs/bootstrap/5.3.0/js/bootstrap.bundle.min.js"
import DirectoryGridContainer from "./DirectoryGridContainer";
import StateContext from "../context/StateContext";
import Accordion from 'react-bootstrap/Accordion';
import thumbup from '../assets/thums-up.png'
import { CiClock2, CiLocationOn, CiStar } from "react-icons/ci";
import { MdOutlineEmail, MdOutlineVerified } from "react-icons/md";
import { IoChatbubblesOutline } from "react-icons/io5";
import { FaBusinessTime, FaDotCircle, FaRegEye } from "react-icons/fa";
import { SiScpfoundation } from "react-icons/si";
import { LuBriefcaseBusiness } from "react-icons/lu";
import { RiFilterLine } from "react-icons/ri";
import { IoIosArrowForward, IoMdStar } from "react-icons/io";
import { BiCategory } from "react-icons/bi";

const slider = [{ id: 1 }, { id: 2 }, { id: 3 }];

export const DirectoryallListing = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [isOpenPopularity, setIsOpenPopularity] = useState(false);
  const [isOpenRating, setIsOpenRating] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [products, setProducts] = useState([]);
  const [productCount, setProductCount] = useState("0 sellers");
  const [sortBy, setSortBy] = useState();
  const [locaion, setlocaion] = useState();
  const dropdownRef = useRef(null);
  const navigate = useNavigate();
  const [sellerdata, setsellerdata] = useState([]);
  const [categoryIds, setCategoryIds] = useState([])
  const {slug} = useParams();
  const { locationdata, setlocationdata, countrycity, setcountrycity } = useContext(StateContext);

  // useEffect(() => {
  //     document.addEventListener('mousedown', handleClickOutside);
  //     return () => {
  //         document.removeEventListener('mousedown', handleClickOutside);
  //     };
  // }, []);

  const ratingChanged = (newRating) => {
    console.log(newRating);
  };

  useEffect(() => {
    const timer = setTimeout(() => {
      getsellerdata();
    }, 3000);
    return () => clearTimeout(timer);
  }, []);

  const getsellerdata = () => {
    axios
      .get(BASE_URL + "/getprefredsellerdata")
      .then(({ data }) => {
        setsellerdata(data);
      })
      .catch(({ response }) => { });
  };

  const [callbackName, setCallbackName] = useState("");
  const [callbackMobile, setCallbackMobile] = useState();
  const [callbackEmail, setCallbackEmail] = useState("");
  const [interested, setInterested] = useState('');
  const [category, setCategory] = useState([]);
  const [subCategory, setSubCategory] = useState([]);
  const [details, setDetails] = useState("");
  const [sellerName, setSellerName] = useState("");
  const [selleremail, setSellerEmail] = useState("");
  const [sellermobile, setSellerMobile] = useState("");
  const [businessNature, setBusinessNature] = useState("");
  const [fetchCountry, setFetchedCountry] = useState([]);
  const [fetchState, setFetchedState] = useState([]);
  const [fetchCity, setFetchedCity] = useState([]);
  const [country, setCountry] = useState([]);
  const [state, setState] = useState("");
  const [city, setCity] = useState("");
  const [clicked, setclicked] = useState(0);
  const [filteractive, setfilteractive] = useState("");
  const [sellerDetails, setSellerDetails] = useState("");
 
  const [slugurl, setslugurl] = useState(slug ? slug : "Manufacturer");
  const [data, setData] = useState([]);
  const [datas, setDatas] = useState([]);
  const [categorys, setcategorys] = useState([]);
  const [errors, setError] = useState([]);
  const [err, setErr] = useState([]);

  const [categoryss, setcategoryss] = useState([]);
  // const [currScat, setCurrScat] = useState(null)
  const [showsCat, setShowsCat] = useState(false)


  const toggleShow = (i, k) => {
    let currElem = document.querySelector(`.showhide${i}${k}`)
    if (currElem) {
      let display1 = window.getComputedStyle(currElem).display;
      display1 === "none" ? currElem.style.display = "block" : currElem.style.display = "none"
    }

  }

  function setChecked(e) {
    let tempCategoryIds = [...categoryIds];
    if (e.target.checked) {
      tempCategoryIds.push(e.target.value);
    } else {
      tempCategoryIds = tempCategoryIds.filter((tempCategoryId) => tempCategoryId !== e.target.value);
    }
    console.log(tempCategoryIds)

    setCategoryIds(tempCategoryIds);
  }
  const [businessnature1, setBusinessnature1] = useState([])
  useEffect(() => {
    getdirectorydata();
  }, []);
  const getdirectorydata = async () => {
    try {
      const dirData = await axios.get(BASE_URL + "/get-business-type-and-nature");
      setBusinessnature1(dirData.data.data.business_nature);
   
    } catch (err) {
      console.log(err);
    }
  };
console.log(businessnature1)
  // var businessnature = [
  //   "Manufacturer",
  //   "Exporters & Importers",
  //   "Suppliers",
  //   "Distributors",
  //   "Retailers",
  //   "Service Providers",
  //   "Educational Institutes",
  // ];
  useEffect(() => {
    getCountry();
    // ManufacturerData(slug ? slug : "Manufacturer");
    getCategory();
    getSubCategory();

    if (slug) {
      const index = businessnature1.indexOf(slug);
      // const index = businessnature1;
      setclicked(index);
    }
  }, [slug]);
  useEffect(() => {
    if (slug == "Manufacturers" || slug == "Exporters & Importers" || slug == "Suppliers" || slug == "Distributors" || slug == "Retailers") {
      getcategoryss(1);
    } else if (slug == "Service Providers") {
      getcategoryss(2);
    } else if (slug == "Educational Institutes") {
      getcategoryss(5);
    }
  }, [slug])
  useEffect(() => {
    ManufacturerData(slug)
  }, [slug, categoryIds, sortBy, countrycity])
  const getcategoryss = (data) => {
    axios
      .post(`${BASE_URL}/getcategorydirectory`, { category_type: data })
      .then(function (res) {
        setcategoryss(res.data.data);
      })
      .catch(function (error) {
        // console.log(error);
      });
  };
  const getCountry = () => {
    axios
      .get(`${BASE_URL}/country`)
      .then(function (res) {
        setFetchedCountry(res.data.data);
      })
      .catch(function (error) {
        // console.log(error);
      });
  };

  const getCategory = () => {
    axios
      .get(`${BASE_URL}/allcategories`)
      .then(function (res) {
        console.log(res, "category res");
        setCategory(res?.data?.data);
      })
      .catch(function (error) {
        // console.log(error);
      });
  };

  const getSubCategory = () => {
    axios
      .get(`${BASE_URL}/getsubcategories/${interested}`)
      .then(function (res) {
        console.log(res, "category res");
        setSubCategory(res?.data?.data);
      })
      .catch(function (error) {
        // console.log(error);
      });
  };

  console.log("category", category, interested);
  console.log(" sub category", subCategory);
  const getState = (name) => {
    axios
      .get(`${BASE_URL}/state/${name}`)
      .then(function (res) {
        setFetchedState(res.data.data);
      })
      .catch(function (error) {
        // console.log(error);
      });
  };
  const getCity = (id) => {
    axios
      .get(`${BASE_URL}/city/${id}`)
      .then(function (res) {
        setFetchedCity(res.data.data);
      })
      .catch(function (error) {
        // console.log(error);
      });
  };

  const Addfreecallback = async () => {
    const data = {
      name: callbackName,
      email: callbackEmail,
      mobile_number: callbackMobile,
      interested_in: "yes",
      details: details,
    };
    await axios
      .post(BASE_URL + "/addfreecallback", data)
      .then(({ data }) => {
        console.log("get data", data);
        if (data.status == true) {
          toast.success(data.message);
          document.getElementById("formdata").reset();
        }
      })
      .catch(({ response }) => {
        setError(response.data.message);
      });
  };

  const Addsellercallback = async () => {
    const data = {
      name: sellerName,
      email: selleremail,
      mobile_number: sellermobile,
      interested_in: "yes",
      nature_of_business: businessNature,
      country: country,
      state: state,
      city: city,
      details: sellerDetails,
    };
    await axios
      .post(BASE_URL + "/addsellercallback", data)
      .then(({ data }) => {
        console.log("get data", data);
        if (data.status == true) {
          toast.success(data.message);
          document.getElementById("sellerform").reset();
        }
      })
      .catch(({ response }) => {
        setErr(response.data.message);
        console.log(response.data.message);
      });
  };

  const ManufacturerData = async () => {
    var formdata = new FormData();
    // formdata.append("business_nature", slug)
    // formdata.append("sort_by", sortBy)
    // formdata.append("countrycity", countrycity)
    // {
    //   categoryIds && categoryIds.map((item, i) => {
    //     formdata.append("category[]", item)
    //   })
    // }
    // {
    //   categoryIds && categoryIds.map((item, i) => {
    //     formdata.append("subcategory[]", item)
    //   })
    // }
    // const data = {
    //   business_nature: d,
    //   "category[]": categoryIds,
    // };
    await axios
      .post(BASE_URL + "/getsellerdata", formdata, {
        headers: {
          "content-type": "multipart/form-data",
          Accept: "application/json",
        },
      })
      .then(({ data }) => {
        setData(data.data);
        setDatas(data.data);
        setcategorys(data.category);
      })
      .catch(({ response }) => {
        console.log(response.data.message);
      });
  };
  const filterdata = (item) => {
    // console.log(item);
    const d = new Date()
      .toLocaleString("en-us", { weekday: "long" })
      .toLowerCase();
    const e = item.filter((e) => e.day == d);
    if (e.length > 0) {
      // console.log(e[0]);
      return e[0];
    }
  };
  const updatesellerview = async (id) => {
    await axios
      .get(BASE_URL + "/updatesellerview/" + id)
      .then(({ data }) => {
        // setData(data.data)
      })
      .catch(({ response }) => {
        console.log(response.data.message);
      });
  };
  const filteradverified = () => {
    var d = datas.filter((e) => e.verified_seller == "Yes");
    setData(d);
  };
  const filtertrusted = () => {
    var d = datas.filter((e) => e.trust_seal == "Yes");
    setData(d);
  };
  function cahechcategory(category, id) {
    return category == id;
  }
  const filtercategory = (id) => {
    var d = datas.filter(
      (e) =>
        e.category.length > 0 &&
        e.category.find((category) => cahechcategory(category, id))
    );
    console.log(d, "=====---");
    setData(d);
  };
  function GetSortOrder(prop) {
    return function (a, b) {
      if (a[prop] > b[prop]) {
        return 1;
      } else if (a[prop] < b[prop]) {
        return -1;
      }
      return 0;
    };
  }

  const filtertolowhigh = async () => {
    const data = {
      business_nature: "Manufacturer",
    };
    await axios
      .post(BASE_URL + "/getsellerdataasc", data)
      .then(({ data }) => {
        setData(data.data);
        setDatas(data.data);
        setcategorys(data.category);
      })
      .catch(({ response }) => {
        console.log(response.data.message);
      });
  };
  const filterhightolow = async () => {
    const data = {
      business_nature: "Manufacturer",
    };
    await axios
      .post(BASE_URL + "/getsellerdatadesc", data)
      .then(({ data }) => {
        setData(data.data);
        setDatas(data.data);
        setcategorys(data.category);
      })
      .catch(({ response }) => {
        console.log(response.data.message);
      });
  };

  let scrollRight = () => {
    var container = document.querySelector(".right-top-btns-div");
    sideScroll(container, "right", 25, 100, 10);
  };

  let scrollLeft = () => {
    var container = document.querySelector(".right-top-btns-div");
    sideScroll(container, "left", 25, 100, 10);
  };

  function sideScroll(element, direction, speed, distance, step) {
    let scrollAmount = 0;
    var slideTimer = setInterval(function () {
      if (direction == "left") {
        element.scrollLeft -= step;
      } else {
        element.scrollLeft += step;
      }
      scrollAmount += step;
      if (scrollAmount >= distance) {
        window.clearInterval(slideTimer);
      }
    }, speed);
  }
  const truncateText = (htmlString, wordLimit) => {
    // Strip HTML tags
    const div = document.createElement("div");
    div.innerHTML = htmlString;
    const textContent = div.textContent || div.innerText || "";

    // Truncate to the specified number of words
    const words = textContent.split(" ").slice(0, wordLimit).join(" ");

    // Re-add HTML tags (ensure valid truncation)
    const truncatedHTML = textContent.length > words.length ? `${words}...` : words;

    return truncatedHTML;
  };

  return (
    <>
      <div className="mt-3" style={{ background: "" }}>
        <div className="menu-cont">
          <div className="row">
            <div className="col-md-6">
              <Carousel
                showThumbs={false}
                autoPlay={true}
                interval={2500}
                showStatus={false}
                showArrows={false}
                showIndicators={false}
                infiniteLoop={true}
              >
                {slider.map((data) => (
                  <div>
                    <img
                      src="/assets/images/banner_interiordesigners 1.png"
                      height={230}
                    />
                  </div>
                ))}
              </Carousel>
            </div>
            <div className="col-md-6 row">
              <div className="col-md-3">
                <div className="banner-card-img">
                  <img src="/assets/images/Frame 2.png" />
                </div>
              </div>
              <div className="col-md-3">
                <div className="banner-card-img">
                  <img src="/assets/images/Frame 3.png" />
                </div>
              </div>
              <div className="col-md-3">
                <div className="banner-card-img">
                  <img src="/assets/images/Frame 4.png" />
                </div>
              </div>
              <div className="col-md-3">
                <div className="banner-card-img">
                  <img src="/assets/images/Frame 5.png" />
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="manufactures-section directory-listing">
          {/* Cards Section */}
          <div>
            <div className="row">
              {/* left side ----------- */}

              <div className="col-md-3 px-0  left bg-white">
                <div className="side-head-blk mb-1" >
                  <h4 ><RiFilterLine style={{ marginTop: "-6px" }} /> Filter By</h4>

                  <h4><IoIosArrowForward /></h4>

                </div>
                <Accordion className="header-accordian mt-2 mb-2 p-1">
                  <Accordion.Item eventKey="0" flush>
                    <Accordion.Header className="py-1">
                      Seller By Preference
                    </Accordion.Header>
                    <Accordion.Body>
                      <Form className="p-2">

                        <div className="form-group form-check customcheckbox">
                          <input type="checkbox" className="form-check-input" />
                          <label className="form-check-label" >
                            Most Preferred
                            <span>(0)</span>
                          </label>

                        </div>
                        <div className="form-group form-check customcheckbox">
                          <input type="checkbox" className="form-check-input" />
                          <label className="form-check-label" >
                            Verified Tags
                            <span>(0)</span>
                          </label>

                        </div>
                        <div className="form-group form-check customcheckbox">
                          <input type="checkbox" className="form-check-input" />
                          <label className="form-check-label" >
                            Trusted Tags
                            <span>(0)</span>
                          </label>

                        </div>
                        <div className="form-group form-check customcheckbox">
                          <input type="checkbox" className="form-check-input" />
                          <label className="form-check-label" >
                            Premium Seller
                            <span>(0)</span>
                          </label>

                        </div>


                      </Form>


                    </Accordion.Body>
                  </Accordion.Item>
                  <Accordion.Item eventKey="1" flush>
                    <Accordion.Header className="py-1">
                    Ratings
                    </Accordion.Header>
                    <Accordion.Body>
                      <Form className="p-2">

                        <div className="form-group form-check customcheckbox">
                          <input type="checkbox" className="form-check-input" />
                          <label className="form-check-label"  style={{color:"#ffb800", fontSize:"20px"}}>
                          <IoMdStar  style={{marginTop:"-10px"}}/>
                            
                          </label>

                        </div>
                        <div className="form-group form-check customcheckbox">
                          <input type="checkbox" className="form-check-input" />
                          <label className="form-check-label" style={{color:"#ffb800", fontSize:"20px"}}>
                          <IoMdStar  style={{marginTop:"-10px"}}/> <IoMdStar  style={{marginTop:"-10px"}}/>
                          </label>

                        </div>
                        <div className="form-group form-check customcheckbox">
                          <input type="checkbox" className="form-check-input" />
                          <label className="form-check-label" style={{color:"#ffb800", fontSize:"20px"}}>
                          <IoMdStar  style={{marginTop:"-10px"}}/> <IoMdStar  style={{marginTop:"-10px"}}/> <IoMdStar  style={{marginTop:"-10px"}}/>
                          </label>

                        </div>
                        <div className="form-group form-check customcheckbox">
                          <input type="checkbox" className="form-check-input" />
                          <label className="form-check-label" style={{color:"#ffb800", fontSize:"20px"}}>
                          <IoMdStar  style={{marginTop:"-10px"}}/> <IoMdStar  style={{marginTop:"-10px"}}/> <IoMdStar  style={{marginTop:"-10px"}}/> <IoMdStar  style={{marginTop:"-10px"}}/>
                          </label>

                        </div>
                        <div className="form-group form-check customcheckbox">
                          <input type="checkbox" className="form-check-input" />
                          <label className="form-check-label" style={{color:"#ffb800", fontSize:"20px"}}>
                          <IoMdStar  style={{marginTop:"-10px"}}/> <IoMdStar  style={{marginTop:"-10px"}}/> <IoMdStar  style={{marginTop:"-10px"}}/> <IoMdStar  style={{marginTop:"-10px"}}/> <IoMdStar  style={{marginTop:"-10px"}}/>
                          </label>

                        </div>


                      </Form>


                    </Accordion.Body>
                  </Accordion.Item>
                  <Accordion.Item eventKey="2" flush>
                    <Accordion.Header className="py-1">
                    Availability
                    </Accordion.Header>
                    <Accordion.Body>
                      <Form className="p-2">

                        <div className="form-group form-check customcheckbox">
                          <input type="checkbox" className="form-check-input" />
                          <label className="form-check-label" >
                          Online
                            
                          </label>

                        </div>
                        <div className="form-group form-check customcheckbox">
                          <input type="checkbox" className="form-check-input" />
                          <label className="form-check-label" >
                            Offline
                            
                          </label>

                        </div>
                        


                      </Form>


                    </Accordion.Body>
                  </Accordion.Item>
                </Accordion>
                <div className="side-head-blk mb-1 mt-3" >
                  <h4 ><BiCategory  style={{ marginTop: "-5px", marginRight:"5px" }} />All Categories</h4>

                  <h4><IoIosArrowForward /></h4>

                </div>
                <Accordion defaultActiveKey="0">
                  {categoryss &&
                    categoryss.map((item, i) => (
                      <Accordion.Item eventKey={i}>
                        <Accordion.Header>  {item.name}</Accordion.Header>
                        <Accordion.Body>
                          {item?.childrens?.map((item, k) => (
                            <>
                              <div className=" sub-cat d-flex justify-content-between align-items-center" key={k} onClick={() => toggleShow(i, k)}>
                                <input type="checkbox" id={item.slug} value={item.id} onChange={(e) => setChecked(e)} />
                                <label htmlFor={item.slug}>
                                  <span>{item.name}</span> <span>{`(${item.product_count})`} </span>
                                </label>
                              </div>
                              {/* sub sub categories */}
                              {item.childrens_count > 0 && (
                                <div className={`subs-cat px-lg-3 px-2 py-2 showhide${i}${k}`} style={{ display: "none" }}>
                                  {item.childrens_count > 0 && item.childrens.map((itemch, k) => (
                                    <div className=" sub-cat d-flex justify-content-between align-items-center">
                                      <input type="checkbox" id={itemch.slug} value={itemch.id} onChange={(e) => setChecked(e)} />
                                      <label htmlFor={itemch.slug}>
                                        <span>{item.name}</span>
                                      </label>
                                    </div>
                                  ))}

                                </div>
                              )}

                            </>

                          ))}


                        </Accordion.Body>
                      </Accordion.Item>

                    ))}

                </Accordion>

                {/* <div
                  class="accordion border-0"
                  id="accordionPanelsStayOpenExample" >
                  {categoryss &&
                    categoryss.map((item, i) => (
                      <div class="accordion-item" key={i}>
                        <h2 class="accordion-header">
                          <button
                            class="accordion-button collapsed bg-white"
                            type="button"
                            data-bs-toggle="collapse"
                            data-bs-target={"#panelsStayOpen-collapse" + i}
                            aria-expanded="false"
                            aria-controls={"panelsStayOpen-collapse" + i}
                          >
                            {item.name}
                          </button>
                        </h2>
                        <div
                          id={"panelsStayOpen-collapse" + i}
                          class="accordion-collapse collapse"
                        >
                          <div class="accordion-body">
                            {item?.childrens?.map((item, k) => (
                              <>
                                <div className=" sub-cat d-flex justify-content-between align-items-center" key={k} onClick={() => toggleShow(i, k)}>
                                  <input type="checkbox" id={item.slug} value={item.id} onChange={(e) => setChecked(e)} />
                                  <label htmlFor={item.slug}>
                                    <span>{item.name}</span> <span>{`(${item.product_count})`} </span>
                                  </label>
                                </div>
                                
                                {item.childrens_count > 0 && (
                                  <div className={`subs-cat px-lg-3 px-2 py-2 showhide${i}${k}`} style={{ display: "none" }}>
                                    {item.childrens_count > 0 && item.childrens.map((itemch, k) => (
                                      <div className=" sub-cat d-flex justify-content-between align-items-center">
                                        <input type="checkbox" id={itemch.slug} value={itemch.id} onChange={(e) => setChecked(e)} />
                                        <label htmlFor={itemch.slug}>
                                          <span>{item.name}</span>
                                        </label>
                                      </div>
                                    ))}



                                  </div>
                                )}

                              </>

                            ))}




                          </div>
                        </div>
                      </div>
                    ))}


                </div> */}

                <div className="manufactures-froms">
                  <form id="formdata">
                    <h1>Get a Hassle Free Callback from Experts!</h1>
                    <Form.Group className="mb-3">
                      <Form.Control
                        placeholder="Full Name"
                        onChange={(e) => setCallbackName(e.target.value)}
                      />
                      {errors && errors.name ? (
                        <div className="text-danger">{errors.name}</div>
                      ) : (
                        ""
                      )}
                    </Form.Group>
                    <Form.Group className="mb-3" controlId="formBasicEmail">
                      <Form.Control
                        type="number"
                        placeholder="Mobile Number"
                        onChange={(e) => setCallbackMobile(e.target.value)}
                      />
                      {errors && errors.mobile_number ? (
                        <div className="text-danger">
                          {errors.mobile_number}
                        </div>
                      ) : (
                        ""
                      )}
                    </Form.Group>
                    <Form.Group className="mb-3" controlId="formBasicEmail">
                      <Form.Control
                        type="email"
                        placeholder="Email Id"
                        onChange={(e) => setCallbackEmail(e.target.value)}
                      />
                      {errors && errors.email ? (
                        <div className="text-danger">{errors.email}</div>
                      ) : (
                        ""
                      )}
                    </Form.Group>
                    <Form.Group className="mb-3">
                      <Form.Select
                        onChange={(e) => setInterested(e.target.value)}
                      >
                        <option>select category</option>
                        {category?.map((item, index) => (
                          <option key={item.value} value={item.value}>
                            {item.label}
                          </option>
                        ))}
                      </Form.Select>
                    </Form.Group>
                    <Form.Group className="mb-3">
                      <Form.Select
                        onChange={(e) => setInterested(e.target.value)}
                      >
                        <option>select subcategory</option>
                        {subCategory?.map((item, index) => (
                          <option value={item.value}>{item.label}</option>
                        ))}
                      </Form.Select>
                    </Form.Group>
                    <Form.Group className="form-group">
                      <Form.Control
                        as="textarea"
                        placeholder="Enter Detail"
                        rows={3}
                        onChange={(e) => setDetails(e.target.value)}
                      />
                    </Form.Group>
                    {errors && errors.details ? (
                      <div className="text-danger">{errors.details}</div>
                    ) : (
                      ""
                    )}
                    {/* <FloatingLabel
                                        controlId="floatingTextarea"
                                        label="Enter Detail"
                                        className="mb-3"
                                    >
                                        <Form.Control as="textarea" placeholder="Leave a comment here" onChange={(e) => setDetails(e.target.value)} />
                                       
                                    </FloatingLabel> */}
                  </form>

                  <button className="form-submit-btn" onClick={Addfreecallback}>
                    SUBMIT
                  </button>
                </div>

                <div className="manufactures-froms">
                  <h1>Want to be a Seller? Quick Callback from Experts</h1>
                  <Form id="sellerform">
                    <Form.Group className="mb-3" controlId="formBasicEmail">
                      <Form.Control
                        type="text"
                        placeholder="Full Name"
                        onChange={(e) => setSellerName(e.target.value)}
                      />
                      {err && err.name ? (
                        <div className="text-danger">{err.name}</div>
                      ) : (
                        ""
                      )}
                    </Form.Group>
                    <Form.Group className="mb-3" controlId="formBasicEmail">
                      <Form.Control
                        type="number"
                        placeholder="Mobile Number"
                        onChange={(e) => setSellerMobile(e.target.value)}
                      />
                      {err && err.mobile_number ? (
                        <div className="text-danger">{err.mobile_number}</div>
                      ) : (
                        ""
                      )}
                    </Form.Group>
                    <Form.Group className="mb-3" controlId="formBasicEmail">
                      <Form.Control
                        type="email"
                        placeholder="Email Id"
                        onChange={(e) => setSellerEmail(e.target.value)}
                      />
                      {err && err.email ? (
                        <div className="text-danger">{err.email}</div>
                      ) : (
                        ""
                      )}
                    </Form.Group>
                    <Form.Group className="mb-3">
                      <Form.Select
                        onChange={(e) => setBusinessNature(e.target.value)}
                      >
                        <option value="">Nature of Business</option>
                        <option value="Manufacturer">Manufacturer</option>
                        <option value="Trader">Trader</option>
                        <option value="Wholeseller">Wholeseller</option>
                        <option value="Distributor">Distributor</option>
                        <option value="Service Provider">
                          Service Provider
                        </option>
                        <option value="Importer & Exporter">
                          Importer & Exporter
                        </option>
                      </Form.Select>
                      {err && err.nature_of_business ? (
                        <div className="text-danger">
                          {err.nature_of_business}
                        </div>
                      ) : (
                        ""
                      )}
                    </Form.Group>
                    <Form.Group className="mb-3">
                      <Form.Select
                        onChange={(e) => {
                          setCountry(e.target.value);
                          getState(e.target.value);
                        }}
                      >
                        <option>Select Country</option>
                        {fetchCountry &&
                          fetchCountry.map((data) => (
                            <option value={data.id}>{data.name}</option>
                          ))}
                      </Form.Select>
                      {err && err.country ? (
                        <div className="text-danger">{err.country}</div>
                      ) : (
                        ""
                      )}
                    </Form.Group>
                    <Form.Group className="mb-3">
                      <Form.Select
                        onChange={(e) => {
                          setState(e.target.value);
                          getCity(e.target.value);
                        }}
                      >
                        <option>Select State</option>
                        {fetchState &&
                          fetchState.map((data) => (
                            <option value={data.id}>{data.name}</option>
                          ))}
                      </Form.Select>
                      {err && err.state ? (
                        <div className="text-danger">{err.state}</div>
                      ) : (
                        ""
                      )}
                    </Form.Group>
                    <Form.Group className="mb-3">
                      <Form.Select onChange={(e) => setCity(e.target.value)}>
                        <option>Select City</option>
                        {fetchCity &&
                          fetchCity.map((data) => (
                            <option value={data.id}>{data.name}</option>
                          ))}
                      </Form.Select>
                      {err && err.city ? (
                        <div className="text-danger">{err.city}</div>
                      ) : (
                        ""
                      )}
                    </Form.Group>
                    <Form.Group className="form-group">
                      <Form.Control
                        as="textarea"
                        placeholder="Enter Detail"
                        rows={3}
                        onChange={(e) => setSellerDetails(e.target.value)}
                      />
                    </Form.Group>
                    {err && err.details ? (
                      <div className="text-danger">{err.details}</div>
                    ) : (
                      ""
                    )}
                    {/* <FloatingLabel
                                        controlId="floatingTextarea"
                                        label="Detail"
                                        className="mb-3 manufactures-textarea"
                                    >
                                        <Form.Control as="textarea" placeholder="Leave a comment here" onChange={(e) => setSellerDetails(e.target.value)} />
                                    </FloatingLabel>
                                    {err && err.details ? <div className="text-danger">{err.details}</div> : ''} */}
                  </Form>
                  <button
                    className="form-submit-btn"
                    onClick={Addsellercallback}
                  >
                    SUBMIT
                  </button>
                </div>
              </div>

              {/* Right side  */}
              <div className="col-md-9 right">
                <div className="right-top-btns d-flex gap-2 px-1 py-2 mx-2 bg-white">
                  <button onClick={scrollLeft} className=" px-2 py-1">
                    <i class="fa fa-chevron-left"></i>
                  </button>
                  <div className="right-top-btns-div overflow-auto d-flex  gap-2">
                    {businessnature1 &&
                      businessnature1?.map((item, i) => (
                        <Link to={`/directory-listing/${item.title}`}>
                        <button
                          // onClick={() => {
                          //   ManufacturerData(item.slug);
                          //   setclicked(i);
                          //   setslugurl(item.slug)
                          // }}
                          className={slug == item.title ? "active-tbtn-mf" : ""}
                          key={i}
                        >
                          {item.title}
                        </button>
                        </Link>
                      ))}
                    
                  </div>
                  <button onClick={scrollRight} className=" px-2 py-1">
                    <i class="fa fa-chevron-right"></i>
                  </button>
                </div>
                <div className="serchbar-div px-2">
                  <DirectoryGridContainer
                    title="All Products"
                    products={products}
                    productCount={data?.length + " sellers"}
                    setSortBy={setSortBy}
                    setlocaion={setlocaion}
                  />
                </div>
                <div>
                  {data &&
                    data.map((item, i) => (
                      <div className="row partners-cards" key={i}>
                        <div
                          className="col-md-3 "
                          onClick={() => updatesellerview(item.id)}
                        >
                          <div className="company_logo">
                            <Link to={"/seller-detail/" + item.business_slug}>
                              {" "}
                              <img src={item.logo} />
                            </Link>
                          </div>
                        </div>
                        <div className="col-md-9">
                          <div className="d-flex flex-column justify-content-between" style={{ height: "100%" }}>
                            <div>
                              <div className="d-flex justify-content-between">
                                <Link
                                  to={"/seller-detail/" + item.business_slug}
                                  onClick={() => updatesellerview(item.id)}
                                >
                                  <h6 className="m-0" style={{ minHeight: "30px" }}>

                                    {item.business_name}
                                    <MdOutlineVerified style={{ marginLeft: "5px", color: "green", fontWeight: "400", marginTop: "-5px", fontSize: "18px" }} />
                                    {/* <img src={thumbup} /> */}
                                  </h6>
                                </Link>
                                <div className="d-flex">
                                  {item.trust_seal == "Yes" && (
                                    <div>
                                      <img src="/assets/images/trust.png" style={{ width: "65px" }} />
                                    </div>
                                  )}
                                  {item.verified_seller == "Yes" && (
                                    <div>
                                      <img src="/assets/images/verify.png" style={{ width: "65px" }} />
                                    </div>
                                  )}
                                  <div className="shop-types" >

                                    <button style={{ background: "green", color: "#ffffff", height: "20px", padding: "0px 5px", borderRadius: "2px", marginLeft: "10px" }}>Online</button>

                                  </div>
                                </div>
                              </div>
                              <div className="rating-section">
                                {/* <p>{item.rating}</p> */}
                                <ReactStars
                                  count={5}
                                  onChange={ratingChanged}
                                  size={24}
                                  color2={"#ffd700"}
                                  className="rating-stars"
                                  value={item.rating}
                                  edit={false}
                                />
                                <p>({item.rating} Reviews)</p>


                              </div>
                              <p
                                className="company-list-discription"
                                style={{ color: "#000" }}
                                dangerouslySetInnerHTML={{
                                  __html: truncateText(item.introduction, 30),
                                }}
                              ></p>


                              <div className="shop-details">
                                <h3 className="opened-year">
                                  <FaBusinessTime /> {item.business_nature}
                                </h3>


                                <h3 className="opened-year">
                                  <SiScpfoundation /> Estd. {item.estblish_year &&
                                    new Date().getUTCFullYear() -
                                    item.estblish_year +
                                    " Years"}
                                </h3>
                                {filterdata(item.business_opening) && (
                                  <h3>
                                    {/* <span style={{ marginRight: "30px" }}>
                                      
                                    </span> */}
                                    {filterdata(item.business_opening).status ==
                                      "open" && (
                                        <><CiClock2 style={{ marginRight: "5px" }} />
                                          {filterdata(item.business_opening).in_time}{" "}
                                          to{" "}
                                          {filterdata(item.business_opening).out_time}
                                          <span style={{ marginLeft: "10px" }}>({filterdata(item.business_opening).status})</span>
                                        </>
                                      )}{" "}
                                  </h3>
                                )}

                              </div>
                              <h2><CiLocationOn /> {item.full_address}</h2>
                              <div className="shop-types">
                                {item.category.map((data) => (
                                  <button>{data}</button>
                                ))}
                              </div>

                              <div>

                              </div>
                            </div>
                            <div className="jio-mart-partner">
                              <div className="startup-deal-btn" style={{ width: "72%" }}>
                                <Link to={`https://api.whatsapp.com/send?phone= +${item.whatsapp_number}`}>
                                  <button className="raise-capital"><IoChatbubblesOutline /> Chat Now </button>
                                </Link>
                                <Link to={`"mailto:" + ${item.business_email}`}>
                                  <button className="raise-capital"><MdOutlineEmail /> Send E-mail</button>
                                </Link>
                                <Link to={`"tel:" + ${item.business_mobilenumber}`}>
                                  <button className="raise-capital"><FaRegEye /> Show Contact</button>
                                </Link>
                              </div>

                            
                              <div>
                                {/* <h3 className="growth-pera"><img src="/assets/images/growth-icon.png" />100 Customer Inquired this profile in last 30 Days</h3> */}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    ))}
                </div>
                <ToastContainer />
              </div>
            </div>

            {/* Preferred Sellers */}

            <ProfessionalClient
              data={sellerdata}
              heading={"Preferred Sellers"}
            />
          </div>
        </div>
      </div>
    </>
  );
};
