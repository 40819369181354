import React, { useContext, useState, useEffect } from "react";
import ReactOwlCarousel from 'react-owl-carousel';
import { Carousel } from 'react-responsive-carousel';
import { Link } from 'react-router-dom';
import StateContext from '../../context/StateContext';
import ProductStarRating from '../product-star/product-star-rating.component';

const   FeaturedProductContainer = () => {
  const { currencysymbol,featuredProducts } = useContext(StateContext);
  // const { currencysymbol } = useContext(StateContext);
  useEffect(()=>{
    getProductList();
  },[currencysymbol]);
  const getProductList = () => {
   
    let products = [];
    for (let index = 0; index < featuredProducts.length; index += 2) {
      const featuredProductEven = featuredProducts[index];
      const featuredProductOdd = featuredProducts[index + 1];
      products.push(
        <div className="item" key={index}>
          <div className="feature-pro-blk shadow-sm">
            <Link to={`/product/${featuredProductEven && featuredProductEven.slug}`}>
            {/* <Link to={`/comming-soon`} > */}
              <div className="feat-pro-img">
                <img src={featuredProductEven.thumbnail ? featuredProductEven.thumbnail : 'assets/images/image-11.jpg'} alt={featuredProductEven.name} />
              </div>
              <div className="fea-pro-cont">
                <h3>{featuredProductEven.name}</h3>
                <ProductStarRating rating={featuredProductEven.rating} />
                <div className="currency-section">
                <p className="currency-symbol-offer">{currencysymbol} {featuredProductEven.base_discounted_price}</p>&nbsp;
                <p className="currency-symbol-price">{currencysymbol} {featuredProductEven.base_discounted_price}</p>
                </div>
              </div>
            </Link>
          </div>
          <div className="feature-pro-blk">
            <Link to={`/product/${featuredProductOdd && featuredProductOdd.slug}`}>
            {/* <Link to={`/comming-soon`} > */}
              <div className="feat-pro-img">
                <img src={featuredProductOdd ? featuredProductOdd.thumbnail : 'assets/images/image-11.jpg'} alt={featuredProductOdd && featuredProductOdd.name} />
              </div>
              <div className="fea-pro-cont">
                <h3>{featuredProductOdd && featuredProductOdd.name}</h3>
                <ProductStarRating rating={featuredProductOdd && featuredProductEven.rating} />
                <div className="currency-section">
                <p className="currency-symbol-offer">{currencysymbol} {featuredProductEven.saleprice1}</p>&nbsp;
                <p className="currency-symbol-price">{currencysymbol} {featuredProductEven.unit_price}</p>
                </div>
              </div>
            </Link>
          </div>
        </div>
      );
    }
    return products;
  };
  const feature_options = {
    margin: 20,
    responsiveClass: true,
    nav: true,
    dots: false,
    autoplay: false,
    loop: true,
    responsive: {
      0: {
        items: 2,
      },
      600: {
        items: 3,
      },
      1000: {
        items: 4,
      },
    },
  };
  return (
    <>
    {/* <Carousel>
    {getProductList()}
    </Carousel> */}
    <ReactOwlCarousel className="owl-theme owl-feature-slider" {...feature_options}>
      {getProductList()}
    </ReactOwlCarousel>
    </>
  );
};

export default FeaturedProductContainer;
