import React, { useContext, useState, useEffect } from "react";
import Category from "./Components/Category";
import Adds from "../products/Components/Adds";
import BannerPage from "../apna-shaher/components/Banner";
import SecondSection from "../products/Components/SecondSection";
import ThirdSection from "../products/Components/ThirdSection";
import Professional from "./Components/Professional";
import Digital from "./Components/Digital";
import Discover from "./Components/Discover";
import ProfessionalClient from "./Components/ProfessionalClient";
import ProfessionalServices from "./Components/ProfessionalServices";
import RecentView from "../products/Components/RecentView";
import ServicesAdds from "./Components/ServicesAdds";
import ServicesAdvertize from "./Components/ServicesAdvertize";
import { Itservices } from "./Components/Itservices";
import axios from "axios";
import { BASE_URL } from "../../config";
import { BannerNew } from "../apna-shaher/components/BannerNew";
import SeoServicePage from "../../seo page/SeoServicepage";
import UpdatedBannerPage from "../apna-shaher/components/Updatedbanner";
import TwoBannerSection from "../TwoBannerSection";
import StateContext from "../../context/StateContext";
import { Link } from "react-router-dom";
const Services = () => {
  const [pdata, setpdata] = useState([]);
  const [premdata, setpremdata] = useState([]);
  const [searchdata, setsearchdata] = useState([]);
  const [sellerdata, setsellerdata] = useState([]);
  const [servicedata, setservicedata] = useState([]);


  const [allwidget, setAllwidget] = useState([]);
  const widgetData = async () => {
      try {
          const wgtData = await axios.get(BASE_URL + "/homepagewidget");
          setAllwidget(wgtData.data.data)
      }
      catch (err) {
          console.log(err)
      }
  }

 

  useEffect(() => {
      widgetData()

  }, [])

  useEffect(() => {
    window.scrollTo(0, 0);
    getservices();
  }, [])

  useEffect(() => {
    const timer = setTimeout(() => {
      getsellerservicedata();
    }, 3000);
    return () => clearTimeout(timer);
  }, [])

  const getservices = () => {
    axios
      .get(BASE_URL + "/popularcategoriesservices")
      .then(({ data }) => {
        setpdata(data.data);


      })
      .catch(({ response }) => { });
    axios
      .get(BASE_URL + "/premiumcategoriesservices")
      .then(({ data }) => {
        setpremdata(data.data);


      })
      .catch(({ response }) => { });
    axios
      .get(BASE_URL + "/servicecategorysearch")
      .then(({ data }) => {
        setsearchdata(data.data);


      })
      .catch(({ response }) => { });
    axios
      .get(BASE_URL + "/getcategorywithservice")
      .then(({ data }) => {
        setservicedata(data.data);


      })
      .catch(({ response }) => { });

  }
  const getsellerservicedata = () => {
    axios
      .get(BASE_URL + "/getsellerservicedata")
      .then(({ data }) => {
        setsellerdata(data);


      })
      .catch(({ response }) => { });
  }

  const {
    alldata,
    currencysymbol,
    homepageSetting,
    categories,
    topSellingProducts,
    recentlyViewedProducts,
    productSuggestions,
    countrycity,
    locationdata,
  } = useContext(StateContext);
  return (
    <>
      <SeoServicePage />
      <div className="show-desktop-banner">
      <div className="new-startup-top-banner" style={{ background: "url(/assets/images/s1.jpg)" }}>
                    <div className="startup-right-content-banner">
                        <h1>Connecting Service Providers with Customers – A Platform for Growth!</h1>
                        <p>Whether you're offering services or seeking them, our platform bridges the gap to help you succeed and thrive together.</p>
                        <div className="startup-deal-btn" style={{ width: "100%" }}>
                          <Link to={'/all-services-category'}>
                           {/* <Link to={`/comming-soon`} > */}
                            <button className="raise-capital">Show All Services</button>
                            </Link>
                        </div>
                    </div>
                </div>
                <div className="banner-bottom-part mt-5">
                    <div className="" dangerouslySetInnerHTML={{ __html: allwidget.widget1 }}></div>
                </div>
        {/* <UpdatedBannerPage data={searchdata} /> */}
      </div>

      <div className="show-mobile-banner">
      <div className="new-startup-top-banner" style={{ background: "url(/assets/images/s1.jpg)" }}>
                    <div className="startup-right-content-banner">
                        <h1>Connecting Service Providers with Customers – A Platform for Growth!</h1>
                        <p>Whether you're offering services or seeking them, our platform bridges the gap to help you succeed and thrive together.</p>
                        <div className="startup-deal-btn" style={{ width: "100%" }}>
                          <Link to={'/all-services-category'}>
                           {/* <Link to={`/comming-soon`} > */}
                            <button className="raise-capital mobile-btn">Show All Services</button>
                            </Link>
                        </div>
                    </div>
                </div>
                <div className="banner-bottom-part mt-5">
                    <div className="" dangerouslySetInnerHTML={{ __html: allwidget.widget1 }}></div>
                </div>
      </div>
      {/* <SecondSection />
            <ThirdSection /> */}
      <Professional />

      <Digital data={pdata} heading="Popular Services" />
      {servicedata && servicedata.map((item, i) => (
        <>
          <ServicesAdds />

          <Itservices heading={item.name} data={item} />
        </>
      ))}
      {/* <ServicesAdds />
            <Itservices heading = {"IT Services"}/>
            <Itservices heading = {"Customer Services"}/>
            <Itservices heading = {"Graphic Design and Multimedia Services"}/>
            <Discover />
            <Itservices heading = {"Media and Entertainment Services"}/>
            <Itservices heading = {"E-commerce Services"}/>
            <Itservices heading = {"Statistics Services"}/>
            <ServicesAdds />
            <Itservices heading = {"Gaming and Casino Management Services"}/> */}
      <ServicesAdvertize />

      <Itservices data={pdata} heading={"Animation Services"} />
      <ProfessionalServices data={premdata} />
      <Itservices data={pdata} heading={"Professional Services"} />
      {/* <Discover /> */}
      {/* <Adds /> */}
      {/* <ServicesAdds /> */}
      <TwoBannerSection homepageSetting={homepageSetting} />
      <Itservices data={pdata} heading={"Financial Services"} />
      <ProfessionalClient data={sellerdata} heading="Preferred Sellers" />

      {/* <Itservices heading={"Recently Viewed"} /> */}
      <RecentView />
      {/* <Category />
           <Adds />
           <Category /> */}
    </>
  )
}

export default Services;