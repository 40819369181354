
// import 'bootstrap/dist/css/bootstrap.min.css';
// import { NavLink } from 'react-router-dom';

// import Page from '../components/Page';
// import Loader from '../components/loader/Loader.component';
// import FaqContainer from '../components/faq/faq-container.component';
// import React, { useState, useEffect } from "react";
// import Accordion from 'react-bootstrap/Accordion';
// import axios from "axios";
// import { BASE_URL } from "../config";
// import { Link } from "react-router-dom";
// import { FaArrowRightLong } from "react-icons/fa6";

// const Faq = () => {
//   const currentUrl = window.location.href;
//   const urlSegments = currentUrl.split('/');
//   const lastSegment = urlSegments[urlSegments.length - 1];
//   let faq_type;
//   if (lastSegment === "seller-faq") {
//     faq_type = "For Seller";
//   } else {
//     faq_type = "For Buyers";
//   }

//   const [faqs, setfaqs] = useState([]);
//   const [active, setactive] = useState(0);
//   useEffect(() => {
//     Fetchfaqs();
//   }, [])
//   const Fetchfaqs = async () => {
//     await axios.post(BASE_URL + "/faq", { faq_type })
//       .then(({ data }) => {
//         setfaqs(data.data.faqs);
//       });
//   };
//   const [data, setData] = useState(""); 
//   const [isLoading, setIsLoading] = useState(true); 

//   const widgetData = async () => {
//     try {
//       setIsLoading(true); 
//       const wgtData = await axios.get(BASE_URL + `/get-faq-types`);
//       setData(wgtData.data.data.types);
//       setIsLoading(false); 
//     } catch (err) {
//       console.log(err);
//       setIsLoading(false); 
//     }
//   };

//   useEffect(() => {
    
//       widgetData();
   
//   }, []);
//   console.log(data)
//   return (
//     <Page title="FAQ">
//       <div className="new-startup-top-banner" style={{ background: "url(/assets/images/faq.jpg)" }}>
//           <div className="startup-right-content-banner">
//             <h1>Have Questions? We’ve Got Answers!</h1>
//             <p>Explore our FAQ section to find clear and quick solutions to your queries. Get all the information you need about ApnaShaher.com in one place!</p>

//           </div>
//         </div>
//         <div className="faq-sb-content">
//         <div className="container d-flex justify-content-between gap-lg-4 gap-3 my-lg-5 my-md-4 my-3">
//           <div className="left-headings col-lg-3 bg-white">
//             <ul className="ps-0 d-flex flex-column ">
//               {data && data.map((data, i) => (
//                 <Link to="#" onClick={() => setactive(i)} className={i == active ? "active-heaidng" : ""} key={i}>
//                   <div className="d-flex justify-content-between">
//                   <li>{data.faq_type}</li>
//                   <li>{i == active ?<FaArrowRightLong />:null}</li>
//                   </div>
//                 </Link>
//               ))}


//             </ul>
//           </div>
//           {faqs && faqs.map((data, i) => (
//             <>
//               {i === active && (
//                 <div className="right-answers col-lg-8 bg-white">
//                   <div className="breadcrum  ">
//                     {/* <p>Faq &gt; Type of Issue &gt; Help with your issue</p> */}
//                     <h4 className="m-0" style={{fontSize:"26px", fontWeight:"600", borderBottom:"0px solid #DCDCDC", paddingBottom:"10px"}}>{data.title}</h4>
//                   </div>

//                   <Accordion className="border-0" defaultActiveKey="0">
//                   {data?.faqs.length > 0 && data?.faqs.map((item, k) => (
//                       <Accordion.Item className="" eventKey={k}>
//                       <Accordion.Header className="">{item.question}</Accordion.Header>
//                       <Accordion.Body dangerouslySetInnerHTML={{ __html: item.answer }}>
                    
//                       </Accordion.Body>
//                     </Accordion.Item>
//                    ))}                   
//                   </Accordion>

//                 </div>
//               )}
//             </>
//           ))}
//         </div>
//       </div>
     
//     </Page>
//   );
// };
// export default Faq;
import "bootstrap/dist/css/bootstrap.min.css";
import { Link } from "react-router-dom";
import Page from "../components/Page";
import Accordion from "react-bootstrap/Accordion";
import React, { useState, useEffect } from "react";
import axios from "axios";
import { BASE_URL } from "../config";
import { FaArrowRightLong } from "react-icons/fa6";

const Faq = () => {
  const [faqTypes, setFaqTypes] = useState([]); // Stores the FAQ types
  const [active, setActive] = useState(0); // Tracks the active FAQ type index
  const [faqs, setFaqs] = useState([]); // Stores the FAQs for the active type
  const [isLoading, setIsLoading] = useState(false); // Loader state

  // Fetch all FAQ types when the component mounts
  const fetchFaqTypes = async () => {
    try {
      const response = await axios.get(BASE_URL + "/get-faq-types");
      setFaqTypes(response.data.data.types);
    } catch (err) {
      console.error(err);
    }
  };

  // Fetch FAQs based on selected FAQ type
  const fetchFaqs = async (faqType) => {
    try {
      setIsLoading(true); // Start loader
      const response = await axios.post(BASE_URL + "/index-faqs", {
        faq_type: faqType,
      });
      setFaqs(response.data.data.faqs); // Set FAQ data
      setIsLoading(false); // Stop loader
    } catch (err) {
      console.error(err);
      setIsLoading(false); // Stop loader in case of error
    }
  };

  // Handle sidebar click and load respective FAQs
  const handleFaqClick = (index, faqType) => {
    setActive(index); // Update the active FAQ type index
    fetchFaqs(faqType); // Fetch FAQs for the clicked type
  };

  useEffect(() => {
    fetchFaqTypes(); // Fetch FAQ types on component mount
  }, []);

  useEffect(() => {
    if (faqTypes.length > 0) {
      // Fetch FAQs for the first type by default
      fetchFaqs(faqTypes[0].faq_type);
    }
  }, [faqTypes]);

  return (
    <Page title="FAQ">
      <div
        className="new-startup-top-banner"
        style={{ background: "url(/assets/images/faq.jpg)" }}
      >
        <div className="startup-right-content-banner">
          <h1>Have Questions? We’ve Got Answers!</h1>
          <p>
            Explore our FAQ section to find clear and quick solutions to your
            queries. Get all the information you need about ApnaShaher.com in
            one place!
          </p>
        </div>
      </div>
      <div className="faq-sb-content">
        <div className="container d-flex justify-content-between gap-lg-4 gap-3 my-lg-5 my-md-4 my-3 desktop-view-faq">
          {/* Sidebar */}
          <div className="left-headings col-lg-3 bg-white ">
            <ul className="ps-0 d-flex flex-column">
              {faqTypes &&
                faqTypes.map((type, i) => (
                  <Link
                    to="#"
                    onClick={() => handleFaqClick(i, type.faq_type)}
                    className={i === active ? "active-heaidng" : ""}
                    key={i}
                  >
                    <div className="d-flex justify-content-between">
                      <li>{type.faq_type}</li>
                      <li>{i === active ? <FaArrowRightLong /> : null}</li>
                    </div>
                  </Link>
                ))}
            </ul>
          </div>

          {/* FAQs Section */}
          <div className="right-answers col-lg-8 bg-white">
            {isLoading ? (
              <p>Loading...</p>
            ) : (
              faqs && (
                <>
                  <div className="breadcrum">
                    <h4
                      className="m-0"
                      style={{
                        fontSize: "26px",
                        fontWeight: "600",
                        borderBottom: "0px solid #DCDCDC",
                        paddingBottom: "10px",
                      }}
                    >
                      {faqTypes[active]?.faq_type || "FAQs"}
                    </h4>
                  </div>
                  <Accordion className="border-0" defaultActiveKey="0">
                    {faqs.length > 0 ? (
                      faqs.map((faq, k) => (
                        <Accordion.Item key={k} eventKey={k}>
                          <Accordion.Header>{faq.question}</Accordion.Header>
                          <Accordion.Body
                            dangerouslySetInnerHTML={{ __html: faq.answer }}
                          ></Accordion.Body>
                        </Accordion.Item>
                      ))
                    ) : (
                      <p>No FAQs found for this category.</p>
                    )}
                  </Accordion>
                </>
              )
            )}
          </div>
        </div>
        <div className="container d-flex justify-content-between gap-lg-4 gap-3 my-lg-5 my-md-4 my-3 mobile-view-faq">
          {/* Sidebar */}
          <div className="left-headings col-12 col-lg-3 bg-white ">
            <ul className="ps-0 d-flex flex-column">
              {faqTypes &&
                faqTypes.map((type, i) => (
                  <>
                  <Link
                    to="#"
                    onClick={() => handleFaqClick(i, type.faq_type)}
                    className={i === active ? "active-heaidng" : ""}
                    key={i}
                  >
                    <div className="d-flex justify-content-between">
                      <li>{type.faq_type}</li>
                      <li>{i === active ? <FaArrowRightLong /> : null}</li>
                    </div>
                  </Link>
                  {i== active? <div className="right-answers1 col-12 col-lg-8 bg-white">
                  {isLoading ? (
                    <p>Loading...</p>
                  ) : (
                    faqs && (
                      <>
                        <div className="breadcrum">
                          <h4
                            className="m-0"
                            style={{
                              fontSize: "26px",
                              fontWeight: "600",
                              borderBottom: "0px solid #DCDCDC",
                              paddingBottom: "10px",
                            }}
                          >
                            {faqTypes[active]?.faq_type || "FAQs"}
                          </h4>
                        </div>
                        <Accordion className="border-0" defaultActiveKey="0">
                          {faqs.length > 0 ? (
                            faqs.map((faq, k) => (
                              <Accordion.Item key={k} eventKey={k}>
                                <Accordion.Header>{faq.question}</Accordion.Header>
                                <Accordion.Body
                                  dangerouslySetInnerHTML={{ __html: faq.answer }}
                                ></Accordion.Body>
                              </Accordion.Item>
                            ))
                          ) : (
                            <p>No FAQs found for this category.</p>
                          )}
                        </Accordion>
                      </>
                    )
                  )}
                </div>:null}
                 
                </>
                ))}
            </ul>
            
          </div>

          {/* FAQs Section */}
          
        </div>
      </div>
    </Page>
  );
};

export default Faq;
